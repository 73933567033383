import { useUser } from 'hooks';
import { ErrorBoundary } from 'utils';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Containers
import ChangeContextMenu from 'containers/widgets/ChangeContextMenu';
import CustomTheme from 'containers/common/CustomTheme';
import DuePayments from 'pages/accounts/AccountDuePaymentPage/components/DuePayments';
import FamilyCartUser from 'containers/widgets/FamilyCartUser';
import CartProvider from 'containers/common/CartProvider';

// Components
import { Mui } from 'components';
import { Footer, TopBar } from 'components/layouts';

// Local Components
import NavBar from './NavBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    // eslint-disable-next-line no-useless-computed-key
    ['@media print']: {
      overflow: 'hidden',
    },
  },
}));

function AccountLayout({ children }) {
  // Hooks
  const { user } = useUser();
  const classes = useStyles();

  // States
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <CustomTheme>
      <CartProvider />
      <div className={classes.root}>
        <Mui.Box displayPrint="none">
          <TopBar homeTo="/families" user={user} onMobileNavOpen={() => setMobileNavOpen(true)}>
            <DuePayments />
            <FamilyCartUser type="fixed" />
            <ChangeContextMenu />
          </TopBar>
          <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
        </Mui.Box>
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content} id="content">
              <ErrorBoundary>{children}</ErrorBoundary>
              <Mui.Box mt={3} displayPrint="none">
                <Footer />
              </Mui.Box>
            </div>
          </div>
        </div>
      </div>
    </CustomTheme>
  );
}

AccountLayout.propTypes = {
  children: PropTypes.any,
};

export default AccountLayout;
