import { GoogleLogin } from 'react-google-login';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Mui, SocialButton } from '../..';
import envs from '../../../envs';

function Google({ onFailure, onSuccess }) {
  const [loading, setLoading] = useState(false);

  return (
    <GoogleLogin
      clientId={envs.GOOGLE_CLIENT_ID}
      onSuccess={response => {
        setLoading(false);
        onSuccess(response);
      }}
      onFailure={error => {
        setLoading(false);
        onFailure(error);
      }}
      cookiePolicy="single_host_origin"
      onRequest={() => setLoading(true)}
      render={({ onClick, disabled }) => (
        <>
          {loading ? (
            <center>
              <Mui.CircularProgress size={20} />
            </center>
          ) : (
            <SocialButton variant="google" disabled={disabled} onClick={onClick}></SocialButton>
          )}
        </>
      )}
    />
  );
}

Google.propTypes = {
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default Google;
