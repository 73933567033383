/*
 * ModelKnowledge Messages
 *
 * This contains all the text for the ModelKnowledge component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.widgets.ModelKnowledge';

export default defineMessages({
  helpTitle: {
    id: `${scope}.helpTitle`,
    defaultMessage: 'Ajuda',
  },
  helpSubheader: {
    id: `${scope}.helpSubheader`,
    defaultMessage: 'Dúvida sobre o que significa algum conceito?',
  },
  modelsButton: {
    id: `${scope}.modelsButton`,
    defaultMessage: 'Saber mais',
  },
  storeLabel: {
    id: `${scope}.storeLabel`,
    defaultMessage: 'Loja',
  },
  storeMessage: {
    id: `${scope}.storeMessage`,
    defaultMessage:
      'A sua instituição respeita os modelos de nível educacional, segmento e graus. Dentro de cada grau você pode criar lojas para configurar suas vitrines. É possível criar diversas lojas para cada grau disponível em sua instituição. Ativá-las ou desativá-las, de acordo com a necessidade da instituição.',
  },
  storeStep1Label: {
    id: `${scope}.storeStep1Label`,
    defaultMessage: 'Veja abaixo um exemplo da visualização de uma loja',
  },
  collectionLabel: {
    id: `${scope}.collectionLabel`,
    defaultMessage: 'Coleção',
  },
  collectionMessage: {
    id: `${scope}.collectionMessage`,
    defaultMessage:
      'As coleções agrupam diversos itens, com elas, podemos segmentar e facilitar o acesso para que as famílias encontrem facilmente os produtos e serviços que procuram. É possível criar diversas coleções, por exemplo, uma para material escolar e outra para livros.',
  },
  collectionStep1Label: {
    id: `${scope}.collectionStep1Label`,
    defaultMessage: 'Veja abaixo um exemplo da visualização das coleções',
  },
  showcaseLabel: {
    id: `${scope}.showcaseLabel`,
    defaultMessage: 'Vitrine',
  },
  showcaseMessage: {
    id: `${scope}.showcaseMessage`,
    defaultMessage:
      'Dentro de cada loja, você pode criar inúmeras vitrines. Assim será possível oferecer diferentes vitrines para diferentes famílias. Por exemplo, uma vitrine para os alunos do 2ºano do ensino fundamental e outra para o 3ºano do ensino médio. Com isso, poderá oferecer os produtos de acordo com as necessidades da família.',
  },
  showcaseStep1Label: {
    id: `${scope}.showcaseStep1Label`,
    defaultMessage: 'Veja abaixo um exemplo de visualização da vitrine',
  },
  itemLabel: {
    id: `${scope}.itemLabel`,
    defaultMessage: 'Prateleira',
  },
  itemMessage: {
    id: `${scope}.itemMessage`,
    defaultMessage:
      'As prateleiras são espaços onde a instituição irá disponibilizar produtos e agrupamentos de produtos. E para que eles fiquem disponíveis, é necessário vincular as prateleiras a pelo menos uma vitrine e uma coleção.',
  },
  itemStep1Label: {
    id: `${scope}.itemStep1Label`,
    defaultMessage: 'Veja abaixo um exemplo da visualização dos itens',
  },
});
