/*
 * Dialogs Messages
 *
 * This contains all the text for the Dialogs component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.common.Dialogs';

export default defineMessages({
  closeTooltip: {
    id: `${scope}.closeTooltip`,
    defaultMessage: 'Fechar',
  },
  closeButton: {
    id: `${scope}.closeButton`,
    defaultMessage: 'Fechar',
  },
  confirmButton: {
    id: `${scope}.confirmButton`,
    defaultMessage: 'Confirmar',
  },
  // ./Alert.js
  warningTitle: {
    id: `${scope}.warningTitle`,
    defaultMessage: 'Atenção',
  },
  infoTitle: {
    id: `${scope}.infoTitle`,
    defaultMessage: 'Informação',
  },
  successTitle: {
    id: `${scope}.successTitle`,
    defaultMessage: 'Feito',
  },
  errorTitle: {
    id: `${scope}.errorTitle`,
    defaultMessage: 'Erro',
  },
  // ./Confirmation.js
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Confirmar ação',
  },
});
