/*
 *
 * ThemeProvider ducks
 * Read more about this pattern: https://blog.rocketseat.com.br/estrutura-redux-escalavel-com-ducks/
 *
 */

import produce from 'immer';

// Constants

const app = 'app/common/ThemeProvider';

export const RETRIEVE_REQUEST = `${app}/RETRIEVE_REQUEST`;
export const RETRIEVE_SUCCESS = `${app}/RETRIEVE_SUCCESS`;
export const RETRIEVE_FAILURE = `${app}/RETRIEVE_FAILURE`;

export const DEFAULT_THEME = `${app}/DEFAULT_THEME`;
export const CHANGE_TYPE = `${app}/CHANGE_TYPE`;

// Actions

export function retrieveRequest(payload) {
  return { type: RETRIEVE_REQUEST, payload };
}

export function retrieveSuccess(payload) {
  return { type: RETRIEVE_SUCCESS, payload };
}

export function retrieveFailure(payload) {
  return { type: RETRIEVE_FAILURE, payload };
}

export function defaultTheme(payload) {
  return { type: DEFAULT_THEME, payload };
}

export function changeType(payload) {
  return { type: CHANGE_TYPE, payload };
}

// Reducer

export const initialState = {
  isFetching: {
    retrieve: false,
  },
  retrieve: {},
  activeTheme: '',
  type: 'light',
};

/* eslint-disable default-case, no-param-reassign */
export const themeProviderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RETRIEVE_REQUEST: {
        draft.isFetching.retrieve = true;
        break;
      }
      case RETRIEVE_SUCCESS: {
        const { institutionSlug, response } = action.payload;
        draft.isFetching.retrieve = false;
        draft.retrieve[institutionSlug] = response;
        draft.activeTheme = institutionSlug;
        break;
      }
      case RETRIEVE_FAILURE: {
        const { institutionSlug } = action.payload;
        draft.isFetching.retrieve = false;
        draft.retrieve[institutionSlug] = null;
        break;
      }
      case DEFAULT_THEME: {
        draft.activeTheme = '';
        break;
      }
      case CHANGE_TYPE: {
        const { type } = action.payload;
        draft.type = type || initialState.type;
        break;
      }
    }
  });

export default themeProviderReducer;
