/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.landing.Footer';

export default defineMessages({
  footerSection1Title: {
    id: `${scope}.footerSection1Title`,
    defaultMessage: 'Empresa',
  },
  footerSection1Item1: {
    id: `${scope}.footerSection1Item1`,
    defaultMessage: 'Por que a Eskolare',
  },
  footerSection1Item2: {
    id: `${scope}.footerSection1Item2`,
    defaultMessage: 'Segurança',
  },
  footerSection1Item3: {
    id: `${scope}.footerSection1Item3`,
    defaultMessage: 'Quem somos',
  },
  footerSection1Item4: {
    id: `${scope}.footerSection1Item4`,
    defaultMessage: 'Casos de sucesso',
  },
  footerSection2Title: {
    id: `${scope}.footerSection2Title`,
    defaultMessage: 'Plataforma',
  },
  footerSection2Item1: {
    id: `${scope}.footerSection2Item1`,
    defaultMessage: 'Para famílias',
  },
  footerSection2Item2: {
    id: `${scope}.footerSection2Item2`,
    defaultMessage: 'Para instituições',
  },
  footerSection2Item3: {
    id: `${scope}.footerSection2Item3`,
    defaultMessage: 'Para fornecedores',
  },
  footerSection3Title: {
    id: `${scope}.footerSection3Title`,
    defaultMessage: 'Relacionamento',
  },
  footerSection3Item1: {
    id: `${scope}.footerSection3Item1`,
    defaultMessage: 'Eventos',
  },
  footerSection3Item2: {
    id: `${scope}.footerSection3Item2`,
    defaultMessage: 'Webinars',
  },
  footerSection3Item3: {
    id: `${scope}.footerSection3Item3`,
    defaultMessage: 'Pol. de Cancelamento',
  },
  footerSection3Item4: {
    id: `${scope}.footerSection3Item4`,
    defaultMessage: 'Trocas e Devoluções',
  },
  footerSection3Item5: {
    id: `${scope}.footerSection3Item5`,
    defaultMessage: 'Pol. de Entrega',
  },
  footerSection4Title: {
    id: `${scope}.footerSection4Title`,
    defaultMessage: 'Recursos',
  },
  footerSection4Item1: {
    id: `${scope}.footerSection4Item1`,
    defaultMessage: 'Documentação',
  },
  footerSection4Item2: {
    id: `${scope}.footerSection4Item2`,
    defaultMessage: 'Integrações',
  },
  footerSection4Item3: {
    id: `${scope}.footerSection4Item3`,
    defaultMessage: 'Status',
  },
  footerPaymentMethodsTitle: {
    id: `${scope}.footerPaymentMethodsTitle`,
    defaultMessage: 'Formas de pagamento',
  },
  footerCertificatesTitle: {
    id: `${scope}.footerCertificatesTitle`,
    defaultMessage: 'Certificados',
  },
  footerBadgesTitle: {
    id: `${scope}.footerBadgesTitle`,
    defaultMessage: 'Prêmios e reconhecimentos',
  },
  footerTermsButtonTitle: {
    id: `${scope}.footerTermsButtonTitle`,
    defaultMessage: 'Termos de Uso',
  },
  footerPrivacyButtonTitle: {
    id: `${scope}.footerPrivacyButtonTitle`,
    defaultMessage: 'Política de Privacidade',
  },
  footerContactButtonTitle: {
    id: `${scope}.footerContactButtonTitle`,
    defaultMessage: 'Fale Conosco',
  },
  version: {
    id: `${scope}.version`,
    defaultMessage: 'Versão {version}',
  },
});
