import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Mui } from '../..';
import messages from './messages';

const useStyles = Mui.makeStyles(theme => ({
  fab: {
    zIndex: 1100,
    position: 'fixed',
    bottom: theme.spacing(1.5),
    right: 20,
  },
}));

function FloatIcon(props) {
  const classes = useStyles();

  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  const { onClick, count } = props;

  return (
    <Mui.Tooltip placement="top" title={<FormattedMessage {...messages.cartLabel} />}>
      <Mui.Fab
        color="primary"
        size={fullScreen ? 'medium' : 'large'}
        className={classes.fab}
        onClick={onClick}>
        <Mui.Badge color="error" badgeContent={count}>
          <Mui.Icon>shopping_cart</Mui.Icon>
        </Mui.Badge>
      </Mui.Fab>
    </Mui.Tooltip>
  );
}

FloatIcon.propTypes = {
  onClick: PropTypes.func,
  count: PropTypes.number,
};

export default FloatIcon;
