/**
 *
 * Styles for JoyrideTooltip
 *
 *
 */

import { Mui } from '../..';

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    marginLeft: '20px',
    maxWidth: '520px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minWidth: '280px',
    },
  },
  content: {
    padding: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));
