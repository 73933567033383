import PropTypes from 'prop-types';
import React from 'react';

import { Mui, Grid, Typography } from '../..';

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  icon: {
    backgroundColor: props => {
      if (props.color) {
        const newColor = theme.palette[props.color];

        if (newColor) return newColor.main;
      }

      return props.color || theme.palette.primary.main;
    },
    width: 60,
    height: 60,
  },
}));

function Item(props) {
  const { icon, feature, subtitle, color } = props;

  const classes = useStyles({ color });

  return (
    <Grid container spacing={2} justifyContent="center">
      {icon && (
        <Grid item>
          <Mui.Avatar className={classes.icon}>
            <Mui.Icon fontSize="large">{icon}</Mui.Icon>
          </Mui.Avatar>
        </Grid>
      )}
      {(feature || subtitle) && (
        <Grid item xs={12}>
          {feature && (
            <Typography variant="h6" component="div" align="center" gutterBottom={!!subtitle}>
              {feature}
            </Typography>
          )}
          {subtitle && (
            <Typography align="center" variant="body1" color="textPrimary">
              {subtitle}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}

Item.propTypes = {
  icon: PropTypes.string,
  feature: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string,
};

function Feature(props) {
  const { title, subtitle, items } = props;

  const classes = useStyles({});

  return (
    <Mui.Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={2} justifyContent="center">
        {title && (
          <Grid item xs={12}>
            <Mui.Box mb={8}>
              {subtitle && (
                <Typography variant="overline" align="center" component="div" color="secondary">
                  {subtitle}
                </Typography>
              )}
              <Typography variant="h1" component="div" align="center" color="textPrimary">
                {title}
              </Typography>
            </Mui.Box>
          </Grid>
        )}
        {items &&
          items.map((item, index) => (
            <Grid item lg={3} md={4} sm={4} xs={12} key={`${index.toString()}`}>
              <Item {...item} />
            </Grid>
          ))}
      </Grid>
    </Mui.Container>
  );
}

Feature.defaultProps = {
  variant: 'outlined',
};

Feature.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  variant: PropTypes.oneOf(['elevation', 'outlined']),
  items: PropTypes.array,
};

export default Feature;
