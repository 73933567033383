import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import makeSelectProductProvider from './selectors';
import * as ducks from './ducks';

const stateSelector = createStructuredSelector({
  productProvider: makeSelectProductProvider(),
});

function useProduct() {
  // Hooks
  const dispatch = useDispatch();

  // Store
  const { productProvider } = useSelector(stateSelector);

  // Constants
  const { isFetching, enrolled, landingPage, showcase, publicPage } = productProvider;

  // Actions
  const retrieveProduct = useCallback(
    ({ landingPageSlug, enrollmentSlug, institutionSlug, showcaseSlug, callback }, productSlug) => {
      if (landingPageSlug) {
        dispatch(ducks.landingPageRequest({ landingPageSlug, productSlug, callback }));
      } else if (enrollmentSlug) {
        dispatch(ducks.enrolledRequest({ enrollmentSlug, productSlug, callback }));
      } else if (institutionSlug && showcaseSlug) {
        dispatch(ducks.showcaseRequest({ institutionSlug, showcaseSlug, productSlug, callback }));
      } else {
        dispatch(ducks.publicPageRequest({ productSlug, callback }));
      }
    },
    [dispatch],
  );

  return {
    isFetching,
    enrolled,
    landingPage,
    showcase,
    publicPage,
    retrieveProduct,
  };
}

export default useProduct;
