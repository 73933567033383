/*
 *
 * AnalyticsProvider ducks
 * Read more about this pattern: https://blog.rocketseat.com.br/estrutura-redux-escalavel-com-ducks/
 *
 */

import produce from 'immer';

// Constants

const app = 'app/common/AnalyticsProvider';

export const REGISTER_EVENT_REQUEST = `${app}/REGISTER_EVENT_REQUEST`;
export const REGISTER_EVENT_SUCCESS = `${app}/REGISTER_EVENT_SUCCESS`;
export const REGISTER_EVENT_FAILURE = `${app}/REGISTER_EVENT_FAILURE`;

// Actions

export function registerEventRequest(payload) {
  return { type: REGISTER_EVENT_REQUEST, payload };
}

export function registerEventSuccess(payload) {
  return { type: REGISTER_EVENT_SUCCESS, payload };
}

export function registerEventFailure(payload) {
  return { type: REGISTER_EVENT_FAILURE, payload };
}

// Reducer

export const initialState = {
  isFetching: {
    registerEvent: {},
  },
};

/* eslint-disable default-case, no-param-reassign */
export const analyticsProviderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case REGISTER_EVENT_REQUEST: {
        draft.isFetching.registerEvent = true;
        break;
      }
      case REGISTER_EVENT_SUCCESS: {
        draft.isFetching.registerEvent = false;
        break;
      }
      case REGISTER_EVENT_FAILURE: {
        draft.isFetching.registerEvent = false;
        break;
      }
    }
  });
