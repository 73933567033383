import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ErrorBoundary } from 'utils';

// Containers
import ContactForm from 'containers/landing/ContactForm';
import CustomTheme from 'containers/common/CustomTheme';
import ChangeContextMenu from 'containers/widgets/ChangeContextMenu';
import FamilyCartUser from 'containers/widgets/FamilyCartUser';

// Components
import { Footer, TopBar as AuthenticatedTopBar } from 'components/layouts';

// Providers
import CartProvider from 'containers/common/CartProvider';

// Hooks
import { useUser, useTheme } from 'hooks';

import AnonymousTopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    minHeight: '100%',
    overflow: 'auto',
  },
}));

function PublicLayout({ children }) {
  const classes = useStyles();

  // Custom hooks
  const { isAuthenticated, user } = useUser();
  const { homePath } = useTheme();

  return (
    <CustomTheme>
      <CartProvider />
      <div className={classes.root}>
        {isAuthenticated ? (
          <AuthenticatedTopBar homeTo="/families" user={user}>
            <ChangeContextMenu />
            <FamilyCartUser type="fixed" />
          </AuthenticatedTopBar>
        ) : (
          <AnonymousTopBar homeTo={homePath} />
        )}
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content} id="content">
              <ErrorBoundary>{children}</ErrorBoundary>
              <Footer ContactForm={<ContactForm />} />
            </div>
          </div>
        </div>
      </div>
    </CustomTheme>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.any,
};

export default PublicLayout;
