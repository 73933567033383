import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the notifications state domain
 */

const selectCommonJobNotificationDomain = state => state.commonJobNotification || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by CommonJobNotification
 */

const makeSelectCommonJobNotification = () =>
  createSelector(selectCommonJobNotificationDomain, substate => substate);

export default makeSelectCommonJobNotification;
export { selectCommonJobNotificationDomain };
