/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import { hot } from 'react-hot-loader/root';
import { SWRConfig } from 'swr';
import React from 'react';

import api from 'utils/api';

import GlobalStyle from '../../../global-styles';

// Components
import { ScrollToTop, CookiesNotification } from '../../../components/widgets';

// Local components
import SnackbarProvider from '../SnackbarProvider';
import UserProvider from '../UserProvider';
import PermissionProvider from '../PermissionProvider';
import AnalyticsProvider from '../AnalyticsProvider';
import Global from './Global';
import Routes from '../Routes';
import ThemeProvider from '../ThemeProvider';
import ProductProvider from '../ProductProvider';

const fetcher = url => api({ method: 'GET', url });

function App() {
  const swrConfig = {
    refreshInterval: 0,
    revalidateOnFocus: false,
    fetcher: (...args) => fetcher(...args).then(res => res.data),
  };
  return (
    <Global>
      <SWRConfig value={swrConfig}>
        <ThemeProvider>
          <SnackbarProvider autoHideDuration={3000}>
            <GlobalStyle />
            <AnalyticsProvider>
              <UserProvider>
                <PermissionProvider>
                  <ProductProvider />
                  <Routes />
                </PermissionProvider>
              </UserProvider>
            </AnalyticsProvider>
            <ScrollToTop />
            <CookiesNotification />
          </SnackbarProvider>
        </ThemeProvider>
      </SWRConfig>
    </Global>
  );
}

export default hot(App);
