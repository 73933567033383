/*
 *
 * CommonJobNotification actions
 *
 */

import * as constants from './constants';

export function listRequest(payload) {
  return { type: constants.LIST_REQUEST, payload };
}

export function listSuccess(payload) {
  return { type: constants.LIST_SUCCESS, payload };
}

export function listFailure(payload) {
  return { type: constants.LIST_FAILURE, payload };
}

export function retrieveRequest(payload) {
  return { type: constants.RETRIEVE_REQUEST, payload };
}

export function retrieveSuccess(payload) {
  return { type: constants.RETRIEVE_SUCCESS, payload };
}

export function retrieveFailure(payload) {
  return { type: constants.RETRIEVE_FAILURE, payload };
}

export function addJob(payload) {
  return { type: constants.ADD_JOB, payload };
}

export function removeJob(payload) {
  return { type: constants.REMOVE_JOB, payload };
}
