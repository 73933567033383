import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Button, Mui } from 'components';

import messages from './messages';

function Confirmation(props) {
  const {
    children,
    open,
    loading,
    title,
    onClose,
    disabled,
    onConfirm,
    disableTypography,
    typographyProps,
  } = props;

  return (
    <Mui.Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Mui.DialogTitle>{title || <FormattedMessage {...messages.title} />}</Mui.DialogTitle>
      <Mui.DialogContent>
        {disableTypography ? (
          children
        ) : (
          <Mui.DialogContentText color="textPrimary" {...typographyProps}>
            {children}
          </Mui.DialogContentText>
        )}
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Box
          px={1}
          width="100%"
          display="flex"
          alignContent="center"
          alignItems="center"
          justifyContent="space-between">
          {onClose && (
            <Button
              color="secondary"
              variant="text"
              fullWidth={false}
              disabled={loading}
              onClick={onClose}>
              <FormattedMessage {...messages.closeButton} />
            </Button>
          )}
          {onConfirm && (
            <Button
              color="primary"
              variant="text"
              disabled={disabled}
              onClick={onConfirm}
              fullWidth={false}
              loading={loading}>
              <FormattedMessage {...messages.confirmButton} />
            </Button>
          )}
        </Mui.Box>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
}

Confirmation.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  disableTypography: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.any,
  typographyProps: PropTypes.object,
};

export default Confirmation;
