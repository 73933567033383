import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer from './ducks';
import saga from './saga';

function ProductProvider() {
  useInjectReducer({ key: 'productProvider', reducer });
  useInjectSaga({ key: 'productProvider', saga });
  return null;
}

export default ProductProvider;
