import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiRadio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';

function Radio(props) {
  const { name, options, formik, onSelect, messages } = props;
  const { values, touched, errors, setFieldValue, setFieldTouched } = formik || {};

  // This section of the code handles with formik integration
  // using vars that came from formik object, such: values, errors,
  // touched, for example.
  const hasMessage = messages && messages[name];
  const helperText = hasMessage && messages[name].helperText;
  const label = hasMessage ? messages[name].label : props.label;

  const hasValue = values && values[name];
  const value = hasValue && values[name];

  // Check if exist an errors, and the input was touched
  const hasTouched = touched && touched[name];
  const isTouched = hasTouched && touched[name];
  const errorText = errors && errors[name];
  const hasError = isTouched && errorText !== undefined;

  const muiRadioGroupProps = {
    name,
    value,
    'aria-label': name,
    onChange: event => {
      setFieldTouched(name, true, true);
      setFieldValue(name, event.target.value, true);
      if (onSelect) onSelect(event.target.value);
    },
  };

  // if there's no options to print, don't print anything!
  if (!(options instanceof Array) || options.length < 1) return null;

  return (
    <MuiFormControl component="fieldset" error={hasError}>
      <MuiFormLabel component="legend">{label}</MuiFormLabel>
      <MuiRadioGroup {...muiRadioGroupProps}>
        {options.map(option => (
          <MuiFormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            control={<MuiRadio color="primary" />}
          />
        ))}
      </MuiRadioGroup>
      {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
    </MuiFormControl>
  );
}

Radio.propTypes = {
  /**
   * The formik object.
   */
  formik: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }).isRequired,
  /**
   * The select label.
   */
  label: PropTypes.any,
  /**
   * The name used to reference the value of the control.
   */
  name: PropTypes.string,
  /**
   * The message object.
   */
  messages: PropTypes.object,
  /**
   * Callback fired when a radio button is selected.
   *
   * @param {string} value The `value` of the selected radio button
   */
  onSelect: PropTypes.func,
  /**
   * The array of label and value.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  /**
   * Value of the selected radio button.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Radio.defaultProps = {
  options: [],
};

export default Radio;
