import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Mui, Typography } from '../components';

const ErrorZone = styled.div`
  text-align: center;
  background-color: #dcdcdc;
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 15px,
    rgba(255, 255, 255, 0.5) 15px,
    rgba(255, 255, 255, 0.5) 30px
  );
  border-radius: 8px;
  border: 2px dashed #dcdcdc;
  color: grey;
  padding: 0.5em;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eventId: '' };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);

      const eventId = Sentry.captureException(error);

      this.setState({ eventId });
    });
  }

  render() {
    const { children } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { hasError, eventId } = this.state;

    if (hasError) {
      return (
        <ErrorZone>
          <Mui.Icon>warning</Mui.Icon>
          <Typography variant="overline" color="inherit" component="div">
            Erro
          </Typography>
        </ErrorZone>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
