/**
 *
 * JoyrideTooltip
 *
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Button, Mui, Typography } from '../..';
import messages from './messages';
import { useStyles } from './styles';

function JoyrideTooltip(props) {
  const {
    continuous,
    index,
    isLastStep,
    step = {},
    backProps,
    primaryProps,
    skipProps,
    tooltipProps,
  } = props;

  const classes = useStyles(props);

  const nextLabel = !isLastStep ? (
    <FormattedMessage {...messages.next} />
  ) : (
    <FormattedMessage {...messages.last} />
  );

  return (
    <Mui.Grow in>
      <div className={classes.root} {...tooltipProps}>
        <div className={classes.content}>
          {step.title && (
            <Typography variant="h5" color="primary">
              {step.title}
            </Typography>
          )}
          {step.content && <div>{step.content}</div>}
        </div>
        {!step.hideFooter && (
          <div className={classes.footer}>
            {!isLastStep ||
              (step.showSkipButton && (
                <Button
                  fullWidth={false}
                  color="warning"
                  className={classes.button}
                  size="small"
                  {...skipProps}>
                  <FormattedMessage {...messages.skip} />
                </Button>
              ))}
            {index > 0 && (
              <Button
                fullWidth={false}
                className={classes.button}
                color="secondary"
                size="small"
                {...backProps}>
                <FormattedMessage {...messages.back} />
              </Button>
            )}
            <Button
              fullWidth={false}
              className={classes.button}
              color={isLastStep ? 'success' : 'primary'}
              size="small"
              {...primaryProps}>
              {continuous ? nextLabel : <FormattedMessage {...messages.close} />}
            </Button>
          </div>
        )}
      </div>
    </Mui.Grow>
  );
}

JoyrideTooltip.propTypes = {
  continuous: PropTypes.any,
  index: PropTypes.any,
  isLastStep: PropTypes.any,
  step: PropTypes.any,
  backProps: PropTypes.any,
  primaryProps: PropTypes.any,
  skipProps: PropTypes.any,
  tooltipProps: PropTypes.any,
};

export default JoyrideTooltip;
