import { useUser, usePermissions, useSeller } from 'hooks';
import { sessionToken } from 'utils';
import { Redirect, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { SplashScreen } from 'components/layouts';

// Pages
import LoginPage from 'pages/root/LoginPage';

function SellerGuard({ children }) {
  // Custom hooks
  const { isAuthenticated } = useUser();
  const { permissions } = usePermissions();
  const { sellerSlug } = useParams();
  const { error, sellerPermissions } = useSeller();

  // Constants
  const { isSeller, isStaff } = permissions;
  const { token } = sessionToken();

  if (!isAuthenticated && !token) return <LoginPage />;

  if (!isStaff && !isSeller) return <Redirect to="/not-authorized" />;

  if (sellerSlug && !sellerPermissions && !error) return <SplashScreen />;

  if (error) return <Redirect to="/not-authorized" />;

  return children;
}

SellerGuard.propTypes = {
  children: PropTypes.any,
};

export default SellerGuard;
