import { put, takeLatest } from 'redux-saga/effects';

import * as notifications from '../../common/SnackbarProvider/ducks';
import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* contactRequestSaga(action) {
  const { name, email, phone, message } = action.payload;

  try {
    const response = yield api.contact({ name, email, phone, message });

    yield put(actions.contactSuccess({ email, response: response.data }));
  } catch (error) {
    yield put(actions.contactFailure({ email, error }));
  }
}

export function* contactSuccessSaga() {
  yield put(notifications.enqueueSnackbar({ messageId: 'MSG0110' }));
}

// Individual exports for testing
export default function* contactFormSaga() {
  yield takeLatest(constants.CONTACT_REQUEST, contactRequestSaga);
  yield takeLatest(constants.CONTACT_SUCCESS, contactSuccessSaga);
}
