/* eslint-disable indent */
import result from 'lodash/result';

import { RESET_APP } from '../constants';

const requestMiddleware =
  ({ getState }) =>
  next =>
  action => {
    const { global } = getState();

    if (global) {
      const { isStoreReady } = global;

      /**
       * We need to check if the action is RESET_APP to avoid
       * infinite loop, once that every action pass thru this middleware
       */
      if (action.type !== RESET_APP && isStoreReady && action.type.includes('FAILURE')) {
        // eslint-disable-next-line no-unused-vars
        const status = result(action, 'payload.error.response.status', undefined);
      }
    }

    return next(action);
  };

export default requestMiddleware;
