import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '../..';

function CaptionField(props) {
  const { children, ...otherProps } = props;

  return (
    <Typography variant="caption" color="textSecondary" {...otherProps}>
      {children}
    </Typography>
  );
}

CaptionField.propTypes = {
  children: PropTypes.any,
};

export default CaptionField;
