import { FormattedMessage } from 'react-intl';
import { useInView } from 'react-intersection-observer';
import * as React from 'react';
import PropTypes from 'prop-types';

// Components
import { Grid, Mui, Slick, Typography } from 'components';
import { LoadingScreen } from 'components/layouts';
import { DisplayData } from 'components/common';

import messages from './messages';

export const useStyles = Mui.makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    minHeight: '50vh',
    display: 'flex',
    alignItems: 'center',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    '& img': {
      objectFit: 'contain',
    },
  },
}));

export const slickResponsive = [
  {
    breakpoint: 1920,
    settings: {
      slidesPerRow: 3,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesPerRow: 3,
    },
  },
  {
    breakpoint: 960,
    settings: {
      slidesPerRow: 2,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesPerRow: 1,
    },
  },
];

function NewsBox(props) {
  const { articles = [], loading } = props;

  // Hooks
  const classes = useStyles();
  const theme = Mui.useTheme();
  const [inViewRef, inView] = useInView({ threshold: 0.33 });

  if (loading) return <LoadingScreen />;

  return (
    <Mui.Box p={3} className={classes.container} ref={inViewRef}>
      <Grid container justifyContent="space-around" alignItems="center" spacing={3}>
        <Grid item md={3} xs={12}>
          <Typography component="p" variant="overline" color="inherit">
            <FormattedMessage {...messages.overline} />
          </Typography>
          <Mui.Typography variant="h2" component="div" color="inherit">
            <FormattedMessage {...messages.title} />
          </Mui.Typography>
        </Grid>
        <Grid item md={9} xs={12}>
          <Slick dotActiveColor={theme.palette.common.white} responsive={slickResponsive}>
            {articles.map((news, index) => (
              <Mui.Grow key={`${index.toString()}`} in={inView} timeout={500 * (index + 1)}>
                <Mui.Box p={1}>
                  <Article news={news} />
                </Mui.Box>
              </Mui.Grow>
            ))}
          </Slick>
        </Grid>
      </Grid>
    </Mui.Box>
  );
}

NewsBox.propTypes = {
  articles: PropTypes.array,
  loading: PropTypes.bool,
};

const Article = ({ news }) => {
  const classes = useStyles();

  return (
    <Mui.Card elevation={12}>
      <Mui.CardHeader
        avatar={
          <Mui.Avatar variant="rounded" src={news.publisherLogo} className={classes.avatar} />
        }
        title={news.headline}
        subheader={<DisplayData.DateField type="datetime" value={news.date} />}
      />
      <Mui.CardMedia image={news.cover} className={classes.media} />
      <Mui.CardContent>
        <Typography variant="body1" color="textPrimary" component="p">
          {news.summary.substr(0, 255)}...
        </Typography>
      </Mui.CardContent>
      <Mui.CardActions>
        <Mui.Box width="100%" display="flex" justifyContent="flex-end">
          <Mui.Button component="a" href={news.link} target="_blank" color="primary">
            <FormattedMessage {...messages.readMore} />
          </Mui.Button>
        </Mui.Box>
      </Mui.CardActions>
    </Mui.Card>
  );
};

Article.propTypes = {
  news: PropTypes.object,
};

export default NewsBox;
