/**
 * A custom component to mask a date
 *
 */
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

export default function DateMask(props) {
  const { inputRef, mask, ...rest } = props;

  return (
    <MaskedInput
      {...rest}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask || [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    />
  );
}

DateMask.propTypes = {
  inputRef: PropTypes.any,
  mask: PropTypes.any,
};
