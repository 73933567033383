import { AppBar, Box, Button, Divider, Toolbar, Link, makeStyles } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { Logo, Mui } from 'components';
import { Repeater } from 'components/layouts';

// Containers
import FamilyCartAnonymous from 'containers/widgets/FamilyCartAnonymous';

import messages from './messages';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    height: 64,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

function TopBar({ className, homeTo, ...rest }) {
  const classes = useStyles();

  const { pathname } = useLocation();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" elevation={0} {...rest}>
      <Toolbar className={classes.toolbar} id="toolbar">
        <RouterLink to={homeTo || '/'}>
          <Mui.Hidden smUp>
            <Logo type="icon" height="42px" className={classes.logo} />
          </Mui.Hidden>
          <Mui.Hidden xsDown>
            <Logo height="32px" className={classes.logo} />
          </Mui.Hidden>
        </RouterLink>
        <Box flexGrow={1} />
        <Box ml={2}>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <FamilyCartAnonymous />
            </Box>
          </Box>
        </Box>
        <Link
          noWrap
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to={['/sign-up/express', `redirect=${pathname}`].join('?')}
          underline="none"
          variant="body2">
          <FormattedMessage {...messages.signUpButton} />
        </Link>
        <Divider className={classes.divider} />
        <Button
          color="primary"
          component={RouterLink}
          to={['/login', `redirect=${pathname}`].join('?')}
          variant="contained"
          size="small">
          <FormattedMessage {...messages.loginButton} />
        </Button>
      </Toolbar>
      <Repeater />
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  homeTo: PropTypes.string,
};

export default TopBar;
