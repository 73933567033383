/**
 *
 * Student
 *
 */

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import * as images from 'images';

// Components
import { Avatar, Mui } from 'components';

import messages from './messages';

// Local components
import Clean from './Clean';

function Student(props) {
  const { children, firstName, lastName, picture, onClean, loading } = props;

  return (
    <Mui.Card variant="outlined">
      <Mui.CardHeader
        avatar={
          <Avatar
            imgProps={{ width: 48, height: 48 }}
            src={picture || images.facesOtherFace}
            alt={firstName}
          />
        }
        title={
          <>
            <Mui.Typography variant="caption" color="textSecondary">
              <FormattedMessage {...messages.buyingFor} />
            </Mui.Typography>
            <Mui.Typography variant="body2">{[firstName, lastName].join(' ')}</Mui.Typography>
          </>
        }
        action={
          <Clean onClick={onClean} loading={loading} firstName={firstName} lastName={lastName} />
        }
        disableTypography
      />
      <Mui.CardContent>{children}</Mui.CardContent>
    </Mui.Card>
  );
}

Student.propTypes = {
  picture: PropTypes.any,
  firstName: PropTypes.any,
  lastName: PropTypes.any,
  children: PropTypes.any,
  loading: PropTypes.any,
  onClean: PropTypes.func,
};

export default Student;
