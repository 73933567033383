import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Mui, Grid, Typography } from '../..';
import { Product } from '../../common';

function MultiProduct(props) {
  const { products, title, subtitle, to, PurchaseButton } = props;

  if (products && products.length === 0) return null;

  return (
    <Mui.Container maxWidth="lg">
      <Mui.Box mb={8}>
        <Typography variant="h2" component="div" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" component="div" color="textPrimary" gutterBottom>
          {subtitle}
        </Typography>
      </Mui.Box>
      <Grid container spacing={2}>
        {products.map(product => {
          const { images, upc, parent, brand } = product;
          const [image] = images;

          return (
            <Grid item key={product.uid}>
              <Mui.Box width={160} height="100%">
                <Product.Display
                  variant="outlined"
                  title={product.title}
                  subtitle={product.subtitle}
                  upc={upc}
                  parent={parent}
                  image={image?.file}
                  brand={brand}
                  CardActionAreaProps={{ component: RouterLink, to: to(product) }}>
                  {PurchaseButton && PurchaseButton(product)}
                </Product.Display>
              </Mui.Box>
            </Grid>
          );
        })}
      </Grid>
    </Mui.Container>
  );
}

MultiProduct.propTypes = {
  products: PropTypes.array,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  to: PropTypes.func,
  PurchaseButton: PropTypes.func,
};

export default MultiProduct;
