import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import * as images from 'images';

// Components
import { Mui } from 'components';
import { Buttons } from 'components/layouts';

import messages from './messages';

// Local components
import Gallery from './Gallery';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.info.main}`,
  },
  header: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
}));

const MAX_CHIPS = 5;

function ModelKnowledge(props) {
  const { models } = props;

  // Hooks
  const classes = useStyles();

  // States
  const [anchorEl, setAnchorEl] = useState(null);
  const [model, setModel] = useState(null);

  // Actions
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = item => {
    setAnchorEl(null);
    setModel(item);
  };

  // Constants
  const options = [
    {
      id: 'store',
      label: <FormattedMessage {...messages.storeLabel} />,
      icon: <Mui.Icon>store_outlined</Mui.Icon>,
      message: <FormattedMessage {...messages.storeMessage} />,
      steps: [
        {
          image: images.modelKnowledgeStore,
          label: <FormattedMessage {...messages.storeStep1Label} />,
        },
      ],
    },
    {
      id: 'collection',
      label: <FormattedMessage {...messages.collectionLabel} />,
      icon: <Mui.Icon>collections_bookmark_outlined</Mui.Icon>,
      message: <FormattedMessage {...messages.collectionMessage} />,
      steps: [
        {
          image: images.modelKnowledgeCollections,
          label: <FormattedMessage {...messages.collectionStep1Label} />,
        },
      ],
    },
    {
      id: 'showcase',
      label: <FormattedMessage {...messages.showcaseLabel} />,
      icon: <Mui.Icon>storefront_outlined</Mui.Icon>,
      message: <FormattedMessage {...messages.showcaseMessage} />,
      steps: [
        {
          image: images.modelKnowledgeShowcase,
          label: <FormattedMessage {...messages.showcaseStep1Label} />,
        },
      ],
    },
    {
      id: 'item',
      label: <FormattedMessage {...messages.itemLabel} />,
      icon: <Mui.Icon>list_alt_outlined</Mui.Icon>,
      message: <FormattedMessage {...messages.itemMessage} />,
      steps: [
        {
          image: images.modelKnowledgeItem,
          label: <FormattedMessage {...messages.itemStep1Label} />,
        },
      ],
    },
  ];

  const visible = options.filter(item => models.includes(item.id));

  return (
    <Mui.Card className={classes.root}>
      <Mui.CardHeader
        className={classes.header}
        titleTypographyProps={{ variant: 'h5' }}
        title={<FormattedMessage {...messages.helpTitle} />}
        avatar={<Mui.Icon>help</Mui.Icon>}
      />
      <Mui.CardContent>
        <Mui.Typography gutterBottom>
          <FormattedMessage {...messages.helpSubheader} />{' '}
          {MAX_CHIPS > 5 && (
            <Mui.Link
              component="button"
              variant="body1"
              aria-controls="model-knowledge"
              aria-haspopup="true"
              onClick={handleClick}>
              <FormattedMessage {...messages.modelsButton} />
            </Mui.Link>
          )}
        </Mui.Typography>
        {MAX_CHIPS <= 5 && (
          <Mui.Grid container spacing={1}>
            {visible.map(item => (
              <Mui.Grid item xs="auto">
                <Mui.Chip
                  key={item.id}
                  icon={item.icon}
                  label={item.label}
                  onClick={() => setModel(item)}
                />
              </Mui.Grid>
            ))}
          </Mui.Grid>
        )}
        <Mui.Menu
          id="model-knowledge"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {visible.map(item => (
            <Mui.MenuItem key={item.id} value={item.id} onClick={() => handleClose(item)}>
              {item.label}
            </Mui.MenuItem>
          ))}
        </Mui.Menu>
        <Mui.Dialog
          disableEscapeKeyDown
          open={!!model}
          onClose={() => handleClose(null)}
          maxWidth="sm"
          fullWidth>
          <Mui.DialogTitle disableTypography>
            <Mui.Typography component="h2" variant="h5">
              {model?.label}
            </Mui.Typography>
          </Mui.DialogTitle>
          <Mui.DialogContent>
            <Mui.Typography>{model?.message}</Mui.Typography>
            <Mui.Box mt={2} display="flex" justifyContent="center">
              <Gallery steps={model?.steps || []} />
            </Mui.Box>
          </Mui.DialogContent>
          <Mui.DialogActions>
            <Buttons preset="close" onClick={() => handleClose(null)} color="primary" />
          </Mui.DialogActions>
        </Mui.Dialog>
      </Mui.CardContent>
    </Mui.Card>
  );
}

ModelKnowledge.propTypes = {
  models: PropTypes.array,
};

export default ModelKnowledge;
