import * as Sentry from '@sentry/browser';

const logger = ({ message }, extras) => {
  const error = new Error(message);

  if (process.env.NODE_ENV === 'production') {
    Sentry.withScope(scope => {
      scope.setExtras(extras);

      Sentry.captureException(error);
    });
  } else {
    // eslint-disable-next-line no-console
    console.log(error, extras);
  }
};

export default logger;
