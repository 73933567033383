import { createSelector } from 'reselect';

import { initialState } from './ducks';

/**
 * Direct selector to the cartProvider state domain
 */

const selectCartProviderDomain = state => state.cartProvider || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by CartProvider
 */

const makeSelectCartProvider = () => createSelector(selectCartProviderDomain, substate => substate);

export default makeSelectCartProvider;
export { selectCartProviderDomain };
