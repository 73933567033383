import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui } from 'components';
import * as DisplayData from 'components/common/DisplayData';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

function Step(props) {
  const {
    children,
    step,
    title,
    subheader,
    open,
    checked,
    disabled,
    onClick,
    cleared,
    ...restProps
  } = props;

  // Hooks
  const classes = useStyles(props);

  if (open) {
    return (
      <Mui.Card {...restProps} variant="outlined">
        <Mui.CardHeader
          avatar={<Mui.Avatar className={classes.avatar}>{step}</Mui.Avatar>}
          title={title}
          subheader={subheader}
        />
        <Mui.CardContent>{children}</Mui.CardContent>
      </Mui.Card>
    );
  }

  return (
    <Mui.Card variant="outlined">
      <Mui.CardActionArea onClick={!disabled ? onClick : undefined} disabled={disabled}>
        <Mui.CardHeader
          avatar={<Mui.Avatar className={classes.avatar}>{step}</Mui.Avatar>}
          action={!open && cleared && <DisplayData.BooleanField value={checked} />}
          title={title}
          subheader={subheader}
        />
      </Mui.CardActionArea>
    </Mui.Card>
  );
}

Step.propTypes = {
  children: PropTypes.any,
  step: PropTypes.string,
  title: PropTypes.string,
  subheader: PropTypes.string,
  open: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  cleared: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Step;
