import React from 'react';
import { Mui } from '../..';

export const Back = props => <Mui.Icon {...props}>arrow_back_outlined</Mui.Icon>;
export const Forward = props => <Mui.Icon {...props}>arrow_forward_outlined</Mui.Icon>;
export const Home = props => <Mui.Icon {...props}>home_outlined</Mui.Icon>;
export const Next = props => <Mui.Icon {...props}>keyboard_arrow_right</Mui.Icon>;
export const Previous = props => <Mui.Icon {...props}>keyboard_arrow_left</Mui.Icon>;
export const Up = props => <Mui.Icon {...props}>keyboard_arrow_up</Mui.Icon>;
export const Down = props => <Mui.Icon {...props}>keyboard_arrow_down</Mui.Icon>;
