import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import TopBar from './TopBar';
import { ErrorBoundary } from '../../utils';

// Components
import { Footer } from '../../components/landing';
import { Mui } from '../../components';

// Local components
import ContactForm from '../../containers/landing/ContactForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    minHeight: '100%',
    overflow: 'auto',
  },
}));

function MainLayout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="content">
            <ErrorBoundary>{children}</ErrorBoundary>
            <Mui.Box mt={3}>
              <Footer ContactForm={<ContactForm />} />
            </Mui.Box>
          </div>
        </div>
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any,
};

export default MainLayout;
