import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export function generateExport(fileName = 'Export', jsonData, options = {}) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(jsonData, options);
  const wb = { Sheets: { Example: ws }, SheetNames: ['Example'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  return FileSaver.saveAs(data, fileName + fileExtension);
}
