import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Mui } from '../..';
import * as images from '../../../images';
import messages from './messages';
import { useStyles } from './styles';

function Rewards(props) {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles(props);

  const items = [
    {
      image: images.logoAbo2o,
      href: 'https://o2obrasil.com.br/associadoso2o/',
      name: 'Somos associados a ABO2O',
    },
    {
      image: images.logoAwsEdStartMember,
      href: 'https://aws.amazon.com/pt/education/edstart/members/',
      name: 'AWS EdStart Member',
    },
    {
      image: images.logoCubo,
      href: 'https://cubo.network/startups/eskolare',
      name: 'Somos residentes do Cubo',
    },
  ];

  return (
    <div>
      <Mui.Typography variant="overline" color="textSecondary">
        <FormattedMessage {...messages.footerBadgesTitle} />
      </Mui.Typography>
      <Grid container spacing={1}>
        {items.map(item => (
          <Grid item key={item.name}>
            <Mui.Tooltip title={item.name}>
              <Mui.Link href={item.href} target="_blank" rel="noreferrer">
                <img src={item.image} alt={item.name} width={90} />
              </Mui.Link>
            </Mui.Tooltip>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

Rewards.propTypes = {};

export default Rewards;
