import { ErrorBoundary } from 'utils';
import { makeStyles } from '@material-ui/core';
import { useInstitution, useUser } from 'hooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Containers
import ChangeContextMenu from 'containers/widgets/ChangeContextMenu';
import CommonJobNotification from 'containers/widgets/CommonJobNotification';

// Components
import { Mui } from 'components';
import { Footer, TopBar } from 'components/layouts';

// Local components
import NavBar from './NavBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: '100%',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    paddingTop: 64,
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
  },
  content: {
    flex: '1 1 auto',
    minHeight: '100%',
    maxWidth: '100%',
  },
}));

function InstitutionLayout({ children }) {
  // Hooks
  const classes = useStyles();
  const { institution } = useInstitution();
  const { user } = useUser();

  // States
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar
        homeTo={`/institutions/${institution?.slug}`}
        data={institution}
        user={user}
        onMobileNavOpen={() => setMobileNavOpen(true)}>
        {/* <FloatingSuggestion
          title="Deixe sua sugestão"
          icon="emoji_objects"
          link="https://docs.google.com/forms/d/e/1FAIpQLSdwz2vn3fcindwTbPYxQwy3erbiZU2Oa3hDZlqFQIEaM_2Uew/viewform"
        /> */}
        <CommonJobNotification />
        <ChangeContextMenu />
      </TopBar>
      <Mui.Box displayPrint="none">
        <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      </Mui.Box>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="content">
            <ErrorBoundary>{children}</ErrorBoundary>
            <Mui.Box mt={3} displayPrint="none">
              <Footer />
            </Mui.Box>
          </div>
        </div>
      </div>
    </div>
  );
}

InstitutionLayout.propTypes = {
  children: PropTypes.any,
};

export default InstitutionLayout;
