/*
 *
 * UserProvider saga
 *
 */

import { Buffer } from 'buffer';
import { call, put, takeLatest } from 'redux-saga/effects';
import { sessionToken } from 'utils';
import * as gtm from 'gtm';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import envs from 'envs';

import * as ducks from './ducks';
import * as notifications from '../SnackbarProvider/ducks';

// API's

const domain = `${envs.PROTOCOL}://${envs.ENTRYPOINT}/api`;

export const endpoints = {
  login: `${domain}/commons/auth/login/`,
  retrieve: `${domain}/families/me/`,
  passwordReset: `${domain}/commons/password-reset/`,
  signUp: `${domain}/commons/signup/`,
  updatePassword: `${domain}/commons/password/`,
  passwordConfirm: `${domain}/commons/password-reset/confirm/`,
};

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#fc11d97f-f74b-47a9-aa9c-ba082737e893
 */
export function login({ email, password }) {
  const config = {
    method: 'post',
    url: endpoints.login,
    headers: {
      // eslint-disable-next-line sonarjs/no-nested-template-literals
      Authorization: `Basic ${Buffer.from(`${email}:${password}`).toString('base64')}`,
    },
  };

  return axios(config);
}

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#dfdede5f-4fb3-4638-acc5-ed195eee7678
 */
export function retrieve() {
  const config = {
    method: 'get',
    url: endpoints.retrieve,
  };
  return axios(config);
}

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#de701bed-fe81-441a-947a-86cb0626edf4
 */
export function passwordReset({ email }) {
  const config = {
    method: 'post',
    url: endpoints.passwordReset,
    data: { email },
  };
  return axios(config);
}

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#0053b2a8-ff70-4d36-8abf-b6bd0b8e4a05
 */
export function signUp({
  email,
  firstName,
  lastName,
  birthdate,
  gender,
  newsletter,
  taxDocument,
  showcase,
  password,
}) {
  const config = {
    method: 'post',
    url: endpoints.signUp,
    data: {
      email,
      firstName,
      lastName,
      birthdate,
      gender,
      newsletter,
      taxDocument,
      showcase,
      password,
    },
  };
  return axios(config);
}

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#1dca8fff-4837-4a7e-ae6a-d051a9b1be82
 */
export function updatePassword({ oldPassword, newPassword }) {
  const config = {
    method: 'post',
    url: endpoints.updatePassword,
    data: { oldPassword, newPassword },
  };
  return axios(config);
}

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#f10e5e9b-7ae9-49ad-b1a6-ef9c1e09a8cb
 */
export function passwordConfirm({ password, uidb64, token }) {
  const config = {
    method: 'post',
    url: endpoints.passwordConfirm,
    data: { password, uidb64, token },
  };
  return axios(config);
}

// Side-effects

export function* loginRequestSaga(action) {
  const { email, password, callback } = action.payload;
  try {
    const response = yield call(login, { email, password });

    yield put(ducks.loginSuccess({ callback, response: response.data }));
  } catch (error) {
    yield put(ducks.loginFailure({ callback, error }));
  }
}

export function* loginSuccessSaga(action) {
  const { callback, response } = action.payload;
  const { user, token, expiry } = response;

  yield sessionToken({ token, expiry });

  yield put(ducks.retrieveRequest({ callback }));

  yield call(gtm.gaLoginSuccess, user);
}

export function* loginFailureSaga(action) {
  const { callback } = action.payload;

  if (callback) callback(null, true);

  yield put(notifications.enqueueSnackbar({ messageId: 'MSG0142', variant: 'error' }));

  yield true;
}

export function* retrieveRequestSaga(action = {}) {
  const { callback } = action.payload || {};

  try {
    const response = yield retrieve();

    yield put(ducks.retrieveSuccess({ callback, response: response.data }));
  } catch (response) {
    yield put(ducks.retrieveFailure({ callback, response }));
  }
}

export function* retrieveSuccessSaga(action) {
  const { response, callback } = action.payload;

  const {
    email,
    uid,
    language,
    countryCode,
    timezone,
    hasStudents,
    hasEnrollments,
    isStaff,
    isInstitution,
    isSeller,
    isActive,
  } = response;

  /**
   * Set the User context to Sentry
   */

  Sentry.configureScope(scope => {
    scope.setUser({ email, uid });
    scope.setTag('isStaff', isStaff);
    scope.setTag('isInstitution', isInstitution);
    scope.setTag('isSeller', isSeller);
    scope.setTag('isActive', isActive);
    scope.setTag('timezone', timezone);
    scope.setTag('countryCode', countryCode);
    scope.setTag('language', language);
    scope.setTag('hasStudents', hasStudents);
    scope.setTag('hasEnrollments', hasEnrollments);
  });

  /**
   * Send User data to GTM
   */
  yield call(gtm.gaUserData, { uid, email, isStaff, isInstitution, isSeller });

  if (callback) yield callback(response, false);
}

export function* retrieveFailureSaga(action) {
  const { response, callback } = action.payload;

  if (callback) callback(null, response);

  yield true;
}

export function* passwordResetRequestSaga(action) {
  const { email, callback } = action.payload;

  try {
    const response = yield passwordReset({ email });

    yield put(ducks.passwordResetSuccess({ callback, email, response: response.data }));
  } catch (error) {
    yield put(ducks.passwordResetFailure({ error }));
  }
}

export function* passwordResetSuccessSaga(action) {
  const { callback, response, email } = action.payload;

  if (callback) callback(response, false);

  yield put(
    notifications.enqueueSnackbar({ messageId: 'MSG0004', variant: 'success', values: { email } }),
  );
}

export function* signUpRequestSaga(action) {
  const {
    email,
    firstName,
    lastName,
    birthdate,
    gender,
    newsletter,
    taxDocument,
    showcase,
    password,
    callback,
  } = action.payload;

  try {
    const response = yield signUp({
      email,
      firstName,
      lastName,
      birthdate,
      gender,
      newsletter,
      taxDocument,
      showcase,
      password,
    });

    yield put(ducks.signUpSuccess({ callback, response: response.data }));
  } catch (error) {
    yield put(ducks.signUpFailure({ callback }));
  }
}

export function* signUpSuccessSaga(action) {
  const { callback, response } = action.payload;
  yield put(ducks.loginSuccess({ callback, response }));
}

export function* signUpFailureSaga(action) {
  const { callback } = action.payload;

  if (callback) callback(null, true);

  yield put(notifications.enqueueSnackbar({ messageId: 'MSG0127', variant: 'warning' }));

  yield true;
}

export function* updatePasswordRequestSaga(action) {
  const { oldPassword, newPassword, callback } = action.payload;

  try {
    const response = yield updatePassword({ oldPassword, newPassword });

    yield put(ducks.updatePasswordSuccess({ callback, response: response.data }));
  } catch (error) {
    yield put(ducks.updatePasswordFailure({ error }));
  }
}

export function* updatePasswordSuccessSaga(action) {
  const { callback, response } = action.payload;

  yield put(ducks.loginSuccess({ callback, response }));
}

export function* passwordConfirmRequestSaga(action) {
  const { uidb64, token, password, callback } = action.payload;

  try {
    const response = yield passwordConfirm({ uidb64, token, password });

    yield put(ducks.passwordConfirmSuccess({ callback, response: response.data }));
  } catch (error) {
    yield put(ducks.passwordConfirmFailure({ error }));
  }
}

export function* passwordConfirmSuccessSaga(action) {
  const { callback, response } = action.payload;

  if (callback) callback(response, false);

  yield put(notifications.enqueueSnackbar({ messageId: 'MSG0010', variant: 'success' }));
}

// Individual exports for testing
export default function* userProviderSaga() {
  yield takeLatest(ducks.LOGIN_REQUEST, loginRequestSaga);
  yield takeLatest(ducks.LOGIN_SUCCESS, loginSuccessSaga);
  yield takeLatest(ducks.LOGIN_FAILURE, loginFailureSaga);
  yield takeLatest(ducks.RETRIEVE_REQUEST, retrieveRequestSaga);
  yield takeLatest(ducks.RETRIEVE_SUCCESS, retrieveSuccessSaga);
  yield takeLatest(ducks.RETRIEVE_FAILURE, retrieveFailureSaga);
  yield takeLatest(ducks.PASSWORD_RESET_REQUEST, passwordResetRequestSaga);
  yield takeLatest(ducks.PASSWORD_RESET_SUCCESS, passwordResetSuccessSaga);
  yield takeLatest(ducks.SIGN_UP_REQUEST, signUpRequestSaga);
  yield takeLatest(ducks.SIGN_UP_SUCCESS, signUpSuccessSaga);
  yield takeLatest(ducks.SIGN_UP_FAILURE, signUpFailureSaga);
  yield takeLatest(ducks.UPDATE_PASSWORD_REQUEST, updatePasswordRequestSaga);
  yield takeLatest(ducks.UPDATE_PASSWORD_SUCCESS, updatePasswordSuccessSaga);
  yield takeLatest(ducks.PASSWORD_CONFIRM_REQUEST, passwordConfirmRequestSaga);
  yield takeLatest(ducks.PASSWORD_CONFIRM_SUCCESS, passwordConfirmSuccessSaga);
}
