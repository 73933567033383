import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Grid, Avatar, Mui, Typography } from '../..';
import CaptionField from '../DisplayData/CaptionField';

const useStyles = Mui.makeStyles(theme => ({
  root: {},
  icon: {
    width: props => (props.caption ? 35 : 25),
    height: props => (props.caption ? 35 : 25),
    color: props =>
      props.variant === 'filled'
        ? theme.palette[props.color]?.contrastText
        : theme.palette[props.color]?.main,
    backgroundColor: props =>
      props.variant === 'filled' ? theme.palette[props.color]?.main : 'transparent',
  },
  status: {
    color: props => theme.palette[props.color]?.main,
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: '340px',
  },
  popover: {
    pointerEvents: 'none',
  },
  bar: {
    backgroundColor: props => props.color && theme.palette[props.color]?.light,
    width: '100%',
    height: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
  },
  progress: {
    backgroundColor: props => props.color && theme.palette[props.color]?.main,
    width: '70%',
    marginLeft: '10%',
    height: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
  },
}));

function Base(props) {
  const {
    id,
    children,
    icon,
    caption,
    color,
    content,
    disableTypography,
    captionFieldProps,
    onlyIcon,
    progressBar,
    variant,
    // eslint-disable-next-line no-unused-vars
    ...otherProps
  } = props;

  if (content && !id) throw Error('Id is required using content props');

  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = event => setAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <div>
      <Grid container spacing={1} alignItems="center" className={classes.root} wrap="nowrap">
        {icon && (
          <Grid item>
            {onlyIcon && (
              <Mui.Tooltip title={children}>
                <Avatar className={classes.icon} variant="rounded">
                  <Mui.Icon fontSize="small">{icon}</Mui.Icon>
                </Avatar>
              </Mui.Tooltip>
            )}
            {!onlyIcon && (
              <Avatar className={classes.icon} variant="rounded">
                <Mui.Icon fontSize="small">{icon}</Mui.Icon>
              </Avatar>
            )}
          </Grid>
        )}
        {!onlyIcon && (
          <Grid item zeroMinWidth className={classes.status}>
            {caption && (
              <CaptionField component="p" {...captionFieldProps}>
                {caption}
              </CaptionField>
            )}
            {!disableTypography && children && (
              <Typography component="div" color="inherit">
                {children}
              </Typography>
            )}
            {disableTypography && children}
          </Grid>
        )}
        {content && (
          <Grid item>
            <Mui.IconButton
              size="small"
              aria-owns={open ? id : undefined}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}>
              <Mui.Icon>arrow_drop_down</Mui.Icon>
            </Mui.IconButton>
            <Mui.Popover
              id={id}
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus>
              {content}
            </Mui.Popover>
          </Grid>
        )}
      </Grid>
      {progressBar && (
        <div className={classes.bar}>
          <div className={classes.progress}></div>
        </div>
      )}
    </div>
  );
}

Base.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.string,
  caption: PropTypes.any,
  color: PropTypes.oneOf(['primary', 'secondary', 'warning', 'info', 'error', 'success']),
  captionFieldProps: PropTypes.object,
  disableTypography: PropTypes.bool,
  content: PropTypes.any,
  onlyIcon: PropTypes.bool,
  progressBar: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'filled']),
};

Base.defaultProps = {
  color: 'secondary',
  variant: 'standard',
};

export default Base;
