import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Components
import { Mui, PerfectScrollbar } from 'components';

const useStyles = Mui.makeStyles(() => ({
  lastBorder: { '& tr:last-child td': { borderBottom: 0 } },
}));

function Tanstack({
  columns,
  data,
  isPaginated = false,
  isSortable = false,
  defaultSortOption,
  defaultPaginationOption,
  pageSizes,
  columnsAlignment = {},
  customStyles = {},
}) {
  // Hooks
  const classes = useStyles();

  // States
  const [sorting, setSorting] = useState(defaultSortOption || []);
  const [pagination, setPagination] = useState(
    defaultPaginationOption || {
      pageIndex: 0,
      pageSize: 10,
    },
  );

  const table = useReactTable({
    data,
    columns,
    state: { pagination, sorting },
    onSortingChange: isSortable ? setSorting : null,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const { pageSize, pageIndex } = isPaginated ? table.getState().pagination : {};
  const hasFooters =
    table
      .getFooterGroups()
      .map(group => group.headers.map(header => header.column.columnDef.footer))
      .flat()
      .filter(Boolean).length > 0;

  return (
    <Mui.Box sx={{ width: '100%' }}>
      <Mui.Paper variant="outlined">
        <PerfectScrollbar>
          <Mui.Table style={{ minWidth: customStyles?.minWidth || 550 }}>
            <Mui.TableHead>
              {table.getHeaderGroups().map(headerGroup => (
                <Mui.TableRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <Mui.TableCell
                      key={header.id}
                      colSpan={header.colSpan}
                      align={columnsAlignment?.[header.id] || 'left'}>
                      {header.isPlaceholder ? null : (
                        <Mui.Box
                          onClick={header.column.getToggleSortingHandler()}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: columnsAlignment?.[header.id] || '',
                          }}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {isSortable && header.column.getCanSort()
                            ? { asc: ' 🔼', desc: ' 🔽' }[header.column.getIsSorted()] ?? ' 📶'
                            : null}
                        </Mui.Box>
                      )}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              ))}
            </Mui.TableHead>
            <Mui.TableBody className={hasFooters || isPaginated ? null : classes.lastBorder}>
              {table.getRowModel().rows.map(row => (
                <Mui.TableRow key={row.id} hover>
                  {row.getVisibleCells().map(cell => (
                    <Mui.TableCell
                      key={cell.id}
                      size="small"
                      align={columnsAlignment?.[cell.column.id] || 'left'}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              ))}
            </Mui.TableBody>
            {hasFooters && (
              <Mui.TableFooter className={isPaginated ? null : classes.lastBorder}>
                {table.getFooterGroups().map(footerGroup => (
                  <Mui.TableRow key={footerGroup.id}>
                    {footerGroup.headers.map(header => (
                      <Mui.TableCell
                        key={header.id}
                        size="small"
                        align={columnsAlignment?.[header.id] || 'left'}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.footer, header.getContext())}
                      </Mui.TableCell>
                    ))}
                  </Mui.TableRow>
                ))}
              </Mui.TableFooter>
            )}
          </Mui.Table>
        </PerfectScrollbar>
        {isPaginated && (
          <Mui.TablePagination
            rowsPerPageOptions={[
              ...(pageSizes || [5, 10, 25]),
              { label: 'Todos', value: data.length },
            ]}
            component="div"
            count={table.getFilteredRowModel().rows.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
            onPageChange={(_, page) => table.setPageIndex(page)}
            onRowsPerPageChange={e => {
              const size = e.target.value ? Number(e.target.value) : 10;
              table.setPageSize(size);
            }}
            ActionsComponent={Mui.TablePaginationActions}
          />
        )}
      </Mui.Paper>
    </Mui.Box>
  );
}

Tanstack.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  defaultPaginationOption: PropTypes.any,
  defaultSortOption: PropTypes.any,
  pageSizes: PropTypes.array,
  isPaginated: PropTypes.bool,
  isSortable: PropTypes.bool,
  columnsAlignment: PropTypes.object,
  customStyles: PropTypes.object,
};

export default Tanstack;
