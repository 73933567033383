const TOKEN = `@eskolare/token`;
const EXPIRY = `@eskolare/expiry`;

export default function sessionToken(tokens = null) {
  if (typeof localStorage !== 'undefined') {
    if (tokens && (tokens.token || tokens.expiry)) {
      if (tokens.token) localStorage.setItem(TOKEN, tokens.token);
      if (tokens.expiry) localStorage.setItem(EXPIRY, tokens.expiry);
    }

    if (tokens === null) {
      return {
        token: localStorage.getItem(TOKEN),
        expiry: localStorage.getItem(EXPIRY),
      };
    }

    if (tokens.destroy) {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(EXPIRY);
    }
  }

  return { token: null, expiry: null };
}
