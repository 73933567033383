import { usePermissions } from 'hooks';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React, { useState, useRef } from 'react';

// Components
import { Mui } from 'components';

export function ChangeContextMenu() {
  // Hooks
  const ref = useRef(null);
  const { pathname } = useLocation();
  const { permissions } = usePermissions();

  // Constants
  const { isStaff = false, isSeller = false, isInstitution = false } = permissions;

  // States
  const [open, setOpen] = useState(false);

  const items = [
    {
      key: 'institution',
      icon: <Mui.Icon>school_outlined</Mui.Icon>,
      label: 'Instituição',
      visible: isInstitution || isStaff,
      to: '/institutions',
    },
    {
      key: 'seller',
      icon: <Mui.Icon>store_outlined</Mui.Icon>,
      label: 'Vendedor',
      visible: isSeller || isStaff,
      to: '/sellers',
    },
    {
      key: 'family',
      icon: <Mui.Icon>house_outlined</Mui.Icon>,
      label: 'Família',
      visible: true,
      to: '/accounts',
    },
  ].filter(item => item.visible);

  if (isStaff || isSeller || isInstitution) {
    return (
      <>
        <Mui.IconButton onClick={() => setOpen(true)} ref={ref}>
          <Mui.Icon>apps</Mui.Icon>
        </Mui.IconButton>
        <Mui.Menu
          keepMounted
          anchorEl={ref.current}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          open={open}
          onClose={() => setOpen(false)}
          getContentAnchorEl={null}>
          {items.map(item => (
            <Mui.MenuItem
              key={item.key}
              component={RouterLink}
              to={item.to}
              target={item.target}
              selected={pathname.includes(item.to)}>
              <Mui.ListItemIcon>
                <Mui.Icon>{item.icon}</Mui.Icon>
              </Mui.ListItemIcon>
              <Mui.ListItemText>{item.label}</Mui.ListItemText>
            </Mui.MenuItem>
          ))}
        </Mui.Menu>
      </>
    );
  }

  return null;
}

export default ChangeContextMenu;
