/*
 *
 * SocialAuthentication actions
 *
 */

import * as constants from './constants';

export function socialConnectRequest(payload) {
  return {
    type: constants.SOCIAL_CONNECT_REQUEST,
    payload,
  };
}

export function socialConnectSuccess(payload) {
  return {
    type: constants.SOCIAL_CONNECT_SUCCESS,
    payload,
  };
}

export function socialConnectFailure(payload) {
  return {
    type: constants.SOCIAL_CONNECT_FAILURE,
    payload,
  };
}
