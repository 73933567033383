/*
 * FamilyPurchaseButton Messages
 *
 * This contains all the text for the FamilyPurchaseButton container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.families.FamilyPurchaseButton';

export default defineMessages({
  outOfStock: {
    id: `${scope}.outOfStock`,
    defaultMessage: 'Sem estoque',
  },
});
