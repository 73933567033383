import { Mui } from 'components';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    backgroundImage: ({ disableRepeater }) => !disableRepeater && `url(${theme.assets.repeater})`,
    backgroundColor: ({ color }) => color || theme.palette.primary.main,
    backgroundRepeat: 'repeat',
    width: '-webkit-fill-available',
    minWidth: '100%',
    height: 2,
    minHeight: 2,
    content: '',
  },
}));

function Repeater(props) {
  const { color, disableRepeater } = props;

  const classes = useStyles({ color, disableRepeater });

  return <div className={classes.root}></div>;
}

Repeater.propTypes = {
  color: PropTypes.string,
  disableRepeater: PropTypes.bool,
};

export default Repeater;
