/**
 *
 * Summary
 *
 */

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Divider, Grid, Mui, Paper, Typography } from 'components';
import * as DisplayData from '../DisplayData';

import messages from './messages';

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  summary: {
    padding: theme.spacing(2),
  },
}));

function Summary(props) {
  const {
    variant,
    children,
    loading,
    disabled,
    discountTotal,
    canShip,
    freightTotal,
    interestTotal,
    numItems,
    onContinue,
    subtotal,
    checkoutPaymentsTotal,
    futurePaymentsTotal,
    total,
    requiresShipping,
    postcode,
    shippingAddress,
  } = props;

  const classes = useStyles(props);

  // eslint-disable-next-line react/prop-types
  const Item = ({ label, value, chip = false, bottom = false }) => (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography
          variant={bottom ? 'body1' : 'overline'}
          color={bottom ? 'inherit' : 'textSecondary'}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        {chip ? (
          <Mui.Chip color="secondary" variant="outlined" label={<strong>{value}</strong>} />
        ) : (
          <Typography variant={bottom ? 'h6' : 'body1'} color={bottom ? 'primary' : 'textPrimary'}>
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Mui.Box>
      <Paper className={classes.root} variant={variant}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              <FormattedMessage {...messages.summaryHeader} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Item label={<FormattedMessage {...messages.itemsLabel} />} value={numItems} chip />
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
          <Grid item xs={12}>
            <Item
              label={<FormattedMessage {...messages.subtotalLabel} />}
              value={<DisplayData.CurrencyField value={subtotal} />}
            />
          </Grid>
          {requiresShipping && (
            <Grid item xs={12}>
              <Item
                label={<FormattedMessage {...messages.freightLabel} />}
                value={
                  canShip && (postcode || shippingAddress) ? (
                    <DisplayData.CurrencyField free value={freightTotal} />
                  ) : (
                    <FormattedMessage {...messages.noFreightLabel} />
                  )
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Item
              label={<FormattedMessage {...messages.discountLabel} />}
              value={<DisplayData.CurrencyField value={discountTotal} />}
            />
          </Grid>
          {interestTotal !== '0.00' && (
            <Grid item xs={12}>
              <Item
                label={<FormattedMessage {...messages.interestLabel} />}
                value={<DisplayData.CurrencyField value={interestTotal} />}
              />
            </Grid>
          )}
          {checkoutPaymentsTotal !== total && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Item
                  label={<FormattedMessage {...messages.checkoutPaymentsTotalLabel} />}
                  value={<DisplayData.CurrencyField value={checkoutPaymentsTotal} />}
                />
              </Grid>
              <Grid item xs={12}>
                <Item
                  label={<FormattedMessage {...messages.futurePaymentsTotalLabel} />}
                  value={<DisplayData.CurrencyField value={futurePaymentsTotal} />}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Item
              bottom
              label={<FormattedMessage {...messages.totalLabel} />}
              value={<DisplayData.CurrencyField free value={total} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              component={RouterLink}
              to="/checkout"
              disabled={disabled}
              loading={loading}
              fullWidth
              size="large">
              <FormattedMessage {...messages.checkoutButton} />
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {onContinue && (
        <Mui.Box mt={1}>
          <Button onClick={onContinue} variant="text" fullWidth>
            <FormattedMessage {...messages.cartCloseButton} />
          </Button>
        </Mui.Box>
      )}
    </Mui.Box>
  );
}

Summary.defaultProps = {
  variant: 'elevation',
};

Summary.propTypes = {
  children: PropTypes.any,
  discountTotal: PropTypes.any,
  canShip: PropTypes.bool,
  freightTotal: PropTypes.any,
  interestTotal: PropTypes.any,
  numItems: PropTypes.any,
  onContinue: PropTypes.func,
  subtotal: PropTypes.any,
  total: PropTypes.any,
  loading: PropTypes.bool,
  requiresShipping: PropTypes.bool,
  disabled: PropTypes.bool,
  checkoutPaymentsTotal: PropTypes.any,
  futurePaymentsTotal: PropTypes.any,
  variant: PropTypes.oneOf(['elevation', 'outlined']),
  postcode: PropTypes.any,
  shippingAddress: PropTypes.any,
};

export default Summary;
