import axios from 'axios';

import sessionToken from '../sessionToken';

const registerAccessTokenInterceptor = () => {
  axios.interceptors.request.use(
    config => {
      const { token } = sessionToken();
      const { Authorization } = config.headers;

      if (token && !Authorization) {
        config.headers.Authorization = `Token ${token}`; //eslint-disable-line
      }

      return config;
    },
    error => Promise.reject(error),
  );
};

export default () => {
  registerAccessTokenInterceptor();
};
