/*
 *
 * CommonJobNotification api's
 *
 */

import axios from 'axios';

import envs from '../../../envs';

const domain = `${envs.PROTOCOL}://${envs.ENTRYPOINT}/api`;

export const endpoints = {
  list: query => `${domain}/commons/jobs/?${query}`,
  retrieve: jobUid => `${domain}/commons/jobs/${jobUid}/`,
};

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#5781b4dc-6619-4bc6-b356-e7f99b636794
 */
export function list({ query }) {
  const newQuery = new URLSearchParams(query);

  const options = {
    method: 'get',
    url: endpoints.list(newQuery.toString()),
  };
  return axios(options);
}

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#504dffb1-3414-419c-b7ee-34dcb184353f
 */
export function retrieve({ jobUid }) {
  const options = {
    method: 'get',
    url: endpoints.retrieve(jobUid),
  };
  return axios(options);
}
