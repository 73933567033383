import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Box, Button, Link, Portal, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(2),
    padding: theme.spacing(1.5),
    outline: 'none',
    zIndex: 2000,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

function CookiesNotification() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography variant="body1" color="primary">
          <FormattedMessage {...messages.privacyTitle} />
        </Typography>
        <Typography variant="body1" color="inherit">
          <FormattedMessage
            {...messages.agreementMessage}
            values={{
              b: words => <strong>{words}</strong>,
              privacyPolicy: (
                <Link
                  component="a"
                  color="primary"
                  underline="always"
                  href="/privacy"
                  target="_blank">
                  <FormattedMessage {...messages.privacyLink} />
                </Link>
              ),
            }}
          />
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={handleClose} color="primary" variant="outlined">
            <FormattedMessage {...messages.agreeButton} />
          </Button>
        </Box>
      </div>
    </Portal>
  );
}

export default CookiesNotification;
