/*
 *
 * PermissionProvider constants
 *
 */

const app = 'app/widgets/PermissionProvider';

export const RETRIEVE_REQUEST = `${app}/RETRIEVE_REQUEST`;
export const RETRIEVE_SUCCESS = `${app}/RETRIEVE_SUCCESS`;
export const RETRIEVE_FAILURE = `${app}/RETRIEVE_FAILURE`;

export const INSTITUTIONS_REQUEST = `${app}/INSTITUTIONS_REQUEST`;
export const INSTITUTIONS_SUCCESS = `${app}/INSTITUTIONS_SUCCESS`;
export const INSTITUTIONS_FAILURE = `${app}/INSTITUTIONS_FAILURE`;

export const SELLERS_REQUEST = `${app}/SELLERS_REQUEST`;
export const SELLERS_SUCCESS = `${app}/SELLERS_SUCCESS`;
export const SELLERS_FAILURE = `${app}/SELLERS_FAILURE`;
