import { defaultParseDate } from 'utils/formatter';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

// Components
import { Button, Mui, TextField } from 'components';
import { Drawer, Stack } from 'components/layouts';

// Local Components
import PresetPeriodMenu from '../PresetPeriodMenu/Loadable';

function PeriodCompareFilter({ query, setQuery, open, setOpen, title, description, checkKeys }) {
  // Hooks
  const buttonRef = useRef(null);

  // States
  const [values, setValues] = useState({
    fromDate: query?.[checkKeys.fromDate] || '',
    toDate: query?.[checkKeys.toDate] || '',
    prevFromDate: query?.[checkKeys.prevFromDate] || '',
    prevToDate: query?.[checkKeys.prevToDate] || '',
  });

  // Constants
  const { fromDate, toDate, prevFromDate, prevToDate } = values;

  // Actions
  const handleDateChange = (field, newDate, period) =>
    setValues(prevValues => ({
      ...prevValues,
      [checkKeys.cycle]: '',
      [checkKeys.prevCycle]: '',
      [field]: newDate ? defaultParseDate(newDate, period).toISOString() : '',
    }));

  const handleFilter = newValues => {
    const changeValues = {
      [checkKeys.fromDate]: newValues?.fromDate,
      [checkKeys.toDate]: newValues?.toDate,
      [checkKeys.prevFromDate]: newValues?.prevFromDate,
      [checkKeys.prevToDate]: newValues?.prevToDate,
    };
    setQuery(prevQuery => ({ ...prevQuery, ...changeValues }));
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} title={title} description={description}>
      <Mui.Container>
        <Stack
          layout={[
            { xs: 12 },
            { xs: 12 },
            { xs: 12 },
            { xs: 12, sm: 6 },
            { xs: 12, sm: 6 },
            { xs: 12 },
            { xs: 12, sm: 6 },
            { xs: 12, sm: 6 },
            { xs: 12 },
          ]}>
          <Mui.Card variant="outlined">
            <PresetPeriodMenu
              onSelect={({ startDate, endDate, prevStartDate, prevEndDate }) => {
                setValues({
                  cycle: '',
                  prevCycle: '',
                  fromDate: startDate,
                  toDate: endDate,
                  prevFromDate: prevStartDate,
                  prevToDate: prevEndDate,
                });
                buttonRef.current.scrollIntoView();
              }}
              onSubmit={newValues => {
                handleFilter(newValues);
                setValues(newValues);
                setOpen(false);
              }}
              dense
            />
          </Mui.Card>
          <Mui.Typography variant="h6">Quer consultar um período específico?</Mui.Typography>
          <Mui.Typography>Período comparação atual</Mui.Typography>
          <TextField
            id={checkKeys.fromDate}
            value={fromDate}
            label="De"
            envelope="date"
            onChange={newDate => handleDateChange('fromDate', newDate, 'start')}
            margin="none"
          />
          <TextField
            id={checkKeys.toDate}
            value={toDate}
            label="Até"
            envelope="date"
            onChange={newDate => handleDateChange('toDate', newDate, 'end')}
            margin="none"
          />
          <Mui.Typography>Período comparação anterior</Mui.Typography>
          <TextField
            id={checkKeys.prevFromDate}
            value={prevFromDate}
            label="De"
            envelope="date"
            onChange={newDate => handleDateChange('prevFromDate', newDate, 'start')}
            margin="none"
          />
          <TextField
            id={checkKeys.prevToDate}
            value={prevToDate}
            label="Até"
            envelope="date"
            onChange={newDate => handleDateChange('prevToDate', newDate, 'end')}
            margin="none"
          />
          <Mui.Box ref={buttonRef} mb={10}>
            <Button
              color="primary"
              size="large"
              onClick={() => {
                handleFilter(values);
                setOpen(false);
              }}>
              Filtrar
            </Button>
          </Mui.Box>
        </Stack>
      </Mui.Container>
    </Drawer>
  );
}

PeriodCompareFilter.propTypes = {
  query: PropTypes.object,
  setQuery: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  checkKeys: PropTypes.shape({
    cycle: PropTypes.string,
    prevCycle: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    prevFromDate: PropTypes.string,
    prevToDate: PropTypes.string,
  }),
};

PeriodCompareFilter.defaultProps = {
  title: 'Filtrar por período',
  description: 'Você pode filtrar pelo período predefinido abaixo ou um período específico.',
  checkKeys: {
    cycle: 'cycle',
    prevCycle: 'prevCycle',
    fromDate: 'fromDate',
    toDate: 'toDate',
    prevFromDate: 'prevFromDate',
    prevToDate: 'prevToDate',
  },
};

export default PeriodCompareFilter;
