import { loadable } from 'utils';

export const Grade = loadable(() => import('./Grade'), {});
export const InstitutionStudent = loadable(() => import('./InstitutionStudent'), {});
export const InstitutionStudentEnrollment = loadable(
  () => import('./InstitutionStudentEnrollment'),
  {},
);
export const InstitutionContractTemplate = loadable(
  () => import('./InstitutionContractTemplate'),
  {},
);
export const InstitutionContractTemplatePayment = loadable(
  () => import('./InstitutionContractTemplatePayment'),
  {},
);
export const InstitutionContractTemplateProduct = loadable(
  () => import('./InstitutionContractTemplateProduct'),
  {},
);
export const InstitutionEnrollmentTemplate = loadable(
  () => import('./InstitutionEnrollmentTemplate'),
  {},
);
export const Configuration = loadable(() => import('./Configuration'), {});
