/**
 * This componente is the landing page footer.
 *
 */

import * as React from 'react';
import PropTypes from 'prop-types';

import { Grid, Mui } from '../..';
import { useStyles } from './styles';
import * as images from '../../../images';
import Bottom from './Bottom';
import Legal from './Legal';
import PaymentMethods from './PaymentMethods';
import Rewards from './Rewards';
import Section from './Section';
import SocialLink from './SocialLink';

function Footer(props) {
  const classes = useStyles(props);

  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  const { children, ContactForm, simple } = props;

  if (simple) {
    return (
      <div>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={2} xs={12}>
            <center>
              <img className={classes.logo} src={images.logoSymbolSvg} alt="Eskolare" />
            </center>
          </Grid>
          <Grid item md={10} xs={12}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              <Grid item xs>
                <Legal ContactForm={ContactForm} />
              </Grid>
              <Grid item xs={fullScreen && 6}>
                <SocialLink />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Bottom>{children}</Bottom>
      </div>
    );
  }

  return (
    <Mui.Box mb={1} mt={1}>
      <Mui.Divider />
      <Mui.Box mt={3} mb={3}>
        <Mui.Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item md={2} xs={12}>
              <center>
                <img className={classes.logo} src={images.logoSymbolSvg} alt="Eskolare" />
              </center>
            </Grid>
            <Grid item md={10} xs={12}>
              <Section />
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <PaymentMethods />
                </Grid>
                <Grid item md={9} sm={6} xs={12}>
                  <Rewards />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Mui.Container>
      </Mui.Box>
      <Mui.Divider />
      <Mui.Box mt={3} mb={3}>
        <Mui.Container maxWidth="md">
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item xs={fullScreen && 12}>
              <Legal ContactForm={ContactForm} />
            </Grid>
            <Grid item xs={fullScreen && 12}>
              <SocialLink />
            </Grid>
          </Grid>
        </Mui.Container>
      </Mui.Box>
      <Mui.Divider />
      <Mui.Box mt={3} mb={2}>
        <Bottom>{children}</Bottom>
      </Mui.Box>
    </Mui.Box>
  );
}

Footer.propTypes = {
  children: PropTypes.any,
  ContactForm: PropTypes.any,
  simple: PropTypes.bool,
};

export default Footer;
