import { useInjectSaga } from 'redux-injectors';
import PropTypes from 'prop-types';

import saga from './saga';

function Global(props) {
  useInjectSaga({ key: 'global', saga });

  const { children } = props;

  return children;
}

Global.propTypes = {
  children: PropTypes.any,
};

export default Global;
