/*
 *
 * ContactForm actions
 *
 */

import * as constants from './constants';

export function contactRequest(payload) {
  return {
    type: constants.CONTACT_REQUEST,
    payload,
  };
}

export function contactSuccess(payload) {
  return {
    type: constants.CONTACT_SUCCESS,
    payload,
  };
}

export function contactFailure(payload) {
  return {
    type: constants.CONTACT_FAILURE,
    payload,
  };
}
