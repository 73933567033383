/*
 * PublicLayout Messages
 *
 * This `conta`ins all the text for the PublicLayout container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.layouts.PublicLayout';

export default defineMessages({
  loginButton: {
    id: `${scope}.loginButton`,
    defaultMessage: 'Entrar',
  },
  signUpButton: {
    id: `${scope}.signUpButton`,
    defaultMessage: 'Cadastre-se',
  },
});
