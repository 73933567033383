import { Box, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import * as images from 'images';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import TextLoop from 'react-text-loop';

// Components
import { Mui } from 'components';
import { DisplayData } from 'components/common';

import messages from './messages';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    minHeight: '60vh',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 60,
    },
  },
  image: {
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      backfaceVisibility: 'hidden',
    },
  },
}));

function Hero(props) {
  const {
    className,
    institutionsCount,
    sellersCount,
    familiesCount,
    productsCount,
    children,
    ...rest
  } = props;

  // Hooks
  const classes = useStyles();
  const [inViewRef, inView] = useInView({ threshold: 0.33 });

  return (
    <Mui.Fade in={inView} ref={inViewRef}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                <Typography variant="h1" color="textPrimary">
                  <FormattedMessage
                    {...messages.title}
                    values={{
                      loop: (
                        <TextLoop noWrap springConfig={{ stiffness: 180, damping: 8 }}>
                          <strong>tudo para a educação</strong>
                          <strong>uniformes escolares</strong>
                          <strong>plataformas digitais</strong>
                          <strong>livros didáticos</strong>
                          <strong>material escolar</strong>
                          <strong>cursos e esportes</strong>
                          <strong>transporte escolar</strong>
                        </TextLoop>
                      ),
                    }}
                  />
                </Typography>
                <Box mt={3}>
                  <Typography variant="body1" color="textSecondary">
                    <FormattedMessage {...messages.subtitle} />
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography variant="h1" color="primary">
                        <DisplayData.NumberField value={institutionsCount} />+
                      </Typography>
                      <Typography variant="overline" color="textSecondary">
                        <FormattedMessage {...messages.institutions} />
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h1" color="primary">
                        <DisplayData.NumberField value={sellersCount} />+
                      </Typography>
                      <Typography variant="overline" color="textSecondary">
                        <FormattedMessage {...messages.sellers} />
                      </Typography>
                    </Grid>
                    {familiesCount && (
                      <Grid item>
                        <Typography variant="h1" color="primary">
                          <DisplayData.NumberField value={familiesCount} />+
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                          <FormattedMessage {...messages.families} />
                        </Typography>
                      </Grid>
                    )}
                    {productsCount && (
                      <Grid item>
                        <Typography variant="h1" color="primary">
                          <DisplayData.NumberField value={productsCount} />+
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                          <FormattedMessage {...messages.products} />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                {children && <Box mt={3}>{children}</Box>}
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Mui.Grow in={inView} timeout={500}>
                <Box position="relative">
                  <div className={classes.image}>
                    <img alt="Presentation" src={images.notebookSmartphoneIllustration} />
                  </div>
                </Box>
              </Mui.Grow>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Mui.Fade>
  );
}

Hero.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  institutionsCount: PropTypes.number,
  sellersCount: PropTypes.number,
  familiesCount: PropTypes.number,
  productsCount: PropTypes.number,
};

export default Hero;
