/*
 *
 * CheckoutExpressPage ducks
 * Read more about this pattern: https://blog.rocketseat.com.br/estrutura-redux-escalavel-com-ducks/
 *
 */

import produce from 'immer';
import { logger } from 'utils';

// Constants

const app = 'app/checkout/CheckoutExpressPage';

export const CHECKOUT_REQUEST = `${app}/CHECKOUT_REQUEST`;
export const CHECKOUT_SUCCESS = `${app}/CHECKOUT_SUCCESS`;
export const CHECKOUT_FAILURE = `${app}/CHECKOUT_FAILURE`;

export const SUBMIT_REQUEST = `${app}/SUBMIT_REQUEST`;
export const SUBMIT_SUCCESS = `${app}/SUBMIT_SUCCESS`;
export const SUBMIT_FAILURE = `${app}/SUBMIT_FAILURE`;

export const TEMP_CREDIT_CARDS = `${app}/TEMP_CREDIT_CARDS`;

// Actions

export function checkoutRequest(payload) {
  return { type: CHECKOUT_REQUEST, payload };
}

export function checkoutSuccess(payload) {
  return { type: CHECKOUT_SUCCESS, payload };
}

export function checkoutFailure(payload) {
  return { type: CHECKOUT_FAILURE, payload };
}

export function submitRequest(payload) {
  return { type: SUBMIT_REQUEST, payload };
}

export function submitSuccess(payload) {
  return { type: SUBMIT_SUCCESS, payload };
}

export function submitFailure(payload) {
  return { type: SUBMIT_FAILURE, payload };
}

export function tempCreditCards(payload) {
  return { type: TEMP_CREDIT_CARDS, payload };
}

// Reducer

export const initialState = {
  isFetching: {
    checkout: {},
    submit: {},
  },
  orders: {},
  contracts: {},
  tuition: {},
  subscriptions: {},
  checkout: {},
  submit: {},
  tempCreditCards: {},
};

/* eslint-disable default-case, no-param-reassign */
const checkoutExpressPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CHECKOUT_REQUEST: {
        const { basketUid } = action.payload;
        draft.isFetching.checkout[basketUid] = true;
        break;
      }
      case CHECKOUT_SUCCESS: {
        const { basketUid, response } = action.payload;
        const { packages } = response;
        draft.isFetching.checkout[basketUid] = false;
        draft.checkout[basketUid] = response;
        if (packages) {
          draft.orders[basketUid] = packages.filter(item => item.type === 'order');
          draft.contracts[basketUid] = packages.filter(item => item.type === 'contract');
          draft.tuition[basketUid] = packages.filter(item => item.type === 'tuition');
        } else {
          logger({ message: 'Response without object packages key' }, { response });
        }
        break;
      }
      case CHECKOUT_FAILURE: {
        const { basketUid } = action.payload;
        draft.isFetching.checkout[basketUid] = false;
        draft.checkout[basketUid] = null;
        break;
      }
      case SUBMIT_REQUEST: {
        const { basketUid } = action.payload;
        draft.isFetching.submit[basketUid] = true;
        break;
      }
      case SUBMIT_SUCCESS: {
        const { basketUid, response } = action.payload;
        draft.isFetching.submit[basketUid] = false;
        draft.submit[basketUid] = response;
        break;
      }
      case SUBMIT_FAILURE: {
        const { basketUid } = action.payload;
        draft.isFetching.submit[basketUid] = false;
        draft.submit[basketUid] = null;
        break;
      }
      case TEMP_CREDIT_CARDS: {
        const { basketUid, creditCards } = action.payload;
        draft.tempCreditCards[basketUid] = creditCards;
        break;
      }
    }
  });

export default checkoutExpressPageReducer;
