/*
 * ReasonsToChoose Messages
 *
 * This `conta`ins all the text for the ReasonsToChoose container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.landing.ReasonsToChoose';

export default defineMessages({
  reasonsToChooseSectionTitle: {
    id: `${scope}.reasonsToChooseSectionTitle`,
    defaultMessage: 'Seis motivos para comprar na Eskolare',
  },
  reasonsToChooseSectionOverline: {
    id: `${scope}.reasonsToChooseSectionOverline`,
    defaultMessage: 'Proposta de valor e benefícios exclusivos',
  },
  reasonsToChooseReason1: {
    id: `${scope}.reasonsToChooseReason1`,
    defaultMessage: 'Melhor custo benefício',
  },
  reasonsToChooseReason1Description: {
    id: `${scope}.reasonsToChooseReason1Description`,
    defaultMessage: 'Encontre produtos e serviços de A a Z pelo melhor preço',
  },
  reasonsToChooseReason2: {
    id: `${scope}.reasonsToChooseReason2`,
    defaultMessage: 'Protegemos os seus dados',
  },
  reasonsToChooseReason2Description: {
    id: `${scope}.reasonsToChooseReason2Description`,
    defaultMessage: 'Cumprimos com os mais altos padrões de segurança da indústria',
  },
  reasonsToChooseReason3: {
    id: `${scope}.reasonsToChooseReason3`,
    defaultMessage: 'Pagamento facilitado',
  },
  reasonsToChooseReason3Description: {
    id: `${scope}.reasonsToChooseReason3Description`,
    defaultMessage: `Débito em conta,
      transferência bancária, pagamento em dinheiro, cartão de crédito e boleto bancário`,
  },
  reasonsToChooseReason4: {
    id: `${scope}.reasonsToChooseReason4`,
    defaultMessage: 'Sua secretaria 24 horas',
  },
  reasonsToChooseReason4Description: {
    id: `${scope}.reasonsToChooseReason4Description`,
    defaultMessage: 'Nossa equipe especializada sempre está pronta para ajudar você',
  },
  reasonsToChooseReason5: {
    id: `${scope}.reasonsToChooseReason5`,
    defaultMessage: 'Compre com confiança',
  },
  reasonsToChooseReason5Description: {
    id: `${scope}.reasonsToChooseReason5Description`,
    defaultMessage: `Queremos que você se sinta seguro ao fazer suas compras. Por isso, criamos ferramentas para cuidar de você em cada etapa`,
  },
  reasonsToChooseReason6: {
    id: `${scope}.reasonsToChooseReason6`,
    defaultMessage: 'Acompanhamos até a entrega',
  },
  reasonsToChooseReason6Description: {
    id: `${scope}.reasonsToChooseReason6Description`,
    defaultMessage: 'Garantimos a entrega de sua compra, seja um produto ou serviço',
  },
});
