import { useUser } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui } from 'components';
import { NavBar as DefaultNavBar } from 'components/layouts';

function NavBar({ onMobileClose, openMobile, ...restProps }) {
  // Custom hooks
  const { user } = useUser();

  // Constants
  const { picture, firstName, lastName, email } = user;

  // Props
  const profile = {
    image: picture,
    displayName: [firstName, lastName].join(' '),
    profileUrl: '/accounts',
    info: email,
  };

  const items = [
    {
      subheader: 'Sua conta',
      items: [
        {
          title: 'Seu perfil',
          href: '/accounts/profile',
          icon: newProps => <Mui.Icon {...newProps}>account_circle</Mui.Icon>,
        },
        {
          title: 'Alterar senha',
          href: '/accounts/change-password',
          icon: newProps => <Mui.Icon {...newProps}>lock</Mui.Icon>,
        },
        {
          title: 'Pedidos',
          href: '/accounts/orders',
          icon: newProps => <Mui.Icon {...newProps}>check_box_outlined</Mui.Icon>,
        },
      ],
    },
    {
      subheader: 'Outros',
      items: [
        {
          title: 'Sair',
          href: '/logout',
          icon: newProps => <Mui.Icon {...newProps}>exit_to_app</Mui.Icon>,
        },
      ],
    },
  ];

  return (
    <DefaultNavBar
      onMobileClose={onMobileClose}
      openMobile={openMobile}
      items={items}
      profile={profile}
      {...restProps}></DefaultNavBar>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
