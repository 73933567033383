import useSWRInfinite from 'swr/infinite';
import api from 'utils/api';

function useFetchInfinite(...args) {
  const [params, ...restArgs] = args;
  const [key, query = {}] = params || [];

  const getKey = index => {
    const newQuery = new URLSearchParams({ ...query, page: index + 1 });
    return `${key}?${newQuery.toString()}`;
  };

  const { data, error, isValidating, mutate, size, setSize } = useSWRInfinite(
    getKey,
    url => api({ method: 'GET', url }).then(res => res.data),
    ...restArgs,
  );

  return { data, error, isValidating, mutate, size, setSize };
}

export default useFetchInfinite;
