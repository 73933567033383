import { createMemoryHistory } from 'history';
import { createTheme } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from '@material-ui/core/locale';
import { Router } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import ptBrLocale from 'date-fns/locale/pt-BR';
import React from 'react';

import { DEFAULT_LOCALE } from '../i18n';
import { defaultTheme } from '../themes';
import ThemeBaseProvider from '../components/eskolare-ui/ThemeBaseProvider';

import ptMessages from '../translations/pt.json';

function TestComponentWrapper(props) {
  const { children } = props;

  const history = createMemoryHistory();
  const theme = createTheme(defaultTheme, ptBR);

  return (
    <IntlProvider locale={DEFAULT_LOCALE} messages={ptMessages}>
      <ThemeBaseProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
          <Router history={history}>{children}</Router>
        </MuiPickersUtilsProvider>
      </ThemeBaseProvider>
    </IntlProvider>
  );
}

TestComponentWrapper.propTypes = {
  children: PropTypes.any,
};

export default TestComponentWrapper;
