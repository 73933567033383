/*
 * JoyrideTooltip Messages
 *
 * This contains all the text for the JoyrideTooltip component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.widgets.JoyrideTooltip';

export default defineMessages({
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Voltar',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Fechar',
  },
  last: {
    id: `${scope}.last`,
    defaultMessage: 'Concluir',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Próximo',
  },
  open: {
    id: `${scope}.open`,
    defaultMessage: 'Abrir',
  },
  restart: {
    id: `${scope}.restart`,
    defaultMessage: 'Recomeçar',
  },
  skip: {
    id: `${scope}.skip`,
    defaultMessage: 'Pular',
  },
});
