import * as FileSaver from 'file-saver';

function generateImportTemplateFromSpreadsheet(fileData, fileName) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = 'xlsx';

  const data = new Blob([fileData], { type: fileType });
  return FileSaver.saveAs(data, `${fileName}.${fileExtension}`);
}

export default generateImportTemplateFromSpreadsheet;
