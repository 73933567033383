import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useQuery } from 'hooks';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui, Logo } from 'components';

// Local Components
import Account from './Account';
import Repeater from '../Repeater';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    zIndex: theme.zIndex.drawer + 100,
    color: theme.palette.text.secondary,
  },
  toolbar: { minHeight: 64 },
  link: {
    textDecoration: 'none',
    '&:link': { textDecoration: 'none' },
    '&:visited': { textDecoration: 'none' },
    '&:hover': { textDecoration: 'none' },
  },
}));

function TopBar({ className, children, data, user, onMobileNavOpen, homeTo = '/', ...rest }) {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();

  // Actions
  const handleBack = () => {
    const swap = query.get('swap');
    if (swap) {
      history.push(swap);
    } else {
      history.goBack();
    }
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
          <Box flexGrow={1}>
            {onMobileNavOpen ? (
              <IconButton color="inherit" onClick={onMobileNavOpen}>
                <Mui.Icon>menu</Mui.Icon>
              </IconButton>
            ) : (
              <IconButton color="inherit" onClick={handleBack}>
                <Mui.Icon>arrow_back</Mui.Icon>
              </IconButton>
            )}
          </Box>
        </Hidden>
        <Hidden smDown>
          <RouterLink to={homeTo} className={classes.link}>
            <Logo displayName={data?.displayName} src={data?.logo} height="32px" />
          </RouterLink>
        </Hidden>
        <Box flexGrow={1} />
        <Box ml={2}>
          <Box display="flex" alignItems="center">
            {React.Children.map(children, (child, index) => {
              if (child)
                return (
                  <Box key={`${index.toString()}`} mr={1}>
                    {child}
                  </Box>
                );

              return null;
            })}
            {user && <Account user={user} />}
          </Box>
        </Box>
      </Toolbar>
      <Repeater />
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  homeTo: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.object,
  user: PropTypes.object,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
