import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';

import { Mui } from '../..';
import * as images from '../../../images';
import messages from './messages';

// Local components
import FeatureExplorer from './FeatureExplorer';
import TabPanel from './TabPanel';

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: '70vh',
  },
  tabsPaper: {
    paddingLeft: 10,
    paddingRight: 10,
    alignSelf: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    overflow: 'hidden',
  },
  indicator: {
    backgroundColor: '#FFF',
  },
}));

function PersonasTabs() {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => setValue(newValue);

  const tabs = [
    {
      label: <FormattedMessage {...messages.personasTabsFamiliesLabel} />,
      title: <FormattedMessage {...messages.personasTabsFamiliesTitle} />,
      overline: <FormattedMessage {...messages.personasTabsFamiliesOverline} />,
      href: '/families',
      features: [
        {
          icon: images.iconsMagnifyingGlass,
          body: 'Pesquise tudo para educação dos seus filhos',
          helperImage: images.personasFeaturesFamiliesFeatureOne,
        },
        {
          icon: images.iconsExpressCheckout,
          body: 'Compre em poucos minutos',
          helperImage: images.personasFeaturesFamiliesFeatureTwo,
        },
        {
          icon: images.iconsCustomStore,
          body: 'Experiência de compra customizada para você',
          helperImage: images.personasFeaturesFamiliesFeatureThree,
        },
        {
          icon: images.iconsGroupOfPeople,
          body: 'Múltiplos alunos, múltiplas matrículas',
          helperImage: images.personasFeaturesFamiliesFeatureFour,
        },
        {
          icon: images.iconsMoney,
          body: 'Utilize a forma de pagamento que preferir',
          helperImage: images.personasFeaturesFamiliesFeatureFive,
        },
      ],
    },
    {
      label: <FormattedMessage {...messages.personasTabsInstitutionsLabel} />,
      title: <FormattedMessage {...messages.personasTabsInstitutionsTitle} />,
      overline: <FormattedMessage {...messages.personasTabsInstitutionsOverline} />,
      href: 'https://rdstation.eskolare.com.br/conversao_instituicoes',
      features: [
        {
          icon: images.iconsCustomStore,
          body: 'Loja customizada',
          helperImage: images.personasFeaturesInstitutionsFeatureOne,
        },
        {
          icon: images.iconsExpressCheckout,
          body: 'Checkout expresso',
          helperImage: images.personasFeaturesInstitutionsFeatureTwo,
        },
        {
          icon: images.iconsReceipt,
          body: 'Contrato e assinaturas',
          helperImage: images.personasFeaturesInstitutionsFeaturThree,
        },
        {
          icon: images.iconsOrderManagement,
          body: 'Gestão de pedidos',
          helperImage: images.personasFeaturesInstitutionsFeatureFour,
        },
        {
          icon: images.iconsGroupOfPeople,
          body: 'Divisão de comissões',
          helperImage: images.personasFeaturesInstitutionsFeatureFive,
        },
      ],
    },
    {
      label: <FormattedMessage {...messages.personasTabsSellersLabel} />,
      title: <FormattedMessage {...messages.personasTabsSellersTitle} />,
      overline: <FormattedMessage {...messages.personasTabsSellersOverline} />,
      href: 'https://rdstation.eskolare.com.br/fornecedores-eskolare',
      features: [
        {
          icon: images.iconsGlobe,
          body: 'Venda online em poucos minutos',
          helperImage: images.personasFeaturesSellersFeatureOne,
        },
        {
          icon: images.iconsCashRegister,
          body: 'Múltiplos pagamentos',
          helperImage: images.personasFeaturesSellersFeatureTwo,
        },
        {
          icon: images.iconsReceipt,
          body: 'Pedidos, contratos e assinaturas',
          helperImage: images.personasFeaturesSellersFeatureThree,
        },
        {
          icon: images.iconsCurrencySign,
          body: 'Split automático de múltiplas partes',
          helperImage: images.personasFeaturesSellersFeatureFour,
        },
        {
          icon: images.iconsExpressCheckout,
          body: 'Checkout expresso',
          helperImage: images.personasFeaturesSellersFeatureFive,
        },
      ],
    },
  ];

  return (
    <div className={classes.root}>
      <Mui.Paper className={classes.tabsPaper}>
        <Mui.Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="on"
          classes={{ indicator: classes.indicator }}>
          {tabs &&
            tabs.map((tab, index) => <Mui.Tab key={`${index.toString()}`} label={tab.label} />)}
        </Mui.Tabs>
      </Mui.Paper>
      {tabs.map((tab, index) => (
        <TabPanel key={`${index.toString()}`} value={value} index={index}>
          <Mui.Box pt={4} pb={4}>
            <Mui.Typography variant="overline" component="div" color="primary" align="center">
              {tab.overline}
            </Mui.Typography>
            <Mui.Typography variant="h1" component="div" color="textPrimary" align="center">
              {tab.title}
            </Mui.Typography>
          </Mui.Box>
          <FeatureExplorer features={tab.features} />
        </TabPanel>
      ))}
    </div>
  );
}

export default PersonasTabs;
