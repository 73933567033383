/*
 * CookiesNotification Messages
 *
 * This contains all the text for the CookiesNotification component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.widgets.CookiesNotification';

export default defineMessages({
  agreementMessage: {
    id: `${scope}.agreementMessage`,
    defaultMessage:
      'Utilizamos cookies para personalizar sua experiência em nossa plataforma. Ao continuar navegando, você concorda com a nossa {privacyPolicy}.',
  },
  privacyLink: {
    id: `${scope}.privacyLink`,
    defaultMessage: 'Política de Privacidade',
  },
  agreeButton: {
    id: `${scope}.agreeButton`,
    defaultMessage: 'Continuar e fechar',
  },
  privacyTitle: {
    id: `${scope}.privacyTitle`,
    defaultMessage: 'Privacidade',
  },
});
