/*
 * Workflow Messages
 *
 * This contains all the text for the Workflow component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.common.Workflow';

export default defineMessages({
  optionalLabel: {
    id: `${scope}.optionalLabel`,
    defaultMessage: 'Opcional',
  },
});
