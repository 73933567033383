import useSWR from 'swr';

function useFetch(...args) {
  const [params, ...restArgs] = args;

  const [url, query = {}] = params;
  const newQuery = new URLSearchParams(query);
  const queryString = newQuery.toString();
  const newUrl = queryString ? [url, queryString].join('?') : url;

  const { data, error, isLoading, isValidating, mutate } = useSWR(url ? newUrl : null, ...restArgs);

  return { data, error, isLoading, isValidating, mutate };
}

export default useFetch;
