import { makeStyles } from '@material-ui/styles';
import MuiButtonBase from '@material-ui/core/ButtonBase';
import MuiGrid from '@material-ui/core/Grid';
import MuiPaper from '@material-ui/core/Paper';
import MuiTypography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import * as images from '../../images';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  paper: {
    width: '100%',
  },
}));

function SocialBase(props) {
  const classes = useStyles(props);

  const { children, onClick, src, ...restProps } = props;

  return (
    <MuiPaper variant="outlined" className={classes.paper}>
      <MuiButtonBase onClick={onClick} className={classes.root} {...restProps}>
        <MuiGrid container alignItems="center" justifyContent="center" spacing={1}>
          <MuiGrid item>
            <img src={src} alt="Eskolare" />
          </MuiGrid>
          <MuiGrid item>
            <MuiTypography color="inherit" variant="overline">
              {children}
            </MuiTypography>
          </MuiGrid>
        </MuiGrid>
      </MuiButtonBase>
    </MuiPaper>
  );
}

SocialBase.propTypes = {
  /**
   * The component content.
   */
  children: PropTypes.any,
  /**
   * Callback fired when the user clicks on.
   */
  onClick: PropTypes.func,
  /**
   * The image source url.
   */
  src: PropTypes.any,
};

SocialBase.defaultProps = {
  // eslint-disable-next-line no-console
  onClick: () => null,
};

function SocialButton(props) {
  const { variant, onClick, ...restProps } = props;

  const social = variant || 'facebook';

  const icon = {
    facebook: {
      src: images.logoFacebook,
      label: 'Facebook',
    },
    google: {
      src: images.logoGoogle,
      label: 'Google',
    },
  };

  return (
    <SocialBase onClick={onClick} src={icon[social].src} {...restProps}>
      {icon[social].label}
    </SocialBase>
  );
}

SocialButton.propTypes = {
  /**
   * Callback fired when the user clicks on.
   */
  onClick: PropTypes.func,
  /**
   * The variant option.
   */
  variant: PropTypes.oneOf(['facebook', 'google']),
};

export default SocialButton;
