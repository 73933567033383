import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import * as ducks from './ducks';

function useAnalytics() {
  const dispatch = useDispatch();

  const registerEvent = useCallback(
    (metric, metadata) =>
      dispatch(ducks.registerEventRequest({ metric, source: 'frontend', metadata })),
    [dispatch],
  );

  return {
    registerEvent,
  };
}

export default useAnalytics;
