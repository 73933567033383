import { loadable } from '../../../utils';

export const Active = loadable(() => import('./Active'));
export const AbandonedBasket = loadable(() => import('./AbandonedBasket'));
export const Base = loadable(() => import('./Base'));
export const BillingInvoice = loadable(() => import('./BillingInvoice'));
export const BillingPlan = loadable(() => import('./BillingPlan'));
export const BooleanBadge = loadable(() => import('./BooleanBadge'));
export const ChannelProfile = loadable(() => import('./ChannelProfile'));
export const Concierge = loadable(() => import('./Concierge'));
export const ConciergeBasket = loadable(() => import('./ConciergeBasket'));
export const Enrollment = loadable(() => import('./Enrollment'));
export const EnrollmentRequest = loadable(() => import('./EnrollmentRequest'));
export const EnotasTaxInvoice = loadable(() => import('./EnotasTaxInvoice'));
export const FinancialWithdrawal = loadable(() => import('./FinancialWithdrawal'));
export const FinancialWithdrawalInvoice = loadable(() => import('./FinancialWithdrawalInvoice'));
export const FinancialWithdrawalLine = loadable(() => import('./FinancialWithdrawalLine'));
export const FreightFile = loadable(() => import('./FreightFile'));
export const InstitutionShipmentInventory = loadable(() =>
  import('./InstitutionShipmentInventory'),
);
export const InstitutionShipmentInventoryItem = loadable(() =>
  import('./InstitutionShipmentInventoryItem'),
);
export const InstitutionShipmentInvoice = loadable(() => import('./InstitutionShipmentInvoice'));
export const InstitutionShipmentOrderLine = loadable(() =>
  import('./InstitutionShipmentOrderLine'),
);
export const InstitutionShipmentReverseLogistic = loadable(() =>
  import('./InstitutionShipmentReverseLogistic'),
);
export const Job = loadable(() => import('./Job'));
export const Order = loadable(() => import('./Order'));
export const OrderCancellation = loadable(() => import('./OrderCancellation'));
export const OrderLine = loadable(() => import('./OrderLine'));
export const Payment = loadable(() => import('./Payment'));
export const PaymentCharge = loadable(() => import('./PaymentCharge'));
export const Privacy = loadable(() => import('./Privacy'));
export const ProductSuggestion = loadable(() => import('./ProductSuggestion'));
export const SaleAgreement = loadable(() => import('./SaleAgreement'));
export const Ticket = loadable(() => import('./Ticket'));
export const TicketPriority = loadable(() => import('./TicketPriority'));
export const Transaction = loadable(() => import('./Transaction'));
