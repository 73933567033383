/*
 * SocialButtons Messages
 *
 * This contains all the text for the SocialButtons component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.common.SocialButtons';

export default defineMessages({
  share: {
    id: `${scope}.share`,
    defaultMessage: 'Compartilhe',
  },
});
