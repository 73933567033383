import { create } from 'jss';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import ptBrLocale from 'date-fns/locale/pt-BR';
import React from 'react';
import rtl from 'jss-rtl';

import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import DateFnsUtils from '@date-io/date-fns';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#app': {
        height: '100%',
        width: '100%',
      },
    },
  }),
);

function ThemeBaseProvider(props) {
  useStyles();

  const { children, ...other } = props;

  return (
    <>
      <CssBaseline />
      <ThemeProvider {...other}>
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
            {children || <div></div>}
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </>
  );
}

ThemeBaseProvider.propTypes = {
  children: PropTypes.any,
};

export default ThemeBaseProvider;
