import { FormattedMessage } from 'react-intl';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { matchPath } from 'react-router';
import React, { useState, useEffect } from 'react';

// Components
import { Mui } from 'components';
import { Drawer } from 'components/layouts';

import messages from './messages';

// Local components
import Students from './components/Students';
import Enrollments from './components/Enrollments';
import CurrentEnrollment from './components/CurrentEnrollment';

function FamilyEnrollmentMenu() {
  // Hooks
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const match = matchPath(pathname, { path: '/families/:enrollmentSlug' });

  // Constants
  const { params = {} } = match || {};
  const { enrollmentSlug } = params;

  // Effects
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <div>
      <CurrentEnrollment enrollmentSlug={enrollmentSlug} onClick={() => setOpen(true)} />
      <Mui.Divider />
      <Drawer
        open={open}
        width={500}
        onClose={() => setOpen(false)}
        title={<FormattedMessage {...messages.enrollments} />}
        description={
          <FormattedMessage
            {...messages.students}
            values={{
              a: chunks => (
                <Mui.Link
                  variant="body1"
                  component={RouterLink}
                  to={{ pathname: '/accounts/students', search: '?new=1' }}>
                  <strong>{chunks}</strong>
                </Mui.Link>
              ),
            }}
          />
        }>
        <Mui.Container>
          <Students>
            {student => <Enrollments student={student} selected={enrollmentSlug} />}
          </Students>
          <Mui.Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            startIcon={<Mui.Icon>add_circle</Mui.Icon>}
            component={RouterLink}
            to={{ pathname: '/accounts/students', search: '?new=1' }}>
            Cadastrar novo aluno
          </Mui.Button>
        </Mui.Container>
      </Drawer>
    </div>
  );
}

FamilyEnrollmentMenu.propTypes = {};

export default FamilyEnrollmentMenu;
