import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Mui } from '../..';
import * as Dialogs from '../Dialogs';
import messages from './messages';

function Clean(props) {
  const { onClick, loading, firstName, lastName } = props;

  const [open, setOpen] = useState(false);

  return (
    <div>
      <Mui.Tooltip title={<FormattedMessage {...messages.cleanTooltip} />}>
        <Mui.IconButton onClick={() => setOpen(true)}>
          <Mui.Icon color="error">delete</Mui.Icon>
        </Mui.IconButton>
      </Mui.Tooltip>
      <Dialogs.Confirmation
        open={open}
        title={<FormattedMessage {...messages.cleanTooltip} />}
        onClose={() => setOpen(!open)}
        loading={loading}
        onConfirm={onClick}>
        <FormattedMessage
          {...messages.cleanMessage}
          values={{
            firstName: <strong>{firstName}</strong>,
            lastName: <strong>{lastName}</strong>,
          }}
        />
      </Dialogs.Confirmation>
    </div>
  );
}

Clean.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default Clean;
