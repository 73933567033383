/**
 *
 * PermissionProvider
 *
 */
import React, { useEffect, useState, useCallback } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { SplashScreen } from 'components/layouts';

import reducer from './reducer';
import * as actions from './actions';
import saga from './saga';

// Hooks
import { useUser } from '../../../hooks';
import usePermissions from './usePermissions';

export function PermissionProvider(props) {
  useInjectReducer({ key: 'permissionProvider', reducer });
  useInjectSaga({ key: 'permissionProvider', saga });

  const { children } = props;

  // Hooks
  const dispatch = useDispatch();

  // Custom hooks
  const { isAuthenticated } = useUser();

  // States
  const [sessionVerified, setSessionVerified] = useState(false);

  const callback = useCallback(() => {
    setTimeout(() => setSessionVerified(true), 250);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actions.retrieveRequest({ callback }));
    } else {
      setSessionVerified(true);
    }
  }, [isAuthenticated, sessionVerified, dispatch, callback]);

  if (sessionVerified) return children;

  return <SplashScreen />;
}

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PermissionProvider;
export { usePermissions };
