import { createTheme } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import deepmerge from 'deepmerge';

// Components
import { Mui } from 'components';

// Containers
import useTheme from '../ThemeProvider/useTheme';

import { themeConstructor } from './utils';

export function CustomTheme(props) {
  const { children } = props;

  // Store
  const { theme } = useTheme();

  // Constants
  const { activeTheme, type, retrieve } = theme;
  const data = retrieve[activeTheme];
  const customTheme = themeConstructor({ data, type });

  return (
    <Mui.ThemeProvider theme={prevTheme => createTheme(deepmerge(prevTheme, customTheme))}>
      {children}
    </Mui.ThemeProvider>
  );
}

CustomTheme.propTypes = {
  children: PropTypes.any,
};

export default CustomTheme;
