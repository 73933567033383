import PropTypes from 'prop-types';
import React from 'react';

import { Mui } from '../..';
import Base from './Base';

import { CONTRACT_STATUS } from '../DisplayStatus/Order';
import * as DisplayData from '../DisplayData';

function Contract(props) {
  const { changes, ...otherProps } = props;

  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  const steps = changes.map(change => ({
    color: CONTRACT_STATUS[change.status].color,
    icon: <Mui.Icon>{CONTRACT_STATUS[change.status].icon}</Mui.Icon>,
    status: change.status,
    label: change.statusDisplay,
    optional: !!change.dateCreated || !!change.date,
    optionalLabel: (
      <DisplayData.DateField type="datetime" value={change.dateCreated || change.date} />
    ),
  }));

  /**
   * The usual workflow is mounted depending
   * of the last step in changes
   */
  if (steps[steps.length - 1]?.status === 'pending') {
    steps.push(CONTRACT_STATUS.active);
  }
  if (steps[steps.length - 1]?.status === 'active') {
    steps.push(CONTRACT_STATUS.finished);
  }
  if (steps[steps.length - 1]?.status === 'cancellation-requested') {
    steps.push(CONTRACT_STATUS.canceled);
  }

  return (
    <Base
      steps={steps}
      alternativeLabel={!fullScreen}
      orientation={fullScreen ? 'vertical' : 'horizontal'}
      activeStep={changes.length - 1}
      {...otherProps}
    />
  );
}

Contract.propTypes = {
  changes: PropTypes.array.isRequired,
};

export default Contract;
