import TagManager from 'react-gtm-module';

/**
 * Product Helper
 *
 * The `price` can be and Object or String
 *
 * @param {*} product
 */
function productHelper(product) {
  const { uid: id, parent, title, categories = [] } = product;

  const name = parent?.title || title || '';
  const variant = title || '';
  const brand = product.brand || '';
  const price = product.price?.sellingPrice || (!parent && product.price) || '0.00';
  const quantity = product.price?.quantity || product.quantity || 0;
  const category = categories?.find(item => item.depth === 1)?.fullName || '';

  return { id, name, variant, brand, category, price, quantity };
}

/**
 * E-commerce Enhanced Data Layer
 */

/**
 * Impressions
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#product-impressions
 *
 * The following pages must have this tag:
 *
 * 1. Authenticated experience
 * - /families/skoolare-regular-2019-antonio-fogaca-p1txa1k/material-escolar -> product carousel
 * - /families/skoolare-regular-2019-antonio-fogaca-p1txa1k/material-escolar/i/8593407d-ee1f-4953-9742-c555cc8a64a2 -> item details and product search
 * - /families/skoolare-regular-2019-antonio-fogaca-p1txa1k/material-escolar?searchable=1 -> product catalog search
 * - /families/skoolare-regular-2019-antonio-fogaca-p1txa1k/material-escolar/p/colecao-mais-cores-ei-g2 -> product recommendations
 * 2. Public experience
 * - /e/skoolare/regular-geral-todos-2019/transporte-escolar -> product carousel
 * - /e/skoolare/regular-geral-todos-2019/transporte-escolar/i/f4677907-ddae-4ed3-9ff3-cdb5ad4255a5 -> item details and product search
 * - /e/skoolare/regular-geral-todos-2019/transporte-escolar?searchable=1 -> product catalog search
 * - /e/skoolare/regular-geral-todos-2019/transporte-escolar/p/colecao-mais-cores-ei-g2 -> product recommendations
 * 3. Landing Page
 * - /l/pais-presentes-filhos-inteligentes -> landing page product recommendations
 * - /l/pais-presentes-filhos-inteligentes/p/caderno-desenho-brochura-40-folhas-113042-sapeca-tilibra -> product recommendations
 *
 * Last reviewed: 07/01/2021 by Victor B.
 *
 * @param {*} products
 */
export function gtmImpressions(products = [], list = '') {
  const tagManagerArgs = {
    dataLayer: {
      ecommerce: {
        currencyCode: 'BRL',
        impressions: products.map((product, index) => {
          const { id, name, variant, brand, category, price } = productHelper(product);

          return { name, id, price, brand, category, variant, list, position: index };
        }),
      },
    },
  };

  if (products.length > 0) TagManager.dataLayer(tagManagerArgs);
}

/**
 * Product Click
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#product-clicks
 *
 * Last reviewed: 11/01/2021 by Victor B.
 *
 * @param {*} product
 */
export function gtmProductClick(product = {}, source = '', position = 0) {
  const { id, name, variant, brand, category, price } = productHelper(product);

  const tagManagerArgs = {
    dataLayer: {
      event: 'productClick',
      ecommerce: {
        currencyCode: 'BRL',
        click: {
          actionField: { list: source },
          products: [{ name, id, price, brand, category, variant, position }],
        },
      },
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Product Detail
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#details
 *
 * The following pages must have this tag:
 *
 * - /families/skoolare-regular-2019-antonio-fogaca-p1txa1k/material-escolar/p/colecao-mais-cores-ei-g2
 * - /e/skoolare/regular-geral-todos-2019/transporte-escolar/p/colecao-mais-cores-ei-g2
 * - /l/pais-presentes-filhos-inteligentes/p/caderno-desenho-brochura-40-folhas-113042-sapeca-tilibra
 *
 * Last reviewed: 07/01/2021 by Victor B.
 *
 * @param {*} product
 */
export function gtmDetail(product = {}, list) {
  const { id, name, variant, brand, category, price } = productHelper(product);

  const tagManagerArgs = {
    dataLayer: {
      ecommerce: {
        currencyCode: 'BRL',
        detail: {
          actionField: { list },
          products: [{ name, id, price, brand, category, variant }],
        },
      },
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Add to Cart
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#cart
 *
 * Last reviewed: 07/01/2021 by Victor B.
 *
 * @param {*} product
 */
export function gtmAddToCart(product = {}, quantity = 1) {
  const { id, name, variant, brand, category, price } = productHelper(product);

  const tagManagerArgs = {
    dataLayer: {
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'BRL',
        add: {
          products: [{ name, id, price, brand, category, variant, quantity }],
        },
      },
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Remove from Cart
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#cart
 *
 * Last reviewed: 07/01/2021 by Victor B.
 *
 * @param {*} product
 */
export function gtmRemoveFromCart(product = {}, quantity = 1) {
  const { id, name, variant, brand, category, price } = productHelper(product);

  const tagManagerArgs = {
    dataLayer: {
      event: 'removeFromCart',
      ecommerce: {
        currencyCode: 'BRL',
        remove: {
          products: [{ name, id, price, brand, category, variant, quantity }],
        },
      },
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Promotions View
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#promo
 *
 * @param {*} promotions
 */
export function gtmPromotionsView(promotions = []) {
  const tagManagerArgs = {
    dataLayer: {
      ecommerce: {
        currencyCode: 'BRL',
        promoView: {
          promotions: promotions.map((promotion, index) => ({
            id: promotion.code, // ID or Name is required.
            name: promotion.name,
            creative: '',
            position: index,
          })),
        },
      },
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Promotion Click
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#promo
 *
 * @param {*} promotion
 */
export function gtmPromotionClick(promotion = {}, eventCallback) {
  const tagManagerArgs = {
    dataLayer: {
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: promotion.code, // ID or Name is required.
              name: promotion.name,
              creative: '',
              position: 0,
            },
          ],
        },
      },
      eventCallback,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Checkout
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#checkout
 *
 * Last reviewed: 07/01/2021 by Victor B.
 */
export function gtmCheckout(step, products = []) {
  const tagManagerArgs = {
    dataLayer: {
      event: 'checkout',
      ecommerce: {
        currencyCode: 'BRL',
        checkout: {
          actionField: { step },
          products: products.map(product => {
            const { id, name, variant, brand, category, price, quantity } = productHelper(product);

            return { name, id, price, brand, category, variant, quantity };
          }),
        },
      },
    },
  };

  if (products.length > 0) TagManager.dataLayer(tagManagerArgs);
}

/**
 * Checkout Option
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#checkout
 *
 */
export function gtmCheckoutOption(step, option) {
  const tagManagerArgs = {
    dataLayer: {
      event: 'checkoutOption',
      ecommerce: {
        currencyCode: 'BRL',
        checkout_option: {
          actionField: { step, option },
        },
      },
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Purchase
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#purchases
 *
 * Last reviewed: 11/01/2021 by Victor B.
 */
export function gtmPurchase(orderNumber, sellerSlug, total, shipping, products = [], voucher = {}) {
  const { code: coupon = '' } = voucher;

  const tagManagerArgs = {
    dataLayer: {
      event: 'orderSuccess',
      ecommerce: {
        currencyCode: 'BRL',
        purchase: {
          actionField: {
            id: orderNumber, // Transaction ID. Required for purchases and refunds.
            affiliation: sellerSlug,
            revenue: total, // Total transaction value (incl. tax and shipping)
            tax: '0.00',
            shipping,
            coupon,
          },
          products: products.map(product => {
            const { id, name, variant, brand, category, price, quantity } = productHelper(product);

            return { name, id, price, brand, category, variant, quantity, coupon };
          }),
        },
      },
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Refund
 *
 * Documentation: https://developers.google.com/tag-manager/enhanced-ecommerce?hl=pt-br#refunds
 *
 * Last reviewed: 07/01/2021 by Victor B.
 *
 * @param {*} orderNumber
 */
export function gtmRefund(orderNumber) {
  const tagManagerArgs = {
    dataLayer: {
      ecommerce: {
        refund: {
          actionField: {
            id: orderNumber, // Transaction ID. Required for purchases and refunds.
          },
        },
      },
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Google Analytics Goals Events
 */

export function gaCustomEvent(event) {
  const tagManagerArgs = {
    dataLayer: {
      event,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Login
 *
 * Successful login attempt
 *
 * Last reviewed: 06/11/2023 by Hideki J.
 *
 *
 */
export function gaLoginSuccess() {
  const tagManagerArgs = {
    dataLayer: { event: 'loginSuccess' },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Sign-up
 *
 * Successful signup
 *
 * Last reviewed: 06/11/2023 by Victor B.
 *
 */
export function gaSignUpSuccess() {
  const tagManagerArgs = {
    dataLayer: { event: 'signUpSuccess' },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * User data
 *
 * Send user data from /families/me
 *
 * Last reviewed: 06/11/2023 by Victor B.
 *
 * @param {*} user
 */
export function gaUserData(user) {
  const tagManagerArgs = {
    dataLayer: {
      event: 'userData',
      userUid: user.uid,
      userEmail: user.email,
      userIsStaff: user.isStaff,
      userIsInstitution: user.isInstitution,
      userIsSeller: user.isSeller,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Change Payment Method Success
 *
 * Last reviewed: 07/01/2021 by Victor B.
 */
export function gaChangePaymentMethodSuccess() {
  const tagManagerArgs = {
    dataLayer: {
      event: 'changePaymentMethodSuccess',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Create Student and Enrollment
 *
 * Goal being dispatched in the following pages:
 * - /accounts/students/
 * - FamilyStudentAutocomplete container
 *
 * Last reviewed: 07/01/2021 by Victor B.
 */
export function gaCreateStudentEnrollmentSuccess() {
  const tagManagerArgs = {
    dataLayer: {
      event: 'createStudentEnrollmentSuccess',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Add Basket Item Success
 *
 * Last reviewed: 07/01/2021 by Victor B.
 */
export function gaAddBasketItemSuccess() {
  const tagManagerArgs = {
    dataLayer: {
      event: 'addBasketItemSuccess',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Cancel Order Success
 *
 * Last reviewed: 07/01/2021 by Victor B.
 */
export function gaCancelOrderSuccess() {
  const tagManagerArgs = {
    dataLayer: {
      event: 'cancelOrderSuccess',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Order Success
 *
 * Last reviewed: 07/01/2021 by Victor B.
 */
export function gaOrderSuccess() {
  const tagManagerArgs = {
    dataLayer: {
      event: 'orderSuccess',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Create Enrollment Success
 *
 * - /accounts/enrollments/
 *
 * Last reviewed: 07/01/2021 by Victor B.
 */
export function gaCreateEnrollmentSuccess() {
  const tagManagerArgs = {
    dataLayer: {
      event: 'createEnrollmentSuccess',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Archive Enrollment Success
 *
 * - /accounts/enrollments/
 *
 * Last reviewed: 07/01/2021 by Victor B.
 */
export function gaArchiveEnrollmentSuccess() {
  const tagManagerArgs = {
    dataLayer: {
      event: 'archiveEnrollmentSuccess',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}

/**
 * Add Voucher Success
 *
 * Last reviewed: 07/01/2021 by Victor B.
 */
export function gaAddVoucherSuccess() {
  const tagManagerArgs = {
    dataLayer: {
      event: 'addVoucherSuccess',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
}
