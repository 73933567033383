import { useTheme, makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

export const useStyles = makeStyles(theme => ({
  circle: {
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    width: 42,
    height: 42,
    '& img': {
      borderRadius: '50%',
      width: 42,
      height: 42,
    },
  },
  icon: { maxHeight: 72 },
  horizontal: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    gap: 4,
  },
  logo: { maxHeight: 42 },
  displayName: { color: 'rgba(0, 0, 0, 0.87)' },
}));

function Logo(props) {
  const { displayName, src, type, ...restProps } = props;

  const classes = useStyles();
  const theme = useTheme();

  if (type === 'circle')
    return (
      <div className={classes.circle}>
        <img alt="Eskolare" src={theme.assets.icon} {...props} />
      </div>
    );

  if (type === 'icon')
    return <img alt="Eskolare" src={theme.assets.icon} className={classes.icon} {...props} />;

  return (
    <div className={classes.horizontal}>
      <img
        alt={displayName || 'Eskolare'}
        src={src || theme.assets.logoHorizontal}
        className={classes.logo}
        {...restProps}
      />
      {!!displayName && !!src && <h2 className={classes.displayName}>{displayName}</h2>}
    </div>
  );
}

Logo.defaultProps = {
  type: 'horizontal',
};

Logo.propTypes = {
  /**
   * The image source
   */
  displayName: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.oneOf(['horizontal', 'icon', 'circle']),
};

export default Logo;
