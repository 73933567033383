import { ErrorBoundary } from 'utils';
import { Footer, TopBar } from 'components/layouts';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

// Hooks
import { useUser } from 'hooks';

// Providers
import CartProvider from 'containers/common/CartProvider';

// Components
import { Mui } from 'components';

// Containers
import ChangeContextMenu from 'containers/widgets/ChangeContextMenu';
import CustomTheme from 'containers/common/CustomTheme';
import DuePayments from 'pages/accounts/AccountDuePaymentPage/components/DuePayments';
import FamilyCartUser from 'containers/widgets/FamilyCartUser';

// Local
import NavBar from './NavBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

function FamilyLayout(props) {
  const { children } = props;

  // Custom hooks
  const { user, refresh } = useUser();
  // useScript({
  //   id: 'ze-snippet',
  //   url: envs.ZENDESK_SCRIPT_URL,
  // });

  // Hooks
  const classes = useStyles();

  // States
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  // Effects
  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <CustomTheme>
      <CartProvider />
      <div className={classes.root}>
        <TopBar homeTo="/families" user={user} onMobileNavOpen={() => setMobileNavOpen(true)}>
          <DuePayments />
          <FamilyCartUser type="fixed" />
          <ChangeContextMenu />
        </TopBar>
        <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content} id="content">
              <ErrorBoundary>{children}</ErrorBoundary>
              <Mui.Box mt={3}>
                <Footer />
              </Mui.Box>
            </div>
          </div>
        </div>
      </div>
    </CustomTheme>
  );
}

FamilyLayout.propTypes = {
  children: PropTypes.any,
};

export default FamilyLayout;
