import { useUser } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';

// Pages
import LoginPage from 'pages/root/LoginPage';

function FamilyGuard(props) {
  const { children } = props;

  // Custom hooks
  const { isAuthenticated } = useUser();

  if (!isAuthenticated) return <LoginPage />;

  return children;
}

FamilyGuard.propTypes = {
  children: PropTypes.any,
};

export default FamilyGuard;
