import { loadable } from '../../../utils';

export { default as Login } from './Login';
export const PasswordConfirm = loadable(() => import('./PasswordConfirm'), {});
export const PasswordReset = loadable(() => import('./PasswordReset'), {});
export const SignUp = loadable(() => import('./SignUp'), {});
export const InviteSignUp = loadable(() => import('./InviteSignUp'), {});
export const ExpressSignUp = loadable(() => import('./ExpressSignUp'), {});
export const PasswordUpdate = loadable(() => import('./PasswordUpdate'), {});
export const PasswordNew = loadable(() => import('./PasswordNew'), {});
