/*
 *
 * Global saga
 *
 */

// Individual exports for testing
export default function* globalSaga() {
  yield null;
}
