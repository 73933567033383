import underscoreFromCamelCase from './underscoreFromCamelCase';

function querier(prevQuery, values) {
  const { search, page, pageSize, filters, ordering, ignoreFilters } = values;

  let newQuery = {
    ...prevQuery,
  };

  if (ignoreFilters) {
    newQuery = { ...newQuery, ignoreFilters };
  }

  if (ordering) {
    newQuery = {
      ...newQuery,
      ordering: {
        ...newQuery.ordering,
        ...ordering,
      },
    };
  }

  if (search !== undefined) newQuery = { ...newQuery, search, page: 1 };
  if (page !== undefined) newQuery = { ...newQuery, page };
  if (pageSize !== undefined) newQuery = { ...newQuery, pageSize };

  if (filters) {
    newQuery = {
      ...newQuery,
      page: 1,
      filters: {
        ...newQuery.filters,
        ...filters,
      },
    };
  }

  return newQuery;
}

function queryHandler(query) {
  const { search, page, page_size: pageSize, filters, ordering, ignoreFilters } = query;

  const newQuery = [];

  newQuery.push(`search=${search || ''}`);

  if (page) {
    newQuery.push(`page=${page}`);
  }
  if (pageSize) {
    newQuery.push(`page_size=${pageSize}`);
  }

  if (ordering) {
    const newOrdering = Object.keys(ordering).map(key => ({ ...ordering[key], field: key }));
    const orderingParams = [];

    newOrdering.map(order => {
      const { field, direction } = order;
      const newField = underscoreFromCamelCase(field);
      const newDirection = direction === 'asc' ? '' : '-';

      orderingParams.push(`${newDirection}${newField}`);

      return order;
    });

    newQuery.push(`ordering=${orderingParams.join(',')}`);
  }

  if (filters) {
    const newFilters = Object.keys(filters).map(key => ({ ...filters[key], field: key }));

    // TODO: Must review the logic bellow in future refactoring
    newFilters.map(filter => {
      const { field, type } = filter;
      const newField = underscoreFromCamelCase(field);

      if (ignoreFilters && ignoreFilters.length > 0 && ignoreFilters.includes(field)) {
        return filter;
      }

      if (type === 'date') {
        const { startDate, endDate } = filter;
        if (startDate && startDate !== '') newQuery.push(`${newField}__gt=${startDate}`);
        if (endDate && endDate !== '') newQuery.push(`${newField}__lt=${endDate}`);
      }
      if (type === 'lookup') {
        const { value } = filter;
        if (value && value.length > 0) newQuery.push(`${newField}__in=${value.join(',')}`);
      }
      if (type === 'filter') {
        const { value } = filter;
        if (value && value !== '') newQuery.push(`${newField}=${value}`);
      }
      if (type === 'boolean') {
        const { value } = filter;
        if (value !== null) newQuery.push(`${newField}=${value}`);
      }
      return filter;
    });
  }
  return `?${newQuery.join('&')}`;
}

export default queryHandler;
export { querier };
