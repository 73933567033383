/*
 * CartFreight Messages
 *
 * This contains all the text for the CartFreight component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.common.Cart';

export default defineMessages({
  // Freight.js
  freightHeader: {
    id: `${scope}.freightHeader`,
    defaultMessage: 'Frete simulado',
  },
  freightEmptyMessage: {
    id: `${scope}.freightEmptyMessage`,
    defaultMessage: 'Informe um CEP para simular o frete do pedido',
  },
  // Line.js
  upcLabel: {
    id: `${scope}.upcLabel`,
    defaultMessage: 'Cód. {upc}',
  },
  // Showcase.js
  showcaseLabel: {
    id: `${scope}.showcaseLabel`,
    defaultMessage: 'Vitrine de compra',
  },
  // Clean.js
  cleanTooltip: {
    id: `${scope}.cleanTooltip`,
    defaultMessage: 'Limpar este carrinho',
  },
  cleanMessage: {
    id: `${scope}.cleanMessage`,
    defaultMessage:
      'Tem certeza que deseja remover todos os itens do carrinho do aluno {firstName} {lastName}?',
  },
  // Summary.js
  summaryHeader: {
    id: `${scope}.summaryHeader`,
    defaultMessage: 'Resumo',
  },
  itemsLabel: {
    id: `${scope}.itemsLabel`,
    defaultMessage: 'Itens',
  },
  additionLabel: {
    id: `${scope}.additionLabel`,
    defaultMessage: 'Acréscimo',
  },
  discountLabel: {
    id: `${scope}.discountLabel`,
    defaultMessage: 'Desconto',
  },
  interestLabel: {
    id: `${scope}.interestLabel`,
    defaultMessage: 'Juros',
  },
  freightLabel: {
    id: `${scope}.freightLabel`,
    defaultMessage: 'Frete',
  },
  noFreightLabel: {
    id: `${scope}.noFreightLabel`,
    defaultMessage: 'À calcular',
  },
  subtotalLabel: {
    id: `${scope}.subtotalLabel`,
    defaultMessage: 'Sub-total',
  },
  checkoutPaymentsTotalLabel: {
    id: `${scope}.checkoutPaymentsTotalLabel`,
    defaultMessage: 'Pago agora',
  },
  futurePaymentsTotalLabel: {
    id: `${scope}.futurePaymentsTotalLabel`,
    defaultMessage: 'Cobranças futuras',
  },
  checkoutButton: {
    id: `${scope}.checkoutButton`,
    defaultMessage: 'Avançar',
  },
  cartCloseButton: {
    id: `${scope}.cartCloseButton`,
    defaultMessage: 'Continuar comprando',
  },
  // Voucher.js
  voucherHeader: {
    id: `${scope}.voucherHeader`,
    defaultMessage: 'Cupons',
  },
  voucherTitle: {
    id: `${scope}.voucherTitle`,
    defaultMessage: 'Cupom de desconto',
  },
  voucherInstructionsMessage: {
    id: `${scope}.voucherInstructionsMessage`,
    defaultMessage: 'Gostaria de utilizar um cupom?',
  },
  vouchersLabel: {
    id: `${scope}.vouchersLabel`,
    defaultMessage: '{count} adicionado(s)',
  },
  vouchersEmpty: {
    id: `${scope}.vouchersEmpty`,
    defaultMessage: 'Nenhum cupom aplicado ao seu carrinho',
  },
  removeTooltip: {
    id: `${scope}.removeTooltip`,
    defaultMessage: 'Remover',
  },
  closeButton: {
    id: `${scope}.closeButton`,
    defaultMessage: 'Fechar',
  },
  saveButton: {
    id: `${scope}.saveButton`,
    defaultMessage: 'Salvar',
  },
  viewTooltip: {
    id: `${scope}.viewTooltip`,
    defaultMessage: 'Ver',
  },
  // ...
  closeTooltip: {
    id: `${scope}.closeTooltip`,
    defaultMessage: 'Fechar',
  },
  saveTooltip: {
    id: `${scope}.saveTooltip`,
    defaultMessage: 'Salvar',
  },
  editTooltip: {
    id: `${scope}.editTooltip`,
    defaultMessage: 'Alterar',
  },
  totalLabel: {
    id: `${scope}.totalLabel`,
    defaultMessage: 'Total',
  },
  // FloatIcon.js
  cartLabel: {
    id: `${scope}.cartLabel`,
    defaultMessage: 'Seu carrinho',
  },
  // Sidebar.js
  buyButton: {
    id: `${scope}.buyButton`,
    defaultMessage: 'Finalizar',
  },
  // Student.js
  buyingFor: {
    id: `${scope}.buyingFor`,
    defaultMessage: 'Comprando para',
  },
});
