import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Components
import { Mui, TextField } from 'components';
import { Drawer, Stack, Buttons } from 'components/layouts';
import PresetDateRangeMenu from '../PresetDateRangeMenu/Loadable';

function DatePeriodFilter(props) {
  const { query, setQuery, title, description } = props;

  // States
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    from_date: query?.from_date || '',
    to_date: query?.to_date || '',
  });

  // Constants
  const { from_date: fromDate, to_date: toDate } = values;

  // Actions
  const handleDateChange = (field, newDate) =>
    setValues(prevValues => ({ ...prevValues, [field]: newDate ? newDate.toISOString() : '' }));

  return (
    <>
      <Mui.IconButton onClick={() => setOpen(true)}>
        <Mui.Icon color={query?.from_date && query?.to_date ? 'primary' : 'inherit'}>
          date_range
        </Mui.Icon>
      </Mui.IconButton>
      <Drawer open={open} onClose={() => setOpen(false)} title={title} description={description}>
        <Mui.Container>
          <Stack>
            <Mui.Card variant="outlined">
              <PresetDateRangeMenu
                onSelect={({ startDate, endDate }) => {
                  setQuery(prevQuery => ({ ...prevQuery, from_date: startDate, to_date: endDate }));
                  setOpen(false);
                }}
                dense
              />
            </Mui.Card>
            <Mui.Typography>Quer consultar um período específico?</Mui.Typography>
            <TextField
              id="fromDate"
              value={fromDate}
              label="De"
              envelope="date"
              onChange={newDate => {
                const dateUpdated = new Date(newDate);
                dateUpdated.setHours(0, 0, 0);
                handleDateChange('from_date', newDate ? dateUpdated : '');
              }}
              margin="none"
            />
            <TextField
              id="toDate"
              value={toDate}
              label="Até"
              envelope="date"
              onChange={newDate => {
                const dateUpdated = new Date(newDate);
                dateUpdated.setHours(23, 59, 59);
                handleDateChange('to_date', newDate ? dateUpdated : '');
              }}
              margin="none"
            />
            <Buttons
              preset="save"
              color="primary"
              variant="contained"
              onClick={() => {
                setQuery(prevQuery => ({ ...prevQuery, ...values }));
                setOpen(false);
              }}
            />
          </Stack>
        </Mui.Container>
      </Drawer>
    </>
  );
}

DatePeriodFilter.propTypes = {
  query: PropTypes.object,
  setQuery: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default DatePeriodFilter;
