import { useFetch } from 'hooks';
import { Link as RouterLink } from 'react-router-dom';
import React, { useRef, useState } from 'react';

// Components
import { Mui } from 'components';

// Local Components
import * as images from '../../../../images';
import * as api from '../api';

const useStyles = Mui.makeStyles(theme => ({
  popover: {
    width: 320,
  },
  icon: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

export function DuePayments() {
  // Hooks
  const classes = useStyles();

  // Refs
  const ref = useRef(null);

  // Fetch
  const { data } = useFetch([api.endpoints.list()]);
  const { dashboard, overdue = [] } = data || {};

  // Constants
  const count = overdue?.length || 0;
  const icons = {
    order: 'check_box_outlined',
    contract: 'assignment_outlined',
    tuition: 'event',
  };
  const countDuePayments = countPayments => {
    if (countPayments === 0) return 'Nenhum pagamento vencido';
    if (countPayments === 1) return '1 pagamento vencido';
    return `${countPayments} pagamentos vencidos`;
  };

  // States
  const [open, setOpen] = useState(false);

  // Actions
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!dashboard) return null;

  return (
    <>
      <Mui.Tooltip title={countDuePayments(count)}>
        <Mui.IconButton onClick={handleOpen} ref={ref}>
          <Mui.Badge badgeContent={count} color="error">
            <Mui.Icon color={count > 0 ? 'error' : 'inherit'}>receipt</Mui.Icon>
          </Mui.Badge>
        </Mui.IconButton>
      </Mui.Tooltip>
      <Mui.Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={open}>
        <Mui.Box p={2}>
          <Mui.Typography variant="h6" color="textPrimary" noWrap>
            {countDuePayments(count)}
          </Mui.Typography>
        </Mui.Box>
        {count > 0 ? (
          <Mui.List className={classes.list} disablePadding>
            {overdue
              .filter((item, index) => index < 5)
              .map(item => {
                const {
                  order: { orderNumber, type },
                } = item;

                return (
                  <Mui.ListItem
                    component={RouterLink}
                    button
                    divider
                    key={item.uid}
                    to={['/accounts/orders', orderNumber].join('/')}>
                    <Mui.ListItemAvatar>
                      <Mui.Avatar className={classes.icon}>
                        <Mui.Icon fontSize="small">{icons[type]}</Mui.Icon>
                      </Mui.Avatar>
                    </Mui.ListItemAvatar>
                    <Mui.ListItemText
                      primary={orderNumber}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={item.dueDate}
                    />
                    <Mui.ListItemSecondaryAction>
                      <Mui.Typography variant="body2">{item.total}</Mui.Typography>
                    </Mui.ListItemSecondaryAction>
                  </Mui.ListItem>
                );
              })}
          </Mui.List>
        ) : (
          <Mui.Box display="flex" p={3} justifyContent="center">
            <img src={images.illustrationsBankEmpty} alt="Eskolare" height={200} />
          </Mui.Box>
        )}
        <Mui.Box p={1} display="flex" justifyContent="center">
          <Mui.Button fullWidth component={RouterLink} size="small" to="/accounts/payments/due">
            Ver mais
          </Mui.Button>
        </Mui.Box>
      </Mui.Popover>
    </>
  );
}

export default DuePayments;
