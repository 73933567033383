import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui } from 'components';

function Tooltip({
  color = 'primary',
  description,
  placement = 'top',
  title,
  boxProps,
  iconProps,
  titleProps,
  typographyProps,
}) {
  return (
    <Mui.Box display="flex" alignItems="center" {...boxProps}>
      {typeof title === 'string' ? (
        <Mui.Typography variant="body2" {...titleProps}>
          {title}
        </Mui.Typography>
      ) : (
        title
      )}
      <Mui.Tooltip
        color={color}
        title={
          <Mui.Typography variant="body2" {...typographyProps}>
            {description}
          </Mui.Typography>
        }
        placement={placement}>
        <Mui.Icon color="action" style={{ marginLeft: 4 }} {...iconProps}>
          info
        </Mui.Icon>
      </Mui.Tooltip>
    </Mui.Box>
  );
}

Tooltip.propTypes = {
  color: PropTypes.string,
  description: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.any,
  boxProps: PropTypes.object,
  iconProps: PropTypes.object,
  titleProps: PropTypes.object,
  typographyProps: PropTypes.object,
};

export default Tooltip;
