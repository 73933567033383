import { loadable } from 'utils';

export const Basic = loadable(() => import('./Basic'), {});
export const ImportWizard = loadable(() => import('./ImportWizard'), {});
export const Inventory = {
  Basic: loadable(() => import('./Inventory/Basic'), {}),
  Institution: loadable(() => import('./Inventory/Institution'), {}),
  Seller: loadable(() => import('./Inventory/Seller'), {}),
};
export const Invoice = loadable(() => import('./Invoice'), {});
export const OnDemand = loadable(() => import('./OnDemand'), {});
export const Provisioned = loadable(() => import('./Provisioned'), {});
export const ReverseLogistics = {
  Basic: loadable(() => import('./ReverseLogistics/Basic'), {}),
  Institution: loadable(() => import('./ReverseLogistics/Institution'), {}),
};
export const Stock = loadable(() => import('./Stock'), {});
export const Tracking = loadable(() => import('./Tracking'), {});
