import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiSwitch from '@material-ui/core/Switch';

function Switch({
  fullWidth,
  label,
  formik,
  name,
  defaultChecked,
  messages,
  labelProps,
  ...otherProps
}) {
  const { touched, errors, values, setFieldValue, setFieldTouched } = formik || {};

  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    setChecked(defaultChecked);

    return () => {};
  }, [defaultChecked]);

  const errorText = errors && errors[name];
  const hasError = touched && touched[name] && errorText !== undefined;
  const value = values && values[name];

  const switchProps = {
    name,
    checked,
    value,
    onChange: event => {
      setChecked(event.target.checked);
      if (setFieldTouched) setFieldTouched(name, true, true);
      if (setFieldValue) setFieldValue(name, event.target.checked, true);
    },
  };

  return (
    <MuiFormControl fullWidth={fullWidth} component="fieldset" error={hasError} margin="none">
      <MuiFormControlLabel
        control={<MuiSwitch {...switchProps} {...otherProps}></MuiSwitch>}
        label={label}
        {...labelProps}
      />
      {hasError && <MuiFormHelperText>{errorText}</MuiFormHelperText>}
    </MuiFormControl>
  );
}

Switch.propTypes = {
  defaultChecked: PropTypes.bool,
  formik: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }),
  fullWidth: PropTypes.bool,
  label: PropTypes.any,
  messages: PropTypes.object,
  name: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
};

Switch.defaultProps = {
  fullWidth: true,
  defaultChecked: false,
  formik: {
    touched: null,
    errors: null,
    values: null,
    setFieldValue: () => null,
    setFieldTouched: () => null,
  },
};

export default Switch;
