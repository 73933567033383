import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui } from 'components';
import Label from 'components/layouts/Label';

// Local components
import Base from './Base';
import messages from './messages';

const STATUS_TYPES = {
  order_created: 'order-created',
  payment_pending: 'payment-pending',
  payment_approved: 'payment-approved',
  payment_denied: 'payment-denied',
  handling: 'handling',
  invoice: 'invoice',
  invoiced: 'invoiced',
  delivered: 'delivered',
  cancellation_requested: 'cancellation-requested',
  cancel: 'cancel',
  canceled: 'canceled',
  reversed: 'reversed',
  pending: 'pending',
  active: 'active',
  finished: 'finished',
  transferred: 'transferred',
};

export const ORDER_STATUS = {
  'order-created': {
    status: STATUS_TYPES.order_created,
    color: 'primary',
    icon: 'create_new_folder_outlined',
    label: 'Pedido Criado',
    recommended: false,
  },
  'payment-pending': {
    status: STATUS_TYPES.payment_pending,
    color: 'warning',
    icon: 'access_time',
    label: 'Pagamento Pendente',
    recommended: false,
  },
  'payment-approved': {
    status: STATUS_TYPES.payment_approved,
    color: 'success',
    icon: 'monetization_on',
    label: 'Pagamento Aprovado',
    recommended: true,
  },
  'payment-denied': {
    status: STATUS_TYPES.payment_denied,
    color: 'error',
    icon: 'error',
    label: 'Pedido Negado',
    recommended: true,
  },
  handling: {
    status: STATUS_TYPES.handling,
    color: 'secondary',
    icon: 'pan_tool',
    label: 'Em Manuseio',
    recommended: true,
  },
  invoice: {
    status: STATUS_TYPES.invoice,
    color: 'primary',
    icon: 'file_copy',
    label: 'Faturando',
    recommended: false,
  },
  invoiced: {
    status: STATUS_TYPES.invoiced,
    color: 'success',
    icon: 'receipt',
    label: 'Faturado',
    recommended: false,
  },
  delivered: {
    status: STATUS_TYPES.delivered,
    color: 'success',
    icon: 'local_shipping',
    label: 'Entregue',
    recommended: false,
  },
  'cancellation-requested': {
    status: STATUS_TYPES.cancellation_requested,
    color: 'info',
    icon: 'live_help',
    label: 'Cancelamento solicitado',
    recommended: true,
  },
  cancel: {
    status: STATUS_TYPES.cancel,
    color: 'info',
    icon: 'cancel',
    label: 'Cancelando',
    recommended: false,
  },
  canceled: {
    status: STATUS_TYPES.canceled,
    color: 'error',
    icon: 'archive',
    label: 'Cancelado',
    recommended: false,
  },
  reversed: {
    status: STATUS_TYPES.reversed,
    color: 'error',
    icon: 'restore',
    label: 'Revertido',
    recommended: false,
  },
};

export const CONTRACT_STATUS = {
  pending: {
    status: STATUS_TYPES.pending,
    color: 'warning',
    icon: 'access_time',
    label: 'Pendente',
  },
  active: {
    status: STATUS_TYPES.active,
    color: 'success',
    icon: 'play_circle_filled',
    label: 'Ativo',
  },
  'cancellation-requested': {
    status: STATUS_TYPES.cancellation_requested,
    color: 'info',
    icon: 'live_help',
    label: 'Cancelamento de contrato solicitado',
  },
  canceled: {
    status: STATUS_TYPES.canceled,
    color: 'error',
    icon: 'archive',
    label: 'Cancelado',
  },
  finished: {
    status: STATUS_TYPES.finished,
    color: 'success',
    icon: 'check_circle_outlined',
    label: 'Finalizado',
  },
};

export const TUITION_STATUS = {
  pending: {
    status: STATUS_TYPES.pending,
    color: 'warning',
    icon: 'access_time',
    label: 'Pendente',
  },
  active: {
    status: STATUS_TYPES.active,
    color: 'success',
    icon: 'play_circle_filled',
    label: 'Ativo',
  },
  'cancellation-requested': {
    status: STATUS_TYPES.cancellation_requested,
    color: 'info',
    icon: 'live_help',
    label: 'Cancelamento de mensalidade solicitado',
  },
  canceled: {
    status: STATUS_TYPES.canceled,
    color: 'error',
    icon: 'archive',
    label: 'Cancelado',
  },
  finished: {
    status: STATUS_TYPES.finished,
    color: 'success',
    icon: 'check_circle_outlined',
    label: 'Finalizado',
  },
  transferred: {
    status: STATUS_TYPES.transferred,
    color: 'secondary',
    icon: 'send',
    label: 'Transferido',
  },
};

const setDefaultStatus = objStatus =>
  Object.keys(objStatus).map(key => ({ ...objStatus[key], key }));

const getDefaultValues = (values, objDefaultStatus) => {
  const defaultValues = [];

  values.map(item => {
    const [option] = objDefaultStatus.filter(status => status.key === item);

    if (option) defaultValues.push(option);

    return item;
  });

  return defaultValues;
};

export const helpers = { setDefaultStatus, getDefaultValues };

const revertedLabel = isReverted =>
  !isReverted ? null : (
    <Mui.Box mt={0.5}>
      <Label color="error">
        <Mui.Box
          width="100%"
          p={0.5}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <Mui.Icon fontSize="small">settings_backup_restore</Mui.Icon>
          <Mui.Box px={0.5} />
          <FormattedMessage {...messages.reverted} />
        </Mui.Box>
      </Label>
    </Mui.Box>
  );

function Single(props) {
  const { status, statusDisplay, isReverted, ...otherProps } = props;

  if (!status) return null;

  return (
    <Base
      color={ORDER_STATUS[status]?.color || 'inherit'}
      icon={ORDER_STATUS[status]?.icon}
      {...otherProps}>
      {statusDisplay}
      {revertedLabel(isReverted)}
    </Base>
  );
}

Single.propTypes = {
  status: PropTypes.string,
  statusDisplay: PropTypes.string,
  isReverted: PropTypes.bool,
};

function Contract(props) {
  const { status, statusDisplay, isReverted, ...otherProps } = props;

  if (!status) return null;

  return (
    <Base
      color={CONTRACT_STATUS[status]?.color || 'inherit'}
      icon={CONTRACT_STATUS[status]?.icon}
      {...otherProps}>
      {statusDisplay}
      {revertedLabel(isReverted)}
    </Base>
  );
}

Contract.propTypes = {
  status: PropTypes.string,
  statusDisplay: PropTypes.string,
  isReverted: PropTypes.bool,
};

function Tuition(props) {
  const { status, statusDisplay, isReverted, ...otherProps } = props;

  if (!status) return null;

  return (
    <Base
      color={TUITION_STATUS[status]?.color || 'inherit'}
      icon={TUITION_STATUS[status]?.icon}
      {...otherProps}>
      {statusDisplay}
      {revertedLabel(isReverted)}
    </Base>
  );
}

Tuition.propTypes = {
  status: PropTypes.string,
  statusDisplay: PropTypes.string,
  isReverted: PropTypes.bool,
};

function Order(props) {
  const { type, progressBar, variant, orderNumber, isReverted, ...otherProps } = props;

  const getCaption = () => {
    if (!orderNumber) return undefined;

    if (type === 'contract')
      return <FormattedMessage {...messages.contract} values={{ orderNumber }} />;
    if (type === 'tuition')
      return <FormattedMessage {...messages.tuition} values={{ orderNumber }} />;
    return <FormattedMessage {...messages.order} values={{ orderNumber }} />;
  };

  const commonProps = {
    progressBar,
    variant,
    caption: getCaption(),
    captionFieldProps: { color: 'textPrimary', variant: 'body1', noWrap: true },
    isReverted,
    ...otherProps,
  };

  if (type === 'contract') return <Contract {...commonProps} />;
  if (type === 'tuition') return <Tuition {...commonProps} />;
  return <Single {...commonProps} />;
}

Order.propTypes = {
  type: PropTypes.oneOf(['order', 'contract', 'tuition']),
  progressBar: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'filled']),
  orderNumber: PropTypes.string,
  isReverted: PropTypes.bool,
};

Order.defaultProps = {
  progressBar: true,
  variant: 'filled',
};

export default Order;
