/*
 *
 * ProductProvider saga
 *
 */

import { put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import * as ducks from './ducks';
import envs from '../../../envs';

// API's

const domain = `${envs.PROTOCOL}://${envs.ENTRYPOINT}/api`;

export const endpoints = {
  enrolled: (enrollmentSlug, productSlug) =>
    `${domain}/families/enrollments/${enrollmentSlug}/products/${productSlug}/`,
  landingPage: (landingPageSlug, productSlug) =>
    `${domain}/commons/landing-pages/${landingPageSlug}/products/${productSlug}/`,
  showcase: (institutionSlug, showcaseSlug, productSlug) =>
    `${domain}/commons/institutions/${institutionSlug}/showcases/${showcaseSlug}/products/${productSlug}/`,
  publicPage: productSlug => `${domain}/commons/catalog/${productSlug}/`,
};

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#7846c018-61d4-4470-be92-691504bb2abe
 */
export function enrolled({ enrollmentSlug, productSlug }) {
  const config = {
    method: 'get',
    url: endpoints.enrolled(enrollmentSlug, productSlug),
  };

  return axios(config);
}

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#fa5a7812-d077-401f-9684-26a32a379c66
 */
export function landingPage({ landingPageSlug, productSlug }) {
  const config = {
    method: 'get',
    url: endpoints.landingPage(landingPageSlug, productSlug),
  };

  return axios(config);
}

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#9575b8e5-aefa-46d9-8ad5-dad15d69a5ed
 */
export function showcase({ institutionSlug, showcaseSlug, productSlug }) {
  const config = {
    method: 'get',
    url: endpoints.showcase(institutionSlug, showcaseSlug, productSlug),
  };

  return axios(config);
}

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#ce8a33e0-c00e-4c54-9568-f74e1e7ab164
 */
export function publicPage({ productSlug }) {
  const config = {
    method: 'get',
    url: endpoints.publicPage(productSlug),
  };

  return axios(config);
}

// Side-effects

export function* enrolledRequestSaga(action) {
  const { enrollmentSlug, productSlug, callback } = action.payload;

  try {
    const response = yield enrolled({ enrollmentSlug, productSlug });

    yield put(
      ducks.enrolledSuccess({ enrollmentSlug, productSlug, callback, response: response.data }),
    );
  } catch (error) {
    yield put(ducks.enrolledFailure({ enrollmentSlug, productSlug, callback, error }));
  }
}

export function* enrolledSuccessSaga(action) {
  const { response, callback } = action.payload;
  if (callback) callback(response, false);
}

export function* landingPageRequestSaga(action) {
  const { landingPageSlug, productSlug, callback } = action.payload;

  try {
    const response = yield landingPage({ landingPageSlug, productSlug });

    yield put(
      ducks.landingPageSuccess({ landingPageSlug, productSlug, callback, response: response.data }),
    );
  } catch (error) {
    yield put(ducks.landingPageFailure({ landingPageSlug, productSlug, callback, error }));
  }
}

export function* landingPageSuccessSaga(action) {
  const { response, callback } = action.payload;
  if (callback) callback(response, false);
}

export function* showcaseRequestSaga(action) {
  const { institutionSlug, showcaseSlug, productSlug, callback } = action.payload;

  try {
    const response = yield showcase({ institutionSlug, showcaseSlug, productSlug });

    yield put(
      ducks.showcaseSuccess({ showcaseSlug, productSlug, callback, response: response.data }),
    );
  } catch (error) {
    yield put(ducks.showcaseFailure({ showcaseSlug, productSlug, callback, error }));
  }
}

export function* showcaseSuccessSaga(action) {
  const { response, callback } = action.payload;
  if (callback) callback(response, false);
}

export function* publicPageRequestSaga(action) {
  const { productSlug, callback } = action.payload;

  try {
    const response = yield publicPage({ productSlug, callback });

    yield put(ducks.publicPageSuccess({ productSlug, callback, response: response.data }));
  } catch (error) {
    yield put(ducks.publicPageFailure({ productSlug, callback, error }));
  }
}

export function* publicPageSuccessSaga(action) {
  const { response, callback } = action.payload;
  if (callback) callback(response, false);
}

export function* commonFailureSaga(action) {
  const { error, callback } = action.payload;

  if (callback) callback(false, error);

  yield true;
}

// Individual exports for testing
export default function* productProviderSaga() {
  yield takeEvery(ducks.ENROLLED_REQUEST, enrolledRequestSaga);
  yield takeEvery(ducks.ENROLLED_SUCCESS, enrolledSuccessSaga);
  yield takeEvery(ducks.ENROLLED_FAILURE, commonFailureSaga);
  yield takeEvery(ducks.LANDING_PAGE_REQUEST, landingPageRequestSaga);
  yield takeEvery(ducks.LANDING_PAGE_SUCCESS, landingPageSuccessSaga);
  yield takeEvery(ducks.LANDING_PAGE_FAILURE, commonFailureSaga);
  yield takeEvery(ducks.SHOWCASE_REQUEST, showcaseRequestSaga);
  yield takeEvery(ducks.SHOWCASE_SUCCESS, showcaseSuccessSaga);
  yield takeEvery(ducks.SHOWCASE_FAILURE, commonFailureSaga);
  yield takeEvery(ducks.PUBLIC_PAGE_REQUEST, publicPageRequestSaga);
  yield takeEvery(ducks.PUBLIC_PAGE_SUCCESS, publicPageSuccessSaga);
  yield takeEvery(ducks.PUBLIC_PAGE_FAILURE, commonFailureSaga);
}
