/*
 *
 * PermissionProvider reducer
 *
 */
import produce from 'immer';

import * as constants from './constants';

export const initialState = {
  isFetching: {
    retrieve: false,
    institutions: {},
    sellers: {},
  },
  isStaff: true,
  isSeller: false,
  isInstitution: false,
  institutions: {},
  sellers: {},
};

/* eslint-disable default-case, no-param-reassign */
const permissionProviderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constants.RETRIEVE_REQUEST:
        draft.isFetching.retrieve = true;
        break;
      case constants.RETRIEVE_SUCCESS: {
        const { response } = action.payload;
        const { isStaff, isSeller, isInstitution } = response;
        draft.isFetching.retrieve = false;
        draft.isStaff = isStaff;
        draft.isSeller = isSeller;
        draft.isInstitution = isInstitution;
        break;
      }
      case constants.RETRIEVE_FAILURE:
        draft = initialState;
        break;
      case constants.INSTITUTIONS_REQUEST: {
        const { institutionSlug } = action.payload;
        draft.isFetching.institutions[institutionSlug] = true;
        break;
      }
      case constants.INSTITUTIONS_SUCCESS: {
        const { institutionSlug, response } = action.payload;
        draft.isFetching.institutions[institutionSlug] = false;
        draft.institutions[institutionSlug] = response;
        break;
      }
      case constants.INSTITUTIONS_FAILURE: {
        const { institutionSlug } = action.payload;
        draft.isFetching.institutions[institutionSlug] = false;
        draft.institutions[institutionSlug] = { isAdmin: false };
        break;
      }
      case constants.SELLERS_REQUEST: {
        const { sellerSlug } = action.payload;
        draft.isFetching.sellers[sellerSlug] = true;
        break;
      }
      case constants.SELLERS_SUCCESS: {
        const { sellerSlug, response } = action.payload;
        draft.isFetching.sellers[sellerSlug] = false;
        draft.sellers[sellerSlug] = response;
        break;
      }
      case constants.SELLERS_FAILURE: {
        const { sellerSlug } = action.payload;
        draft.isFetching.sellers[sellerSlug] = false;
        draft.sellers[sellerSlug] = {};
        break;
      }
    }
  });

export default permissionProviderReducer;
