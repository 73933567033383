import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { Mui, Grid, PerfectScrollbar } from '../..';
import messages from './messages';
import { Repeater } from '../../layouts';

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    zIndex: props => `${1300 + props.zIndex} !important`,
  },
  footer: {
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  children: {
    paddingTop: props => theme.spacing(props.padding.top || 2),
    paddingBottom: props => theme.spacing(props.padding.bottom || 2),
  },
}));

function Page(props) {
  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  const { children, open, onClose, onClick, title, disableHeader, disableFooter, disableRepeater } =
    props;

  const classes = useStyles(props);

  return (
    <Mui.Dialog
      fullScreen
      open={open}
      TransitionComponent={Mui.Fade}
      classes={{ root: classes.root }}>
      {!disableHeader && (
        <Mui.DialogTitle>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap">
            <Grid item xs>
              {title}
            </Grid>
            <Grid item>
              {(onClose || onClick) && (
                <Mui.Tooltip title={<FormattedMessage {...messages.closeTooltip} />}>
                  <Mui.IconButton aria-label="close" onClick={onClose || onClick}>
                    <Mui.Icon>close</Mui.Icon>
                  </Mui.IconButton>
                </Mui.Tooltip>
              )}
            </Grid>
          </Grid>
        </Mui.DialogTitle>
      )}
      {!disableRepeater && <Repeater />}
      <PerfectScrollbar>
        <div className={classes.children}>{children}</div>
      </PerfectScrollbar>
      {fullScreen && <Repeater />}
      {!disableFooter && (
        <Mui.DialogActions className={classes.footer}>
          <img src={theme.assets.logoHorizontal} alt="Eskolare" style={{ height: 20 }} />
        </Mui.DialogActions>
      )}
    </Mui.Dialog>
  );
}

Page.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  open: PropTypes.bool,
  disableHeader: PropTypes.bool,
  disableRepeater: PropTypes.bool,
  disableFooter: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  padding: PropTypes.object,
  zIndex: PropTypes.number,
};

Page.defaultProps = {
  padding: { top: 2, bottom: 2 },
  zIndex: 0,
};

export default Page;
