import { alpha } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 20,
    maxWidth: '100%',
    height: 20,
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 2,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0.5,
    padding: theme.spacing(0.5, 1),
    textTransform: 'uppercase',
  },
  sm: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
  },
  md: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  lg: {
    minWidth: 40,
    height: 40,
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightBold,
  },
  normal: {
    color: theme.palette.grey[600],
    backgroundColor: alpha(theme.palette.grey[600], 0.08),
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.08),
  },
  info: {
    color: theme.palette.info.main,
    backgroundColor: alpha(theme.palette.info.main, 0.08),
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.08),
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.08),
  },
}));

function Label({ className, size = 'sm', color, children, ...rest }) {
  const classes = useStyles();

  return (
    <span
      className={clsx(
        classes.root,
        {
          [classes[color]]: color,
          [classes[size]]: size,
        },
        className,
      )}
      {...rest}>
      {children}
    </span>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.oneOf(['normal', 'primary', 'secondary', 'error', 'info', 'warning', 'success']),
};

Label.defaultProps = {
  className: '',
  color: 'secondary',
};

export default Label;
