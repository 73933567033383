import { makeStyles, useTheme, useMediaQuery, Drawer, Divider } from '@material-ui/core';
import { matchPath, useLocation } from 'react-router-dom';
import { usePermissions } from 'hooks';
import * as images from 'images';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Components
import { Mui, PerfectScrollbar } from 'components';

// Local Components
import NavItem from './NavItem';

function renderNavItems({ items, isStaff, ...rest }) {
  return (
    <Mui.List disablePadding>
      {items
        .filter(item => (!item.isStaff && !item.invisible) || (item.isStaff && isStaff))
        .reduce(
          (acc, item, index) => reduceChildRoutes({ acc, isStaff, item, index, ...rest }),
          [],
        )}
    </Mui.List>
  );
}

function reduceChildRoutes(props) {
  const { acc, pathname, index, isStaff, item, depth = 0 } = props;

  const key = [index, depth].join('-');

  if (item.items) {
    const open = matchPath(pathname, { path: item.href, exact: false });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        avatar={item.avatar}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        isNew={item.isNew}
        isStaff={item.isStaff}>
        {renderNavItems({ depth: depth + 1, pathname, isStaff, items: item.items })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        target={item.target}
        icon={item.icon}
        avatar={item.avatar}
        disabled={item.disabled}
        key={key}
        info={item.info}
        title={item.title}
        isNew={item.isNew}
        isStaff={item.isStaff}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 300,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    width: 64,
    height: 64,
  },
  poweredBy: {
    maxWidth: '100%',
  },
}));

function NavBar(props) {
  const { openMobile, onMobileClose, profile = {}, items, children } = props;

  // Hooks
  const classes = useStyles();
  const location = useLocation();
  const { permissions } = usePermissions();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Constants
  const { info, cover } = profile || {};
  const { isStaff } = permissions || {};

  // Effects
  useEffect(() => {
    if (openMobile && onMobileClose) onMobileClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Mui.Box
      height="100%"
      display="flex"
      flexDirection="column"
      style={
        cover && {
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          background: `linear-gradient(to top, white, white, white, white, rgba(255, 255, 255, 0.6)), url(${cover})`,
        }
      }>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {children}
        {info && (
          <>
            <Mui.Box p={2} py={4}>
              <Mui.Typography variant="body2" color="textSecondary" align="center" noWrap>
                {info}
              </Mui.Typography>
            </Mui.Box>
            <Divider />
          </>
        )}
        <Mui.Box px={2}>
          {items
            .filter(item => (!item.isStaff && !item.invisible) || (item.isStaff && isStaff))
            .map((config, index) => {
              const key = [config.subheader || 'subheader', index.toString()].join('-');
              if (Array.isArray(config.items) && config.items.length < 1) return null;
              return (
                <Mui.List
                  key={key}
                  subheader={
                    <Mui.ListSubheader disableGutters disableSticky>
                      {config.subheader}{' '}
                      {config.isStaff && (
                        <Mui.Chip
                          size="small"
                          label="STAFF"
                          style={{ backgroundColor: '#f44336', color: 'white' }}
                        />
                      )}
                      {!config.isStaff && config.isNew && (
                        <Mui.Chip size="small" label="NOVO" color="primary" />
                      )}
                    </Mui.ListSubheader>
                  }>
                  {renderNavItems({ isStaff, items: config.items, pathname: location.pathname })}
                </Mui.List>
              );
            })}
        </Mui.Box>
        <Divider />
        <Mui.Box py={4} px={6} textAlign="center">
          <img
            alt="Powered by Eskolare"
            src={images.logoHorizontalGreyPoweredBy}
            className={classes.poweredBy}
          />
        </Mui.Box>
      </PerfectScrollbar>
    </Mui.Box>
  );

  if (fullScreen)
    return (
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary">
        {content}
      </Drawer>
    );

  return (
    <Mui.Hidden smDown>
      <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
        {content}
      </Drawer>
    </Mui.Hidden>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  profile: PropTypes.object,
  items: PropTypes.array,
  children: PropTypes.any,
};

export default NavBar;
