import { useParams } from 'react-router-dom';
import useFetch from './useFetch';

export default function useInstitution() {
  // Hooks
  const { institutionSlug } = useParams();

  // Constants
  const endpoints = {
    institution: `/institutions/${institutionSlug}/`,
  };

  // Fetch
  const { data, mutate } = useFetch([institutionSlug ? endpoints.institution : null]);

  return { institution: data, refresh: mutate };
}
