import { Box, Container, Divider, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Mui } from '../..';

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2),
    },
  },
}));

function Faq(props) {
  const { variant, title, subtitle, items, className, ...restProps } = props;

  const classes = useStyles({});

  return (
    <div className={clsx(classes.root, className)} {...restProps}>
      <Container maxWidth="lg">
        <Typography variant="h1" color="textPrimary">
          {title}
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        {subtitle && (
          <Typography variant="subtitle1" color="textPrimary">
            {subtitle}
          </Typography>
        )}
        <Grid container spacing={3} component="dl">
          {items &&
            items.map((item, index) => (
              <Grid item xs={12} md={6} key={`${index.toString()}`}>
                <Box mt={6}>
                  <dd>
                    <Typography variant="h4" color="textPrimary">
                      {item.question}
                    </Typography>
                  </dd>
                  <dt>
                    <Typography variant="body1" color="textSecondary">
                      {item.answer}
                    </Typography>
                  </dt>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
}

Faq.defaultProps = {
  variant: 'outlined',
};

Faq.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  variant: PropTypes.oneOf(['elevation', 'outlined']),
  items: PropTypes.array,
  className: PropTypes.any,
};

export default Faq;
