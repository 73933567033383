// Import package to version
import { version } from '../version';

// Reset app constant to handle action
export const RESET_APP = 'RESET_APP';

// Application version
export const RELEASE = version;

// Setup Sentry
export const SENTRY = 'https://f7bada5901d24bc2a6f3e4d54ea9e743@sentry.io/1424590';
