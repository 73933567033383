/**
 * Create the store with dynamic reducers
 */

import { applyMiddleware, compose, createStore } from 'redux';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { addBreadcrumb } from '@sentry/browser';

import createReducer from './reducers';
import failureMiddleware from './utils/middlewares/failureMiddleware';
import requestMiddleware from './utils/middlewares/requestMiddleware';

// eslint-disable-next-line no-unused-vars
export default function configureStore(initialState = {}, history) {
  // Sentry middleware
  const sentryReporter = store => next => action => {
    addBreadcrumb({
      message: action.type,
      category: 'redux action',
      level: 'info',
      store,
      data: {
        action,
      },
    });
    return next(action);
  };

  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    /* eslint-enable */
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  const middlewares = [sagaMiddleware, failureMiddleware, requestMiddleware, sentryReporter];

  const enhancers = [
    applyMiddleware(...middlewares),
    createInjectorsEnhancer({ createReducer, runSaga }),
  ];

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store);
    });
  }

  return { store };
}
