/**
 *
 * ProgressWrapper
 *
 */

import PropTypes from 'prop-types';
import React from 'react';

import { Mui } from '../..';
import { useStyles } from './styles';

function ProgressWrapper(props) {
  const { loading, children, minHeight } = props;

  const classes = useStyles({ loading, minHeight });

  return (
    <div className={classes.root}>
      <Mui.Backdrop className={classes.backdrop} open={loading || false}>
        <Mui.CircularProgress color="primary" />
      </Mui.Backdrop>
      {children}
    </div>
  );
}

ProgressWrapper.propTypes = {
  loading: PropTypes.bool,
  minHeight: PropTypes.number,
  children: PropTypes.any,
};

export default ProgressWrapper;
