/*
 *
 * SocialAuthentication api's
 *
 */

import axios from 'axios';

import envs from '../../../envs';

const domain = `${envs.PROTOCOL}://${envs.ENTRYPOINT}/api`;

export const endpoints = {
  socialConnect: `${domain}/commons/auth/social/connect/`,
};

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#5cf849a1-85c5-4929-a300-446f931b4e43
 */
export function socialConnect({ provider, accessToken }) {
  const config = {
    method: 'post',
    url: endpoints.socialConnect,
    data: { provider, accessToken },
  };

  return axios(config);
}
