/**
 *
 * ContactForm
 *
 */

import { createStructuredSelector } from 'reselect';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import * as Yup from 'yup';
import React from 'react';

import * as actions from './actions';
import * as images from '../../../images';
import makeSelectContactForm from './selectors';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

// Components
import { Button, Grid, Mui, TextField, Typography } from '../../../components';
import { Stack } from '../../../components/layouts';

const stateSelector = createStructuredSelector({
  contactForm: makeSelectContactForm(),
});

export function ContactForm() {
  useInjectReducer({ key: 'contactForm', reducer });
  useInjectSaga({ key: 'contactForm', saga });

  // Hooks
  const dispatch = useDispatch();
  const intl = useIntl();

  // Store
  const { contactForm } = useSelector(stateSelector);

  // Constants
  const { isFetching } = contactForm;

  const methods = [
    {
      icon: images.iconsFaq,
      title: <FormattedMessage {...messages.contactSectionMethod1Title} />,
      identifier: 'Perguntas Frequentes',
      extraInfo: '',
      href: 'https://eskolare.zendesk.com/hc/pt-br',
    },
    {
      icon: images.iconsPhone,
      title: <FormattedMessage {...messages.contactSectionMethod2Title} />,
      identifier: '(11) 4200-2416',
      extraInfo: <FormattedMessage {...messages.contactSectionMethod2ExtraInfo} />,
      href: 'tel:+551142002416',
    },
    {
      icon: images.iconsWhatsapp,
      title: <FormattedMessage {...messages.contactSectionMethod3Title} />,
      identifier: '(11) 984-341-278',
      extraInfo: '',
      href: 'https://wa.me/5511984341278',
    },
    {
      icon: images.iconsMail,
      title: <FormattedMessage {...messages.contactSectionMethod4Title} />,
      identifier: 'atendimento@eskolare.com.br',
      extraInfo: '',
      href: 'mailto:atendimento@eskolare.com.br',
    },
    {
      icon: images.iconsChat,
      title: <FormattedMessage {...messages.contactSectionMethod5Title} />,
      identifier: '',
      extraInfo: <FormattedMessage {...messages.contactSectionMethod5ExtraInfo} />,
      href: 'https://eskolare.zendesk.com/hc/pt-br/',
    },
  ];

  const formMessages = {
    name: {
      label: intl.formatMessage(messages.nameLabel),
      required: intl.formatMessage(messages.nameRequired),
      helperText: '',
    },
    email: {
      label: intl.formatMessage(messages.emailLabel),
      required: intl.formatMessage(messages.emailRequired),
      format: intl.formatMessage(messages.emailFormat),
      helperText: '',
    },
    phone: {
      label: intl.formatMessage(messages.phoneLabel),
      required: intl.formatMessage(messages.phoneRequired),
      helperText: '',
    },
    message: {
      label: intl.formatMessage(messages.messageLabel),
      required: intl.formatMessage(messages.messageRequired),
      helperText: '',
    },
  };

  const yup = Yup.object().shape({
    name: Yup.string().required(formMessages.name.required),
    email: Yup.string().email(formMessages.email.format).required(formMessages.email.required),
    phone: Yup.string().required(formMessages.phone.required),
    message: Yup.string().required(formMessages.message.required),
  });

  const onSubmit = (values, { resetForm }) => {
    dispatch(actions.contactRequest({ ...values }));

    resetForm();
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit,
    validationSchema: yup,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div>
      <Mui.Box mt={4} mb={8}>
        <Typography component="p" variant="overline" color="primary" align="center">
          <FormattedMessage {...messages.overline} />
        </Typography>
        <Typography variant="h1" align="center" color="textPrimary">
          <FormattedMessage {...messages.title} />
        </Typography>
      </Mui.Box>
      <Grid container justifyContent="center" spacing={5} alignItems="center">
        <Grid item md={6} xs={12}>
          {methods.map((method, index) => (
            <Mui.Box key={`${index.toString()}`} mb={1}>
              <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item xs={2}>
                  <img src={method.icon} alt={method.title} />
                </Grid>
                <Grid item xs>
                  <Mui.Typography variant="h6" color="primary" component="div">
                    {method.title}
                  </Mui.Typography>
                  <Mui.Link href={method.href} target="_blank" variant="body1" color="textPrimary">
                    {method.identifier}
                  </Mui.Link>
                  <Mui.Typography variant="body1" color="textSecondary" component="div">
                    {method.extraInfo}
                  </Mui.Typography>
                </Grid>
              </Grid>
            </Mui.Box>
          ))}
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack>
            <TextField
              required
              name="name"
              type="text"
              formik={formik}
              messages={formMessages}
              margin="none"
            />
            <TextField
              required
              name="email"
              type="text"
              formik={formik}
              messages={formMessages}
              margin="none"
            />
            <TextField
              name="phone"
              type="text"
              formik={formik}
              messages={formMessages}
              margin="none"
            />
            <TextField
              required
              name="message"
              type="text"
              multiline
              rows="4"
              formik={formik}
              messages={formMessages}
              margin="none"
            />
            <Button
              loading={isFetching.contact}
              onClick={() => formik.handleSubmit()}
              fullWidth
              size="large"
              endIcon={<Mui.Icon>email</Mui.Icon>}>
              <FormattedMessage {...messages.sendButton} />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}

ContactForm.propTypes = {};

export default ContactForm;
