/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'sanitize.css/sanitize.css';

import './css/offline-theme.css';
import 'nprogress/nprogress.css';
import 'react-medium-image-zoom/dist/styles.css';

// Polyfills
import './utils/polyfills/replaceAll';

// Import all the third party stuff
import { QueryParamProvider } from 'use-query-params';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import FontFaceObserver from 'fontfaceobserver';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';

// Import Sentry
import * as Sentry from '@sentry/browser';

import { ErrorBoundary } from './utils';
// Configure store
import configureStore from './configureStore';
// Import root app
import App from './containers/common/App';
// Import Language Provider
import LanguageProvider from './containers/common/LanguageProvider';
// Import envs
import envs from './envs';
// Import i18n messages
import { translationMessages } from './i18n';
// Import the request interceptors
import registerAxiosInterceptors from './utils/middlewares/axiosInterceptors';
// Import the local storage fallback heler
import configureLocalStorage from './utils/configureLocalStorage';
// Import release version and sentry
import { RELEASE, SENTRY } from './utils/constants';
// Import history
import history from './utils/history';
// Set Yup pt-br
import { setYupPtBr } from './utils/setLocaleYup';

// Moment
require('moment/locale/pt-br');

// Yup
setYupPtBr();

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Montserrat', {});

/**
 * Configure the local storage.
 * On older browsers the local storage is not available on incognito mode.
 * In this case we swap the local storage with a memory storage option
 */
configureLocalStorage();

// Immer
enableES5();

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Register the request interceptors
registerAxiosInterceptors();

// Create redux store with history
const initialState = {};
const { store } = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

// Activate Google Tag Manager and separate between production and local devlopment
// https://www.googletagmanager.com/start_preview/gtm?uiv2&id=GTM-NVG7X3TT&check_preview_status=1&gtm_auth=NUjpgc-eMW3NVMcQNpDu0w&gtm_preview=env-1&gtm_debug=x&url=http%3A%2F%2Flocalhost%3A3000
let gtmParams = { gtmId: envs.GOOGLE_TAG_MANAGER_ID };

if (process.env.NODE_ENV !== 'production') {
  gtmParams = { ...gtmParams, auth: 'NUjpgc-eMW3NVMcQNpDu0w', preview: 'env-1' };
}

TagManager.initialize(gtmParams);

const ConnectedApp = props => (
  <ErrorBoundary>
    <Router>
      <Provider store={store}>
        <LanguageProvider messages={props.messages}>
          <HelmetProvider>
            <QueryParamProvider ReactRouterRoute={Route}>
              <App />
            </QueryParamProvider>
          </HelmetProvider>
        </LanguageProvider>
      </Provider>
    </Router>
  </ErrorBoundary>
);

ConnectedApp.propTypes = {
  messages: PropTypes.object,
};

const render = messages => {
  ReactDOM.render(<ConnectedApp messages={messages} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

render(translationMessages);

// Init Sentry when the code is in production
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY,
    release: RELEASE,
    ignoreErrors: [/NetworkError/, 'Network Error', /ChunkLoadError/, /Loading chunk \d+ failed/],
    environment: envs.ENVIRONMENT,
    tracesSampleRate: 0.01,
  });
}
