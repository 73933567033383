import { createSelector } from 'reselect';
import { initialState } from './ducks';

/**
 * Direct selector to the productProvider state domain
 */

const selectProductProviderDomain = state => state.productProvider || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ProductProvider
 */

const makeSelectProductProvider = () =>
  createSelector(selectProductProviderDomain, substate => substate);

export default makeSelectProductProvider;
export { selectProductProviderDomain };
