import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { ErrorBoundary } from '../../utils';

// Local components
import CustomTheme from '../../containers/common/CustomTheme';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
  },
}));

function EmptyLayout(props) {
  const { children } = props;

  const classes = useStyles();

  return (
    <CustomTheme>
      <div className={classes.root}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </CustomTheme>
  );
}

EmptyLayout.propTypes = {
  children: PropTypes.any,
};

export default EmptyLayout;
