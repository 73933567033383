/* eslint-disable sonarjs/no-duplicate-string */
export const LOCAL = 'LOCAL';
export const DEVELOPMENT = 'DEVELOPMENT';
export const PRODUCTION = 'PRODUCTION';

const local = {
  PROTOCOL: 'https',
  ENTRYPOINT: 'api.eskolare-development.com',
  WHITELABEL_URL: 'eskolare-development.shop',
  GOOGLE_TAG_MANAGER_ID: 'GTM-NVG7X3TT',
  FACEBOOK_APP_ID: '390835928434710',
  DEV: true,
  ENVIRONMENT: 'local',
  EXPERIMENTAL_FEATURES: true,
  IUGU_SCRIPT_URL: 'https://js.iugu.com/v2',
  IUGU_ACCOUNT_ID: '9E47E4AEF5A8430DB0F42D74C8D3491F',
  ADYEN_SCRIPT_URL: 'https://test.adyen.com/hpp/cse/js/8015889713770082.shtml',
  ADYEN_PUBLIC_KEY:
    '10001|A5B18D978844C4C4D1D04564622B9B59E00BA9A66C33B5FF862F2B82A659E7E2F87203F63B19ED5F6CA6E94B8A6B0E109881144C21A7F33802CACC95B3493C806E077EF695536C294F0B3E1E819551B3653575A334D2BB34C89018E1B0DEE876BD6CF9D4ABF06F48468C80E207F737CBC1419614F9FA9DA17F0817DDE4C7D44E2DA1175981662B8AA4548761C0BAE30EAF3E7E1FA4DCFCD7A0756CC20BB58D468E09127DA1B61446CE9E70538D995F7E3A03808F6FAB76E980CF3ADABF7FD7054721CACA58DE356B7562C4214BABE1F9A9F22DA0AE46AF1F6040A678C48DFD8A7442956620084BFF54FFF02B75F97BA53170166843CC87A6A4611E3A979CBC3F',
  PAGSEGURO_SCRIPT_URL:
    'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js',
  PAGSEGURO_PUBLIC_KEY:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB',
  WIRECARD_B64_PUBLIC_KEY:
    'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQklqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FROEFNSUlCQ2dLQ0FRRUFoNXlBc09xTDljaGZiRlBXU2kvSgpHWUIwRzM1RFBpS2w3bk5KNWhVbzBzWHY2Z3VLTEtPNEQ5WUp5NWc1dzB5dXJMMGlLeUhwWkpoZTVUb2V0ZlJICnoyVzJ1am40bDFqdzMxNTRDVUNVU2dYd1o4bEZtOXprYVVsKzBMbC9MZHJoclROOGUyaXAxOXlGN3NYRHI3N1oKS2twVm4xdGFXdkh2NXN3UmpsTXcwaFZPeCtOaEg1MGJzaVhUY2xvcDArT3hadmFEUWN1Z2c3ZlJpa0pldDFveApJTm9mNWh6dWpETGxCTUhEQ3Z3YU15c2t1NmxYZVJ3TWY2SHdzZ3M2bkwrUGh2d0FWSkt0cmljSXFHRkh6UklIClZNY2dJQXVUd2svb1V0WnY2T3ZMZUUwSEZ3ME5qTE9TVGxzTm9mSWpGdzlEUmhYcS9sSTVlNUtQUmxGb1JVVUMKTndJREFRQUIKLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t',
};

const development = {
  PROTOCOL: 'https',
  ENTRYPOINT: 'api.eskolare-development.com',
  WHITELABEL_URL: 'eskolare-development.shop',
  GOOGLE_TAG_MANAGER_ID: 'GTM-NVG7X3TT',
  FACEBOOK_APP_ID: '390835928434710',
  DEV: true,
  ENVIRONMENT: 'development',
  EXPERIMENTAL_FEATURES: true,
  IUGU_SCRIPT_URL: 'https://js.iugu.com/v2',
  IUGU_ACCOUNT_ID: '9E47E4AEF5A8430DB0F42D74C8D3491F',
  ADYEN_SCRIPT_URL: 'https://test.adyen.com/hpp/cse/js/8015889713770082.shtml',
  ADYEN_PUBLIC_KEY:
    '10001|A5B18D978844C4C4D1D04564622B9B59E00BA9A66C33B5FF862F2B82A659E7E2F87203F63B19ED5F6CA6E94B8A6B0E109881144C21A7F33802CACC95B3493C806E077EF695536C294F0B3E1E819551B3653575A334D2BB34C89018E1B0DEE876BD6CF9D4ABF06F48468C80E207F737CBC1419614F9FA9DA17F0817DDE4C7D44E2DA1175981662B8AA4548761C0BAE30EAF3E7E1FA4DCFCD7A0756CC20BB58D468E09127DA1B61446CE9E70538D995F7E3A03808F6FAB76E980CF3ADABF7FD7054721CACA58DE356B7562C4214BABE1F9A9F22DA0AE46AF1F6040A678C48DFD8A7442956620084BFF54FFF02B75F97BA53170166843CC87A6A4611E3A979CBC3F',
  PAGSEGURO_SCRIPT_URL:
    'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js',
  PAGSEGURO_PUBLIC_KEY:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB',
  WIRECARD_B64_PUBLIC_KEY:
    'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQklqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FROEFNSUlCQ2dLQ0FRRUFoNXlBc09xTDljaGZiRlBXU2kvSgpHWUIwRzM1RFBpS2w3bk5KNWhVbzBzWHY2Z3VLTEtPNEQ5WUp5NWc1dzB5dXJMMGlLeUhwWkpoZTVUb2V0ZlJICnoyVzJ1am40bDFqdzMxNTRDVUNVU2dYd1o4bEZtOXprYVVsKzBMbC9MZHJoclROOGUyaXAxOXlGN3NYRHI3N1oKS2twVm4xdGFXdkh2NXN3UmpsTXcwaFZPeCtOaEg1MGJzaVhUY2xvcDArT3hadmFEUWN1Z2c3ZlJpa0pldDFveApJTm9mNWh6dWpETGxCTUhEQ3Z3YU15c2t1NmxYZVJ3TWY2SHdzZ3M2bkwrUGh2d0FWSkt0cmljSXFHRkh6UklIClZNY2dJQXVUd2svb1V0WnY2T3ZMZUUwSEZ3ME5qTE9TVGxzTm9mSWpGdzlEUmhYcS9sSTVlNUtQUmxGb1JVVUMKTndJREFRQUIKLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t',
};

const production = {
  PROTOCOL: 'https',
  ENTRYPOINT: 'api.eskolare.com',
  WHITELABEL_URL: 'eskolare.shop',
  GOOGLE_TAG_MANAGER_ID: 'GTM-T27FZ3ZD',
  FACEBOOK_APP_ID: '390835928434710',
  DEV: false,
  ENVIRONMENT: 'production',
  EXPERIMENTAL_FEATURES: false,
  IUGU_SCRIPT_URL: 'https://js.iugu.com/v2',
  IUGU_ACCOUNT_ID: '9E47E4AEF5A8430DB0F42D74C8D3491F',
  ADYEN_SCRIPT_URL: 'https://live.adyen.com/hpp/cse/js/4116097726201171.shtml',
  ADYEN_PUBLIC_KEY:
    '10001|CB562E9B9EA2CA4E2AB58D1ED6664AB927F10B806806895E409D0AF9E6E650D521F8ECDB41FD81D57073325DC550BDAC4CA371DFE5F07862BD0E867A6297E3DC3A32506D84EF17AF748622CFCC68951A66321FD4144F5C82B915C039ADBB8EDAA6C82064389BCCC1394A2F24BCF5A0E984E2ED9CFEBEDEED0236375674C81FD6B868C708DF2AA1A7BDDC85D06BCEAF7D2972D92B4EBF965F9B862EB6784A56CCF5B1A40A2BEB5B0C5EFDEB8CE5BC79E5BC01BF7F218299498F31865E7B26A386B97F3B635343BE1C7D442CBA40AE7FC9CFF68DF4C750F1B95BF46FE18038CBF8042F4CFFBEC93D420F7A21523712EDF66D75CF4ED717FE981C5C06572786D8D9',
  PAGSEGURO_SCRIPT_URL:
    'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js',
  PAGSEGURO_PUBLIC_KEY:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAx6N5vBLYeiBQ016cqRtFMRf1xwYV92pkmP9AXhW4W1YOiFR9ESuC5Xe1hdrOGFRl1NIIHMbd0sF27o+UeNwZY9i4FCpNy0EB7UvFdkvNdFh5Aejuq90WkbhxmxKaP8QEdDb5UhP1yM/lcyTSAqY50Qp4BdTYcsj5lqLe299d+WFoo9iio9ubyq97A6XQB9JptLuuyCD5RVs1WLSkyShH9TgjHJvjfzOGE667x4ndHPjoViuh2DVdeJ7ELcymVy6/yzz965PP1EhQZOKbvUSQrmkxn4zAqccqdLuKHWE6ClbliZgVXAWKeV15HYTEgV8yAUV1gBBkaHKvjxf4VZdbJwIDAQAB',
  WIRECARD_B64_PUBLIC_KEY:
    'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQklqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FROEFNSUlCQ2dLQ0FRRUFqMFFaT0cvaVM4L0ZBbDk4R3VKdwozbFhsN2NIZzZiaEpNRE5WZzNEaFZSNHZHNzhqVlo1UmlRWkRwWE9QeEZhTVJtOSswNENWWUV4YkI1NkFBQklRClptZXVneU44SEh3OEJhVUJBaXdPd2lxTjVmMmxwTmsxQzYxM3pBME1vWTlqVExnSVdQajZTMiswODFWay9JY2UKZUF4T1VXU3Uyc1VmUkJFOGVNRGVEalZzbkV3YTJBeWZsWkUyTFJlai9uYTcvNkM4K0VHTEp2SnUyMXpyTGY4ZQpZOGI0STE4VWpjeGwwY0dUT2NkdDFqam92K3U2TzF1YWxubVh0K0k5UEN0ZEtJTGxmMEFHZld5MkRZL2RhdzlZCjlETUtZQmcyNGFMenRGVG9iR05EVyt2TGVSUVhRYzNyaDh0Qm00ajlYRXZOd25BenZkVUZ5RVkreUoxNjkzaXQKM3dJREFRQUIKLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t',
};

let config;
let env;

if (process.env.LOCAL) {
  env = LOCAL;
  config = local;
} else if (process.env.STAGE) {
  env = DEVELOPMENT;
  config = development;
} else {
  env = PRODUCTION;
  config = production;
}

export default {
  ...config,
  env,
  GOOGLE_CLIENT_ID: '332744668651-h0ujd082jr8dtlqpvugqqpduh9qipnnb.apps.googleusercontent.com',
  APPLICATION_NAME: 'Eskolare',
  APPLICATION_SLUG: 'eskolare',
};
