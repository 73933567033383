import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Mui } from '../..';

const useStyles = Mui.makeStyles(theme => ({
  iconButton: { color: theme.palette.common.white, padding: 0 },
  fab: {
    bottom: '50px',
    borderRadius: '50px',
    displayPrint: 'none',
    opacity: 0.9,
    padding: '10px',
    position: 'fixed',
    right: '24px',
    zIndex: 1000,
    height: 'auto',
    '& .MuiChip-label': {
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
}));

function FloatingSuggestion({ title = 'Deixe sua sugestão', icon = 'emoji_objects', link }) {
  // Hooks
  const classes = useStyles();

  // States
  const [open, setOpen] = useState(true);
  const [float, setFloat] = useState(true);

  useEffect(() => {
    if (!float && open) setTimeout(() => setOpen(false), 2000);
  }, [float, open]);

  return float ? (
    <Mui.Chip
      icon={<Mui.Icon>{icon}</Mui.Icon>}
      color="secondary"
      variant="default"
      label={title}
      className={classes.fab}
      onClick={() => window.open(link)}
      onDelete={e => {
        e.stopPropagation();
        setFloat(false);
      }}
      deleteIcon={<Mui.Icon>close</Mui.Icon>}
    />
  ) : (
    <Mui.Tooltip title="Deixe sua sugestão" open={open}>
      <Mui.IconButton
        onClick={() =>
          window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSdwz2vn3fcindwTbPYxQwy3erbiZU2Oa3hDZlqFQIEaM_2Uew/viewform',
          )
        }>
        <Mui.Icon>emoji_objects</Mui.Icon>
      </Mui.IconButton>
    </Mui.Tooltip>
  );
}

FloatingSuggestion.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
};

export default FloatingSuggestion;
