import { loadable } from 'utils';

export const BillingDetailsCard = loadable(() => import('./BillingDetailsCard'), {});
export const ContractDetailsCard = loadable(() => import('./ContractDetailsCard'), {});
export const DiscountDetailsCard = loadable(() => import('./DiscountDetailsCard'), {});
export const InvoiceDetailsCard = loadable(() => import('./InvoiceDetailsCard'), {});
export const ItemsDetailsCard = loadable(() => import('./ItemsDetailsCard'), {});
export const ShippingDetailsCard = loadable(() => import('./ShippingDetailsCard'), {});
export const SummaryDetailsCard = loadable(() => import('./SummaryDetailsCard'), {});
export const TuitionDetailsCard = loadable(() => import('./TuitionDetailsCard'), {});
export const VoucherDetailsCard = loadable(() => import('./VoucherDetailsCard'), {});
