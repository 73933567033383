/**
 *
 * Notifier
 *
 */

import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import * as ducks from './ducks';
import makeSelectNotifier from './selectors';
import messages from './messages';
import { Mui } from '../../../components';

let displayed = [];

const stateSelector = createStructuredSelector({
  snackbarProvider: makeSelectNotifier(),
});

export function Notifier() {
  // Hooks
  const dispatch = useDispatch();

  // Store
  const { snackbarProvider } = useSelector(stateSelector);

  // Custom hooks
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Constants
  const { notifications } = snackbarProvider;

  // Actions

  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  // Effects

  useEffect(() => {
    notifications.forEach(notification => {
      const {
        key,
        message,
        messageId,
        values,
        variant,
        options = {},
        dismissed = false,
      } = notification;

      if (dismissed) {
        closeSnackbar(key);

        return;
      }

      if (displayed.includes(key)) return;

      const action = () => (
        <Mui.Button color="inherit" onClick={() => closeSnackbar(key)}>
          <FormattedMessage {...messages.closeButton} />
        </Mui.Button>
      );

      const newOptions = {
        key,
        variant: variant || 'info',
        action,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) options.onClose(event, reason, myKey);
        },
        onExited: (event, myKey) => {
          dispatch(ducks.removeSnackbar(myKey));

          removeDisplayed(myKey);
        },
      };

      const newMessage = message || (
        <div>
          <FormattedMessage {...messages[messageId]} values={values} />
        </div>
      );

      enqueueSnackbar(newMessage, newOptions);

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}

Notifier.propTypes = {};

export default Notifier;
