import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import makeSelectProductProvider from './selectors';
import makeSelectUserProvider from '../UserProvider/selectors';
import makeSelectCartProvider from '../CartProvider/selectors';

import useCart from '../CartProvider/useCart';

const stateSelector = createStructuredSelector({
  productProvider: makeSelectProductProvider(),
  userProvider: makeSelectUserProvider(),
  cartProvider: makeSelectCartProvider(),
});

function useBasket({ productSlug, productUid, enrollmentUid, enrollmentSlug, collectionSlug }) {
  // Hooks
  const history = useHistory();
  const { pathname } = useLocation();

  // Store
  const { cartProvider } = useSelector(stateSelector);

  // Custom hooks
  const { setTemporary, handleCart, productAlert } = useCart();

  // Constants
  const { temporary, linesByEnrollment, isFetching } = cartProvider;
  const { basket } = temporary[productSlug] || {};
  const lines = linesByEnrollment[enrollmentUid] || [];
  const handling =
    isFetching.add[productUid] || isFetching.remove[productUid] || isFetching.handle[productUid];
  const alerting = isFetching.productAlert[productSlug];

  // Actions

  const onAdd = useCallback(
    (item, additional) => {
      if (enrollmentSlug) {
        handleCart(enrollmentSlug, item, { ...additional, collectionSlug });
      } else {
        setTemporary('add', { ...item, basket: additional }, collectionSlug);
      }
    },
    [enrollmentSlug, collectionSlug, handleCart, setTemporary],
  );

  const onRemove = useCallback(
    (item, additional) => {
      if (enrollmentSlug) {
        handleCart(enrollmentSlug, item, additional);
      } else {
        setTemporary('remove', { ...item, basket: additional });
      }
    },
    [enrollmentSlug, handleCart, setTemporary],
  );

  const onBuy = useCallback(
    (item, additional, callback) => {
      if (enrollmentSlug) {
        handleCart(enrollmentSlug, item, { ...additional, collectionSlug }, callback);
      } else {
        setTemporary('add', { ...item, basket: additional }, collectionSlug, callback);
      }
    },
    [enrollmentSlug, collectionSlug, handleCart, setTemporary],
  );

  const onAlert = useCallback(
    item => {
      if (enrollmentSlug) {
        const { slug } = item;
        productAlert(enrollmentSlug, slug);
      } else {
        history.push({ pathname: '/sign-up/express', search: `?redirect=${pathname}` });
      }
    },
    [enrollmentSlug, pathname, productAlert, history],
  );

  if (enrollmentUid) {
    const [line] = lines.filter(item => item.product === productUid);

    return { basket: line, handling, alerting, onAdd, onRemove, onBuy, onAlert };
  }

  return { basket, onAdd, onRemove, onBuy, onAlert };
}

export default useBasket;
