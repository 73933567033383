/*
 *
 * CheckoutRegularPage reducer
 *
 */
import produce from 'immer';

import * as constants from './constants';

export const initialState = {
  isFetching: {
    retrieve: false,
    addVoucher: false,
    removeVoucher: false,
    submit: false,
  },
  retrieve: {
    subtotal: '',
    freightTotal: '',
    interestTotal: '',
    discountTotal: '',
    taxTotal: '',
    total: '',
    requiresShipping: true,
    numItems: 0,
    numLines: 0,
    canCheckout: false,
    organization: null,
    checklist: {
      basketIsOpen: false,
      basketLinesAreNotEmpty: false,
      basketItemsQuantityIsAllowed: false,
      allBasketLinesAreAvailable: false,
      userInformation: false,
      paymentMethod: false,
      hasShippingAddress: false,
      sellersHaveAvailablePaymentMethods: false,
      hasValidSubscription: false,
      hasValidContract: false,
      hasValidTuition: false,
      hasValidCreditCard: false,
      shippingAddressHasValidPostcode: false,
      sellersCanShipAllProducts: false,
    },
    providers: [],
    personalInformation: {},
    shippingAddress: {},
    billingAddress: {},
    commonPaymentMethods: {},
    vouchers: [],
  },
  orders: [],
  contracts: [],
  subscriptions: [],
  tuition: [],
  addVoucher: {},
  removeVoucher: {},
  submit: {},
};

/* eslint-disable default-case, no-param-reassign */
const checkoutRegularPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constants.RETRIEVE_REQUEST: {
        draft.isFetching.retrieve = true;
        break;
      }
      case constants.RETRIEVE_SUCCESS: {
        const { response } = action.payload;
        const { packages } = response;
        draft.isFetching.retrieve = false;
        draft.retrieve = response;
        draft.orders = packages.filter(item => item.type === 'order');
        draft.contracts = packages.filter(item => item.type === 'contract');
        draft.tuition = packages.filter(item => item.type === 'tuition');
        break;
      }
      case constants.RETRIEVE_FAILURE: {
        draft.isFetching.retrieve = false;
        break;
      }
      case constants.ADD_VOUCHER_REQUEST: {
        draft.isFetching.addVoucher = true;
        break;
      }
      case constants.ADD_VOUCHER_SUCCESS: {
        const { voucherCode, response } = action.payload;
        draft.isFetching.addVoucher = false;
        draft.addVoucher[voucherCode] = response;
        break;
      }
      case constants.ADD_VOUCHER_FAILURE: {
        const { voucherCode } = action.payload;
        draft.isFetching.addVoucher = false;
        draft.addVoucher[voucherCode] = null;
        break;
      }
      case constants.REMOVE_VOUCHER_REQUEST: {
        draft.isFetching.removeVoucher = true;
        break;
      }
      case constants.REMOVE_VOUCHER_SUCCESS: {
        const { voucherCode, response } = action.payload;
        draft.isFetching.removeVoucher = false;
        draft.removeVoucher[voucherCode] = response;
        break;
      }
      case constants.REMOVE_VOUCHER_FAILURE: {
        const { voucherCode } = action.payload;
        draft.isFetching.removeVoucher = false;
        draft.removeVoucher[voucherCode] = null;
        break;
      }
      case constants.SUBMIT_REQUEST: {
        draft.isFetching.submit = true;
        break;
      }
      case constants.SUBMIT_SUCCESS: {
        const { response } = action.payload;
        const { orderGroup } = response;
        draft.isFetching.submit = false;
        draft.submit[orderGroup] = response;
        break;
      }
      case constants.SUBMIT_FAILURE: {
        draft.isFetching.submit = false;
        break;
      }
    }
  });

export default checkoutRegularPageReducer;
