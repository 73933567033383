import React from 'react';
import PropTypes from 'prop-types';

import { Mui } from '../..';

export const useStyles = Mui.makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: props => props.backgroundColor || 'transparent',
  },
}));

function Body(props) {
  const { children, backgroundColor } = props;

  const classes = useStyles({ backgroundColor });

  return <div className={classes.root}>{children}</div>;
}

Body.propTypes = {
  children: PropTypes.any,
  backgroundColor: PropTypes.string,
};

export default Body;
