/*
 * DisplayStatus Messages
 *
 * This contains all the text for the DisplayStatus component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.common.DisplayStatus';

export default defineMessages({
  // Order.js
  order: {
    id: `${scope}.order`,
    defaultMessage: 'Pedido {orderNumber}',
  },
  contract: {
    id: `${scope}.contract`,
    defaultMessage: 'Contrato {orderNumber}',
  },
  tuition: {
    id: `${scope}.tuition`,
    defaultMessage: 'Mensalidade {orderNumber}',
  },
  reverted: {
    id: `${scope}.reverted`,
    defaultMessage: 'Pedido estornado',
  },
});
