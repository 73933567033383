/*
 * Feedback Messages
 *
 * This contains all the text for the Feedback component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.layouts.Feedback';

export default defineMessages({
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Parece que houve um problema',
  },
});
