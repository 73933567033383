/**
 *
 * SnackbarProvider ducks
 * Read more about this pattern: https://blog.rocketseat.com.br/estrutura-redux-escalavel-com-ducks/
 *
 */

import produce from 'immer';

// Constants

const app = 'app/common/SnackbarProvider';

export const ENQUEUE_SNACKBAR = `${app}/ENQUEUE_SNACKBAR`;
export const REMOVE_SNACKBAR = `${app}/REMOVE_SNACKBAR`;

// Actions

export function enqueueSnackbar(notification) {
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      key: new Date().getTime() + Math.random(),
      ...notification,
    },
  };
}

export function removeSnackbar(key) {
  return {
    type: REMOVE_SNACKBAR,
    key,
  };
}

// Reducers

export const initialState = {
  notifications: [],
};

/* eslint-disable default-case, no-param-reassign */
const snackbarProviderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ENQUEUE_SNACKBAR:
        draft.notifications = [...state.notifications, { ...action.notification }];
        break;
      case REMOVE_SNACKBAR:
        draft.notifications = state.notifications.filter(
          notification => notification.key !== action.key,
        );
        break;
    }
  });

export default snackbarProviderReducer;
