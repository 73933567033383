import React from 'react';
import { Mui } from '../..';

export const Add = props => <Mui.Icon {...props}>add_shopping_cart_outlined</Mui.Icon>;
export const Details = props => <Mui.Icon {...props}>info_outlined</Mui.Icon>;
export const Edit = props => <Mui.Icon {...props}>create_outlined</Mui.Icon>;
export const Empty = props => <Mui.Icon {...props}>money_off_outlined</Mui.Icon>;
export const Minus = props => <Mui.Icon {...props}>remove</Mui.Icon>;
export const More = props => <Mui.Icon {...props}>add</Mui.Icon>;
export const NoShipping = props => <Mui.Icon {...props}>location_off_outlined</Mui.Icon>;
export const Unavailable = props => <Mui.Icon {...props}>remove_shopping_cart_outlined</Mui.Icon>;
export const Contract = props => <Mui.Icon {...props}>find_in_page_outlined</Mui.Icon>;
