import { FormattedMessage } from 'react-intl';
import { useBasket } from 'hooks';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Product } from 'components/common';
import { Label } from 'components/layouts';
import { Mui } from 'components';

// Local components
import messages from './messages';

function FamilyPurchaseButton(props) {
  const { product, enrollment, simple } = props;

  // Constants
  const { uid: enrollmentUid, slug: enrollmentSlug } = enrollment || {};
  const { uid: productUid, slug: productSlug, price, priceRange } = product;

  // Hooks
  const history = useHistory();
  const { collectionSlug } = useParams();
  const { pathname } = useLocation();

  // Custom hooks
  const { basket, handling, alerting, onAdd, onRemove, onBuy, onAlert } = useBasket({
    productSlug,
    productUid,
    enrollmentUid,
    enrollmentSlug,
    collectionSlug,
  });

  // Constants
  const priceLabelProps = {
    ...price,
    ...priceRange,
    ...basket,
    variant: { listPrice: 'body2', sellingPrice: 'body2' },
  };

  const buyButtonProps = {
    complex: !simple,
    simple,
    product,
    price,
    basket,
    loading: handling || alerting,
    onAdd,
    onRemove,
    onBuy,
    onAlert,
    onSelect: ({ slug }) => history.push([pathname, 'p', slug].join('/')),
  };

  const PriceTag = () => {
    if (!product.hasStock) {
      return (
        <div>
          <Mui.Box px={1}>
            <Label color="error">
              <FormattedMessage {...messages.outOfStock} />
            </Label>
          </Mui.Box>
          {Number(priceLabelProps.sellingPrice) > 0 && (
            <Mui.Box px={1} mt={1}>
              <Product.PriceLabel {...priceLabelProps} />
            </Mui.Box>
          )}
        </div>
      );
    }

    if (product.hasKit) {
      return (
        <Mui.Box px={1}>
          <Mui.Typography variant="body2" color="primary" component="div">
            Consultar
          </Mui.Typography>
        </Mui.Box>
      );
    }

    return (
      <Mui.Box px={1} mt={1}>
        <Product.PriceLabel {...priceLabelProps} />
      </Mui.Box>
    );
  };

  return (
    <Mui.Grid container spacing={1} justifyContent="space-between" alignItems="center">
      <Mui.Grid item xs={12}>
        <PriceTag />
      </Mui.Grid>
      <Mui.Grid item xs={12}>
        <Product.BuyButton {...buyButtonProps} />
      </Mui.Grid>
    </Mui.Grid>
  );
}

FamilyPurchaseButton.propTypes = {
  product: PropTypes.object,
  enrollment: PropTypes.object,
  simple: PropTypes.bool,
};

export default FamilyPurchaseButton;
