import { makeStyles } from '@material-ui/styles';
import MuiIcon from '@material-ui/core/Icon';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiMobileStepper from '@material-ui/core/MobileStepper';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

export const slidesStyles = makeStyles(() => ({
  slides: {
    display: 'flex',
  },
  slide: {
    flex: 1,
  },
}));

export const mobileStepperStyles = makeStyles(theme => ({
  /* Styles applied to the root element. */
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'transparent',
    padding: 8,
    height: props => props.fullWidth && (props.height || 250),
  },
  /* Styles applied to the root element if `position="bottom"`. */
  positionBottom: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.mobileStepper,
  },
  /* Styles applied to the root element if `position="top"`. */
  positionTop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.mobileStepper,
  },
  /* Styles applied to the root element if `position="static"`. */
  positionStatic: {},
  /* Styles applied to the dots container if `variant="dots"`. */
  dots: {
    display: props => (props.fullWidth ? 'none' : 'flex'),
    flexDirection: 'row',
    zIndex: theme.zIndex.mobileStepper,
  },
  /* Styles applied to each dot if `variant="dots"`. */
  dot: {
    backgroundColor: theme.palette.action.disabled,
    borderRadius: '50%',
    width: 8,
    height: 8,
    margin: '0 2px',
  },
  /* Styles applied to a dot if `variant="dots"` and this is the active step. */
  dotActive: {
    backgroundColor: props => props.dotActiveColor || theme.palette.primary.main,
  },
  /* Styles applied to the Linear Progress component if `variant="progress"`. */
  progress: {
    width: '50%',
  },
}));

function MobileStepper({
  steps,
  handleNext,
  handlePrevious,
  activeStep,
  height,
  fullWidth,
  dotActiveColor,
}) {
  const classes = mobileStepperStyles({ fullWidth, height, dotActiveColor });

  return (
    <MuiMobileStepper
      steps={steps}
      position="static"
      variant="dots"
      classes={{
        root: classes.root,
        positionBottom: classes.positionBottom,
        positionTop: classes.positionTop,
        positionStatic: classes.positionStatic,
        dots: classes.dots,
        dot: classes.dot,
        dotActive: classes.dotActive,
        progress: classes.progress,
      }}
      activeStep={activeStep}
      nextButton={
        <MuiIconButton size="small" onClick={handleNext} disabled={activeStep === steps - 1}>
          <MuiIcon>keyboard_arrow_right</MuiIcon>
        </MuiIconButton>
      }
      backButton={
        <MuiIconButton size="small" onClick={handlePrevious} disabled={activeStep === 0}>
          <MuiIcon>keyboard_arrow_left</MuiIcon>
        </MuiIconButton>
      }></MuiMobileStepper>
  );
}

MobileStepper.propTypes = {
  /**
   * Set the active step (zero based index).
   * Defines which dot is highlighted when the variant is 'dots'.
   */
  activeStep: PropTypes.number,
  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,
  /**
   * Callback fired when the user clicks on back button.
   */
  handlePrevious: PropTypes.func,
  /**
   * Callback fired when the user clicks on next button.
   */
  handleNext: PropTypes.func,
  /**
   * The component height.
   */
  height: PropTypes.number,
  /**
   * The total steps.
   */
  steps: PropTypes.number,
  /**
   * The color of the dot when active
   */
  dotActiveColor: PropTypes.string,
};

function Slick({ children, responsive, slidesStyle, slideStyle, dotActiveColor }) {
  const classes = slidesStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [slidesPerRow, setSlidesPerRow] = React.useState(0);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  // window resize event listener
  window.onresize = function listener() {
    setWindowWidth(window.innerWidth);
  };

  // when windowWidth changes, it recalculates how many slidesPerRow should be displayed.
  useEffect(() => {
    const responsiveSorted = responsive.sort((x, y) => x.breakpoint - y.breakpoint);
    const breakpoints = responsiveSorted.map(breakpoint => breakpoint.breakpoint);
    const breakpointIndex = breakpoints.reduce((acc, current, index) => {
      if (windowWidth >= current) {
        return index;
      }
      return acc;
    }, 0);
    const newSlidesPerRow = responsiveSorted[breakpointIndex].settings.slidesPerRow;
    setSlidesPerRow(newSlidesPerRow);
  }, [windowWidth]);

  const lastNumber = activeStep + slidesPerRow - 1;
  const numberOfChildrens = React.Children.count(children);
  let steps = numberOfChildrens - slidesPerRow + 1;

  if (steps < 1) steps = 1;

  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1);

  const handlePrevious = () => setActiveStep(prevActiveStep => prevActiveStep - 1);

  return (
    <div>
      <div className={slidesStyle || classes.slides}>
        {React.Children.map(children, (child, index) => {
          if (index >= activeStep && index <= lastNumber) {
            return (
              <div key={child.key} className={slideStyle || classes.slide}>
                {child}
              </div>
            );
          }
          return null;
        })}
        {numberOfChildrens < slidesPerRow &&
          new Array(slidesPerRow - numberOfChildrens)
            .fill()
            .map((_, index) => <div key={`${index.toString()}`} className={classes.slide} />)}
      </div>
      {steps > 1 && (
        <div>
          <MobileStepper
            steps={steps}
            dotActiveColor={dotActiveColor}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            activeStep={activeStep}></MobileStepper>
        </div>
      )}
    </div>
  );
}

Slick.propTypes = {
  /**
   * The component content.
   */
  children: PropTypes.any,
  /**
   * Array of object settings.
   */
  responsive: PropTypes.array,
  /**
   * Classname to override the slides container style
   */
  slidesStyle: PropTypes.string,
  /**
   * Classname slide style
   */
  slideStyle: PropTypes.string,
  /**
   * The color of the dot when active
   */
  dotActiveColor: PropTypes.string,
};

Slick.defaultProps = {
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesPerRow: 12,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesPerRow: 4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesPerRow: 2,
      },
    },
  ],
};

export default Slick;
