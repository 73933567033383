function underscoreFromCamelCase(input) {
  const upperLetter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  const output = input.split('').map(letter => {
    const hasUpperLetter = upperLetter.filter(i => i === letter).length > 0;

    if (hasUpperLetter) {
      return `_${letter.toLowerCase()}`;
    }
    return letter;
  });

  return output.join('');
}

export default underscoreFromCamelCase;
