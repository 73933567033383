/* eslint-disable no-unused-vars */
import moment from 'moment';

import underscoreFromCamelCase from './underscoreFromCamelCase';

const DJANGO_FILTER_DATE_FORMAT = 'YYYY-MM-DD HH:mm';

function parseDate(date = new Date()) {
  return moment(date).format(DJANGO_FILTER_DATE_FORMAT);
}

function makeRangeFilter(value, type = 'date') {
  let suffixes = {
    start: '__gt',
    end: '__lt',
  };

  const isDate = type === 'date';

  if (type === 'numeric') {
    suffixes = {
      start: '_min',
      end: '_max',
    };
  }

  if (
    typeof value === 'object' &&
    value !== null &&
    Object.prototype.hasOwnProperty.call(value, 'initial') &&
    Object.prototype.hasOwnProperty.call(value, 'final')
  ) {
    if (value.initial && value.final) {
      return [
        {
          value: isDate ? parseDate(value.initial) : value.initial,
          suffix: suffixes.start,
        },
        {
          value: isDate ? parseDate(value.final) : value.final,
          suffix: suffixes.end,
        },
      ];
    }

    if (value.initial) {
      return [
        {
          value: isDate ? parseDate(value.initial) : value.initial,
          suffix: suffixes.start,
        },
      ];
    }

    return [
      {
        value: isDate ? parseDate() : 1,
        suffix: suffixes.start,
      },
    ];
  }

  return [
    {
      value: parseDate(value),
      suffix: suffixes.start,
    },
  ];
}

function queryBuilder(query) {
  const { filters, orderBy, orderDirection, page, pageSize, search, totalCount } = query;

  const url = [];

  if (pageSize) {
    url.push(`page_size=${pageSize}`);
    url.push(`page=${page + 1}`);
  }

  if (search) {
    url.push(`search=${search}`);
  }

  if (filters && filters.length > 0) {
    filters.forEach(filter => {
      const { field, type, lookup } = filter.column;
      const { operator } = filter;

      switch (type) {
        case 'boolean': {
          url.push(`${underscoreFromCamelCase(field)}${operator}${filter.value === 'checked'}`);
          break;
        }

        case 'date': {
          const dateFilter = makeRangeFilter(filter.value, type);
          dateFilter.forEach(({ suffix, value }) => {
            url.push(`${underscoreFromCamelCase(field)}${suffix}${operator}${value}`);
          });
          break;
        }

        case 'numeric': {
          const numericFilter = makeRangeFilter(filter.value, type);
          numericFilter.forEach(({ suffix, value }) => {
            url.push(`${underscoreFromCamelCase(field)}${suffix}${operator}${value}`);
          });
          break;
        }

        default: {
          if (Array.isArray(filter.value)) {
            url.push(`${underscoreFromCamelCase(field)}__in${operator}${filter.value.join(',')}`);
          } else {
            url.push(`${underscoreFromCamelCase(field)}${operator}${filter.value}`);
          }
        }
      }
    });
  }

  return `?${url.join('&')}`;
}

export default queryBuilder;
