/*
 *
 * PermissionProvider actions
 *
 */

import * as constants from './constants';

export function retrieveRequest(payload) {
  return { type: constants.RETRIEVE_REQUEST, payload };
}

export function retrieveSuccess(payload) {
  return { type: constants.RETRIEVE_SUCCESS, payload };
}

export function retrieveFailure(payload) {
  return { type: constants.RETRIEVE_FAILURE, payload };
}

export function institutionsRequest(payload) {
  return { type: constants.INSTITUTIONS_REQUEST, payload };
}

export function institutionsSuccess(payload) {
  return { type: constants.INSTITUTIONS_SUCCESS, payload };
}

export function institutionsFailure(payload) {
  return { type: constants.INSTITUTIONS_FAILURE, payload };
}

export function sellersRequest(payload) {
  return { type: constants.SELLERS_REQUEST, payload };
}

export function sellersSuccess(payload) {
  return { type: constants.SELLERS_SUCCESS, payload };
}

export function sellersFailure(payload) {
  return { type: constants.SELLERS_FAILURE, payload };
}
