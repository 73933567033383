/**
 *
 * ScrollToTop
 *
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

const ignorePaths = [
  '/families/:enrollmentSlug/:collectionSlug',
  '/families/:enrollmentSlug/:collectionSlug/p/:productSlug',
  '/sellers/:sellerSlug/support/tickets/:ticketId',
];

function ScrollToTop() {
  const { pathname } = useLocation();

  const ignore = matchPath(pathname, {
    path: ignorePaths,
    exact: true,
  });

  useEffect(() => {
    if (!ignore) window.scrollTo(0, 0);
  }, [pathname, ignore]);

  return null;
}

ScrollToTop.propTypes = {};

export default ScrollToTop;
