/*
 * CommonJobNotification Messages
 *
 * This contains all the text for the CommonJobNotification container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.widgets.CommonJobNotification';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Processamento de atividades',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Estes são os trabalhos que foram ou estão sendo processados por nossos servidores referentes a importações, exportações ou qualquer tipo de atividade que requeira algum processamento prolongado.',
  },
  emptyMessage: {
    id: `${scope}.emptyMessage`,
    defaultMessage: 'Nenhum trabalho disponível',
  },
  notifyOwnerTooltip: {
    id: `${scope}.notifyOwnerTooltip`,
    defaultMessage: 'Notificar usuário',
  },
  inputFileTooltip: {
    id: `${scope}.inputFileTooltip`,
    defaultMessage: 'Arquivo de entrada',
  },
  outputFileTooltip: {
    id: `${scope}.outputFileTooltip`,
    defaultMessage: 'Arquivo de saída',
  },
  clickHereLabel: {
    id: `${scope}.clickHereLabel`,
    defaultMessage: 'Clique aqui',
  },
  viewMore: {
    id: `${scope}.viewMore`,
    defaultMessage: 'Ver mais',
  },
  errorMessage: {
    id: `${scope}.errorMessage`,
    defaultMessage: '{ quantity, plural, =0 {} one {Erro: } other {Erros: } }',
  },
});
