import { errorHandler } from 'utils';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { MuiLab, Mui } from 'components';

import messages from './messages';

function errorChecker(error) {
  if (Array.isArray(error)) {
    const newError = error.filter(item => !!item);

    return newError.length > 0 ? newError : null;
  }

  return error;
}

function render(error) {
  if (Array.isArray(error)) {
    return error.map((item, index) => (
      <div key={`${index.toString()}`}>{errorHandler(item?.response?.data)}</div>
    ));
  }

  return errorHandler(error?.response?.data);
}

function Feedback(props) {
  const { severity = 'error', title, error: rawError, onClose, ...restProps } = props;

  const error = errorChecker(rawError);

  if (error) {
    return (
      <Mui.Box {...restProps}>
        <MuiLab.Alert severity={severity} onClose={onClose}>
          <MuiLab.AlertTitle>{title || <FormattedMessage {...messages.error} />}</MuiLab.AlertTitle>
          {render(error)}
        </MuiLab.Alert>
      </Mui.Box>
    );
  }

  return null;
}

Feedback.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  severity: PropTypes.any,
  title: PropTypes.any,
  onClose: PropTypes.func,
};

export default Feedback;
