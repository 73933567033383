/*
 * JoyrideCustomBeacon Messages
 *
 * This contains all the text for the JoyrideCustomBeacon component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.widgets.JoyrideCustomBeacon';

export default defineMessages({
  helperTitle: {
    id: `${scope}.helperTitle`,
    defaultMessage: 'Precisa de ajuda?',
  },
});
