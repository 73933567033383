import * as LandingPage from './LandingPage';
import * as PersonasHero from './PersonasHero';

export { default as Footer } from './Footer';
export { default as CovidAlert } from './CovidAlert';
export { default as Hero } from './Hero';
export { default as HowToHorizontalSteps } from './HowToHorizontalSteps';
export { default as CustomerExhibition } from './CustomerExhibition';
export { default as NewsBox } from './NewsBox';
export { default as PersonasTabs } from './PersonasTabs';
export { default as ReasonsToChoose } from './ReasonsToChoose';
export { default as Testimonials } from './Testimonials';

export { LandingPage, PersonasHero };
