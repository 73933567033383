import { Redirect } from 'react-router-dom';
import React, { lazy } from 'react';

// Layouts
import PublicLayout from 'layouts/PublicLayout';

export default [
  {
    path: '/l',
    layout: PublicLayout,
    routes: [
      {
        exact: true,
        path: '/l/:landingPageSlug/p/:productSlug',
        component: lazy(() => import('pages/l/ProductDetailsPage')),
      },
      {
        exact: true,
        path: '/l/:landingPageSlug',
        component: lazy(() => import('pages/l/InstitutionLandingPage')),
      },
      {
        component: () => <Redirect push to="/404" />,
      },
    ],
  },
];
