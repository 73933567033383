import React from 'react';

import { Mui } from '../..';

export const Contract = props => <Mui.Icon {...props}>assignment_outlined</Mui.Icon>;
export const Cart = props => <Mui.Icon {...props}>shopping_cart_outlined</Mui.Icon>;
export const Subscription = props => <Mui.Icon {...props}>subscriptions_outlined</Mui.Icon>;
export const Tuition = props => <Mui.Icon {...props}>event</Mui.Icon>;
export const Order = props => <Mui.Icon {...props}>check_box_outlined</Mui.Icon>;
export const SignedContract = props => <Mui.Icon {...props}>assignment_turned_in</Mui.Icon>;
