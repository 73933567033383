import React from 'react';
import { FormattedMessage } from 'react-intl';
import { put, takeEvery } from 'redux-saga/effects';
import { Mui } from '../../../components';
import * as snackbarProviderActions from '../../common/SnackbarProvider/ducks';
import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import messages from './messages';

export function* listRequestSaga(action) {
  const { query } = action.payload;

  try {
    const response = yield api.list({ query });

    yield put(actions.listSuccess({ response: response.data }));
  } catch (error) {
    yield put(actions.listFailure({ error }));
  }
}

export function* retrieveRequestSaga(action) {
  const { jobUid, notify, callback } = action.payload;

  try {
    const response = yield api.retrieve({ jobUid });

    yield put(
      actions.retrieveSuccess({
        jobUid,
        response: response.data,
        notify,
        ...(callback && { callback }),
      }),
    );
  } catch (error) {
    yield put(actions.retrieveFailure({ jobUid, error }));
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function* retrieveSuccessSaga(action) {
  const { jobUid, response, notify, callback } = action.payload;

  if (response) {
    const { status, outputFile, outputJson } = response;

    if (status === 'completed') {
      const values = {
        link: (
          <Mui.Link color="inherit" href={outputFile}>
            <FormattedMessage {...messages.clickHereLabel} />
          </Mui.Link>
        ),
      };

      yield put(
        snackbarProviderActions.enqueueSnackbar(
          outputFile
            ? { messageId: 'MSG0039', variant: 'success', values }
            : { messageId: 'MSG0143', variant: 'success' },
        ),
      );

      yield callback && callback();
      yield put(actions.removeJob({ jobUid }));
    } else if (status === 'errored') {
      let errors = '';

      if (outputJson.errors) {
        if (Array.isArray(outputJson.errors)) {
          errors = outputJson.errors.join(', ');
        } else {
          errors = outputJson.errors;
        }
      }

      yield !!notify &&
        put(
          snackbarProviderActions.enqueueSnackbar({
            messageId: 'MSG0114',
            variant: 'error',
            values: { errors },
          }),
        );

      yield put(actions.removeJob({ jobUid }));
    }
  }
}

export function* retrieveFailureSaga(action) {
  const { jobUid } = action.payload;

  yield put(actions.removeJob({ jobUid }));
}

// Individual exports for testing
export default function* commonJobNotificationSaga() {
  yield takeEvery(constants.LIST_REQUEST, listRequestSaga);
  yield takeEvery(constants.RETRIEVE_REQUEST, retrieveRequestSaga);
  yield takeEvery(constants.RETRIEVE_SUCCESS, retrieveSuccessSaga);
  yield takeEvery(constants.RETRIEVE_FAILURE, retrieveFailureSaga);
}
