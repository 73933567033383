// prettier-ignore
export const MASKS = {
  taxDocumentCPF: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  taxDocumentCNPJ: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d?/],
  taxDocumentCPFCNPJ: rawValue =>
    rawValue.replace(/[^\d]/g, '').length > 11
      ? [/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'/',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/]
      : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  phoneNumber: rawValue =>
    rawValue.replace(/[^\d]/g, '').length > 10
      ? ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      : ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  postalCode:  [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  percentageNumber: rawValue =>
    rawValue.length < 2
      ? [/\d/]
      : [0, '.', /\d/, /\d/, /\d/, /\d/],
  time: [/\d/, /\d/, ':', /\d/, /\d/],
  ncm: [/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/],
  threeDigits: [/\d/, /\d/, /\d/],
  fourDigits: [/\d/, /\d/, /\d/, /\d/],
};

export const unmask = value => {
  const stringValue = value.toString();
  return stringValue.replace(/\D/g, '');
};
