/**
 * A custom component to mask the credit card number
 *
 */
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

export default function CreditCardNumberMask(props) {
  const { inputRef, mask, ...rest } = props;

  return (
    <MaskedInput
      guide={false}
      {...rest}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={
        mask || [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]
      }
    />
  );
}

CreditCardNumberMask.propTypes = {
  inputRef: PropTypes.any,
  mask: PropTypes.any,
};
