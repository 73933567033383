import { colors } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import * as images from 'images';

import shadows from './shadows';
import typography from './typography';
import zIndex from './zIndex';

export default {
  direction: 'ltr',
  typography,
  palette: {
    type: 'light',
    primary: {
      main: '#0AADFF',
      contrastText: common.white,
    },
    secondary: {
      main: '#546e7a',
      contrastText: common.white,
    },
    info: {
      main: '#2196f3',
      contrastText: common.white,
    },
    warning: {
      main: '#ff9800',
      contrastText: common.white,
    },
    success: {
      main: '#4caf50',
      contrastText: common.white,
    },
    error: {
      main: '#f44336',
      contrastText: common.white,
    },
    background: {
      default: colors.common.white,
      dark: '#fafafa',
      paper: colors.common.white,
    },
    divider: 'rgba(0, 0, 0, 0.09)',
  },
  shadows,
  themeName: 'Eskolare Default Theme 2019',
  zIndex,
  assets: {
    repeater: images.appbarBorder,
    logoHorizontal: images.logoHorizontalColor,
    icon: images.logoSymbolSvg,
  },
  props: {
    MuiButton: { disableElevation: true },
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};
