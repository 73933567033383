// For more infos, see: https://github.com/jquense/yup/blob/master/src/locale.js
import { setLocale } from 'yup';

function setYupPtBr() {
  const translation = {
    mixed: {
      default: 'Está inválido',
      required: 'Campo obrigatório',
      oneOf: ({ values }) => `Deve ser um dos seguintes valores: ${values}`,
      notOneOf: ({ values }) => `Não pode ser um dos seguintes valores: ${values}`,
    },
    string: {
      length: ({ length }) => `Deve ter exatamente ${length} caracteres`,
      min: ({ min }) => `Deve ter pelo menos ${min} caracteres`,
      max: ({ max }) => `Deve ter no máximo ${max} caracteres`,
      email: 'Tem o formato de e-mail inválido',
      url: 'Deve ter um formato de URL válida',
      trim: 'Não deve conter espaços no início ou no fim.',
      lowercase: 'Deve estar em maiúsculo',
      uppercase: 'Deve estar em minúsculo',
    },
    number: {
      min: ({ min }) => `Deve ser no mínimo ${min}`,
      max: ({ max }) => `Deve ser no máximo ${max}`,
      lessThan: ({ less }) => `Deve ser menor que ${less}`,
      moreThan: ({ more }) => `Deve ser maior que ${more}`,
      notEqual: ({ notEqual }) => `Não pode ser igual à ${notEqual}`,
      positive: 'Deve ser um número positivo',
      negative: 'Deve ser um número negativo',
      integer: 'Deve ser um número inteiro',
    },
    date: {
      min: ({ min }) => `Deve ser maior que a data ${min}`,
      max: ({ max }) => `Deve ser menor que a data ${max}`,
    },
    array: {
      min: ({ min }) => `Deve ter no mínimo ${min} itens`,
      max: ({ max }) => `Deve ter no máximo ${max} itens`,
    },
  };

  setLocale(translation);
}

export { setYupPtBr };
