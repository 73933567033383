/*
 * SocialAuthentication Messages
 *
 * This contains all the text for the SocialAuthentication container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.common.SocialAuthentication';

export default defineMessages({
  socialLoginFailureMessage: {
    id: `${scope}.socialLoginFailureMessage`,
    defaultMessage:
      'Não foi possível se conectar utilizando o {provider} por algum problema de permissão do lado deles ou por que você fechou a janela antes de concluir. Você pode tentar se autenticar utilizando seu e-mail e senha.',
  },
});
