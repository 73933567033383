/*
 * HowToHorizontalSteps Messages
 *
 * This `conta`ins all the text for the HowToHorizontalSteps container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.landing.HowToHorizontalSteps';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Como funciona?',
  },
  overline: {
    id: `${scope}.overline`,
    defaultMessage: 'Simples. Fácil. Rápido.',
  },
  howToUseSectionStep1PositionLabel: {
    id: `${scope}.howToUseSectionStep1PositionLabel`,
    defaultMessage: 'Primeiro passo',
  },
  howToUseSectionStep1Title: {
    id: `${scope}.howToUseSectionStep1Title`,
    defaultMessage: 'Criar conta',
  },
  howToUseSectionStep1Description: {
    id: `${scope}.howToUseSectionStep1Description`,
    defaultMessage: `Após criar sua conta como responsável, cadastre o aluno(a) em qual instituição está matriculado. Pronto! Comece a investir na educação do seu filho(a)`,
  },
  howToUseSectionStep2PositionLabel: {
    id: `${scope}.howToUseSectionStep2PositionLabel`,
    defaultMessage: 'Segundo passo',
  },
  howToUseSectionStep2Title: {
    id: `${scope}.howToUseSectionStep2Title`,
    defaultMessage: 'Selecione',
  },
  howToUseSectionStep2Description: {
    id: `${scope}.howToUseSectionStep2Description`,
    defaultMessage:
      'Selecione livros, materiais escolares, cursos, esportes, atividades, uniformes e tudo que precisa com a garantia de que eles foram disponibilizados pela instituição',
  },
  howToUseSectionStep3PositionLabel: {
    id: `${scope}.howToUseSectionStep3PositionLabel`,
    defaultMessage: 'Terceiro passo',
  },
  howToUseSectionStep3Title: {
    id: `${scope}.howToUseSectionStep3Title`,
    defaultMessage: 'Pague',
  },
  howToUseSectionStep3Description: {
    id: `${scope}.howToUseSectionStep3Description`,
    defaultMessage:
      'Selecione o método de entrega e forma de pagamento. Estaremos em contato com você durante todo o processo para que possa acompanhar o status do seu pedido',
  },
  howToUseSectionStep4PositionLabel: {
    id: `${scope}.howToUseSectionStep4PositionLabel`,
    defaultMessage: 'Quarto passo',
  },
  howToUseSectionStep4Title: {
    id: `${scope}.howToUseSectionStep4Title`,
    defaultMessage: 'Receba',
  },
  howToUseSectionStep4Description: {
    id: `${scope}.howToUseSectionStep4Description`,
    defaultMessage:
      'Você tem tudo pronto para a rotina escolar do aluno(a). Em caso de dúvida, conte com a gente. Fique tranquilo!',
  },
});
