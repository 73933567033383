import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui, MuiLab, TextField } from 'components';

function OrderTypeFilter(props) {
  const { multiple, selectedType, onSelect, ...otherProps } = props;

  // Constants
  const options = [
    { label: 'Pedidos Único', icon: 'inventory', value: 'order' },
    { label: 'Contrato', icon: 'assignment', value: 'contract' },
    { label: 'Mensalidade', icon: 'event', value: 'tuition' },
  ];

  // Actions
  const handleTypeFilter = (event, selectedOption) =>
    onSelect(
      !multiple ? selectedOption?.value : selectedOption?.map(option => option.value).join(','),
    );

  return (
    <MuiLab.Autocomplete
      multiple={multiple}
      options={options}
      limitTags={3}
      value={
        !multiple
          ? options.find(option => option?.value === selectedType)
          : options.filter(option => selectedType?.split(',').includes(option?.value))
      }
      getOptionLabel={option => option?.label}
      getOptionSelected={(option, value) => option?.value === value?.value}
      onChange={handleTypeFilter}
      renderOption={(option, { selected }) => (
        <>
          <Mui.ListItemIcon>
            <Mui.Icon color={selected ? 'primary' : 'secondary'}>{option?.icon}</Mui.Icon>
          </Mui.ListItemIcon>
          <Mui.ListItemText primaryTypographyProps={{ color: selected ? 'primary' : 'secondary' }}>
            {option?.label}
          </Mui.ListItemText>
        </>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Mui.Chip
            avatar={
              <Mui.Avatar>
                <Mui.Icon fontSize="small">{option?.icon}</Mui.Icon>
              </Mui.Avatar>
            }
            label={option?.label}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={inputParams => (
        <TextField {...inputParams} label="Tipo do pedido" margin="none" />
      )}
      {...otherProps}
    />
  );
}

OrderTypeFilter.propTypes = {
  multiple: PropTypes.bool,
  selectedType: PropTypes.string,
  onSelect: PropTypes.func,
};

export default OrderTypeFilter;
