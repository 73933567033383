import React from 'react';

import { Grid, Mui, Link } from '../..';
import { Repeater } from '../../layouts';
import * as images from '../../../images';

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    width: '100%',
    bottom: 0,
  },
  container: { paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3) },
}));

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Repeater />
      <Mui.Container className={classes.container}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Link href="/">
              <img src={images.logoHorizontalColor} alt="Eskolare" style={{ height: 20 }} />
            </Link>
          </Grid>
        </Grid>
      </Mui.Container>
    </div>
  );
}

Footer.propTypes = {};

export default Footer;
