import { FormattedMessage } from 'react-intl';
import { Mui } from 'components';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useUser, useFetch } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { NavBar as DefaultNavBar } from 'components/layouts';

// Containers
import FamilyEnrollmentMenu from 'containers/families/FamilyEnrollmentMenu';

import messages from './messages';
import * as api from './api';

function NavBar(props) {
  const { onMobileClose, openMobile, ...restProps } = props;

  // Hooks
  const { pathname } = useLocation();

  // Custom hooks
  const { user } = useUser();
  const match = matchPath(pathname, { exact: false, path: '/families/:enrollmentSlug' });

  // Constants
  const { params = {} } = match || {};
  const { enrollmentSlug } = params;

  // Fetch
  const { data: enrollment } = useFetch(
    [enrollmentSlug ? api.endpoints.enrollment(enrollmentSlug) : null],
    { dedupingInterval: 30000 },
  );

  // Constants
  const { email } = user;
  const { student = {}, showcase = {} } = enrollment || {};
  const { uid: studentUid, firstName, lastName, picture, isResponsible } = student;
  const { collections = [] } = showcase;

  // Props
  const profile = {
    image: picture,
    displayName: firstName || lastName ? [firstName, lastName].join(' ') : email,
    profileUrl: isResponsible
      ? `/accounts/profile`
      : `/accounts/students?student_uid=${studentUid}&edit=1`,
  };

  const items = [
    {
      subheader: <FormattedMessage {...messages.collections} />,
      items: collections.map(collection => ({
        title: collection.displayName,
        avatar: newProps => <Mui.Avatar src={collection.image} {...newProps} />,
        href: `/families/${enrollmentSlug}/${collection.slug}`,
        info: () => (
          <Mui.Chip size="small" color="secondary" variant="outlined" label={collection.items} />
        ),
      })),
      visible: collections.length > 0,
    },
  ].filter(item => item.visible === true);

  return (
    <DefaultNavBar
      onMobileClose={onMobileClose}
      openMobile={openMobile}
      items={items}
      profile={profile}
      {...restProps}>
      <FamilyEnrollmentMenu />
    </DefaultNavBar>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
