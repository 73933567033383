import { useState, useCallback, useEffect, useMemo } from 'react';
import omit from 'lodash/omit';

/**
 * This hook should be use to save the selection of an array of objects
 * with easier hooks to manipulate.
 * @param {String} initialKey Need to be the unique identifier from the object
 * @param {Array} initialState An array of objects with unique identifier values
 */
function useSelection(initialKey, initialState = []) {
  const [defaultKey] = useState(initialKey);
  const [defaultInitialState] = useState(initialState);
  const [get, setGet] = useState({});

  const normalize = useCallback(
    array => {
      const objects = {};

      array.map(item => {
        const key = item[defaultKey];

        objects[key] = item;

        return item;
      });

      return objects;
    },
    [defaultKey],
  );

  useEffect(() => {
    const objects = normalize(defaultInitialState);

    setGet(objects);
  }, [normalize, defaultInitialState]);

  const set = useCallback(
    option => {
      setGet(prevGet => {
        const key = option[defaultKey];

        if (prevGet[key]) return omit(prevGet, [option[defaultKey]]);

        return { ...prevGet, [key]: option };
      });
    },
    [defaultKey],
  );

  const reset = useCallback(
    items => {
      const objects = normalize(items);

      setGet(objects);
    },
    [normalize],
  );

  const selected = Object.keys(get).map(key => get[key]);

  return useMemo(() => [selected, set, get, reset], [selected, set, get, reset]);
}

export default useSelection;
