/*
 *
 * ContactForm constants
 *
 */

const app = 'app/landing/ContactForm';

export const CONTACT_REQUEST = `${app}/CONTACT_REQUEST`;
export const CONTACT_SUCCESS = `${app}/CONTACT_SUCCESS`;
export const CONTACT_FAILURE = `${app}/CONTACT_FAILURE`;
