/**
 *
 * BackToTop
 *
 */

import React from 'react';

import { Mui } from 'components';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(4),
  },
}));

function BackToTop() {
  const content = document.getElementById('content');

  // Hooks
  const classes = useStyles();
  const trigger = Mui.useScrollTrigger({
    target: content,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    if (content && typeof content.scroll === 'function')
      content.scroll({ left: 0, top: 0, behavior: 'smooth' });
  };

  return (
    <Mui.Zoom in={trigger}>
      <Mui.Fab color="secondary" size="small" onClick={handleClick} className={classes.root}>
        <Mui.Icon>keyboard_arrow_up</Mui.Icon>
      </Mui.Fab>
    </Mui.Zoom>
  );
}

BackToTop.propTypes = {};

export default BackToTop;
