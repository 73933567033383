import { FormattedMessage } from 'react-intl';
import { useInView } from 'react-intersection-observer';
import * as images from 'images';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Components
import { Grid, Mui, Typography } from 'components';

import messages from './messages';

export const useStyles = Mui.makeStyles(theme => ({
  root: {},
  stepIcon: {
    display: 'block',
    maxHeight: 40,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 40,
      maxHeight: 'auto',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  chevron: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
}));

function HowToHorizontalSteps() {
  // Hooks
  const classes = useStyles();
  const [inViewRef, inView] = useInView({ threshold: 0.33 });

  const steps = [
    {
      title: <FormattedMessage {...messages.howToUseSectionStep1Title} />,
      position: <FormattedMessage {...messages.howToUseSectionStep1PositionLabel} />,
      icon: images.iconsAddPerson,
      body: <FormattedMessage {...messages.howToUseSectionStep1Description} />,
    },
    {
      title: <FormattedMessage {...messages.howToUseSectionStep2Title} />,
      position: <FormattedMessage {...messages.howToUseSectionStep2PositionLabel} />,
      icon: images.iconsShipped,
      body: <FormattedMessage {...messages.howToUseSectionStep2Description} />,
    },
    {
      title: <FormattedMessage {...messages.howToUseSectionStep3Title} />,
      position: <FormattedMessage {...messages.howToUseSectionStep3PositionLabel} />,
      icon: images.iconsCheckmark,
      body: <FormattedMessage {...messages.howToUseSectionStep3Description} />,
    },
    {
      title: <FormattedMessage {...messages.howToUseSectionStep4Title} />,
      position: <FormattedMessage {...messages.howToUseSectionStep4PositionLabel} />,
      icon: images.iconsShippingTruck,
      body: <FormattedMessage {...messages.howToUseSectionStep4Description} />,
    },
  ];

  return (
    <div ref={inViewRef}>
      <Mui.Box mt={4} mb={8}>
        <Typography component="p" variant="overline" color="primary" align="center">
          <FormattedMessage {...messages.overline} />
        </Typography>
        <Typography variant="h1" align="center" color="textPrimary">
          <FormattedMessage {...messages.title} />
        </Typography>
      </Mui.Box>
      <Grid container justifyContent="center" spacing={4}>
        {steps.map((step, index) => (
          <Grid item md={3} xs={12} key={`${index.toString()}`}>
            <Mui.Grow in={inView} timeout={500 * index + 1}>
              <div>
                <Step step={step} position={index + 1} classes={classes} />
              </div>
            </Mui.Grow>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function Step(props) {
  const { step, position } = props;

  // Hooks
  const classes = useStyles();
  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  // States
  const [collapsed, setCollapsed] = React.useState(false);

  // Effects

  useEffect(() => {
    setCollapsed(fullScreen);
  }, [fullScreen]);

  return (
    <>
      <div className={classes.titleContainer}>
        <Mui.Badge
          color="primary"
          badgeContent={position}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}>
          <img src={step.icon} className={classes.stepIcon} alt={step.title} />
        </Mui.Badge>
        <Mui.Box ml={3}>
          <Mui.Typography variant="h5" component="div">
            {step.title}
          </Mui.Typography>
        </Mui.Box>
        <Mui.Hidden smUp>
          <div
            className={classes.chevron}
            onClick={() => setCollapsed(!collapsed)}
            role="presentation">
            {collapsed ? (
              <Mui.Icon>keyboard_arrow_down</Mui.Icon>
            ) : (
              <Mui.Icon>keyboard_arrow_up</Mui.Icon>
            )}
          </div>
        </Mui.Hidden>
      </div>
      <Mui.Collapse in={!collapsed}>
        <Mui.Box mt={2} mb={2}>
          <Mui.Typography variant="body1" color="textPrimary">
            {step.body}
          </Mui.Typography>
        </Mui.Box>
      </Mui.Collapse>
    </>
  );
}

Step.propTypes = {
  step: PropTypes.object,
  position: PropTypes.number,
};

export default HowToHorizontalSteps;
