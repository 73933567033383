/*
 *
 * PermissionProvider api's
 *
 */

import axios from 'axios';

import envs from '../../../envs';

const domain = `${envs.PROTOCOL}://${envs.ENTRYPOINT}/api`;

export const endpoints = {
  retrieve: () => `${domain}/commons/permissions/`,
  institutions: institutionSlug => `${domain}/commons/permissions/institutions/${institutionSlug}/`,
  sellers: sellerSlug => `${domain}/commons/permissions/sellers/${sellerSlug}/`,
};

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#6d2521e3-d5af-494c-9576-413aaad7fcb0
 */
export function retrieve() {
  const config = {
    method: 'get',
    url: endpoints.retrieve(),
  };

  return axios(config);
}

/**
 * Documentation: ...
 */
export function institutions({ institutionSlug }) {
  const config = {
    method: 'get',
    url: endpoints.institutions(institutionSlug),
  };

  return axios(config);
}

/**
 * Documentation: ...
 */
export function sellers({ sellerSlug }) {
  const config = {
    method: 'get',
    url: endpoints.sellers(sellerSlug),
  };

  return axios(config);
}
