import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiOutlinedInput from '@material-ui/core/OutlinedInput';
import MuiSelect from '@material-ui/core/Select';

function NativeSelect(props) {
  const { name, label, onSelect, children, formik, messages, formControlProps, ...muiProps } =
    props;
  const { touched, errors, values, setFieldValue, setFieldTouched } = formik || {};

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  // This section of the code handles with formik integration
  // using vars that came from formik object, such: values, errors,
  // touched, for example.
  const hasMessage = messages && messages[name];
  const helperText = hasMessage && messages[name].helperText;

  const errorText = errors && errors[name];
  const hasError = touched && touched[name] && errorText !== undefined;
  const value = values && values[name];

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const selectProps = {
    name,
    value,
    onChange: event => {
      if (formik) setFieldTouched(name, true, true);
      if (formik) setFieldValue(name, event.target.value, true);
      if (onSelect) onSelect(event.target.value);
    },
  };

  return (
    <MuiFormControl
      variant="outlined"
      error={hasError}
      fullWidth
      margin="normal"
      {...formControlProps}>
      <MuiInputLabel ref={inputLabel} htmlFor={`outlined-${name}-native-simple`}>
        {label}
      </MuiInputLabel>
      <MuiSelect
        native
        {...selectProps}
        input={
          <MuiOutlinedInput
            name={name}
            labelWidth={labelWidth}
            id={`outlined-${name}-native-simple`}
          />
        }
        {...muiProps}>
        {children}
      </MuiSelect>
      {hasError && <MuiFormHelperText>{errorText}</MuiFormHelperText>}
      {!hasError && helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
    </MuiFormControl>
  );
}

NativeSelect.propTypes = {
  /**
   * The component content.
   */
  children: PropTypes.any,
  /**
   * The formik object.
   */
  formik: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }),
  /**
   * The select label.
   */
  label: PropTypes.any,
  /**
   * The messages object.
   */
  messages: PropTypes.object,
  /**
   * The FormControl component props.
   */
  formControlProps: PropTypes.object,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string.isRequired,
  /**
   * Callback fired when the value is changed.
   *
   * @param {String} value The event source of the callback.
   */ onSelect: PropTypes.func,
  /**
   * The selected value.
   */
  value: PropTypes.any,
};

export default NativeSelect;
