import { loadable } from 'utils';

export const AccessData = loadable(() => import('./AccessData'), {});
export const BusinessIdentification = loadable(() => import('./BusinessIdentification'), {});
export const Certificate = loadable(() => import('./Certificate'), {});
export const Location = loadable(() => import('./Location'), {});
export const MunicipalData = loadable(() => import('./MunicipalData'), {});
export const Settings = loadable(() => import('./Settings'), {});
export const StateData = loadable(() => import('./StateData'), {});
export const Taxes = loadable(() => import('./Taxes'), {});
