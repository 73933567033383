import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Components
import { Grid, Mui, Paper, Typography } from 'components';
import { MaskedPostalCode } from '../InputMask';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

function Freight(props) {
  const { shippingAddress, onSubmit, loading, postcode, variant } = props;

  const classes = useStyles(props);

  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(shippingAddress ? shippingAddress.postcode : postcode || '');

  const handleSubmit = () => {
    if (value) {
      setEdit(false);
      if (onSubmit) onSubmit(value);
    }
  };

  const Address = () =>
    shippingAddress ? (
      <Grid container>
        <Grid item xs={12}>
          {shippingAddress.line1 && (
            <Typography variant="subtitle2" color="textSecondary" component="span">
              {shippingAddress.line1}
            </Typography>
          )}
          {shippingAddress.line4 && (
            <Typography variant="subtitle2" color="textSecondary" component="span">
              , {shippingAddress.line4}
            </Typography>
          )}
          {shippingAddress.line3 && (
            <Typography variant="subtitle2" color="textSecondary" component="span">
              {' '}
              - {shippingAddress.line3}
            </Typography>
          )}
          {shippingAddress.line2 && (
            <Typography variant="subtitle2" color="textSecondary" component="span">
              {' '}
              - {shippingAddress.line2}
            </Typography>
          )}
          <div>
            {shippingAddress.city && (
              <Typography variant="subtitle2" color="textSecondary" component="span">
                {shippingAddress.city}
              </Typography>
            )}
            {shippingAddress.stateCode && (
              <Typography variant="subtitle2" color="textSecondary" component="span">
                /{shippingAddress.stateCode}
              </Typography>
            )}{' '}
            {shippingAddress.postcode && (
              <Typography variant="subtitle2" color="textSecondary" component="span">
                <strong>{shippingAddress.postcode}</strong>
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    ) : null;

  return (
    <Paper className={classes.root} variant={variant}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Mui.Avatar className={classes.avatar}>
                <Mui.Icon>local_shipping</Mui.Icon>
              </Mui.Avatar>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="textPrimary">
                Frete simulado
              </Typography>
              {!edit ? (
                <>
                  <Address />
                  {!shippingAddress && postcode && (
                    <Typography variant="body1" color="textSecondary">
                      {postcode}
                    </Typography>
                  )}
                  {!shippingAddress && !postcode && (
                    <Typography variant="body1" color="textSecondary">
                      Informe um CEP para simular o frete do pedido
                    </Typography>
                  )}
                </>
              ) : (
                <Mui.TextField
                  name="freight"
                  value={value}
                  onKeyPress={ev => {
                    if (ev.key === 'Enter') {
                      handleSubmit();
                      ev.preventDefault();
                    }
                  }}
                  onChange={event => setValue(event.target.value)}
                  InputProps={{ inputComponent: MaskedPostalCode }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <Grid item>
            <Mui.CircularProgress />
          </Grid>
        ) : (
          <Grid item>
            {!edit ? (
              <Mui.Tooltip title="Alterar">
                <span>
                  <Mui.IconButton onClick={() => setEdit(true)} disabled={!!shippingAddress}>
                    <Mui.Icon>edit</Mui.Icon>
                  </Mui.IconButton>
                </span>
              </Mui.Tooltip>
            ) : (
              <>
                <Mui.Tooltip title="Fechar">
                  <Mui.IconButton onClick={() => setEdit(false)}>
                    <Mui.Icon>close</Mui.Icon>
                  </Mui.IconButton>
                </Mui.Tooltip>
                <Mui.Tooltip title="Salvar">
                  <Mui.IconButton onClick={() => handleSubmit()}>
                    <Mui.Icon>save</Mui.Icon>
                  </Mui.IconButton>
                </Mui.Tooltip>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

Freight.defaultProps = {
  variant: 'elevation',
};

Freight.propTypes = {
  shippingAddress: PropTypes.any,
  postcode: PropTypes.any,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['elevation', 'outlined']),
};

export default Freight;
