import { createStructuredSelector } from 'reselect';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as ducks from './ducks';
import makeSelectCartProvider from './selectors';

const stateSelector = createStructuredSelector({
  cartProvider: makeSelectCartProvider(),
});

function useCart() {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // Store
  const { cartProvider } = useSelector(stateSelector);

  // Actions
  const openCart = useCallback(() => dispatch(ducks.openCart()), [dispatch]);
  const closeCart = useCallback(() => dispatch(ducks.closeCart()), [dispatch]);
  const setBasketIsNotEmpty = useCallback(() => dispatch(ducks.basketIsNotEmpty()), [dispatch]);
  const cleanCart = useCallback(
    studentUid => dispatch(ducks.cleanRequest({ studentUid })),
    [dispatch],
  );
  const handleCart = useCallback(
    (enrollmentSlug, product, additional, callback) => {
      const { lineUid, quantity, options: { values: options } = {}, collectionSlug } = additional;

      const payload = {
        enrollmentSlug,
        product,
        quantity,
        options,
        lineUid,
        collectionSlug,
        callback,
      };

      if (!lineUid) {
        dispatch(ducks.addRequest(payload));
      } else if (quantity === 0) {
        dispatch(ducks.removeRequest(payload));
      } else {
        dispatch(ducks.handleRequest(payload));
      }
    },
    [dispatch],
  );
  const setPostcode = useCallback(
    postcode => dispatch(ducks.updateRequest({ postcode })),
    [dispatch],
  );
  const addVoucher = useCallback(
    voucherCode => dispatch(ducks.addVoucherRequest({ voucherCode })),
    [dispatch],
  );
  const removeVoucher = useCallback(
    voucherCode => dispatch(ducks.removeVoucherRequest({ voucherCode })),
    [dispatch],
  );
  const productAlert = useCallback(
    (enrollmentSlug, productSlug) =>
      dispatch(ducks.productAlertRequest({ enrollmentSlug, productSlug })),
    [dispatch],
  );
  const retrieveCart = useCallback(() => dispatch(ducks.retrieveRequest()), [dispatch]);
  const setTemporary = useCallback(
    (event, product, collectionSlug, callback) => {
      dispatch(ducks.setTemporary({ event, product, collectionSlug, callback }));
    },
    [dispatch],
  );
  const clearTemporary = useCallback(() => dispatch(ducks.clearTemporary()), [dispatch]);
  const transferTemporary = useCallback(
    enrollment => dispatch(ducks.transferTemporary({ enrollment, history })),
    [dispatch, history],
  );
  const anonymousCart = useCallback(open => dispatch(ducks.anonymousCart({ open })), [dispatch]);

  return {
    cartProvider,
    retrieveCart,
    openCart,
    closeCart,
    cleanCart,
    handleCart,
    setPostcode,
    setBasketIsNotEmpty,
    addVoucher,
    removeVoucher,
    productAlert,
    setTemporary,
    clearTemporary,
    transferTemporary,
    anonymousCart,
  };
}

export default useCart;
