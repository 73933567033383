import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the contactForm state domain
 */

const selectContactFormDomain = state => state.contactForm || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ContactForm
 */

const makeSelectContactForm = () => createSelector(selectContactFormDomain, substate => substate);

export default makeSelectContactForm;
export { selectContactFormDomain };
