import PropTypes from 'prop-types';
import React from 'react';
import MuiBox from '@material-ui/core/Box';
import MuiCard from '@material-ui/core/Card';
import MuiCardHeader from '@material-ui/core/CardHeader';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiSkeleton from '@material-ui/lab/Skeleton';

function Skeleton(props) {
  const { type } = props;

  switch (type) {
    case 'circle':
      return <MuiSkeleton animation="wave" variant="circle" width={25} height={25} />;
    case 'card':
      return (
        <MuiCard variant="outlined">
          <MuiCardHeader
            title={<MuiSkeleton animation="wave" height={10} width="80%" />}
            subheader={<MuiSkeleton animation="wave" height={10} width="40%" />}
            avatar={<MuiSkeleton animation="wave" variant="circle" width={40} height={40} />}
          />
          <MuiSkeleton animation="wave" variant="rect" height={190} />
          <MuiCardContent>
            <MuiSkeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <MuiSkeleton animation="wave" height={10} width="80%" />
          </MuiCardContent>
        </MuiCard>
      );
    case 'view':
      return <MuiSkeleton animation="wave" variant="rect" width="100%" height="100%" />;
    case 'table':
      return <MuiSkeleton animation="wave" variant="rect" width="100%" height="100%" />;
    case 'list':
      return <MuiSkeleton animation="wave" variant="rect" width="100%" height="100%" />;
    case 'text':
      return <MuiSkeleton animation="wave" variant="text" />;
    case 'data':
      return (
        <MuiBox display="flex">
          <MuiBox mr={2}>
            <MuiSkeleton animation="wave" variant="circle" width={40} height={40} />
          </MuiBox>
          <MuiBox>
            <MuiSkeleton animation="wave" variant="text" width="250px" />
            <MuiSkeleton animation="wave" variant="text" width="150px" />
          </MuiBox>
        </MuiBox>
      );
    default:
      return <MuiSkeleton animation="wave" variant="rect" width="100%" height="100%" />;
  }
}

Skeleton.propTypes = {
  type: PropTypes.string,
};

export default Skeleton;
