import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Dialogs } from '../../common';
import { Grid, Mui } from '../..';
import messages from './messages';

function Legal(props) {
  const [contact, setContact] = useState(false);

  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  const { ContactForm } = props;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={fullScreen && 12}>
        <Mui.Link
          component={RouterLink}
          to="/terms"
          target="_blank"
          color="textSecondary"
          variant="body1">
          <FormattedMessage {...messages.footerTermsButtonTitle} />
        </Mui.Link>
      </Grid>
      <Grid item xs={fullScreen && 12}>
        <Mui.Link
          component={RouterLink}
          to="/privacy"
          target="_blank"
          color="textSecondary"
          variant="body1">
          <FormattedMessage {...messages.footerPrivacyButtonTitle} />
        </Mui.Link>
      </Grid>
      {ContactForm && (
        <Grid item xs={fullScreen && 12}>
          <Dialogs.Page
            maxWidth="md"
            disableRepeater
            fullScreen={fullScreen}
            open={contact}
            onClose={() => setContact(false)}>
            <Mui.Container maxWidth="md">{ContactForm}</Mui.Container>
          </Dialogs.Page>
          <Mui.Link
            onClick={() => setContact(true)}
            color="textSecondary"
            component="button"
            variant="body1">
            <FormattedMessage {...messages.footerContactButtonTitle} />
          </Mui.Link>
        </Grid>
      )}
    </Grid>
  );
}

Legal.propTypes = {
  ContactForm: PropTypes.any,
};

export default Legal;
