/*
 *
 * ContactForm api's
 *
 */

import axios from 'axios';

import envs from '../../../envs';

const domain = `${envs.PROTOCOL}://${envs.ENTRYPOINT}/api`;

export const endpoints = {
  contact: () => `${domain}/commons/cms/contact/`,
};

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#aa902ece-3c7d-414e-ae5d-453644935fed
 */
export function contact({ name, email, phone, message }) {
  const options = {
    method: 'post',
    url: endpoints.contact(),
    data: {
      name,
      email,
      phone,
      message,
    },
  };
  return axios(options);
}
