/*
 * NewsBox Messages
 *
 * This contains all the text for the NewsBox component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.landing.NewsBox';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Veja o que estão falando sobre nós',
  },
  overline: {
    id: `${scope}.overline`,
    defaultMessage: 'Na mídia',
  },
  readMore: {
    id: `${scope}.readMore`,
    defaultMessage: 'Continuar lendo',
  },
  publishedBy: {
    id: `${scope}.publishedBy`,
    defaultMessage: 'Publicado por',
  },
});
