import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFetch } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

// Components
import { Mui } from 'components';
import { DisplayData } from 'components/common';

import * as api from '../api';
import messages from '../messages';

const useStyles = Mui.makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 50,
    height: 50,
  },
}));

function Enrollments(props) {
  const { student, selected } = props;

  // Hooks
  const classes = useStyles();

  // Constants
  const { uid: studentUid, firstName } = student;

  // Queries
  const [query] = useState({
    is_archived: false,
    student__uid: studentUid,
  });

  // Fetch
  const { data } = useFetch([api.endpoints.enrollments(), query], {
    dedupingInterval: 60 * 1000,
  });

  // Constants
  const { results: enrollments } = data || {};

  return (
    <Mui.Card variant="outlined">
      <Mui.List>
        {!enrollments && (
          <Mui.Box display="flex" justifyContent="center" my={2}>
            <Mui.CircularProgress size={30} color="primary" />
          </Mui.Box>
        )}
        {enrollments?.map(item => (
          <Mui.ListItem
            key={item.uid}
            component={RouterLink}
            to={{ pathname: ['/families', item.slug].join('/') }}
            button
            selected={item.slug === selected}
            divider>
            <Mui.ListItemText disableTypography>
              <DisplayData.Enrollment
                institutionProps={{ disableCaption: true }}
                showcase={item.showcase}
                institution={item.institution}
                typographyProps={{ noWrap: false }}>
                {item.basket?.numItems > 0 && (
                  <Mui.Typography variant="body2" color="primary">
                    <FormattedMessage
                      {...messages.basketLabel}
                      values={{
                        basket: <DisplayData.CurrencyField value={item.basket?.subtotal} />,
                      }}
                    />
                  </Mui.Typography>
                )}
              </DisplayData.Enrollment>
            </Mui.ListItemText>
            <Mui.ListItemIcon>
              <Mui.Icon color="action">navigate_next</Mui.Icon>
            </Mui.ListItemIcon>
          </Mui.ListItem>
        ))}
        <Mui.ListItem
          component={RouterLink}
          to={{ pathname: `/accounts/enrollments`, search: `?student_uid=${studentUid}&new=1` }}
          button>
          <Mui.ListItemAvatar>
            <Mui.Avatar className={classes.avatar}>
              <Mui.Icon>add</Mui.Icon>
            </Mui.Avatar>
          </Mui.ListItemAvatar>
          <Mui.ListItemText>
            <FormattedMessage
              {...messages.addEnrollment}
              values={{ firstName: <strong>{firstName}</strong> }}
            />
          </Mui.ListItemText>
          <Mui.ListItemSecondaryAction>
            <Mui.Icon color="action">navigate_next</Mui.Icon>
          </Mui.ListItemSecondaryAction>
        </Mui.ListItem>
      </Mui.List>
    </Mui.Card>
  );
}

Enrollments.propTypes = {
  student: PropTypes.object,
  selected: PropTypes.any,
};

export default Enrollments;
