import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Mui } from '../..';
import { version } from '../../../version';
import messages from './messages';

function Bottom(props) {
  const { children } = props;

  return (
    <div>
      <Mui.Box mt={2}>
        <Mui.Typography color="textSecondary" variant="body2" align="center" gutterBottom>
          Copyright © {new Date().getFullYear()} Eskolare Negócios Digitais Ltda. Me - CNPJ:
          28.180.616/0001-00
        </Mui.Typography>
        <Mui.Typography color="textSecondary" variant="body2" align="center">
          Endereço: Alameda Vicente Pínzon, 54 - Vila Olímpia, São Paulo/SP, CEP 04547-130
        </Mui.Typography>
      </Mui.Box>
      <Mui.Box mt={1}>
        <Mui.Typography variant="caption" component="div" color="textSecondary" align="center">
          <FormattedMessage {...messages.version} values={{ version }} />
        </Mui.Typography>
      </Mui.Box>
      {children}
    </div>
  );
}

Bottom.propTypes = {
  children: PropTypes.any,
};

export default Bottom;
