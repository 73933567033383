import { Box, Typography, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import Buttons from '../Buttons';
import { Mui } from '../..';

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: ({ width }) => width || 500,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: ({ height }) => height,
    maxWidth: '100%',
  },
  backdrop: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
}));

function Drawer(props) {
  const {
    open,
    anchor,
    onClose,
    children,
    title,
    description,
    width,
    height,
    handleScrollContainerRef,
    ...restProps
  } = props;

  const classes = useStyles({ width, height });

  return (
    <Mui.Drawer
      anchor={anchor}
      classes={{ paper: classes.drawer }}
      ModalProps={{ BackdropProps: { className: classes.backdrop } }}
      onClose={onClose}
      open={open}
      variant="temporary"
      {...restProps}>
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        containerRef={ref => handleScrollContainerRef?.(ref)}>
        <Box p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h4" color="textPrimary">
              {title}
            </Typography>
            <Buttons preset="close" component="IconButton" onClick={onClose} />
          </Box>
          {description && typeof description === 'string' && (
            <Typography variant="body1" color="textPrimary">
              {description}
            </Typography>
          )}
          {description && typeof description !== 'string' && <Mui.Box>{description}</Mui.Box>}
        </Box>
        <Box mt={1} mb={1}>
          {children}
        </Box>
      </PerfectScrollbar>
    </Mui.Drawer>
  );
}

Drawer.propTypes = {
  anchor: PropTypes.any,
  title: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  description: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  handleScrollContainerRef: PropTypes.func,
};

Drawer.defaultProps = {
  anchor: 'right',
};

export default Drawer;
