/**
 *
 * SocialAuthentication
 *
 */

import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

// Components
import { SocialButtons } from 'components/common';
import { Grid, MuiLab } from 'components';

import * as actions from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

export function SocialAuthentication(props) {
  useInjectReducer({ key: 'socialAuthentication', reducer });
  useInjectSaga({ key: 'socialAuthentication', saga });

  const { callback } = props;

  // Hooks
  const dispatch = useDispatch();

  // States
  const [socialConnectError, setSocialConnectError] = useState(null);

  // Callbacks
  const socialConnectRequest = useCallback(
    (provider, accessToken) =>
      dispatch(actions.socialConnectRequest({ provider, accessToken, callback })),
    [dispatch, callback],
  );

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <SocialButtons.Facebook
          onFailure={error => setSocialConnectError({ provider: 'Facebook', error })}
          onSuccess={response => {
            socialConnectRequest('facebook', response.tokenDetail.accessToken);
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <SocialButtons.Google
          onFailure={error => setSocialConnectError({ provider: 'Google', error })}
          onSuccess={response => {
            socialConnectRequest('google-oauth2', response.accessToken);
          }}
        />
      </Grid>
      {socialConnectError && (
        <Grid item xs={12}>
          <MuiLab.Alert severity="error">
            <div>
              <FormattedMessage
                {...messages.socialLoginFailureMessage}
                values={{ provider: <strong>{socialConnectError.provider}</strong> }}
              />
            </div>
          </MuiLab.Alert>
        </Grid>
      )}
    </Grid>
  );
}

SocialAuthentication.propTypes = {
  callback: PropTypes.func,
};

export default SocialAuthentication;
