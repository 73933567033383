import { lazy } from 'react';

// Containers
import FamilyGuard from '../FamilyGuard';

// Layouts
import CheckoutLayout from '../../../layouts/CheckoutLayout';

export default [
  {
    path: '/checkout',
    layout: CheckoutLayout,
    guard: FamilyGuard,
    component: lazy(() => import('pages/checkout/CheckoutRedirectPage')),
  },
];
