import PropTypes from 'prop-types';
import React from 'react';

import { Mui } from '../..';

function Table(props) {
  const { message, colSpan } = props;

  return (
    <Mui.Table>
      <Mui.TableBody>
        <Mui.TableRow>
          <Mui.TableCell colSpan={colSpan}>
            <Mui.Box>
              <Mui.Typography variant="body2">{message}</Mui.Typography>
            </Mui.Box>
          </Mui.TableCell>
        </Mui.TableRow>
      </Mui.TableBody>
    </Mui.Table>
  );
}

Table.propTypes = {
  message: PropTypes.string,
  colSpan: PropTypes.number,
};

export default Table;
