import { Redirect } from 'react-router-dom';
import React, { lazy } from 'react';

// Layouts
import FamilyLayout from 'layouts/FamilyLayout';

// Containers
import FamilyGuard from '../FamilyGuard';

export default [
  {
    path: '/families',
    layout: FamilyLayout,
    guard: FamilyGuard,
    routes: [
      {
        exact: true,
        path: '/families/:enrollmentSlug/:collectionSlug/p/:productSlug',
        component: lazy(() => import('pages/families/ProductDetailsPage')),
      },
      {
        exact: true,
        path: '/families/:enrollmentSlug/:collectionSlug/i/:itemUid',
        component: lazy(() => import('pages/families/ItemDetailsPage')),
      },
      {
        exact: true,
        path: '/families/:enrollmentSlug/:collectionSlug',
        component: lazy(() => import('pages/families/CollectionDetailsPage')),
      },
      {
        exact: true,
        path: '/families/:enrollmentSlug',
        component: lazy(() => import('pages/families/EnrollmentRedirectPage')),
      },
      {
        exact: true,
        path: '/families',
        component: () => <Redirect to="/accounts" />,
      },
      {
        component: () => <Redirect push to="/404" />,
      },
    ],
  },
];
