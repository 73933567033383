/* eslint-disable indent */
import * as Sentry from '@sentry/browser';

import notificationMiddleware from '../../containers/common/SnackbarProvider/middleware';
import sessionToken from '../sessionToken';

const HTTP_STATUS_TO_IGNORE = [400, 401, 404];

const logErrorToSentry = action => {
  const {
    payload: { error, ...payloadRest },
    type,
  } = action;

  if (process.env.NODE_ENV === 'production') {
    Sentry.withScope(scope => {
      if (error.response) {
        scope.setExtras({ payload: payloadRest, type, response: error.response });
      } else {
        scope.setExtras({ payload: payloadRest, type });
      }

      const eventId = Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.error(`The error was logged ${eventId}`);
    });
  } else {
    // eslint-disable-next-line no-console
    console.error('The error will only be logged locally', { payloadRest, error, type });
  }
};

const failureMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    /**
     * Use Sentry to log failures
     */

    if (action.type.includes('FAILURE') && action?.payload?.error) {
      if (action.payload.error.response) {
        const { status } = action.payload.error.response;

        if (status === 401) {
          sessionToken({ destroy: true });
        } else {
          notificationMiddleware(dispatch, action.payload.error);
        }

        if (!HTTP_STATUS_TO_IGNORE.includes(status)) logErrorToSentry(action);
      } else {
        logErrorToSentry(action);
      }
    }

    return next(action);
  };

export default failureMiddleware;
