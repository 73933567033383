import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import random from 'lodash/random';
import { Avatar, Box, Container, Typography, makeStyles } from '@material-ui/core';
import { useInView } from 'react-intersection-observer';

// Components
import { Mui } from 'components';
import { LoadingScreen } from 'components/layouts';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 128,
    paddingBottom: 128,
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function Testimonials(props) {
  const { className, loading, testimonials, ...restProps } = props;

  // Hooks
  const classes = useStyles();
  const [inViewRef, inView] = useInView({ threshold: 0.33 });

  if (loading) return <LoadingScreen />;

  const index = random(0, testimonials.length - 1);

  const { description, name, picture, title } = testimonials[index] || {};

  return (
    <div className={clsx(classes.root, className)} ref={inViewRef} {...restProps}>
      <Mui.Fade in={inView} timeout={500}>
        <Container maxWidth="md">
          <Typography variant="h2" align="center" color="textPrimary" className={classes.title}>
            {description}
          </Typography>
          <Box mt={6} display="flex" justifyContent="center" alignItems="center">
            <Avatar src={picture} />
            <Box ml={2}>
              <Typography variant="body1" color="textPrimary">
                {name}
                {title && (
                  <Typography color="textSecondary" display="inline" component="span">
                    , {title}
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Mui.Fade>
    </div>
  );
}

Testimonials.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  testimonials: PropTypes.array,
};

export default Testimonials;
