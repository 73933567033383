/**
 *
 * JoyrideCustomBeacon
 *
 */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import { Mui } from '../..';
import messages from './messages';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color: #60c9fb;
    transform: scale(1.1);
  }
`;

const Beacon = styled.div`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: #60c9fb;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  z-index: 0;
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  justify-content: center;
`;

/* eslint-disable */
export default class JoyrideBeacon extends React.Component {
  render() {
    const { beacon, ...restProps } = this.props;

    return (
      <Mui.Tooltip title={<FormattedMessage {...messages.helperTitle} />}>
        <Beacon {...restProps}>
          <Mui.Typography color="inherit" variant="h6">
            ?
          </Mui.Typography>
        </Beacon>
      </Mui.Tooltip>
    );
  }
}
/* eslint-enable */
