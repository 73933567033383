import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Mui } from '../..';
import * as images from '../../../images';
import messages from './messages';
import { useStyles } from './styles';

function PaymentMethods(props) {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles(props);

  const items = [
    {
      name: 'Mastercard',
      image: images.paymentMethodsMastercard,
    },
    {
      name: 'Visa',
      image: images.paymentMethodsVisa,
    },
    {
      name: 'American Express',
      image: images.paymentMethodsAmericanExpress,
    },
    {
      name: 'Hipercard',
      image: images.paymentMethodsHipercard,
    },
    {
      name: 'Elo',
      image: images.paymentMethodsElo,
    },
    {
      name: 'Boleto bancário',
      image: images.paymentMethodsBoleto,
    },
  ];

  return (
    <div>
      <Mui.Typography variant="overline" color="textSecondary">
        <FormattedMessage {...messages.footerPaymentMethodsTitle} />
      </Mui.Typography>
      <Grid container spacing={1}>
        {items.map(item => (
          <Grid item key={item.name}>
            <Mui.Tooltip title={item.name}>
              <img src={item.image} alt={item.name} className={classes.payments} />
            </Mui.Tooltip>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

PaymentMethods.propTypes = {};

export default PaymentMethods;
