import { loadable } from 'utils';

export const Checkout = loadable(() => import('./Checkout'), {});
export const Collection = loadable(() => import('./Collection'), {});
export const Create = loadable(() => import('./Create'), {});
export const CreateChannelHome = loadable(() => import('./Start/CreateChannelHome'), {});
export const CreateStaticHome = loadable(() => import('./Start/CreateStaticHome'), {});
export const MainChannelHome = loadable(() => import('./Start/MainChannelHome'), {});
export const Maintenance = loadable(() => import('./Maintenance'), {});
export const RedirectHome = loadable(() => import('./Start/RedirectHome'), {});
export const SelectChannelHome = loadable(() => import('./Start/SelectChannelHome'), {});
export const Site = loadable(() => import('./Site'), {});
export const SiteDangerZone = loadable(() => import('./SiteDangerZone'), {});
export const Seller = loadable(() => import('./Seller'), {});
export const StaticHome = loadable(() => import('./Start/StaticHome'), {});
export const Support = loadable(() => import('./Support'), {});
export const Layout = loadable(() => import('./Appearance/Layout'), {});
export const Theme = loadable(() => import('./Appearance/Theme'), {});
