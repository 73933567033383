/**
 *
 * ProductProvider ducks
 * Read more about this pattern: https://blog.rocketseat.com.br/estrutura-redux-escalavel-com-ducks/
 *
 */

import produce from 'immer';

// Constants

const app = 'app/common/ProductProvider';

export const ENROLLED_REQUEST = `${app}/ENROLLED_REQUEST`;
export const ENROLLED_SUCCESS = `${app}/ENROLLED_SUCCESS`;
export const ENROLLED_FAILURE = `${app}/ENROLLED_FAILURE`;

export const LANDING_PAGE_REQUEST = `${app}/LANDING_PAGE_REQUEST`;
export const LANDING_PAGE_SUCCESS = `${app}/LANDING_PAGE_SUCCESS`;
export const LANDING_PAGE_FAILURE = `${app}/LANDING_PAGE_FAILURE`;

export const SHOWCASE_REQUEST = `${app}/SHOWCASE_REQUEST`;
export const SHOWCASE_SUCCESS = `${app}/SHOWCASE_SUCCESS`;
export const SHOWCASE_FAILURE = `${app}/SHOWCASE_FAILURE`;

export const PUBLIC_PAGE_REQUEST = `${app}/PUBLIC_PAGE_REQUEST`;
export const PUBLIC_PAGE_SUCCESS = `${app}/PUBLIC_PAGE_SUCCESS`;
export const PUBLIC_PAGE_FAILURE = `${app}/PUBLIC_PAGE_FAILURE`;

// Actions

export function enrolledRequest(payload) {
  return { type: ENROLLED_REQUEST, payload };
}

export function enrolledSuccess(payload) {
  return { type: ENROLLED_SUCCESS, payload };
}

export function enrolledFailure(payload) {
  return { type: ENROLLED_FAILURE, payload };
}

export function landingPageRequest(payload) {
  return { type: LANDING_PAGE_REQUEST, payload };
}

export function landingPageSuccess(payload) {
  return { type: LANDING_PAGE_SUCCESS, payload };
}

export function landingPageFailure(payload) {
  return { type: LANDING_PAGE_FAILURE, payload };
}

export function showcaseRequest(payload) {
  return { type: SHOWCASE_REQUEST, payload };
}

export function showcaseSuccess(payload) {
  return { type: SHOWCASE_SUCCESS, payload };
}

export function showcaseFailure(payload) {
  return { type: SHOWCASE_FAILURE, payload };
}

export function publicPageRequest(payload) {
  return { type: PUBLIC_PAGE_REQUEST, payload };
}

export function publicPageSuccess(payload) {
  return { type: PUBLIC_PAGE_SUCCESS, payload };
}

export function publicPageFailure(payload) {
  return { type: PUBLIC_PAGE_FAILURE, payload };
}

// Reducer

export const initialState = {
  isFetching: {
    enrolled: {},
    landingPage: {},
    showcase: {},
    publicPage: {},
  },
  enrolled: {},
  landingPage: {},
  showcase: {},
  publicPage: {},
};

/* eslint-disable default-case, no-param-reassign */
const productProviderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ENROLLED_REQUEST: {
        const { productSlug } = action.payload;
        draft.isFetching.enrolled[productSlug] = true;
        break;
      }
      case ENROLLED_SUCCESS: {
        const { productSlug, response } = action.payload;
        draft.isFetching.enrolled[productSlug] = false;
        draft.enrolled[productSlug] = response;
        break;
      }
      case ENROLLED_FAILURE: {
        const { productSlug } = action.payload;
        draft.isFetching.enrolled[productSlug] = false;
        draft.enrolled[productSlug] = null;
        break;
      }
      case LANDING_PAGE_REQUEST: {
        const { productSlug } = action.payload;
        draft.isFetching.landingPage[productSlug] = true;
        break;
      }
      case LANDING_PAGE_SUCCESS: {
        const { productSlug, response } = action.payload;
        draft.isFetching.landingPage[productSlug] = false;
        draft.landingPage[productSlug] = response;
        break;
      }
      case LANDING_PAGE_FAILURE: {
        const { productSlug } = action.payload;
        draft.isFetching.landingPage[productSlug] = false;
        draft.landingPage[productSlug] = null;
        break;
      }
      case SHOWCASE_REQUEST: {
        const { productSlug } = action.payload;
        draft.isFetching.showcase[productSlug] = true;
        break;
      }
      case SHOWCASE_SUCCESS: {
        const { productSlug, response } = action.payload;
        draft.isFetching.showcase[productSlug] = false;
        draft.showcase[productSlug] = response;
        break;
      }
      case SHOWCASE_FAILURE: {
        const { productSlug } = action.payload;
        draft.isFetching.showcase[productSlug] = false;
        draft.showcase[productSlug] = null;
        break;
      }
      case PUBLIC_PAGE_REQUEST: {
        const { productSlug } = action.payload;
        draft.isFetching.publicPage[productSlug] = true;
        break;
      }
      case PUBLIC_PAGE_SUCCESS: {
        const { productSlug, response } = action.payload;
        draft.isFetching.publicPage[productSlug] = false;
        draft.publicPage[productSlug] = response;
        break;
      }
      case PUBLIC_PAGE_FAILURE: {
        const { productSlug } = action.payload;
        draft.isFetching.publicPage[productSlug] = false;
        draft.publicPage[productSlug] = null;
        break;
      }
    }
  });

export default productProviderReducer;
