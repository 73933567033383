/**
 *
 * Global ducks
 * Read more about this pattern: https://blog.rocketseat.com.br/estrutura-redux-escalavel-com-ducks/
 *
 */

import produce from 'immer';

// Constants

export const DEFAULT_LOCALE = 'pt';

// Actions

// Reducer

export const initialState = {
  isStoreReady: true,
};

/* eslint-disable default-case, no-param-reassign */
const globalReducer = (state = initialState, action) =>
  produce(state, draft => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (action.type) {
      case 'DEFAULT': {
        draft.isStoreReady = true;
        break;
      }
      default:
        break;
    }
  });

export default globalReducer;
