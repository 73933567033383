/*
 * Buttons Messages
 *
 * This contains all the text for the Buttons component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.layouts.Buttons';

export default defineMessages({
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Adicionar',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Salvar',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancelar',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Fechar',
  },
  copy: {
    id: `${scope}.copy`,
    defaultMessage: 'Copiar',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continuar',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Excluir',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Editar',
  },
  download: {
    id: `${scope}.download`,
    defaultMessage: 'Download',
  },
  archive: {
    id: `${scope}.archive`,
    defaultMessage: 'Arquivar',
  },
  unarchive: {
    id: `${scope}.unarchive`,
    defaultMessage: 'Desarquivar',
  },
  message: {
    id: `${scope}.message`,
    defaultMessage: 'Mensagem',
  },
  request: {
    id: `${scope}.request`,
    defaultMessage: 'Solicitar',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Selecionar',
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: 'Enviar',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'Ver',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Próximo',
  },
  before: {
    id: `${scope}.before`,
    defaultMessage: 'Anterior',
  },
  open: {
    id: `${scope}.open`,
    defaultMessage: 'Abrir',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirmar',
  },
});
