/**
 *
 * CartProvider ducks
 * Read more about this pattern: https://blog.rocketseat.com.br/estrutura-redux-escalavel-com-ducks/
 *
 */

import produce from 'immer';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import deepmerge from 'deepmerge';

// Constants

const app = 'app/common/CartProvider';

export const CLEAN_REQUEST = `${app}/CLEAN_REQUEST`;
export const CLEAN_SUCCESS = `${app}/CLEAN_SUCCESS`;
export const CLEAN_FAILURE = `${app}/CLEAN_FAILURE`;

export const RETRIEVE_REQUEST = `${app}/RETRIEVE_REQUEST`;
export const RETRIEVE_SUCCESS = `${app}/RETRIEVE_SUCCESS`;
export const RETRIEVE_FAILURE = `${app}/RETRIEVE_FAILURE`;

export const ADD_REQUEST = `${app}/ADD_REQUEST`;
export const ADD_SUCCESS = `${app}/ADD_SUCCESS`;
export const ADD_FAILURE = `${app}/ADD_FAILURE`;

export const HANDLE_REQUEST = `${app}/HANDLE_REQUEST`;
export const HANDLE_SUCCESS = `${app}/HANDLE_SUCCESS`;
export const HANDLE_FAILURE = `${app}/HANDLE_FAILURE`;

export const REMOVE_REQUEST = `${app}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS = `${app}/REMOVE_SUCCESS`;
export const REMOVE_FAILURE = `${app}/REMOVE_FAILURE`;

export const UPDATE_REQUEST = `${app}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${app}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${app}/UPDATE_FAILURE`;

export const STATUS_REQUEST = `${app}/STATUS_REQUEST`;
export const STATUS_SUCCESS = `${app}/STATUS_SUCCESS`;
export const STATUS_FAILURE = `${app}/STATUS_FAILURE`;

export const ADD_VOUCHER_REQUEST = `${app}/ADD_VOUCHER_REQUEST`;
export const ADD_VOUCHER_SUCCESS = `${app}/ADD_VOUCHER_SUCCESS`;
export const ADD_VOUCHER_FAILURE = `${app}/ADD_VOUCHER_FAILURE`;

export const REMOVE_VOUCHER_REQUEST = `${app}/REMOVE_VOUCHER_REQUEST`;
export const REMOVE_VOUCHER_SUCCESS = `${app}/REMOVE_VOUCHER_SUCCESS`;
export const REMOVE_VOUCHER_FAILURE = `${app}/REMOVE_VOUCHER_FAILURE`;

export const PRODUCT_ALERT_REQUEST = `${app}/PRODUCT_ALERT_REQUEST`;
export const PRODUCT_ALERT_SUCCESS = `${app}/PRODUCT_ALERT_SUCCESS`;
export const PRODUCT_ALERT_FAILURE = `${app}/PRODUCT_ALERT_FAILURE`;

export const OPEN_CART = `${app}/OPEN_CART`;
export const CLOSE_CART = `${app}/CLOSE_CART`;
export const BASKET_IS_NOT_EMPTY = `${app}/BASKET_IS_NOT_EMPTY`;
export const SET_TEMPORARY = `${app}/SET_TEMPORARY`;
export const CLEAR_TEMPORARY = `${app}/CLEAR_TEMPORARY`;
export const TRANSFER_TEMPORARY = `${app}/TRANSFER_TEMPORARY`;
export const ANONYMOUS_CART = `${app}/ANONYMOUS_CART`;

// Actions

export function cleanRequest(payload) {
  return { type: CLEAN_REQUEST, payload };
}

export function cleanSuccess(payload) {
  return { type: CLEAN_SUCCESS, payload };
}

export function cleanFailure(payload) {
  return { type: CLEAN_FAILURE, payload };
}

export function retrieveRequest(payload) {
  return { type: RETRIEVE_REQUEST, payload };
}

export function retrieveSuccess(payload) {
  return { type: RETRIEVE_SUCCESS, payload };
}

export function retrieveFailure(payload) {
  return { type: RETRIEVE_FAILURE, payload };
}

export function addRequest(payload) {
  return { type: ADD_REQUEST, payload };
}

export function addSuccess(payload) {
  return { type: ADD_SUCCESS, payload };
}

export function addFailure(payload) {
  return { type: ADD_FAILURE, payload };
}

export function handleRequest(payload) {
  return { type: HANDLE_REQUEST, payload };
}

export function handleSuccess(payload) {
  return { type: HANDLE_SUCCESS, payload };
}

export function handleFailure(payload) {
  return { type: HANDLE_FAILURE, payload };
}

export function removeRequest(payload) {
  return { type: REMOVE_REQUEST, payload };
}

export function removeSuccess(payload) {
  return { type: REMOVE_SUCCESS, payload };
}

export function removeFailure(payload) {
  return { type: REMOVE_FAILURE, payload };
}

export function updateRequest(payload) {
  return { type: UPDATE_REQUEST, payload };
}

export function updateSuccess(payload) {
  return { type: UPDATE_SUCCESS, payload };
}

export function updateFailure(payload) {
  return { type: UPDATE_FAILURE, payload };
}

export function statusRequest(payload) {
  return { type: STATUS_REQUEST, payload };
}

export function statusSuccess(payload) {
  return { type: STATUS_SUCCESS, payload };
}

export function statusFailure(payload) {
  return { type: STATUS_FAILURE, payload };
}

export function addVoucherRequest(payload) {
  return { type: ADD_VOUCHER_REQUEST, payload };
}

export function addVoucherSuccess(payload) {
  return { type: ADD_VOUCHER_SUCCESS, payload };
}

export function addVoucherFailure(payload) {
  return { type: ADD_VOUCHER_FAILURE, payload };
}

export function removeVoucherRequest(payload) {
  return { type: REMOVE_VOUCHER_REQUEST, payload };
}

export function removeVoucherSuccess(payload) {
  return { type: REMOVE_VOUCHER_SUCCESS, payload };
}

export function removeVoucherFailure(payload) {
  return { type: REMOVE_VOUCHER_FAILURE, payload };
}

export function productAlertRequest(payload) {
  return { type: PRODUCT_ALERT_REQUEST, payload };
}

export function productAlertSuccess(payload) {
  return { type: PRODUCT_ALERT_SUCCESS, payload };
}

export function productAlertFailure(payload) {
  return { type: PRODUCT_ALERT_FAILURE, payload };
}

export function openCart() {
  return { type: OPEN_CART };
}

export function closeCart() {
  return { type: CLOSE_CART };
}

export function basketIsNotEmpty() {
  return { type: BASKET_IS_NOT_EMPTY };
}

export function setTemporary(payload) {
  return { type: SET_TEMPORARY, payload };
}

export function clearTemporary(payload) {
  return { type: CLEAR_TEMPORARY, payload };
}

export function transferTemporary(payload) {
  return { type: TRANSFER_TEMPORARY, payload };
}

export function anonymousCart(payload) {
  return { type: ANONYMOUS_CART, payload };
}

// Reducer

export const initialState = {
  isFetching: {
    clean: {},
    retrieve: false,
    add: {},
    handle: {},
    remove: {},
    update: false,
    status: {},
    addVoucher: false,
    removeVoucher: false,
    productAlert: {},
  },
  retrieve: {
    discountTotal: '0.00',
    freightTotal: '0.00',
    interestTotal: '0.00',
    numItems: 0,
    numLines: 0,
    offerApplications: [],
    postcode: '',
    requiresShipping: false,
    shippingAddress: {},
    students: [],
    subtotal: '0.00',
    taxTotal: '0.00',
    checkoutPaymentsTotal: '0.00',
    futurePaymentsTotal: '0.00',
    total: '0.00',
    uid: '',
    vouchers: [],
    lines: [],
  },
  enrollmentsByStudent: {},
  institutionsByUid: {},
  linesByProduct: {},
  linesByEnrollment: {},
  pricesByProduct: {},
  sellersByUid: {},
  productsByUid: {},
  showcasesByUid: {},
  collectionsByUid: {},
  status: {},
  productAlert: {},
  basketIsNotEmpty: false,
  open: false,
  temporary: {},
  anonymous: false,
};

/* eslint-disable default-case, no-param-reassign */
const cartProviderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CLEAN_REQUEST: {
        const { studentUid } = action.payload;
        draft.isFetching.clean[studentUid] = true;
        break;
      }
      case CLEAN_SUCCESS:
      case CLEAN_FAILURE: {
        const { studentUid } = action.payload;
        draft.isFetching.clean[studentUid] = false;
        break;
      }
      case RETRIEVE_REQUEST: {
        draft.isFetching.retrieve = true;
        break;
      }
      case RETRIEVE_SUCCESS: {
        const { response } = action.payload;
        const {
          enrollments,
          institutions,
          lines,
          prices,
          sellers,
          products,
          showcases,
          collections,
        } = response;
        draft.isFetching.retrieve = false;
        draft.retrieve = response;
        draft.enrollmentsByStudent = groupBy(enrollments, 'student');
        draft.institutionsByUid = keyBy(institutions, 'uid');
        draft.linesByProduct = groupBy(lines, 'product');
        draft.linesByEnrollment = groupBy(lines, 'enrollment');
        draft.pricesByUid = keyBy(prices, 'uid');
        draft.sellersByUid = keyBy(sellers, 'uid');
        draft.productsByUid = keyBy(products, 'uid');
        draft.showcasesByUid = keyBy(showcases, 'uid');
        draft.collectionsByUid = keyBy(collections, 'uid');
        break;
      }
      case RETRIEVE_FAILURE: {
        draft.isFetching.retrieve = false;
        draft.retrieve = initialState.retrieve;
        break;
      }
      case ADD_REQUEST: {
        const { product } = action.payload;
        const { uid: productUid, seller } = product;
        const { uid: sellerUid } = seller;
        draft.isFetching.add[productUid] = true;
        draft.productsByUid[productUid] = product;
        draft.sellersByUid[sellerUid] = seller;
        break;
      }
      case ADD_SUCCESS: {
        const { productUid, response } = action.payload;
        const linesByProduct = groupBy(response, 'product');
        const linesByEnrollment = groupBy(response, 'enrollment');

        draft.isFetching.add[productUid] = false;
        draft.linesByProduct = deepmerge(state.linesByProduct, linesByProduct);
        draft.linesByEnrollment = deepmerge(state.linesByEnrollment, linesByEnrollment);
        draft.retrieve.lines = [...state.retrieve.lines, ...response];
        break;
      }
      case ADD_FAILURE: {
        const { productUid } = action.payload;
        draft.isFetching.add[productUid] = false;
        break;
      }
      case HANDLE_REQUEST: {
        const { product } = action.payload;
        const { uid: productUid } = product;
        draft.isFetching.handle[productUid] = true;
        break;
      }
      case HANDLE_SUCCESS: {
        const { response } = action.payload;
        const { uid: lineUid, product: productUid, enrollment: enrollmentUid } = response;
        const prevLinesByProduct = state.linesByProduct[productUid];
        const prevLinesByEnrollment = state.linesByEnrollment[enrollmentUid];

        const refreshFunc = line => (line.uid === lineUid ? response : line);

        draft.isFetching.handle[productUid] = false;
        draft.linesByProduct[productUid] = prevLinesByProduct.map(refreshFunc);
        draft.linesByEnrollment[enrollmentUid] = prevLinesByEnrollment.map(refreshFunc);
        break;
      }
      case HANDLE_FAILURE: {
        const { productUid } = action.payload;
        draft.isFetching.handle[productUid] = false;
        break;
      }
      case REMOVE_REQUEST: {
        const { product } = action.payload;
        const { uid: productUid } = product;
        draft.isFetching.remove[productUid] = true;
        break;
      }
      case REMOVE_SUCCESS: {
        const { productUid, lineUid } = action.payload;
        draft.isFetching.remove[productUid] = false;

        const refreshFunc = item => item.uid !== lineUid;

        const prevLinesByProduct = state.linesByProduct[productUid];
        draft.linesByProduct[productUid] = prevLinesByProduct.filter(refreshFunc);

        const [line] = prevLinesByProduct.filter(item => item.uid === lineUid);
        const { enrollment: enrollmentUid } = line;
        const prevLinesByEnrollment = state.linesByEnrollment[enrollmentUid];
        draft.linesByEnrollment[enrollmentUid] = prevLinesByEnrollment.filter(refreshFunc);

        const { lines } = state.retrieve;
        draft.retrieve.lines = lines.filter(refreshFunc);
        break;
      }
      case REMOVE_FAILURE: {
        const { productUid } = action.payload;
        draft.isFetching.remove[productUid] = false;
        break;
      }
      case UPDATE_REQUEST: {
        draft.isFetching.update = true;
        break;
      }
      case UPDATE_SUCCESS:
      case UPDATE_FAILURE: {
        draft.isFetching.update = false;
        break;
      }
      case STATUS_REQUEST: {
        const { enrollmentSlug } = action.payload;
        draft.isFetching.status[enrollmentSlug] = true;
        break;
      }
      case STATUS_SUCCESS: {
        const { enrollmentSlug, response } = action.payload;
        draft.isFetching.status[enrollmentSlug] = false;
        draft.status[enrollmentSlug] = response;
        break;
      }
      case STATUS_FAILURE: {
        const { enrollmentSlug } = action.payload;
        draft.isFetching.status[enrollmentSlug] = false;
        draft.status[enrollmentSlug] = false;
        break;
      }
      case ADD_VOUCHER_REQUEST: {
        draft.isFetching.addVoucher = true;
        break;
      }
      case ADD_VOUCHER_SUCCESS:
      case ADD_VOUCHER_FAILURE: {
        draft.isFetching.addVoucher = false;
        break;
      }
      case REMOVE_VOUCHER_REQUEST: {
        draft.isFetching.removeVoucher = true;
        break;
      }
      case REMOVE_VOUCHER_SUCCESS:
      case REMOVE_VOUCHER_FAILURE: {
        draft.isFetching.removeVoucher = false;
        break;
      }
      case PRODUCT_ALERT_REQUEST: {
        const { productSlug } = action.payload;
        draft.isFetching.productAlert[productSlug] = true;
        break;
      }
      case PRODUCT_ALERT_SUCCESS:
      case PRODUCT_ALERT_FAILURE: {
        const { productSlug } = action.payload;
        draft.isFetching.productAlert[productSlug] = false;
        break;
      }
      case OPEN_CART: {
        draft.open = true;
        break;
      }
      case CLOSE_CART: {
        draft.open = false;
        break;
      }
      case BASKET_IS_NOT_EMPTY: {
        draft.open = false;
        draft.basketIsNotEmpty = true;
        break;
      }
      case SET_TEMPORARY: {
        const { product, collectionSlug } = action.payload;
        const { slug, basket } = product;
        draft.temporary[slug] = {
          ...state.temporary[slug],
          ...product,
          basket: { ...basket, collectionSlug },
        };
        break;
      }
      case CLEAR_TEMPORARY: {
        draft.temporary = {};
        break;
      }
      case ANONYMOUS_CART: {
        const { open } = action.payload;
        draft.anonymous = open;
        break;
      }
    }
  });

export default cartProviderReducer;
