/**
 *
 * CartProvider
 *
 */

import { FormattedMessage } from 'react-intl';
import { Grid, Mui } from 'components';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useLocation, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';

import { getLineData, getEnrollmentData, getStudentData } from './utils';
import reducer from './ducks';
import * as images from '../../../images';
import messages from './messages';
import saga from './saga';

// Components
import { Cart, DisplayData } from '../../../components/common';
import { Stack, Drawer } from '../../../components/layouts';

// Providers
import useCart from './useCart';
import { useUser } from '../UserProvider';

// Local components
import PurchaseButton from './components/PurchaseButton';

export function CartProvider() {
  useInjectReducer({ key: 'cartProvider', reducer });
  useInjectSaga({ key: 'cartProvider', saga });

  // Hooks
  const history = useHistory();
  const { pathname } = useLocation();
  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  // Custom hooks
  const {
    cartProvider,
    retrieveCart,
    closeCart,
    cleanCart,
    setPostcode,
    removeVoucher,
    addVoucher,
  } = useCart();
  const { isAuthenticated } = useUser();

  // Constants
  const {
    isFetching,
    retrieve,
    enrollmentsByStudent,
    linesByEnrollment,
    productsByUid,
    institutionsByUid,
    showcasesByUid,
    sellersByUid,
    collectionsByUid,
    open,
  } = cartProvider;

  const { lines, vouchers, requiresShipping, displayVoucher, allPackagesDeliverAtInstitutionOnly } =
    retrieve || {};

  // Effects

  useEffect(() => {
    if (isAuthenticated) retrieveCart();
  }, [isAuthenticated, retrieveCart]);

  useEffect(() => {
    if (open) retrieveCart();
  }, [open, retrieveCart]);

  useEffect(() => {
    closeCart();
  }, [pathname, closeCart]);

  return (
    <Drawer
      open={open}
      width={900}
      title={<FormattedMessage {...messages.title} />}
      onClose={() => closeCart()}>
      <Mui.Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <Stack TransitionComponent={Mui.Fade}>
              {requiresShipping && !allPackagesDeliverAtInstitutionOnly && !fullScreen && (
                <Cart.Freight
                  {...retrieve}
                  variant="outlined"
                  loading={isFetching.update}
                  onSubmit={value => setPostcode(value)}
                />
              )}
              {lines.length === 0 && (
                <center>
                  <img src={images.illustrationsEmptyCart} width="50%" alt="Eskolare" />
                </center>
              )}
              {lines.length > 0 &&
                retrieve.students.map(student => {
                  const { studentUid, enrollments } = getStudentData({
                    student,
                    enrollmentsByStudent,
                  });

                  return (
                    <Cart.Student
                      key={studentUid}
                      loading={isFetching.clean[studentUid]}
                      onClean={() => cleanCart(studentUid)}
                      {...student}>
                      <Stack>
                        {enrollments.map(enrollment => {
                          const {
                            enrollmentUid,
                            showcase,
                            institution,
                            lines: newLines,
                          } = getEnrollmentData({
                            enrollment,
                            linesByEnrollment,
                            institutionsByUid,
                            showcasesByUid,
                          });

                          return (
                            <Stack key={enrollmentUid}>
                              <DisplayData.Enrollment
                                size="small"
                                showcase={showcase}
                                institution={institution}
                                typographyProps={{ color: 'textSecondary' }}
                              />
                              {newLines.map((line, index) => {
                                // eslint-disable-next-line no-unused-vars
                                const { lineUid, product, url, selectedEnrollment } = getLineData({
                                  line,
                                  enrollment,
                                  institution,
                                  showcase,
                                  sellersByUid,
                                  productsByUid,
                                  collectionsByUid,
                                });

                                const lineProps = { ...line, product, to: url };

                                return (
                                  <Cart.Line
                                    key={lineUid}
                                    variant="outlined"
                                    divider={index < newLines.length - 1}
                                    PurchaseButton={
                                      <PurchaseButton
                                        enrollment={selectedEnrollment}
                                        product={{ ...product, basket: line }}
                                      />
                                    }
                                    {...lineProps}
                                  />
                                );
                              })}
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Cart.Student>
                  );
                })}
            </Stack>
          </Grid>
          <Grid item md={4} xs={12}>
            <Cart.Summary
              variant="outlined"
              disabled={lines.length === 0}
              loading={isFetching.retrieve}
              onContinue={() => closeCart()}
              onClick={() => {
                closeCart();
                history.push('/checkout');
              }}
              {...retrieve}>
              {displayVoucher === false ? null : (
                <Cart.Voucher
                  loading={isFetching.addVoucher || isFetching.removeVoucher}
                  vouchers={vouchers}
                  onRemove={voucherCode => removeVoucher(voucherCode)}
                  onAdd={voucherCode => addVoucher(voucherCode)}
                />
              )}
            </Cart.Summary>
          </Grid>
        </Grid>
      </Mui.Container>
    </Drawer>
  );
}

export default CartProvider;
export { useCart };
