export default function dateCompare(...dates) {
  if (dates.every(date => typeof date === 'string' || date instanceof String)) {
    return dates.every(date => date.substring(0, 19) === dates[0].substring(0, 19));
  }
  return false;
}

export function dateLessThanYear(firstDate, lastDate, years = 1) {
  const date1 = typeof firstDate === 'string' ? new Date(firstDate) : firstDate;
  const date2 = typeof lastDate === 'string' ? new Date(lastDate) : lastDate;
  const datePlusYear = date1.setFullYear(date1.getFullYear() + years);
  return date2 < new Date(datePlusYear);
}
