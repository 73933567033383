/*
 * ContactForm Messages
 *
 * This contains all the text for the ContactForm container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.landing.ContactForm';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Precisa de ajuda?',
  },
  overline: {
    id: `${scope}.overline`,
    defaultMessage: 'Canais de atendimento',
  },
  contactSectionMethod1Title: {
    id: `${scope}.contactSectionMethod1Title`,
    defaultMessage: 'Faq',
  },
  contactSectionMethod2Title: {
    id: `${scope}.contactSectionMethod2Title`,
    defaultMessage: 'Telefone',
  },
  contactSectionMethod2ExtraInfo: {
    id: `${scope}.contactSectionMethod2ExtraInfo`,
    defaultMessage: 'Segunda à sexta das 9h até 18h',
  },
  contactSectionMethod3Title: {
    id: `${scope}.contactSectionMethod3Title`,
    defaultMessage: 'WhatsApp',
  },
  contactSectionMethod4Title: {
    id: `${scope}.contactSectionMethod4Title`,
    defaultMessage: 'E-mail',
  },
  contactSectionMethod5Title: {
    id: `${scope}.contactSectionMethod5Title`,
    defaultMessage: 'Chat',
  },
  contactSectionMethod5ExtraInfo: {
    id: `${scope}.contactSectionMethod5ExtraInfo`,
    defaultMessage: 'Segunda à sexta das 9h até 18h',
  },
  nameLabel: {
    id: `${scope}.nameLabel`,
    defaultMessage: 'Seu nome',
  },
  nameRequired: {
    id: `${scope}.nameRequired`,
    defaultMessage: 'Precisamos do seu nome para entrar em contato',
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'Seu e-mail',
  },
  emailRequired: {
    id: `${scope}.emailRequired`,
    defaultMessage: 'Precisamos do seu e-mail também',
  },
  emailFormat: {
    id: `${scope}.emailFormat`,
    defaultMessage: 'Esse e-mail não é um e-mail válido',
  },
  phoneLabel: {
    id: `${scope}.phoneLabel`,
    defaultMessage: 'Seu telefone com DDD',
  },
  phoneRequired: {
    id: `${scope}.phoneRequired`,
    defaultMessage: 'Precisamos do seu telefone para um contato mais rápido',
  },
  messageLabel: {
    id: `${scope}.messageLabel`,
    defaultMessage: 'Sua mensagem',
  },
  messageRequired: {
    id: `${scope}.messageRequired`,
    defaultMessage: 'Qual a sua mensagem?',
  },
  sendButton: {
    id: `${scope}.sendButton`,
    defaultMessage: 'Enviar',
  },
});
