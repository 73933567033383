import PropTypes from 'prop-types';
import React from 'react';
import { useUser, useCart } from 'hooks';
import * as images from 'images';

// Components
import { Mui, Badge } from 'components';
import { Cart } from 'components/common';

// Providers

function FamilyCartUser(props) {
  const { type, student = {} } = props;

  // Custom hooks
  const { cartProvider, openCart } = useCart();
  const { user } = useUser();

  // Constants
  const { retrieve } = cartProvider;
  const { lines = [] } = retrieve;

  const getPicture = () => {
    if (student) return student?.picture || images.facesOtherFace;

    return user?.picture || images.facesOtherFace;
  };

  const Icon = () => {
    if (type === 'fixed')
      return (
        <Mui.IconButton onClick={() => openCart()} id="cart-notification">
          <Badge badgeContent={lines.length} color="error">
            <Mui.Icon color={lines.length > 0 ? 'primary' : 'inherit'}>shopping_cart</Mui.Icon>
          </Badge>
        </Mui.IconButton>
      );

    return (
      <Cart.FloatUser picture={getPicture()} onClick={() => openCart()} count={lines.length} />
    );
  };

  return (
    <>
      <Icon />
    </>
  );
}

FamilyCartUser.propTypes = {
  type: PropTypes.oneOf(['float', 'fixed']),
  student: PropTypes.object,
};

FamilyCartUser.defaultProps = {
  type: 'float',
};

export default FamilyCartUser;
