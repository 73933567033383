/*
 * FormAuthentication Messages
 *
 * This contains all the text for the FormAuthentication container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.forms.FormAuthentication';

export default defineMessages({
  // SignUp.js
  singUpHeader: {
    id: `${scope}.singUpHeader`,
    defaultMessage: 'Crie sua conta',
  },
  socialSubtitle: {
    id: `${scope}.socialSubtitle`,
    defaultMessage: 'Ganhe tempo utilizando suas redes sociais...',
  },
  regularAccountSubtitle: {
    id: `${scope}.regularAccountSubtitle`,
    defaultMessage: '...ou crie sua conta manualmente!',
  },
  step1Label: {
    id: `${scope}.step1Label`,
    defaultMessage: 'Informações pessoais',
  },
  step2Label: {
    id: `${scope}.step2Label`,
    defaultMessage: 'Criar senha',
  },
  step2Description: {
    id: `${scope}.step2Description`,
    defaultMessage: 'Siga as instruções abaixo para criar uma senha segura.',
  },
  passwordValidated: {
    id: `${scope}.passwordValidated`,
    defaultMessage: 'Sua senha é forte, está igual nos dois campos e pode ser utilizada!',
  },
  step3Label: {
    id: `${scope}.step3Label`,
    defaultMessage: 'Termo de uso',
  },
  step3Description: {
    id: `${scope}.step3Description`,
    defaultMessage:
      'Ao acessar e utilizar esta plataforma, independente do dispositivo, você concorda com os termos e condições de uso previstos no {agreement}. Além disso, você declara estar ciente da nossa {privacy} e de acordo com ela.',
  },
  firstNameLabel: {
    id: `${scope}.firstNameLabel`,
    defaultMessage: 'Nome',
  },
  firstNameRequired: {
    id: `${scope}.firstNameRequired`,
    defaultMessage: 'Ops! Não esqueça de informar seu nome',
  },
  firstNameHelperText: {
    id: `${scope}.firstNameHelperText`,
    defaultMessage: 'Precisamos do seu nome para uma experiência customizada',
  },
  lastNameLabel: {
    id: `${scope}.lastNameLabel`,
    defaultMessage: 'Sobrenome',
  },
  lastNameRequired: {
    id: `${scope}.lastNameRequired`,
    defaultMessage: 'Ops! Não esqueça de informar seu sobrenome',
  },
  lastNameHelperText: {
    id: `${scope}.lastNameHelperText`,
    defaultMessage: 'Também precisamos saber seu sobrenome para não gerar confusão com os pedidos',
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'E-mail',
  },
  emailRequired: {
    id: `${scope}.emailRequired`,
    defaultMessage: 'Ops! Sem o seu e-mail não é possível utilizar nossos serviços',
  },
  emailHelperText: {
    id: `${scope}.emailHelperText`,
    defaultMessage: 'Você utilizará este mesmo e-mail para acessar nossa plataforma no futuro',
  },
  emailValidate: {
    id: `${scope}.emailValidate`,
    defaultMessage:
      'Não é um formato de e-mail válido, deve conter @ e pertencer algum provedor de e-mail',
  },
  passwordLabel: {
    id: `${scope}.passwordLabel`,
    defaultMessage: 'Senha',
  },
  passwordRequired: {
    id: `${scope}.passwordRequired`,
    defaultMessage: 'Não esqueça sua senha para confirmar que é você',
  },
  passwordHelperText: {
    id: `${scope}.passwordHelperText`,
    defaultMessage: 'Digite uma senha para utilizar da próxima vez que acessar nossa plataforma',
  },
  passwordMinimumValidate: {
    id: `${scope}.passwordMinimumValidate`,
    defaultMessage: 'A senha deve conter no mínimo 8 caracteres',
  },
  passwordUppercaseValidate: {
    id: `${scope}.passwordUppercaseValidate`,
    defaultMessage: 'A senha deve conter uma letra maiúscula',
  },
  passwordLowercaseValidate: {
    id: `${scope}.passwordLowercaseValidate`,
    defaultMessage: 'A senha deve conter uma letra minúscula',
  },
  passwordMatchLabel: {
    id: `${scope}.passwordMatchLabel`,
    defaultMessage: 'Digite novamente a senha',
  },
  passwordMatchRequired: {
    id: `${scope}.passwordMatchRequired`,
    defaultMessage: 'Você precisa confirmar a senha',
  },
  passwordMatchHelperText: {
    id: `${scope}.passwordMatchHelperText`,
    defaultMessage: 'Você precisa que as duas senhas digitadas sejam iguais',
  },
  passwordMatchNoMatchValidate: {
    id: `${scope}.passwordMatchNoMatchValidate`,
    defaultMessage: 'A senha digitada não confere, tente digitar novamente.',
  },
  agreementLabel: {
    id: `${scope}.agreementLabel`,
    defaultMessage:
      'Estou de acordo com os termos de uso da plataforma e sua política de privacidade',
  },
  agreementLink: {
    id: `${scope}.agreementLink`,
    defaultMessage: 'Termo de Uso',
  },
  privacyLink: {
    id: `${scope}.privacyLink`,
    defaultMessage: 'Política de Privacidade',
  },
  agreementMustBeChecked: {
    id: `${scope}.agreementMustBeChecked`,
    defaultMessage: 'Você precisar estar de acordo para poder abrir sua conta',
  },
  signUpButton: {
    id: `${scope}.signUpButton`,
    defaultMessage: 'Criar conta',
  },
  hasAccountMessage: {
    id: `${scope}.hasAccountMessage`,
    defaultMessage: 'Já possui uma conta?',
  },
  // Login.js
  emailInvalid: {
    id: `${scope}.emailInvalid`,
    defaultMessage: 'Não é um endereço de e-mail válido',
  },
  resetPasswordLink: {
    id: `${scope}.resetPasswordLink`,
    defaultMessage: 'Esqueceu sua senha?',
  },
  signUpMessage: {
    id: `${scope}.signUpMessage`,
    defaultMessage: 'Não tem uma conta? {link}',
  },
  signUpLink: {
    id: `${scope}.signUpLink`,
    defaultMessage: 'Cadastre-se',
  },
  loginButton: {
    id: `${scope}.loginButton`,
    defaultMessage: 'Entrar',
  },
  // PasswordReset.js
  passwordResetTitle: {
    id: `${scope}.passwordResetTitle`,
    defaultMessage: 'Recuperação de senha',
  },
  passwordResetDescription: {
    id: `${scope}.passwordResetDescription`,
    defaultMessage:
      'Informe seu e-mail utilizado para cadastro em nossa plataforma, e caso exista uma conta vinculada à ele, iremos enviar um e-mail de confirmação para que possa definir uma nova senha.',
  },
  passwordResetButton: {
    id: `${scope}.passwordResetButton`,
    defaultMessage: 'Enviar',
  },
  backButton: {
    id: `${scope}.backButton`,
    defaultMessage: 'Voltar',
  },
  // PasswordConfirm.js
  passwordConfirmTitle: {
    id: `${scope}.passwordConfirmTitle`,
    defaultMessage: 'Definir nova senha',
  },
  passwordConfirmDescription: {
    id: `${scope}.passwordConfirmDescription`,
    defaultMessage:
      'Agora você só precisa definir sua nova senha, seguindo as regras de segurança de pelo menos 8 dígitos, com 1 letra maiúscula, 1 letra minúscula, não pode ser parecido com o e-mail ou seu nome',
  },
  passwordConfirmButton: {
    id: `${scope}.passwordConfirmButton`,
    defaultMessage: 'Confirmar alteração',
  },
  // ExpressSignUp.js
  expressSignUpTitle: {
    id: `${scope}.expressSignUpTitle`,
    defaultMessage: 'Já possui conta em nossa plataforma?',
  },
  expressSignUpDescription: {
    id: `${scope}.expressSignUpDescription`,
    defaultMessage:
      'Informe seu e-mail para verificar se possui conta em nossa plataforma ou utilize uma das redes sociais disponíveis',
  },
  expressSignUpButton: {
    id: `${scope}.expressSignUpButton`,
    defaultMessage: 'Continuar',
  },
  // PasswordUpdate.js
  passwordUpdateTitle: {
    id: `${scope}.passwordUpdateTitle`,
    defaultMessage: 'Alterar senha',
  },
  passwordUpdateDescription: {
    id: `${scope}.passwordUpdateDescription`,
    defaultMessage:
      'Informe sua senha atual e defina sua nova senha, seguindo as regras de segurança de pelo menos 8 dígitos, com 1 letra maiúscula, 1 letra minúscula, não pode ser parecido com o e-mail ou seu nome',
  },
  oldPasswordLabel: {
    id: `${scope}.oldPasswordLabel`,
    defaultMessage: 'Senha atual',
  },
  oldPasswordRequired: {
    id: `${scope}.oldPasswordRequired`,
    defaultMessage: 'Você precisa informar corretamente sua senha atual',
  },
  newPasswordLabel: {
    id: `${scope}.newPasswordLabel`,
    defaultMessage: 'Nova senha',
  },
  newPasswordRequired: {
    id: `${scope}.newPasswordRequired`,
    defaultMessage: 'Você precisa definir uma nova senha seguindo as regras de segurança',
  },
  passwordUpdateButton: {
    id: `${scope}.passwordUpdateButton`,
    defaultMessage: 'Salvar',
  },
  // PasswordNew.js
  passwordNewTitle: {
    id: `${scope}.passwordNewTitle`,
    defaultMessage: 'Definir nova senha',
  },
  passwordNewDescription: {
    id: `${scope}.passwordNewDescription`,
    defaultMessage:
      'Defina sua nova senha, seguindo as regras de segurança de pelo menos 8 dígitos, com 1 letra maiúscula, 1 letra minúscula, não pode ser parecido com o e-mail ou seu nome',
  },
  // InviteSignUp.js
  inviteSignUpButton: {
    id: `${scope}.inviteSignUpButton`,
    defaultMessage: 'Aceitar e Criar conta',
  },
});
