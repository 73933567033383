import get from 'lodash/get';
import * as images from 'images';

export function themeConstructor({ data, type = 'light' }) {
  const theme = get(data, 'theme', null);

  if (!theme) return {};

  const themeName = get(theme, 'title', '');
  const palette = get(theme, `${type}Palette`, {});
  const typography = get(theme, 'styles.typography', {});
  const repeater = get(theme, 'repeater', null);
  const logoHorizontal = get(theme, 'logoHorizontal', null) || images.logoHorizontalColor;
  const icon = get(theme, 'icon', null) || images.logoSymbolSvg;
  const shadows = get(theme, 'styles.shadows', []);

  return {
    typography,
    palette: {
      type,
      ...palette,
    },
    shadows,
    themeName,
    assets: {
      repeater,
      logoHorizontal,
      icon,
    },
  };
}
