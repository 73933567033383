export { default as appbarBorder } from './appbar-border.png';
export { default as facesFamilyFace } from './faces/family-face.png';
export { default as facesFemaleFace } from './faces/female-face.svg';
export { default as facesMaleFace } from './faces/male-face.svg';
export { default as facesNotSayFace } from './faces/not-say-face.svg';
export { default as facesOtherFace } from './faces/other-face.svg';
export { default as icon512 } from './icon-512x512.png';
export { default as iconsAddPerson } from './icons/add-person.png';
export { default as iconsCashRegister } from './icons/cash-register.png';
export { default as iconsChat } from './icons/chat.png';
export { default as iconsCheckmark } from './icons/checkmark.png';
export { default as iconsCurrencySign } from './icons/currency-sign.png';
export { default as iconsCustomStore } from './icons/custom-store.png';
export { default as iconsExpressCheckout } from './icons/express-checkout.png';
export { default as iconsFacebook } from './icons/facebook.png';
export { default as iconsFaq } from './icons/faq.png';
export { default as iconsGlobe } from './icons/globe.png';
export { default as iconsGraduationCap } from './icons/graduation-cap.png';
export { default as iconsGroupOfPeople } from './icons/group-of-people.png';
export { default as iconsVoucher } from './icons/icon-voucher.png';
export { default as iconsInstagram } from './icons/instagram.png';
export { default as iconsLinkedin } from './icons/linkedin.png';
export { default as iconsLock } from './icons/lock.png';
export { default as iconsMagnifyingGlass } from './icons/magnifying-glass.png';
export { default as iconsMail } from './icons/mail.png';
export { default as iconsMoney } from './icons/money.png';
export { default as iconsNotebookSmartphone } from './icons/notebook-smartphone.png';
export { default as iconsOrderManagement } from './icons/order-management.png';
export { default as iconsPaymentsAlert } from './icons/payments-alert.png';
export { default as iconsPayments } from './icons/payments.png';
export { default as iconsPhone } from './icons/phone.png';
export { default as iconsProductsBox } from './icons/products-box.png';
export { default as iconsReceipt } from './icons/receipt.png';
export { default as iconsSaveMoney } from './icons/save-money.png';
export { default as iconsSecure } from './icons/secure.png';
export { default as iconsShipped } from './icons/shipped.png';
export { default as iconsShippingTruck } from './icons/shipping-truck.png';
export { default as iconsShoppingCart } from './icons/shopping-cart.png';
export { default as iconsSupport } from './icons/support.png';
export { default as iconsWhatsapp } from './icons/whatsapp.png';
export { default as iconsYoutube } from './icons/youtube.png';
export { default as illustrationsAddressEmpty } from './illustrations/address-empty.png';
export { default as illustrationsUploadFile } from './illustrations/upload-file.png';
export { default as illustrationsAddress } from './illustrations/address.png';
export { default as illustrationsBankEmpty } from './illustrations/bank-empty.png';
export { default as illustrationsBank } from './illustrations/bank.png';
export { default as illustrationsCollection } from './illustrations/collection.png';
export { default as illustrationsContractsEmpty } from './illustrations/contracts-empty.png';
export { default as illustrationsDefaultCover } from './illustrations/default-cover.png';
export { default as illustrationsDuePayments } from './illustrations/due-payments.png';
export { default as illustrationsEmptyCart } from './illustrations/empty-cart.png';
export { default as illustrationsEmptyOrder } from './illustrations/empty-order.png';
export { default as illustrationsError } from './illustrations/error.png';
export { default as illustrationsFileNotFound } from './illustrations/file-not-found.png';
export { default as illustrationsFindInstitutions } from './illustrations/find-institutions.png';
export { default as illustrationsForbidden } from './illustrations/forbidden.png';
export { default as glyphsCenterIllustration } from './illustrations/glyphs-center.png';
export { default as glyphsHeroIllustration } from './illustrations/glyphs-hero.png';
export { default as glyphsLeftIllustration } from './illustrations/glyphs-left.png';
export { default as glyphsRightIllustration } from './illustrations/glyphs-right.png';
export { default as heroImageWelcomeIllustration } from './illustrations/heroimage-welcome.png';
export { default as institutionsIllustration } from './illustrations/institutions.png';
export { default as illustrationsLocation } from './illustrations/location.png';
export { default as illustrationsLogout } from './illustrations/logout.png';
export { default as notebookSmartphoneIllustration } from './illustrations/notebook-smartphone.png';
export { default as illustrationsOrderConfirmation } from './illustrations/order-confirmation.png';
export { default as orderHeroIllustration } from './illustrations/order-hero.png';
export { default as illustrationsOrdersEmpty } from './illustrations/orders-empty.png';
export { default as illustrationsPageNotFound } from './illustrations/page-not-found.png';
export { default as illustrationsPaymentEmpty } from './illustrations/payment-empty.png';
export { default as illustrationsPayment } from './illustrations/payment.png';
export { default as personBuyingIllustration } from './illustrations/person-buying.png';
export { default as illustrationsProfileResponsible } from './illustrations/profile-responsible.svg';
export { default as illustrationsResponsibleCreate } from './illustrations/responsible-create.png';
export { default as illustrationsRouterOffline } from './illustrations/router-offline.png';
export { default as sellersIllustration } from './illustrations/sellers.png';
export { default as illustrationsCallToAction } from './illustrations/social-call-to-action.png';
export { default as illustrationsStudentCreate } from './illustrations/student-create.png';
export { default as illustrationsSubscriptionsEmpty } from './illustrations/subscriptions-empty.png';
export { default as illustrationsTimeout } from './illustrations/timeout.png';
export { default as loading } from './loading.png';
export { default as logoAwsEdStartMember } from './logo/aws-ed-start-member.png';
export { default as logoCubo } from './logo/cubo.png';
export { default as logoHorizontalColor } from './logo/horizontal-color.png';
export { default as logoHorizontalGrey } from './logo/horizontal-grey.png';
export { default as logoHorizontalGreyPoweredBy } from './logo/horizontal-grey-poweredby.png';
export { default as logoClassApp } from './logo/logo-ClassApp.svg';
export { default as logoDefaultInstitutions } from './logo/logo-default-institutions.png';
export { default as logoDefaultSellers } from './logo/logo-default-sellers.png';
export { default as logoFacebook } from './logo/logo-Facebook.svg';
export { default as logoGoogle } from './logo/logo-Google.svg';
export { default as logoSymbolColor } from './logo/symbol-color.png';
export { default as logoSymbolSvg } from './logo/symbol-svg.svg';
export { default as logoVerticalColor } from './logo/vertical-color.png';
export { default as logoAbo2o } from './logo/abo2o.png';
export { default as ovni } from './ovni.png';
export { default as paymentMethodsAmericanExpress } from './payment-methods/american-express.png';
export { default as paymentMethodsBancoDoBrasil } from './payment-methods/banco-do-brasil.png';
export { default as paymentMethodsBankDebit } from './payment-methods/bank-debit.png';
export { default as paymentMethodsBankTransfer } from './payment-methods/bank-transfer.png';
export { default as paymentMethodsBanrisul } from './payment-methods/banrisul.png';
export { default as paymentMethodsBillingNote } from './payment-methods/billing-note.png';
export { default as paymentMethodsBoleto } from './payment-methods/boleto.png';
export { default as paymentMethodsBradesco } from './payment-methods/bradesco.png';
export { default as paymentMethodsCash } from './payment-methods/cash.png';
export { default as paymentMethodsCreditCard } from './payment-methods/credit-card.png';
export { default as paymentMethodsDefaultCard } from './payment-methods/default-card.png';
export { default as paymentMethodsDiners } from './payment-methods/diners.png';
export { default as paymentMethodsElo } from './payment-methods/elo.png';
export { default as paymentMethodsHipercard } from './payment-methods/hipercard.png';
export { default as paymentMethodsItau } from './payment-methods/itau.png';
export { default as paymentMethodsMastercard } from './payment-methods/mastercard.png';
export { default as paymentMethodsPix } from './payment-methods/pix.png';
export { default as paymentMethodsVisa } from './payment-methods/visa.png';
export { default as paymentMethodsWallet } from './payment-methods/wallet.png';
export { default as personasFeaturesFamiliesFeatureFive } from './personas-features/families-feature-five.png';
export { default as personasFeaturesFamiliesFeatureFour } from './personas-features/families-feature-four.png';
export { default as personasFeaturesFamiliesFeatureOne } from './personas-features/families-feature-one.png';
export { default as personasFeaturesFamiliesFeatureThree } from './personas-features/families-feature-three.png';
export { default as personasFeaturesFamiliesFeatureTwo } from './personas-features/families-feature-two.png';
export { default as personasFeaturesInstitutionsFeatureFive } from './personas-features/institutions-feature-five.png';
export { default as personasFeaturesInstitutionsFeatureFour } from './personas-features/institutions-feature-four.png';
export { default as personasFeaturesInstitutionsFeatureOne } from './personas-features/institutions-feature-one.png';
export { default as personasFeaturesInstitutionsFeaturThree } from './personas-features/institutions-feature-three.png';
export { default as personasFeaturesInstitutionsFeatureTwo } from './personas-features/institutions-feature-two.png';
export { default as personasFeaturesSellersFeatureFive } from './personas-features/sellers-feature-five.png';
export { default as personasFeaturesSellersFeatureFour } from './personas-features/sellers-feature-four.png';
export { default as personasFeaturesSellersFeatureOne } from './personas-features/sellers-feature-one.png';
export { default as personasFeaturesSellersFeatureThree } from './personas-features/sellers-feature-three.png';
export { default as personasFeaturesSellersFeatureTwo } from './personas-features/sellers-feature-two.png';
export { default as socialImage } from './socialImage.png';
// Model Knowledge
export { default as modelKnowledgeCollections } from './model-knowledge/collections.png';
export { default as modelKnowledgeStore } from './model-knowledge/store.png';
export { default as modelKnowledgeItem } from './model-knowledge/item.png';
export { default as modelKnowledgeShowcase } from './model-knowledge/showcase.png';
