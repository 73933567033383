/**
 *
 * SnackbarProvider
 *
 */

import { Mui } from 'components';
import { SnackbarProvider as NotistackProvider, useSnackbar } from 'notistack';
import { useInjectReducer } from 'redux-injectors';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import reducer from './ducks';

// Local components
import Notifier from './Notifier';

const useStyles = Mui.makeStyles(theme => ({
  variantInfo: { backgroundColor: theme.palette.info.main, boxShadow: theme.shadows[1] },
  variantWarning: { backgroundColor: theme.palette.warning.main, boxShadow: theme.shadows[1] },
  variantError: { backgroundColor: theme.palette.error.main, boxShadow: theme.shadows[1] },
  variantSuccess: { backgroundColor: theme.palette.success.main, boxShadow: theme.shadows[1] },
}));

const CloseAction = ({ snackBarKey }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <Mui.IconButton size="small" color="inherit" onClick={() => closeSnackbar(snackBarKey)}>
      <Mui.Icon>close</Mui.Icon>
    </Mui.IconButton>
  );
};

CloseAction.propTypes = {
  snackBarKey: PropTypes.number,
};

export function SnackbarProvider(props) {
  useInjectReducer({ key: 'snackbarProvider', reducer });

  const { children } = props;

  const classes = useStyles();

  return (
    <NotistackProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      action={key => <CloseAction snackBarKey={key} />}
      classes={{
        variantInfo: classes.variantInfo,
        variantWarning: classes.variantWarning,
        variantError: classes.variantError,
        variantSuccess: classes.variantSuccess,
      }}>
      <Notifier />
      {children}
    </NotistackProvider>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.any,
};

export default memo(SnackbarProvider);
