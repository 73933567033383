import { loadable } from '../../../utils';

export const Institution = loadable(() => import('./Institution'), {});
export const Line = loadable(() => import('./Line'), {});
export const Showcase = loadable(() => import('./Showcase'), {});
export const Voucher = loadable(() => import('./Voucher'), {});
export const Sidebar = loadable(() => import('./Sidebar'), {});

export { default as Student } from './Student';
export { default as Summary } from './Summary';
export { default as Freight } from './Freight';
export { default as FloatIcon } from './FloatIcon';
export { default as FloatUser } from './FloatUser';
