import { useParams } from 'react-router-dom';
import useFetch from './useFetch';

import useUser from '../containers/common/UserProvider/useUser';
import usePermissions from '../containers/common/PermissionProvider/usePermissions';

export default function useSeller() {
  // Hooks
  const { sellerSlug } = useParams();
  const { isAuthenticated } = useUser();
  const { permissions } = usePermissions();
  const { isSeller } = permissions;

  // Constants
  const endpoints = {
    seller: `/sellers/${sellerSlug}/`,
    sellerPermissions: `/sellers/${sellerSlug}/permissions/`,
  };

  // Fetch
  const { data: dataPermissions, error } = useFetch([
    isAuthenticated && sellerSlug ? endpoints.sellerPermissions : null,
  ]);
  const { data, mutate } = useFetch([
    isAuthenticated && isSeller && sellerSlug ? endpoints.seller : null,
  ]);

  return { error, seller: data, sellerPermissions: dataPermissions?.permissions, refresh: mutate };
}
