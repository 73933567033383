import React from 'react';
import { Grid, Mui } from '../..';
import * as images from '../../../images';
import { useStyles } from './styles';

function SocialLink(props) {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles(props);

  const items = [
    {
      image: images.iconsInstagram,
      name: 'Instagram',
      href: 'https://www.instagram.com/eskolare_oficial/?hl=pt',
    },
    {
      image: images.iconsFacebook,
      name: 'Facebook',
      href: 'https://pt-br.facebook.com/eskolare/',
    },
    {
      image: images.iconsYoutube,
      name: 'YouTube',
      href: 'https://www.youtube.com/channel/UCsHNTq8_yrw_8oc0z3dVQ4w',
    },
    {
      image: images.iconsLinkedin,
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/eskolare/?originalSubdomain=pt',
    },
  ];

  return (
    <Grid container spacing={2} alignItems="center">
      {items.map(item => (
        <Grid item key={item.name}>
          <Mui.Tooltip title={item.name}>
            <Mui.Link href={item.href} target="_blank" rel="noreferrer">
              <img src={item.image} alt={item.name} />
            </Mui.Link>
          </Mui.Tooltip>
        </Grid>
      ))}
    </Grid>
  );
}

SocialLink.propTypes = {};

export default SocialLink;
