/**
 *
 * ThemeProvider
 *
 */

import { createTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { ThemeBaseProvider } from 'components';
import { defaultTheme } from 'themes';

// Containers
import useTheme from './useTheme';

import reducer from './ducks';
import saga from './saga';

export function ThemeProvider(props) {
  useInjectReducer({ key: 'themeProvider', reducer });
  useInjectSaga({ key: 'themeProvider', saga });

  const { children } = props;

  // Hooks
  const { changeTheme } = useTheme();

  // States
  const [isReady, setIsReady] = useState(false);

  // Constants
  const activeTheme = localStorage.getItem('activeTheme') || '';
  const settings = createTheme(defaultTheme, ptBR);

  // Effects
  useEffect(() => {
    if (!isReady) {
      setIsReady(true);

      if (activeTheme) changeTheme(activeTheme);
    }
  }, [isReady, activeTheme, changeTheme]);

  return <ThemeBaseProvider theme={settings}>{children}</ThemeBaseProvider>;
}

ThemeProvider.propTypes = {
  children: PropTypes.any,
};

export default ThemeProvider;
