import { FacebookProvider, Login } from 'react-facebook';
import PropTypes from 'prop-types';
import React from 'react';

import { Mui, SocialButton } from '../..';
import envs from '../../../envs';

function Facebook({ onFailure, onSuccess }) {
  return (
    <FacebookProvider appId={envs.FACEBOOK_APP_ID}>
      <Login
        scope="email"
        onCompleted={response => onSuccess(response)}
        onError={error => onFailure(error)}>
        {({ handleClick, loading }) => (
          <>
            {loading ? (
              <center>
                <Mui.CircularProgress size={20} />
              </center>
            ) : (
              <SocialButton variant="facebook" onClick={handleClick} disabled={loading} />
            )}
          </>
        )}
      </Login>
    </FacebookProvider>
  );
}

Facebook.propTypes = {
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default Facebook;
