import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import envs from 'envs';
import * as images from 'images';

function CustomHelmet(props) {
  const { children, messages, values, title } = props;

  const intl = useIntl();

  const newTitle = (messages && intl.formatMessage(messages.title, values)) || title || '...';
  const description = (messages && intl.formatMessage(messages.description, values)) || '...';

  return (
    <Helmet>
      {process.env.NODE_ENV !== 'production' && <meta name="robots" content="noindex"></meta>}
      {/* <!-- Primary Meta Tags --> */}
      <title>{newTitle}</title>
      <meta name="description" content={description} />
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:title" content={newTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={envs.APPLICATION_NAME} />
      <meta property="og:locale" content={intl.locale} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={images.illustrationsCallToAction} />
      <meta property="og:url" content={window.location.href} />
      <meta property="fb:app_id" content={envs.FACEBOOK_APP_ID} />
      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={newTitle} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={images.illustrationsCallToAction} />
      <meta property="twitter:card" content="summary_large_image" />
      {children}
    </Helmet>
  );
}

CustomHelmet.propTypes = {
  children: PropTypes.any,
  messages: PropTypes.object,
  values: PropTypes.object,
  title: PropTypes.string,
};

export default CustomHelmet;
