import { Button, Collapse, ListItem, makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import React, { useState } from 'react';

// Components
import { Mui } from 'components';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    width: 28,
    height: 28,
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    '& $avatar': {
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
    },
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

function NavItem(props) {
  const {
    title,
    href,
    depth,
    children,
    icon,
    avatar,
    className,
    open: openProp,
    info,
    target,
    disabled,
    isNew,
    isStaff,
    ...rest
  } = props;

  // Hooks
  const classes = useStyles();

  // Contstants
  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }
  const style = { paddingLeft };

  // States
  const [open, setOpen] = useState(openProp);

  // Actions
  const handleToggle = () => setOpen(prevOpen => !prevOpen);

  const menuIcon = () =>
    isStaff || isNew ? (
      <Mui.Badge
        // eslint-disable-next-line no-nested-ternary
        color={isStaff ? 'error' : isNew ? 'primary' : ''}
        variant="dot"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {icon({ className: classes.icon, size: 20 })}
      </Mui.Badge>
    ) : (
      icon({ className: classes.icon, size: 20 })
    );

  if (children) {
    return (
      <ListItem className={clsx(classes.item, className)} disableGutters key={title} {...rest}>
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {icon && menuIcon()}
          {avatar && avatar({ className: classes.avatar })}
          <span className={classes.title}>{title}</span>
          {open ? (
            <ExpandLessIcon size="small" color="inherit" />
          ) : (
            <ExpandMoreIcon size="small" color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  const routeProps = {
    to: href,
    component: RouterLink,
    exact: true,
    activeClassName: classes.active,
  };

  const linkProps = { component: 'a', href };

  const routerProps = href.includes('http') ? linkProps : routeProps;

  return (
    <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
      <Button
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        disabled={disabled}
        style={style}
        target={target}
        {...routerProps}>
        {icon && menuIcon()}
        {avatar && avatar({ className: classes.avatar })}
        <span className={classes.title}>
          {!icon && (isStaff || isNew) ? (
            <Mui.Badge
              // eslint-disable-next-line no-nested-ternary
              color={isStaff ? 'error' : isNew ? 'primary' : ''}
              variant="dot"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
              {title}
            </Mui.Badge>
          ) : (
            title
          )}
        </span>
        {info && info({})}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  target: PropTypes.string,
  icon: PropTypes.any,
  avatar: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.any.isRequired,
  isNew: PropTypes.bool,
  isStaff: PropTypes.bool,
};

NavItem.defaultProps = {
  open: false,
  isNew: false,
  isStaff: false,
};

export default NavItem;
