import { createSelector } from 'reselect';

import { initialState } from './ducks';

/**
 * Direct selector to the App state domain
 */

const selectGlobalDomain = state => state.global || initialState;
const selectRouter = state => state.router;

/**
 * Other specific selectors
 */

const makeSelectLocation = () => createSelector(selectRouter, routerState => routerState.location);

/**
 * Default selector used by App
 */

const makeSelectGlobal = () => createSelector(selectGlobalDomain, substate => substate);

export default makeSelectGlobal;
export { selectGlobalDomain, makeSelectLocation };
