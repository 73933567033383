import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFetch } from 'hooks';
import { FormattedMessage } from 'react-intl';

// Components
import { Mui } from 'components';
import { DisplayData } from 'components/common';
import { Buttons } from 'components/layouts';

import * as api from '../api';
import messages from '../messages';

function Students(props) {
  const { children } = props;

  // Queries
  const [query] = useState({
    is_active: true,
  });

  // Fetch
  const { data } = useFetch([api.endpoints.students(), query]);

  // Constants
  const { results: students } = data || {};

  return (
    <div>
      {!students && (
        <Mui.Box display="flex" justifyContent="center" my={2}>
          <Mui.CircularProgress size={50} color="primary" />
        </Mui.Box>
      )}
      {students?.map(item => (
        <Mui.Box key={item.uid} mb={2}>
          <Mui.Box mb={1} display="flex" wrap="nowrap" justifyContent="space-between">
            <DisplayData.Student {...item} disableCaption yourself>
              {item.basket?.numItems > 0 && (
                <Mui.Typography variant="body2" color="primary">
                  <FormattedMessage
                    {...messages.basketLabel}
                    values={{
                      basket: <DisplayData.CurrencyField value={item.basket?.subtotal} />,
                    }}
                  />
                </Mui.Typography>
              )}
            </DisplayData.Student>
            <Buttons
              color="primary"
              preset="edit"
              component="IconButton"
              to={{ pathname: `/accounts/students`, search: `?student_uid=${item.uid}&edit=1` }}
            />
          </Mui.Box>
          {children && children(item)}
        </Mui.Box>
      ))}
    </div>
  );
}

Students.propTypes = {
  children: PropTypes.func,
};

export default Students;
