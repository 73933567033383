import { loadable } from 'utils';

export const Basic = loadable(() => import('./Basic'), {});
export const CreateWizard = loadable(() => import('./CreateWizard'), {});
export const ImportWizard = loadable(() => import('./ImportWizard'), {});
export const Kit = loadable(() => import('./Kit'), {});
export const Media = loadable(() => import('./Media'), {});
export const Stock = loadable(() => import('./Stock'), {});
export const SuggestionWorkflow = loadable(() => import('./SuggestionWorkflow'), {});
export const Taxation = loadable(() => import('./Taxation'), {});
