import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { sessionToken } from 'utils';
import * as images from 'images';

import * as ducks from './ducks';

import makeSelectUserProvider from './selectors';

const stateSelector = createStructuredSelector({
  userProvider: makeSelectUserProvider(),
});

const initialState = { firstName: '...', picture: images.facesOtherFace };

function useUser() {
  // Hooks
  const { token } = sessionToken();
  const dispatch = useDispatch();

  // Store
  const { userProvider } = useSelector(stateSelector);

  // Constants
  const { isAuthenticated, isFetching, user } = userProvider;

  // Actions
  const refresh = useCallback(payload => dispatch(ducks.retrieveRequest(payload)), [dispatch]);
  const login = useCallback(payload => dispatch(ducks.loginRequest(payload)), [dispatch]);
  const passwordReset = useCallback(
    payload => dispatch(ducks.passwordResetRequest(payload)),
    [dispatch],
  );
  const passwordConfirm = useCallback(
    payload => dispatch(ducks.passwordConfirmRequest(payload)),
    [dispatch],
  );
  const signUp = useCallback(payload => dispatch(ducks.signUpRequest(payload)), [dispatch]);
  const updatePassword = useCallback(
    payload => dispatch(ducks.updatePasswordRequest(payload)),
    [dispatch],
  );

  return {
    isAuthenticated: isAuthenticated || !!token,
    refresh,
    refreshing: isFetching.retrieve,
    login,
    authenticating: isFetching.login,
    signUp,
    passwordReset,
    passwordConfirm,
    updatePassword,
    isFetching,
    user: user || initialState,
  };
}

export default useUser;
