/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import { RESET_APP } from './utils/constants';

// Providers
import globalReducer from './containers/common/App/ducks';
import snackbarProviderReducer from './containers/common/SnackbarProvider/ducks';
import languageProviderReducer from './containers/common/LanguageProvider/reducer';
import cartProviderReducer from './containers/common/CartProvider/ducks';
import productProviderReducer from './containers/common/ProductProvider/ducks';
import userProviderReducer from './containers/common/UserProvider/ducks';
import themeProviderReducer from './containers/common/ThemeProvider/ducks';
import permissionProviderReducer from './containers/common/PermissionProvider/reducer';

// Important Pages
import checkoutExpressPageReducer from './pages/checkout/CheckoutExpressPage/ducks';
import checkoutPageReducer from './pages/checkout/CheckoutRegularPage/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const appReducer = combineReducers({
    cartProvider: cartProviderReducer,
    checkoutPage: checkoutPageReducer,
    checkoutExpressPage: checkoutExpressPageReducer,
    global: globalReducer,
    language: languageProviderReducer,
    snackbarProvider: snackbarProviderReducer,
    productProvider: productProviderReducer,
    userProvider: userProviderReducer,
    themeProvider: themeProviderReducer,
    permissionProvider: permissionProviderReducer,
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state

  return (state, action) => {
    const prevState = state;

    if (action.type === RESET_APP) {
      const newState = {
        themeProvider: state.themeProvider,
        permissionProvider: state.permissionProvider,
        global: { isStoreReady: true },
      };

      return appReducer(newState, action);
    }

    return appReducer(prevState, action);
  };
}
