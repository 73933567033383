import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Mui, Button } from '../..';

import messages from './messages';

function renderLink(config) {
  const { title, icon, ...restProps } = config;

  return <Mui.Link {...restProps}></Mui.Link>;
}

function renderButton(config) {
  return <Button {...config}></Button>;
}

function renderIconButton(config) {
  const { title, icon, ...restProps } = config;

  return (
    <Mui.Tooltip title={title}>
      <span>
        <Mui.IconButton {...restProps}>
          <Mui.Icon>{icon}</Mui.Icon>
        </Mui.IconButton>
      </span>
    </Mui.Tooltip>
  );
}

function Buttons(props) {
  const {
    preset,
    component,
    children,
    onClick,
    loading,
    disabled,
    color,
    icon,
    startIcon,
    endIcon,
    variant,
    to,
    ComponentProps,
    ...restProps
  } = props;

  const label = children || <FormattedMessage {...messages[preset]} />;

  const icons = {
    add: 'add',
    archive: 'archive',
    cancel: 'cancel',
    close: 'close',
    copy: 'file_copy',
    continue: 'navigate_next',
    delete: 'delete',
    download: 'get_app',
    edit: 'edit',
    message: 'message',
    request: 'add_box',
    save: 'save',
    select: 'star',
    send: 'send',
    unarchive: 'unarchive',
    view: 'visibility',
    next: 'navigate_next',
    before: 'navigate_before',
    open: 'open_in_new',
    confirm: 'done',
  };

  const buttonProps = {
    children: label,
    onClick,
    loading,
    disabled,
    color,
    startIcon,
    endIcon,
    variant,
    disableElevation: true,
    ...(to && { component: RouterLink, to }),
    ...restProps,
    ...ComponentProps,
  };

  const iconButtonProps = {
    title: label,
    icon: icon || icons[preset],
    onClick,
    disabled: disabled || loading,
    color,
    ...(to && { component: RouterLink, to }),
    ...restProps,
    ...ComponentProps,
  };

  const linkProps = {
    children: label,
    onClick,
    disabled: disabled || loading,
    color,
    variant,
    ...(to && { component: RouterLink, to }),
    ...restProps,
    ...ComponentProps,
  };

  switch (component) {
    case 'Button':
      return renderButton(buttonProps);
    case 'IconButton':
      return renderIconButton(iconButtonProps);
    case 'a':
      return renderLink(linkProps);
    default:
      return null;
  }
}

Buttons.propTypes = {
  preset: PropTypes.oneOf([
    'add',
    'archive',
    'cancel',
    'close',
    'copy',
    'continue',
    'delete',
    'download',
    'edit',
    'message',
    'request',
    'save',
    'select',
    'send',
    'unarchive',
    'view',
    'next',
    'before',
    'confirm',
  ]),
  component: PropTypes.oneOf(['Button', 'IconButton', 'Link']),
  children: PropTypes.any,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.string,
  startIcon: PropTypes.any,
  endIcon: PropTypes.any,
  variant: PropTypes.string,
  to: PropTypes.any,
  ComponentProps: PropTypes.object,
};

Buttons.defaultProps = {
  component: 'Button',
};

export default Buttons;
