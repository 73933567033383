import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';

import makeSelectGlobal from './selectors';

const stateSelector = createStructuredSelector({
  global: makeSelectGlobal(),
});

function useGlobal() {
  const { global } = useSelector(stateSelector);

  const { isStoreReady } = global;

  return {
    isStoreReady,
  };
}

export default useGlobal;
