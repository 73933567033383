/*
 * FamilyLayout Messages
 *
 * This contains all the text for the FamilyLayout container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.layouts.FamilyLayout';

export default defineMessages({
  collections: {
    id: `${scope}.collections`,
    defaultMessage: 'Coleções',
  },
});
