import { Redirect } from 'react-router-dom';
import React, { lazy } from 'react';

// Containers
import FamilyGuard from '../FamilyGuard';

// Layouts
import AccountLayout from '../../../layouts/AccountLayout';
import EmptyLayout from '../../../layouts/EmptyLayout';

export default [
  {
    path: '/accounts/change-password',
    guard: FamilyGuard,
    layout: EmptyLayout,
    component: lazy(() => import('pages/accounts/AccountPasswordUpdatePage')),
  },
  {
    exact: true,
    path: '/accounts/students',
    guard: FamilyGuard,
    layout: EmptyLayout,
    component: lazy(() => import('pages/accounts/AccountStudentsPage')),
  },
  {
    exact: true,
    path: '/accounts/enrollments/requests',
    guard: FamilyGuard,
    layout: EmptyLayout,
    component: lazy(() => import('pages/accounts/AccountEnrollmentsRequestsPage')),
  },
  {
    exact: true,
    path: '/accounts/enrollments',
    guard: FamilyGuard,
    layout: EmptyLayout,
    component: lazy(() => import('pages/accounts/AccountEnrollmentsPage')),
  },
  {
    path: '/accounts',
    layout: AccountLayout,
    guard: FamilyGuard,
    routes: [
      {
        exact: true,
        path: '/accounts/profile',
        component: lazy(() => import('pages/accounts/AccountUserProfilePage')),
      },
      {
        exact: true,
        path: '/accounts/addresses',
        component: lazy(() => import('pages/accounts/AccountAddressPage')),
      },
      {
        exact: true,
        path: '/accounts/credit-cards/:creditCardUid',
        component: lazy(() => import('pages/accounts/AccountCreditCardDetailsPage')),
      },
      {
        exact: true,
        path: '/accounts/credit-cards',
        component: lazy(() => import('pages/accounts/AccountCreditCardPage')),
      },
      {
        exact: true,
        path: '/accounts/bank-accounts',
        component: lazy(() => import('pages/accounts/AccountBankAccountPage')),
      },
      {
        exact: true,
        path: '/accounts/orders/:orderNumber/change-payment-method',
        component: lazy(() => import('pages/accounts/AccountChangePaymentPage')),
      },
      {
        exact: true,
        path: '/accounts/orders/:orderNumber',
        component: lazy(() => import('pages/accounts/AccountOrderDetailsPage')),
      },
      {
        exact: true,
        path: '/accounts/orders',
        component: lazy(() => import('pages/accounts/AccountOrderPage')),
      },
      {
        exact: true,
        path: '/accounts/payments/due',
        component: lazy(() => import('pages/accounts/AccountDuePaymentPage')),
      },
      {
        exact: true,
        path: '/accounts',
        component: lazy(() => import('pages/accounts/AccountPage')),
      },
      { component: () => <Redirect to="/404" /> },
    ],
  },
];
