/*
 *
 * ThemeProvider saga
 *
 */

import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import envs from 'envs';

import * as ducks from './ducks';

// API's

const domain = `${envs.PROTOCOL}://${envs.ENTRYPOINT}/api`;

export const endpoints = {
  retrieve: institutionSlug => `${domain}/commons/institutions/${institutionSlug}/theme/`,
};

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#6e5c12c5-ad8a-484c-885d-9611efcdcd5e
 */
export function retrieve({ institutionSlug }) {
  const options = {
    method: 'get',
    url: endpoints.retrieve(institutionSlug),
  };
  return axios(options);
}

// Side-effects

export function* retrieveRequestSaga(action) {
  const { institutionSlug } = action.payload;

  try {
    const response = yield retrieve({ institutionSlug });

    yield put(ducks.retrieveSuccess({ institutionSlug, response: response.data }));
  } catch (error) {
    yield put(ducks.retrieveFailure({ institutionSlug }));
  }
}

export function* retrieveSuccessSaga(action) {
  const { institutionSlug } = action.payload;

  if (localStorage) localStorage.setItem('activeTheme', institutionSlug);

  yield true;
}

export function* defaultThemeSaga() {
  if (localStorage) localStorage.setItem('activeTheme', '');

  yield true;
}

// Individual exports for testing
export default function* themeProviderSaga() {
  yield takeLatest(ducks.RETRIEVE_REQUEST, retrieveRequestSaga);
  yield takeLatest(ducks.RETRIEVE_SUCCESS, retrieveSuccessSaga);
  yield takeLatest(ducks.DEFAULT_THEME, defaultThemeSaga);
}
