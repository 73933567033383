/**
 *
 * PlatformIcons
 *
 */

/**
 * This approach organizes all the icons used in the application, imported from the
 * material-ui in a single place, optimizing the context and preventing icons that
 * in the same context are used different icons.
 *
 * each created constant represents a context of the application, for example: requests,
 * forms, navigation. It is important to respect the contexts created before thinking
 * about creating a new context
 */

import * as Actions from './Actions';
import * as Checkout from './Checkout';
import * as Institutions from './Institutions';
import * as Navigation from './Navigation';
import * as Notifications from './Notifications';
import * as Orders from './Orders';
import * as Products from './Products';
import * as Users from './Users';

export default {
  Actions,
  Navigation,
  Products,
  Checkout,
  Orders,
  Users,
  Notifications,
  Institutions,
};
