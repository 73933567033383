import isObject from 'lodash/isObject';
import React from 'react';

import * as ducks from './ducks';

function errorHandler(data) {
  try {
    if (isObject(data)) {
      const { detail, nonFieldErrors, options } = data;

      if (detail) {
        return detail;
      }

      if (nonFieldErrors && nonFieldErrors.length > 0) {
        const [error] = nonFieldErrors;

        return error;
      }

      if (options && Array.isArray(options)) {
        return (
          <ul>
            {options.map((option, index) => {
              const [key] = Object.keys(option);
              const message = Array.isArray(option[key]) ? option[key].join(', ') : null;
              return !message ? null : (
                <li key={`${index.toString()}`} style={{ listStyle: 'none' }}>
                  <strong>{key}:</strong> {message}
                </li>
              );
            })}
          </ul>
        );
      }

      return (
        <ul>
          {Object.keys(data).map((key, index) => {
            const value = data[key];

            const newValue = Array.isArray(value) ? value.join(' ') : value;

            return (
              <li key={`${index.toString()}`} style={{ listStyle: 'none' }}>
                <strong>{key}:</strong> {newValue}
              </li>
            );
          })}
        </ul>
      );
    }
  } catch (error) {
    return 'Error';
  }

  return 'Unknown';
}

function middleware(dispatch, payload) {
  let messageId = 'UNKNOWN';
  let variant = 'error';
  let values = {};

  if (payload) {
    const { response } = payload;

    if (response) {
      const { status, data } = response;

      messageId = `ERROR${status}`;

      if (status === 401 || status === 400) {
        values = { error: errorHandler(data) };
        variant = 'error';
      }
    }
  }

  return dispatch(ducks.enqueueSnackbar({ messageId, variant, values }));
}

export default middleware;
