/**
 *
 * AnalyticsProvider
 *
 */

import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import PropTypes from 'prop-types';

import * as ducks from './ducks';
import saga from './saga';
import useAnalytics from './useAnalytics';

export function AnalyticsProvider(props) {
  useInjectReducer({ key: 'analyticsProvider', reducer: ducks.analyticsProviderReducer });
  useInjectSaga({ key: 'analyticsProvider', saga });

  const { children } = props;

  return children;
}

AnalyticsProvider.propTypes = {
  children: PropTypes.any,
};

export default AnalyticsProvider;
export { useAnalytics };
