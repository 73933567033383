import React from 'react';

import { Mui } from '../..';

export const Add = props => <Mui.Icon {...props}>add_outlined</Mui.Icon>;
export const PersonAdd = props => <Mui.Icon {...props}>person_add</Mui.Icon>;
export const Close = props => <Mui.Icon {...props}>close</Mui.Icon>;
export const Delete = props => <Mui.Icon {...props}>delete_outlined</Mui.Icon>;
export const Edit = props => <Mui.Icon {...props}>edit_outlined</Mui.Icon>;
export const Expand = props => <Mui.Icon {...props}>expand_more</Mui.Icon>;
export const Favorite = props => <Mui.Icon {...props}>star</Mui.Icon>;
export const Help = props => <Mui.Icon {...props}>help_outlined</Mui.Icon>;
export const Search = props => <Mui.Icon {...props}>search</Mui.Icon>;
export const Visibility = props => <Mui.Icon {...props}>visibility</Mui.Icon>;
export const VisibilityOff = props => <Mui.Icon {...props}>visibility_off</Mui.Icon>;
export const SignOut = props => <Mui.Icon {...props}>close</Mui.Icon>;
export const Protected = props => <Mui.Icon {...props}>lock_outlined</Mui.Icon>;
export const Done = props => <Mui.Icon {...props}>done</Mui.Icon>;
export const RadioChecked = props => <Mui.Icon {...props}>radio_button_checked</Mui.Icon>;
export const RadioUnchecked = props => <Mui.Icon {...props}>radio_button_unchecked</Mui.Icon>;
