/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useQuery } from 'utils';
import * as Yup from 'yup';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import messages from './messages';

// Components
import { Button, Link, PasswordField, TextField, Typography, Grid, Mui } from '../..';
import { Stack } from '../../layouts';

function Login(props) {
  // Hooks
  const intl = useIntl();
  const query = useQuery();
  const { pathname } = useLocation();

  // Queries
  const redirect = query.get('redirect');

  // Constants
  const { values, onSubmit, SocialAuthentication, loading } = props;

  const formMessages = {
    email: {
      label: intl.formatMessage(messages.emailLabel),
      required: intl.formatMessage(messages.emailRequired),
      invalid: intl.formatMessage(messages.emailInvalid),
    },
    password: {
      label: intl.formatMessage(messages.passwordLabel),
      required: intl.formatMessage(messages.passwordRequired),
    },
  };

  const initialValues = {
    email: get(values, 'email', ''),
    password: '',
  };

  const yup = Yup.object().shape({
    email: Yup.string().email(formMessages.email.invalid).required(formMessages.email.required),
    password: Yup.string().required(formMessages.password.required),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: newValues => onSubmit(newValues),
    validationSchema: yup,
    enableReinitialize: true,
    validateOnBlur: false,
  });

  return (
    <Stack TransitionComponent={Mui.Fade}>
      {SocialAuthentication}
      <TextField
        autoFocus
        autoComplete="email"
        name="email"
        type="email"
        margin="none"
        formik={formik}
        messages={formMessages}
      />
      <PasswordField
        autoComplete="current-password"
        name="password"
        formik={formik}
        messages={formMessages}
        margin="none"
        onKeyPress={ev => {
          if (ev.key === 'Enter') {
            formik.handleSubmit();
            ev.preventDefault();
          }
        }}
      />
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Link component={RouterLink} to="/password-reset" variant="body2">
            <FormattedMessage {...messages.resetPasswordLink} />
          </Link>
        </Grid>
        <Grid item>
          <Button onClick={() => formik.handleSubmit()} loading={loading}>
            <FormattedMessage {...messages.loginButton} />
          </Button>
        </Grid>
      </Grid>
      <Mui.Divider />
      <Typography variant="body2" component="p" align="center">
        <FormattedMessage
          {...messages.signUpMessage}
          values={{
            link: (
              <Link
                component={RouterLink}
                variant="body2"
                to={`/sign-up?redirect=${redirect || pathname || ''}`}>
                <FormattedMessage {...messages.signUpLink} />
              </Link>
            ),
          }}
        />
      </Typography>
    </Stack>
  );
}

Login.propTypes = {
  values: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  SocialAuthentication: PropTypes.any,
};

export default Login;
