import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { Mui, SwipeableViews } from '../..';
import { scrollHandler } from '../../../utils';
import messages from './messages';

export const useColorlibStepIconStyles = Mui.makeStyles(theme => ({
  root: {
    zIndex: 1,
    color: '#eaeaf0',
    width: props => (props.fullScreen ? 35 : 50),
    height: props => (props.fullScreen ? 35 : 50),
    border: '2px solid #eaeaf0',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    color: props =>
      props.color ? theme.palette[props.color].contrastText : theme.palette.primary.contrastText,
    backgroundColor: props =>
      props.color ? theme.palette[props.color].main : theme.palette.primary.main,
    border: `2px solid transparent`,
  },
  completed: {
    color: props =>
      props.color ? theme.palette[props.color].contrastText : theme.palette.success.contrastText,
    backgroundColor: props =>
      props.color ? theme.palette[props.color].main : theme.palette.success.main,
    border: props =>
      props.color ? `2px solid ${theme.palette[props.color].main}` : `2px solid transparent`,
  },
  error: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.main,
    border: `2px solid transparent`,
  },
  disabled: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.grey[150],
    border: `2px solid ${theme.palette.grey[100]}`,
  },
}));

function ColorlibStepIcon(props) {
  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  const { active, completed, error, icon, color, disabled } = props;

  const classes = useColorlibStepIconStyles({ fullScreen, color, error });

  const newIcon = isString(icon) ? <Mui.Icon>{icon}</Mui.Icon> : icon;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: !error && !disabled && active,
        [classes.completed]: !error && !disabled && completed,
        [classes.error]: (active || completed) && error,
        [classes.disabled]: (active || completed) && disabled,
      })}>
      {completed && !error && (icon ? newIcon : <Mui.Icon>check</Mui.Icon>)}
      {(active || completed) && error && (icon ? newIcon : <Mui.Icon>close</Mui.Icon>)}
      {active && !error && newIcon}
      {!(active || completed) && newIcon}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary', 'warning', 'info', 'success', 'error']),
};

export const useColorlibConnector = Mui.makeStyles(theme => ({
  alternativeLabel: {
    top: props => (props.fullScreen ? 15 : 22),
    left: props => (props.fullScreen ? 'calc(-50%)' : 'calc(-50% + 20px)'),
    right: props => (props.fullScreen ? 'calc(50%)' : 'calc(50% + 20px)'),
  },
  active: {
    '& $line': {
      borderColor: props => {
        if (props.disabled) return theme.palette.grey[100];
        return props.color ? theme.palette[props.color].main : theme.palette.primary.main;
      },
    },
  },
  completed: {
    '& $line': {
      borderColor: props =>
        props.color ? theme.palette[props.color].main : theme.palette.success.main,
    },
  },
  line: {
    borderWidth: 2,
    borderColor: props => {
      if (props.disabled) return theme.palette.grey[100];
      return props.color ? theme.palette[props.color].main : '#eaeaf0';
    },
  },
  vertical: {
    marginLeft: props => (props.fullScreen ? theme.spacing(2) : theme.spacing(3)),
    padding: 0,
  },
}));

function ColorlibConnector(props) {
  const { index, steps, activeStep, completed, ...otherProps } = props;

  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));
  const { error } = steps[index];
  const { disabled } = steps[index - 1];
  const color =
    (index <= activeStep && error && 'error') || (index <= activeStep && steps[index - 1].color);

  const classes = useColorlibConnector({ fullScreen, index, color, disabled });

  return (
    <Mui.StepConnector
      index={index}
      completed={!disabled && completed}
      {...otherProps}
      classes={classes}
    />
  );
}

ColorlibConnector.propTypes = {
  index: PropTypes.number,
  activeStep: PropTypes.number,
  completed: PropTypes.bool,
  steps: PropTypes.array,
};

export const useStepLabel = Mui.makeStyles(theme => ({
  root: {
    cursor: props => props.onClick && 'pointer',
  },
  active: {
    color: props => props.disabled && `${theme.palette.secondary.main} !important`,
  },
  completed: {
    color: props => props.disabled && `${theme.palette.secondary.main} !important`,
  },
  label: {
    display: props => props.fullScreen && props.orientation === 'horizontal' && 'none',
    textOverflow: 'ellipsis',
    whitespace: 'nowrap',
    overflow: 'hidden',
    minWidth: '1px',
    wordBreak: 'keep-all',
  },
}));

function StepLabel(props) {
  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  const { completed, error, disabled, color, ...otherProps } = props;

  const classes = useStepLabel({ fullScreen, disabled, ...props });

  return (
    <Mui.StepLabel
      {...otherProps}
      StepIconProps={{ ...props.StepIconProps, error, color, disabled }}
      completed={completed}
      error={completed && error}
      classes={classes}
    />
  );
}

StepLabel.propTypes = {
  completed: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  StepIconProps: PropTypes.object,
  color: PropTypes.oneOf(['primary', 'secondary', 'warning', 'info', 'success', 'error']),
};

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
  },
  step: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
  },
  swipeable: {
    padding: 0,
    margin: 0,
  },
  slide: {
    overflow: 'hidden !important',
  },
  optionalLabel: {
    display: props => props.fullScreen && props.orientation === 'horizontal' && 'none',
  },
}));

// eslint-disable-next-line sonarjs/cognitive-complexity
function Base(props) {
  const { steps, activeStep, children, alternativeLabel, orientation } = props;

  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ fullScreen, orientation });
  const stepperRef = useRef();

  const scrollToRef = ref => setTimeout(() => scrollHandler(ref.current.offsetTop - 56), 500);

  useEffect(() => {
    const top = window.pageYOffset || document.documentElement.scrollTop;

    if (activeStep > 0 && top > 0) scrollToRef(stepperRef);
  }, [activeStep]);

  const labelAlignment = () => {
    if (orientation === 'horizontal' && alternativeLabel) return 'center';
    if (orientation === 'horizontal' && !alternativeLabel) return 'left';
    return 'left';
  };

  return (
    <div ref={stepperRef}>
      <Mui.Stepper
        alternativeLabel={orientation === 'vertical' ? false : alternativeLabel}
        orientation={orientation}
        nonLinear
        connector={<ColorlibConnector steps={steps} activeStep={activeStep} />}
        activeStep={activeStep}
        classes={{ root: classes.root }}>
        {steps &&
          steps.map((step, s) => {
            const stepProps = {
              completed: s < activeStep,
            };
            const labelProps = {
              StepIconComponent: ColorlibStepIcon,
              error: step.error,
              ...(step.icon && {
                icon: step.icon || s + 1,
              }),
              disabled: step.disabled,
              color: step.color,
              ...(step.onClick && { onClick: () => step.onClick() }),
            };
            if (step.optional) {
              labelProps.optional = (
                <Mui.Typography
                  variant="caption"
                  align={labelAlignment()}
                  color="textSecondary"
                  component="div"
                  className={classes.optionalLabel}>
                  {step.optionalLabel || <FormattedMessage {...messages.optionalLabel} />}
                </Mui.Typography>
              );
            }

            return (
              <Mui.Step key={step.key || s.toString()} {...stepProps}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Mui.Step>
            );
          })}
      </Mui.Stepper>
      {children && (
        <SwipeableViews
          autoplay={false}
          index={activeStep}
          className={classes.swipeable}
          slideClassName={classes.slide}>
          {React.Children.map(children, (child, index) => (
            <div className={classes.step} key={`${index.toString()}`}>
              {child}
            </div>
          ))}
        </SwipeableViews>
      )}
    </div>
  );
}

Base.propTypes = {
  children: PropTypes.any,
  steps: PropTypes.array,
  activeStep: PropTypes.number,
  alternativeLabel: PropTypes.bool,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

Base.defaultProps = {
  orientation: 'horizontal',
};

export default Base;
