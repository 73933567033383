import { sessionToken } from 'utils';
import { useUser } from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

function GuestGuard(props) {
  const { children } = props;

  // Hooks
  const history = useHistory();
  const { search } = useLocation();

  // Custom hooks
  const { isAuthenticated } = useUser();

  // Queries
  const prevSearch = new URLSearchParams(search);
  const redirect = prevSearch.get('redirect');
  prevSearch.delete('redirect');
  const newSearch = prevSearch.toString();

  // Constants
  const { token } = sessionToken();

  // Effects

  useEffect(() => {
    if (isAuthenticated) {
      const newRedirect = redirect ? `${redirect}?${newSearch}` : null;

      window.location.href = newRedirect || `/families?${newSearch}`;
    }
  }, [isAuthenticated, token, redirect, newSearch, history]);

  if (isAuthenticated) return null;

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any,
};

export default GuestGuard;
