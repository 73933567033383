/*
 *
 * SocialAuthentication reducer
 *
 */
import produce from 'immer';

import * as constants from './constants';

export const initialState = {
  isFetching: {
    socialConnect: false,
  },
};

/* eslint-disable default-case, no-param-reassign */
const socialAuthenticationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constants.SOCIAL_CONNECT_REQUEST:
        draft.isFetching.socialConnect = true;
        break;
      case constants.SOCIAL_CONNECT_SUCCESS:
        draft.isFetching.socialConnect = false;
        break;
      case constants.SOCIAL_CONNECT_FAILURE:
        draft.isFetching.socialConnect = false;
        break;
    }
  });

export default socialAuthenticationReducer;
