import { useSeller } from 'hooks';
import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy, Suspense, Fragment } from 'react';

// Layouts
import EmptyLayout from 'layouts/EmptyLayout';
import MainLayout from 'layouts/MainLayout';
import PublicLayout from 'layouts/PublicLayout';

// Components
import { LoadingScreen } from 'components/layouts';

// Containers
import GuestGuard from '../GuestGuard';

// Routes
import families from './families';
import sellers from './sellers';
import accounts from './accounts';
import checkout from './checkout';
import institutions from './institutions';
import e from './e';
import l from './l';
import pages from './pages';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/accounts" />,
  },
  {
    exact: true,
    path: '/login',
    guard: GuestGuard,
    layout: EmptyLayout,
    component: lazy(() => import('pages/root/LoginPage')),
  },
  {
    exact: true,
    path: '/logout',
    layout: EmptyLayout,
    component: lazy(() => import('pages/root/LogoutPage')),
  },
  {
    path: '/sign-up',
    layout: EmptyLayout,
    guard: GuestGuard,
    routes: [
      {
        exact: true,
        path: '/sign-up/express',
        component: lazy(() => import('pages/root/ExpressSignUpPage')),
      },
      {
        exact: true,
        path: '/sign-up',
        component: lazy(() => import('pages/root/SignUpPage')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    exact: true,
    path: '/password-reset/:uidb64/:token',
    layout: EmptyLayout,
    component: lazy(() => import('pages/root/PasswordConfirmPage')),
  },
  {
    exact: true,
    path: '/password-reset',
    layout: EmptyLayout,
    component: lazy(() => import('pages/root/PasswordResetPage')),
  },
  {
    exact: true,
    path: '/email-confirm/:uidb64/:token',
    layout: EmptyLayout,
    component: lazy(() => import('pages/root/EmailConfirmPage')),
  },
  {
    exact: true,
    path: '/not-authorized',
    layout: EmptyLayout,
    component: lazy(() => import('pages/root/NotAuthorizedPage')),
  },
  ...checkout,
  ...accounts,
  ...families,
  ...sellers,
  ...institutions,
  ...pages,
  ...e,
  ...l,
  {
    exact: true,
    path: '/p/:productSlug',
    layout: PublicLayout,
    component: lazy(() => import('pages/root/ProductDetailsPage')),
  },
  {
    exact: true,
    path: '/concierge/:conciergeCode',
    layout: PublicLayout,
    component: lazy(() => import('pages/root/SellerConciergePublicPage')),
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('pages/root/NotFoundPage')),
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: () => <Redirect to="/families" />,
      },
      {
        exact: true,
        path: '/review/:orderUid',
        component: lazy(() => import('pages/root/OrderReviewPage')),
      },
      {
        exact: true,
        path: '/delivery/:orderUid/delivered',
        component: lazy(() => import('pages/root/DeliveryPage')),
      },
      {
        exact: true,
        path: '/delivery/:orderUid/not-delivered',
        component: lazy(() => import('pages/root/DeliveryPage')),
      },
      { exact: true, path: '/terms', component: () => <Redirect to="/terms" /> },
      { exact: true, path: '/privacy', component: () => <Redirect to="/privacy" /> },
      { exact: true, path: '/contact-us', component: () => <Redirect to="/contact" /> },
      { component: () => <Redirect to="/404" /> },
    ],
  },
];

const renderRoutes = (routes, permissions) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, index) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          const isProtected =
            !!route.permissionKey &&
            Array.isArray(permissions) &&
            !permissions.includes(route.permissionKey);

          const Component = !isProtected
            ? route.component
            : () => <Redirect to="/not-authorized" />;

          return (
            <Route
              key={route.path || `${index}`}
              path={route.path}
              exact={route.exact}
              strict={route.strict}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes, permissions)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  const { sellerPermissions } = useSeller();

  return renderRoutes(routesConfig, sellerPermissions);
}

export default Routes;
