/**
 * This is a helper to avoid throwing QuotaExceededError on older browsers
 * when using incognito mode.
 *
 * Read about it: https://gist.github.com/philfreo/68ea3cd980d72383c951
 */

import MemoryStorage from 'memorystorage';

export default function configureLocalStorage() {
  try {
    localStorage.setItem('localStorage', 1);
    localStorage.removeItem('localStorage');
  } catch (e) {
    const memorystorage = new MemoryStorage('localStorage');
    Storage.prototype.setItem = memorystorage.setItem.bind(memorystorage);
    Storage.prototype.getItem = memorystorage.getItem.bind(memorystorage);
    Storage.prototype.removeItem = memorystorage.removeItem.bind(memorystorage);
  }
}
