import React from 'react';
import PropTypes from 'prop-types';

import { DisplayData } from '../../common';

function Header(props) {
  const { institution = {} } = props;

  const { logo, displayName } = institution;

  return (
    <div>
      <DisplayData.Picture src={logo} width={90} height={90} elevation={0} alt={displayName} />
    </div>
  );
}

Header.propTypes = {
  institution: PropTypes.object,
};

export default Header;
