import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the permissionProvider state domain
 */

const selectPermissionProviderDomain = state => state.permissionProvider || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by PermissionProvider
 */

const makeSelectPermissionProvider = () =>
  createSelector(selectPermissionProviderDomain, substate => substate);

export default makeSelectPermissionProvider;
export { selectPermissionProviderDomain };
