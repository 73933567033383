/*
 *
 * ContactForm reducer
 *
 */
import produce from 'immer';

import * as constants from './constants';

export const initialState = {
  isFetching: {
    contact: false,
  },
  contact: {},
};

/* eslint-disable default-case, no-param-reassign */
const contactFormReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constants.CONTACT_REQUEST: {
        draft.isFetching.contact = true;
        break;
      }
      case constants.CONTACT_SUCCESS: {
        const { email, response } = action.payload;
        draft.isFetching.contact = false;
        draft.contact[email] = response;
        break;
      }
      case constants.CONTACT_FAILURE: {
        const { email } = action.payload;
        draft.isFetching.contact = false;
        draft.contact[email] = null;
        break;
      }
    }
  });

export default contactFormReducer;
