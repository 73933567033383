import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { Mui, Grid, Typography } from 'components';
import React from 'react';

import messages from './messages';

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  highlight: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
}));

function CovidAlert(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Mui.Container maxWidth="md" className={classes.highlight}>
        <Grid container spacing={2} alignItems="center">
          <Mui.Hidden smDown>
            <Grid item>
              <Mui.Icon>announcement</Mui.Icon>
            </Grid>
          </Mui.Hidden>
          <Grid item xs>
            <Typography variant="h5">
              <FormattedMessage {...messages.highlight} />
            </Typography>
          </Grid>
          <Grid item>
            <Mui.Button
              color="inherit"
              variant="outlined"
              component={RouterLink}
              to="/e/eskolare/loja-da-escola-geral-2020">
              <FormattedMessage {...messages.buyButton} />
            </Mui.Button>
          </Grid>
        </Grid>
      </Mui.Container>
    </div>
  );
}

CovidAlert.propTypes = {};

export default CovidAlert;
