import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import CustomHelmet from '../CustomHelmet';

const Page = forwardRef(({ messages, values, children, ...rest }, ref) => (
  <div ref={ref} {...rest}>
    <CustomHelmet messages={messages} values={values} />
    {children}
  </div>
));

Page.propTypes = {
  children: PropTypes.node,
  messages: PropTypes.object,
  values: PropTypes.object,
};

export default Page;
