/**
 *
 * ThemeProvider
 *
 */

import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import * as ducks from './ducks';
import makeSelectThemeProvider from './selectors';

const stateSelector = createStructuredSelector({
  themeProvider: makeSelectThemeProvider(),
});

export function useTheme() {
  // Hooks
  const dispatch = useDispatch();

  // Store
  const { themeProvider } = useSelector(stateSelector);

  // Actions
  const changeTheme = useCallback(
    institutionSlug => institutionSlug && dispatch(ducks.retrieveRequest({ institutionSlug })),
    [dispatch],
  );
  const resetTheme = useCallback(() => dispatch(ducks.defaultTheme()), [dispatch]);
  const changeType = useCallback(type => dispatch(ducks.changeType({ type })), [dispatch]);

  return {
    changeTheme,
    resetTheme,
    changeType,
    theme: themeProvider,
    homePath: '/',
  };
}

export default useTheme;
