/*
 * LoginPage Messages
 *
 * This contains all the text for the LoginPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.pages.root.LoginPage';

export default defineMessages({
  // index.js
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Entrar | Eskolare',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Entre com seu e-mail e senha ou utilize sua conta do Facebook ou Google para autenticação',
  },
});
