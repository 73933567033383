import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Components
import { Mui } from 'components';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    maxWidth: 400,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2),
  },
  img: {
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));

function Gallery(props) {
  const { steps } = props;

  // Hooks
  const classes = useStyles();

  // States
  const [activeStep, setActiveStep] = useState(0);

  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  if (maxSteps === 0) return null;

  return (
    <div className={classes.root}>
      <Mui.Paper variant="outlined" className={classes.header}>
        <Mui.Typography>{steps[activeStep].label}</Mui.Typography>
      </Mui.Paper>
      <img className={classes.img} src={steps[activeStep].image} alt={steps[activeStep].label} />
      {steps.length > 1 && (
        <Mui.MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Mui.Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              <Mui.Icon>keyboard_arrow_right</Mui.Icon>
            </Mui.Button>
          }
          backButton={
            <Mui.Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              <Mui.Icon>keyboard_arrow_left</Mui.Icon>
            </Mui.Button>
          }
        />
      )}
    </div>
  );
}

Gallery.propTypes = {
  steps: PropTypes.array,
};

export default Gallery;
