/*
 *
 * AnalyticsProvider saga
 *
 */

import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { deviceType, deviceDetect } from 'react-device-detect';

import * as ducks from './ducks';
import envs from '../../../envs';

// API's

const domain = `${envs.PROTOCOL}://${envs.ENTRYPOINT}/api`;

export const endpoints = {
  registerEvent: () => `${domain}/commons/anl/`,
};

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#52627144-934f-406c-86b8-1bce806a7bb7
 */
export function registerEvent({ metric, source, metadata }) {
  const options = {
    method: 'post',
    url: endpoints.registerEvent(),
    data: {
      metric,
      source,
      metadata,
    },
  };
  return axios(options);
}

// Side-effects

export function* registerEventRequestSaga(action) {
  const { metric, source, metadata } = action.payload;

  const { osName, osVersion, browserName, browserMajorVersion, browserFullVersion, userAgent } =
    deviceDetect();

  const newMetada = {
    ...metadata,
    device: {
      os_name: osName,
      os_version: osVersion,
      browser_name: browserName,
      browser_major_version: browserMajorVersion,
      browser_full_version: browserFullVersion,
      type: deviceType,
      user_agent: userAgent,
    },
  };

  try {
    const response = yield registerEvent({ metric, source, metadata: newMetada });

    yield put(ducks.registerEventSuccess({ response: response.data }));
  } catch (error) {
    yield put(ducks.registerEventFailure({ error }));
  }
}

// Individual exports for testing
export default function* analyticsProviderSaga() {
  yield takeLatest(ducks.REGISTER_EVENT_REQUEST, registerEventRequestSaga);
}
