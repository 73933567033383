import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Grid } from '../..';

function Stack(props) {
  const { children, layout, TransitionComponent, timeout, ...otherProps } = props;

  const configureSpacing = index => {
    if (isArray(layout)) {
      if (index <= layout.length - 1) return layout[index];

      return { xs: 12 };
    }
    return layout;
  };

  return (
    <Grid container spacing={2} {...otherProps}>
      {React.Children.map(children, (child, index) => {
        if (child) {
          if (TransitionComponent) {
            return (
              <TransitionComponent key={`${index.toString()}`} in timeout={timeout(index)}>
                <Grid item {...configureSpacing(index)}>
                  {child}
                </Grid>
              </TransitionComponent>
            );
          }

          return (
            <Grid item key={`${index.toString()}`} {...configureSpacing(index)}>
              {child}
            </Grid>
          );
        }

        return null;
      })}
    </Grid>
  );
}

Stack.propTypes = {
  children: PropTypes.node,
  layout: PropTypes.any,
  timeout: PropTypes.func,
  TransitionComponent: PropTypes.any,
};

Stack.defaultProps = {
  layout: { xs: 12 },
  timeout: index => ({ enter: 250 * (index < 5 ? index + 1 : 5), exit: 250 * (index + 1) }),
};

export default memo(Stack);
