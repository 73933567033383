/*
 * EnrollmentSelect Messages
 *
 * This contains all the text for the EnrollmentSelect component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.families.FamilyEnrollmentMenu';

export default defineMessages({
  enrollments: {
    id: `${scope}.enrollments`,
    defaultMessage: 'Lojas disponíveis',
  },
  selectedEnrollment: {
    id: `${scope}.selectedEnrollment`,
    defaultMessage: 'Comprando para {firstName}',
  },
  selectedTitle: {
    id: `${scope}.selectedTitle`,
    defaultMessage: 'Loja selecionada',
  },
  students: {
    id: `${scope}.students`,
    defaultMessage:
      'Estes são os alunos e suas respectivas matrículas disponíveis em sua conta. Precisa cadastrar um novo aluno? <a>Clique aqui</a>.',
  },
  isResponsible: {
    id: `${scope}.isResponsible`,
    defaultMessage: 'Você',
  },
  // Enrollments.js
  addEnrollment: {
    id: `${scope}.addEnrollment`,
    defaultMessage: 'Nova matrícula para {firstName}',
  },
  // ./components/Students.js
  basketLabel: {
    id: `${scope}.basketLabel`,
    defaultMessage: 'Em aberto no carrinho {basket}',
  },
  // ./components/CurrentEnrollment.js
  switchStudent: {
    id: `${scope}.switchStudent`,
    defaultMessage: 'Comprar para outro aluno',
  },
});
