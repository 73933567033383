import moment from 'moment';
import isDate from 'lodash/isDate';
import isString from 'lodash/isString';

const defaultOptions = {
  format: undefined,
  display: {
    date: 'DD/MM/YYYY',
    time: 'HH:mm',
    datetime: 'DD/MM/YYYY HH:mm',
  },
};

export function displayer(inputMoment, options) {
  if (!moment.isMoment(inputMoment))
    return { date: undefined, time: undefined, datetime: undefined };

  return {
    date: inputMoment.format(options.display.date),
    time: inputMoment.format(options.display.time),
    datetime: inputMoment.format(options.display.datetime),
  };
}

function response(input, raw, options) {
  const { format } = options;

  return { input, output: raw.format(format), raw, display: displayer(raw, options) };
}

function datter(input, options = defaultOptions) {
  const inputIsMoment = moment.isMoment(input);
  const inputIsDate = isDate(input);
  const inputIsString = isString(input);

  if (!options) throw Error('Options is mandatory for datter');

  const mergedOptions = { ...defaultOptions, ...options };
  const { format } = mergedOptions;

  if (input === undefined) {
    const raw = moment();

    return response(raw.format(), raw, mergedOptions);
  }

  if (inputIsMoment) {
    const raw = input;

    return response(input, raw, mergedOptions);
  }

  if (inputIsDate) {
    const raw = moment(input);

    return response(input, raw, mergedOptions);
  }

  if (inputIsString) {
    const raw = moment(input, format);

    return response(input, raw, mergedOptions);
  }

  return { input, output: null, raw: null, display: displayer(input, mergedOptions) };
}

export default datter;
