/*
 *
 * CheckoutRegularPage constants
 *
 */

const app = 'app/checkout/CheckoutRegularPage';

export const RETRIEVE_REQUEST = `${app}/RETRIEVE_REQUEST`;
export const RETRIEVE_SUCCESS = `${app}/RETRIEVE_SUCCESS`;
export const RETRIEVE_FAILURE = `${app}/RETRIEVE_FAILURE`;

export const ADD_VOUCHER_REQUEST = `${app}/ADD_VOUCHER_REQUEST`;
export const ADD_VOUCHER_SUCCESS = `${app}/ADD_VOUCHER_SUCCESS`;
export const ADD_VOUCHER_FAILURE = `${app}/ADD_VOUCHER_FAILURE`;

export const REMOVE_VOUCHER_REQUEST = `${app}/REMOVE_VOUCHER_REQUEST`;
export const REMOVE_VOUCHER_SUCCESS = `${app}/REMOVE_VOUCHER_SUCCESS`;
export const REMOVE_VOUCHER_FAILURE = `${app}/REMOVE_VOUCHER_FAILURE`;

export const SUBMIT_REQUEST = `${app}/SUBMIT_REQUEST`;
export const SUBMIT_SUCCESS = `${app}/SUBMIT_SUCCESS`;
export const SUBMIT_FAILURE = `${app}/SUBMIT_FAILURE`;
