import { useUser, useTheme } from 'hooks';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import React from 'react';
import * as images from 'images';

// Containers
import SocialAuthentication from 'containers/common/SocialAuthentication';

// Components
import { Mui, Logo } from 'components';
import { Page } from 'components/layouts';
import { FormAuthentication } from 'components/forms';

// Local Components
import messages from './messages';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: { marginLeft: theme.spacing(2) },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: { padding: theme.spacing(8, 4, 3, 4) },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  media: {
    backgroundSize: 'contain',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
}));

function LoginPage() {
  // Hooks
  const history = useHistory();
  const classes = useStyles();
  const { login, authenticating, refreshing } = useUser();
  const { homePath } = useTheme();

  // Queries
  const [query] = useQueryParams({
    email: withDefault(StringParam, ''),
  });

  // Constants
  const { email } = query;

  return (
    <Page className={classes.root} messages={messages}>
      <Mui.Container maxWidth="md">
        <Mui.Box mb={8} display="flex" alignItems="center">
          <RouterLink to={homePath}>
            <Logo type="circle" />
          </RouterLink>
          <Mui.Button onClick={() => history.goBack()} className={classes.backButton}>
            Voltar
          </Mui.Button>
        </Mui.Box>
        <Mui.Card className={classes.card}>
          <Mui.CardContent className={classes.content}>
            <Mui.Avatar className={classes.icon}>
              <Mui.Icon fontSize="large">lock</Mui.Icon>
            </Mui.Avatar>
            <Mui.Typography variant="h2" color="textPrimary" gutterBottom>
              Entrar
            </Mui.Typography>
            <Mui.Typography variant="subtitle1" color="textSecondary">
              Já possui uma conta em nossa plataforma? Entre utilizando e-mail e senha ou utilize
              suas redes sociais
            </Mui.Typography>
            <Mui.Box mt={3}>
              <FormAuthentication.Login
                SocialAuthentication={<SocialAuthentication />}
                onSubmit={values => login(values)}
                loading={authenticating || refreshing}
                values={{ email }}
              />
            </Mui.Box>
          </Mui.CardContent>
          <Mui.CardMedia
            className={classes.media}
            image={images.heroImageWelcomeIllustration}
            title="Cover"></Mui.CardMedia>
        </Mui.Card>
      </Mui.Container>
    </Page>
  );
}

export default LoginPage;
