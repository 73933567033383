import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
html {
  word-break: inherit;
}

.whats-me-container .call-open-container {
  bottom: 64px !important;
}

#launcher {
  visibility: hidden;
}

.img-responsive {
  width: 100%;
  height: 20%;
}

.img-responsive img {
  height: 100%;
  width: 100%;
}

.google-visualization-tooltip {
  pointer-events: none;
}

@media all and (min-width: 0) and (max-width: 600px) {
  .img-desktop, .img-tablet {
    display: none;
  }
}

@media all and (min-width: 601px) and (max-width: 840px) {
  .img-desktop, .img-mobile {
    display: none;
  }
}

@media all and (min-width: 841px) and (max-width: 1920px) {
  .img-tablet, .img-mobile {
    display: none;
  }
}
`;

export default GlobalStyle;
