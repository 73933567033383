import { Redirect } from 'react-router-dom';
import React, { lazy } from 'react';

// Layouts
import PublicLayout from 'layouts/PublicLayout';

export default [
  {
    path: '/pages',
    layout: PublicLayout,
    routes: [
      {
        exact: true,
        path: '/pages/:pageSlug',
        component: lazy(() => import('pages/pages/CmsDetailsPage')),
      },
      {
        component: () => <Redirect push to="/404" />,
      },
    ],
  },
];
