import PropTypes from 'prop-types';
import React from 'react';

import { Mui } from '../..';
import Base from './Base';

import { ORDER_STATUS } from '../DisplayStatus/Order';
import * as DisplayData from '../DisplayData';

function Order(props) {
  const { changes, ...otherProps } = props;

  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  const STATUS_TYPES = {
    payment_approved: 'payment-approved',
    payment_denied: 'payment-denied',
    payment_pending: 'payment-pending',
    handling: 'handling',
    invoice: 'invoice',
    invoiced: 'invoiced',
    cancellation_requested: 'cancellation-requested',
    cancel: 'cancel',
  };

  const steps = changes.map(change => ({
    color: ORDER_STATUS[change.status].color,
    icon: <Mui.Icon>{ORDER_STATUS[change.status].icon}</Mui.Icon>,
    status: change.status,
    label: change.statusDisplay,
    optional: !!change.dateCreated,
    optionalLabel: <DisplayData.DateField type="datetime" value={change.dateCreated} />,
  }));

  /**
   * The usual workflow is mounted depending
   * of the last step in changes
   */
  if (steps[steps.length - 1]?.status === STATUS_TYPES.payment_denied) {
    steps.push(ORDER_STATUS[STATUS_TYPES.payment_approved]);
  }
  if (steps[steps.length - 1]?.status === STATUS_TYPES.payment_pending) {
    steps.push(ORDER_STATUS[STATUS_TYPES.payment_approved]);
  }
  if (steps[steps.length - 1]?.status === STATUS_TYPES.payment_approved) {
    steps.push(ORDER_STATUS.handling);
  }
  if (steps[steps.length - 1]?.status === STATUS_TYPES.handling) {
    steps.push(ORDER_STATUS.invoice);
  }
  if (steps[steps.length - 1]?.status === STATUS_TYPES.invoice) {
    steps.push(ORDER_STATUS.invoiced);
  }
  if (steps[steps.length - 1]?.status === STATUS_TYPES.invoiced) {
    steps.push(ORDER_STATUS.delivered);
  }
  if (steps[steps.length - 1]?.status === STATUS_TYPES.cancellation_requested) {
    steps.push(ORDER_STATUS.cancel);
  }
  if (steps[steps.length - 1]?.status === STATUS_TYPES.cancel) {
    steps.push(ORDER_STATUS.canceled);
  }

  return (
    <Base
      steps={steps}
      alternativeLabel={!fullScreen}
      orientation={fullScreen ? 'vertical' : 'horizontal'}
      activeStep={changes.length - 1}
      {...otherProps}
    />
  );
}

Order.propTypes = {
  changes: PropTypes.array.isRequired,
};

export default Order;
