import { useInView } from 'react-intersection-observer';
import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Components
import { Grid, Mui, SwipeableViews } from 'components';
import { LoadingScreen } from 'components/layouts';

export const useStyles = Mui.makeStyles(theme => ({
  root: {},
  avatar: {
    height: 90,
    width: 90,
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    transition: theme.transitions.create('box-shadow'),
    [theme.breakpoints.down('sm')]: {
      height: 60,
      width: 60,
    },
  },
  logo: {
    padding: theme.spacing(1),
  },
}));

function CustomerExhibition(props) {
  const { items = [] } = props;

  // Hooks
  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));
  const [inViewRef, inView] = useInView();
  const classes = useStyles();

  // States
  const [index, setIndex] = useState(0);

  // Constants
  const chunks = chunk(items, fullScreen ? 4 : 5);

  if (items.length === 0) return <LoadingScreen mt={2} mb={2} />;

  return (
    <Mui.Grow in={inView} ref={inViewRef}>
      <div>
        <SwipeableViews
          autoplay
          interval={3000}
          enableMouseEvents
          index={index}
          onChangeIndex={newIndex => setIndex(newIndex)}>
          {chunks.map((item, c) => (
            <Mui.Container maxWidth={false} key={`${c.toString()}`}>
              <Grid container justifyContent="space-between" alignItems="center">
                {!fullScreen && (
                  <Grid item>
                    <Mui.IconButton disabled={index === 0} onClick={() => setIndex(index - 1)}>
                      <span>
                        <Mui.Icon>chevron_left</Mui.Icon>
                      </span>
                    </Mui.IconButton>
                  </Grid>
                )}
                {item.map(({ logo, displayName }, i) => (
                  <Grid item key={displayName} className={classes.logo}>
                    <Mui.Fade in timeout={i * 300}>
                      <Mui.Tooltip title={displayName}>
                        <Mui.Avatar src={logo} alt={displayName} className={classes.avatar} />
                      </Mui.Tooltip>
                    </Mui.Fade>
                  </Grid>
                ))}
                {!fullScreen && (
                  <Grid item>
                    <Mui.IconButton
                      disabled={index === chunks.length - 1}
                      onClick={() => setIndex(index + 1)}>
                      <span>
                        <Mui.Icon>chevron_right</Mui.Icon>
                      </span>
                    </Mui.IconButton>
                  </Grid>
                )}
              </Grid>
            </Mui.Container>
          ))}
        </SwipeableViews>
      </div>
    </Mui.Grow>
  );
}

CustomerExhibition.propTypes = {
  items: PropTypes.array,
};

export default CustomerExhibition;
