import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui, Button } from 'components';

import messages from './messages';

export const useStyles = Mui.makeStyles(theme => ({
  icon: {
    color: props => props.severity && theme.palette[props.severity].main,
    fontSize: '48px !important',
  },
}));

function Alert(props) {
  const {
    children,
    onClose,
    onConfirm,
    confirmButton,
    open,
    severity = 'warning',
    title,
    icon,
    loading,
    disabled,
    ...restProps
  } = props;

  const classes = useStyles(props);

  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('xs'));

  if (!severity) return null;

  const ICONS = {
    warning: 'warning',
    info: 'info',
    success: 'check_circle',
    error: 'error',
  };

  const TITLE = {
    warning: <FormattedMessage {...messages.warningTitle} />,
    info: <FormattedMessage {...messages.infoTitle} />,
    success: <FormattedMessage {...messages.successTitle} />,
    error: <FormattedMessage {...messages.errorTitle} />,
  };

  return (
    <Mui.Dialog
      transitionDuration={{ enter: 250, exit: 150 }}
      TransitionComponent={Mui.Grow}
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      {...restProps}>
      <Mui.CardHeader
        avatar={
          <Mui.Icon color="inherit" fontSize="inherit" className={classes.icon}>
            {icon || ICONS[severity]}
          </Mui.Icon>
        }
        action={
          onClose && (
            <Mui.IconButton aria-label="close" onClick={onClose}>
              <Mui.Icon>close</Mui.Icon>
            </Mui.IconButton>
          )
        }
        title={title || TITLE[severity]}
        titleTypographyProps={{ variant: 'h5', noWrap: true }}
      />
      <Mui.DialogContent>
        <Mui.DialogContentText variant="body1" component="div">
          {children}
        </Mui.DialogContentText>
      </Mui.DialogContent>
      <Mui.DialogActions>
        {onConfirm && (
          <Button
            onClick={onConfirm}
            color="primary"
            size="large"
            loading={loading}
            disabled={disabled}>
            {confirmButton || <FormattedMessage {...messages.confirmButton} />}
          </Button>
        )}
      </Mui.DialogActions>
    </Mui.Dialog>
  );
}

Alert.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.any,
  onConfirm: PropTypes.func,
  confirmButton: PropTypes.any,
  open: PropTypes.any,
  severity: PropTypes.any,
  title: PropTypes.any,
  icon: PropTypes.any,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Alert;
