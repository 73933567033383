import { Redirect } from 'react-router-dom';
import React, { lazy } from 'react';

// Layouts
import PublicLayout from 'layouts/PublicLayout';
import EmptyLayout from 'layouts/EmptyLayout';

export default [
  {
    exact: true,
    path: '/e/:institutionSlug/invite/:inviteCode',
    layout: EmptyLayout,
    component: lazy(() => import('pages/e/InvitePage')),
  },
  {
    path: '/e',
    layout: PublicLayout,
    routes: [
      {
        exact: true,
        path: '/e/:institutionSlug/:showcaseSlug/:collectionSlug/p/:productSlug',
        component: lazy(() => import('pages/e/ProductDetailsPage')),
      },
      {
        exact: true,
        path: '/e/:institutionSlug/:showcaseSlug/:collectionSlug/i/:itemUid',
        component: lazy(() => import('pages/e/ItemDetailsPage')),
      },
      {
        exact: true,
        path: '/e/:institutionSlug/:showcaseSlug/:collectionSlug',
        component: lazy(() => import('pages/e/CollectionDetailsPage')),
      },
      {
        exact: true,
        path: '/e/:institutionSlug/:showcaseSlug',
        component: lazy(() => import('pages/e/ShowcaseRedirectPage')),
      },
      {
        exact: true,
        path: '/e/:institutionSlug',
        component: lazy(() => import('pages/e/InstitutionPublicPage')),
      },
      {
        component: () => <Redirect push to="/404" />,
      },
    ],
  },
];
