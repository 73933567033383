import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';

import * as images from 'images';

const useStyles = makeStyles(theme => ({
  avatar: { height: 48, width: 48, marginRight: theme.spacing(1) },
  popover: { width: 200 },
}));

function Account({ user }) {
  // Hooks
  const classes = useStyles();
  const ref = useRef(null);

  // Constants
  const items = [
    { key: 'profile', label: 'Sua conta', to: '/accounts' },
    { key: 'orders', label: 'Pedidos', to: '/accounts/orders' },
    { key: 'logout', label: 'Sair', to: '/logout' },
  ];

  // States
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={() => setOpen(true)}
        ref={ref}>
        <Avatar
          alt={user.firstName}
          className={classes.avatar}
          src={user.picture || images.facesOtherFace}
        />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {`${user.firstName} ${user.lastName}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={open}>
        {items.map(item => (
          <MenuItem key={item.key} component={item.to ? RouterLink : 'a'} to={item.to}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

Account.propTypes = {
  user: PropTypes.object,
};

export default Account;
