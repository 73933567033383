import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';

import makeSelectPermissionProvider from './selectors';

const stateSelector = createStructuredSelector({
  permissionProvider: makeSelectPermissionProvider(),
});

function usePermissions() {
  // Store
  const { permissionProvider } = useSelector(stateSelector);

  return { permissions: permissionProvider };
}

export default usePermissions;
