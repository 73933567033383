/**
 *
 * Styles for ProgressWrapper
 *
 *
 */

import { Mui } from '../..';

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    minHeight: ({ minHeight, loading }) => (loading && minHeight) || '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: theme.shape.borderRadius,
  },
}));
