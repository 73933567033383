export function getStudentData({ student = {}, enrollmentsByStudent = {} }) {
  const { uid: studentUid } = student;
  const enrollments = enrollmentsByStudent[studentUid];

  return { studentUid, enrollments };
}

export function generateUrl({ enrollment, collection, product }) {
  const { slug: enrollmentSlug } = enrollment || {};
  const { slug: collectionSlug } = collection || {};
  const { slug: productSlug, parent } = product || {};
  const { slug: parentSlug } = parent || {};

  if (collectionSlug && parentSlug)
    return [
      '/families',
      enrollmentSlug,
      collectionSlug,
      'p',
      `${parentSlug}?o=${productSlug}`,
    ].join('/');

  if (collectionSlug && !parentSlug)
    return ['/families', enrollmentSlug, collectionSlug, 'p', productSlug].join('/');

  if (parentSlug) return ['/p', `${parentSlug}?o=${productSlug}`].join('/');

  return ['/p', productSlug].join('/');
}

export function getEnrollmentData({
  enrollment = {},
  linesByEnrollment = {},
  institutionsByUid = {},
  showcasesByUid = {},
}) {
  const {
    uid: enrollmentUid,
    institution: institutionUid,
    showcase: showcaseUid,
  } = enrollment || {};
  const lines = linesByEnrollment[enrollmentUid];
  const institution = institutionsByUid[institutionUid];
  const showcase = showcasesByUid[showcaseUid];

  return { enrollmentUid, lines, institution, showcase };
}

export function getLineData({
  line = {},
  productsByUid = {},
  sellersByUid = {},
  enrollment = {},
  institution = {},
  showcase = {},
  collectionsByUid = {},
}) {
  const { uid: lineUid, product: productUid, collection: collectionUid } = line || {};
  const product = productsByUid[productUid];
  const { seller: sellerUid } = product || {};
  const seller = sellersByUid[sellerUid];
  const collection = collectionsByUid[collectionUid];

  const selectedEnrollment = {
    ...enrollment,
    institution,
    showcase,
  };

  const newProduct = {
    ...product,
    seller,
  };

  const url = generateUrl({ enrollment: selectedEnrollment, product, collection });

  return { lineUid, selectedEnrollment, product: newProduct, collection, url };
}

export function temporaryBasket() {
  try {
    return JSON.parse(localStorage?.getItem('temporaryBasket'));
  } catch {
    return {};
  }
}
