export default function useStorage(key, init) {
  const rawState = localStorage.getItem(key);

  if (!rawState) localStorage.setItem(key, JSON.stringify(init));

  const setStorage = payload => {
    const jsonState = JSON.parse(rawState);

    localStorage.setItem(key, JSON.stringify({ ...jsonState, ...payload }));

    return null;
  };

  const jsonState = !rawState ? {} : JSON.parse(rawState);

  return [jsonState, setStorage];
}
