/*
 * CovidAlert Messages
 *
 * This contains all the text for the CovidAlert component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.landing.CovidAlert';

export default defineMessages({
  highlight: {
    id: `${scope}.highlight`,
    defaultMessage: 'Encontre soluções para a segurança da sua instituição no volta às aulas',
  },
  buyButton: {
    id: `${scope}.buyButton`,
    defaultMessage: 'Comprar',
  },
});
