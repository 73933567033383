/*
 *
 * CommonJobNotification reducer
 *
 */
import produce from 'immer';

import * as constants from './constants';

export const initialState = {
  isFetching: {
    retrieve: {},
    list: false,
  },
  retrieve: {},
  list: {
    count: 0,
    results: [],
  },
  jobs: [],
  callback: null,
};

/* eslint-disable default-case, no-param-reassign */
const commonJobNotificationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constants.LIST_REQUEST: {
        draft.isFetching.list = true;
        break;
      }
      case constants.LIST_SUCCESS: {
        const { response } = action.payload;
        draft.isFetching.list = false;
        draft.list = response;
        break;
      }
      case constants.LIST_FAILURE: {
        draft.isFetching.list = false;
        draft.retrieve = {};
        break;
      }
      case constants.RETRIEVE_REQUEST: {
        const { jobUid } = action.payload;
        draft.isFetching.retrieve[jobUid] = true;
        break;
      }
      case constants.RETRIEVE_SUCCESS: {
        const { jobUid, response } = action.payload;
        draft.isFetching.retrieve[jobUid] = false;
        draft.retrieve[jobUid] = response;
        break;
      }
      case constants.RETRIEVE_FAILURE: {
        const { jobUid } = action.payload;
        draft.isFetching.retrieve[jobUid] = false;
        draft.retrieve[jobUid] = null;
        break;
      }
      case constants.ADD_JOB: {
        const { jobUid, callback } = action.payload;
        draft.callback = callback || null;
        draft.jobs = [...state.jobs, jobUid];
        break;
      }
      case constants.REMOVE_JOB: {
        const { jobUid } = action.payload;
        draft.callback = null;
        draft.jobs = state.jobs.filter(job => job !== jobUid);
        break;
      }
    }
  });

export default commonJobNotificationReducer;
