import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Mui } from 'components';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    '& p': {
      ...theme.typography.body1,
      marginBottom: theme.spacing(1),
    },
    '& ol': {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    '& ul': {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    '& li': theme.typography.body1,
    '& h1': theme.typography.h1,
    '& h2': theme.typography.h2,
    '& h3': theme.typography.h3,
    '& h4': theme.typography.h4,
    '& h5': theme.typography.h5,
    '& h6': theme.typography.h6,
    '& blockquote': {
      ...theme.typography.body1,
      borderLeft: `3px solid #ccc`,
      paddingLeft: theme.spacing(1),
    },
  },
}));

function WysiwygViewer(props) {
  const { value } = props;

  // Hooks
  const classes = useStyles();

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: value }} className={classes.root}></div>;
}

WysiwygViewer.propTypes = {
  value: PropTypes.any,
};

export default WysiwygViewer;
