import axios from 'axios';
import envs from 'envs';

import sessionToken from './sessionToken';

const api = axios.create({
  baseURL: `${envs.PROTOCOL}://${envs.ENTRYPOINT}/api`,
});

api.interceptors.request.use(
  config => {
    const { token, expiry } = sessionToken();
    const { Authorization } = config.headers;

    if (expiry) {
      const expiryDate = new Date(expiry);
      const nowDate = new Date();
      const isExpired = nowDate.getTime() > expiryDate.getTime();

      if (isExpired) {
        sessionToken({ destroy: true });

        return config;
      }
    }

    if (token && !Authorization) {
      config.headers.Authorization = `Token ${token}`; //eslint-disable-line
    }

    return config;
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401 && error.response?.data?.detail === 'Token inválido.') {
      sessionToken({ destroy: true });
    }
    return Promise.reject(error);
  },
);

export default api;
