/**
 *
 * UserProvider
 *
 */

import { sessionToken } from 'utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';

// Components
import { SplashScreen } from 'components/layouts';

import reducer from './ducks';
import saga from './saga';
import useUser from './useUser';

export function UserProvider(props) {
  useInjectReducer({ key: 'userProvider', reducer });
  useInjectSaga({ key: 'userProvider', saga });

  const { children } = props;

  // Hooks
  const history = useHistory();
  const { pathname } = useLocation();

  // Custom hooks
  const { isAuthenticated, refresh } = useUser();
  const { token } = sessionToken();

  // States
  const [sessionVerified, setSessionVerified] = useState(false);

  const callback = useCallback(
    (onSuccess, onError) => {
      if (onError) history.push(`/login?redirect=${pathname}`);

      setTimeout(() => setSessionVerified(true), 250);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history],
  );

  useEffect(() => {
    if (!sessionVerified) {
      if (isAuthenticated) {
        refresh({ callback });
      } else {
        setSessionVerified(true);
      }
    }
  }, [isAuthenticated, sessionVerified, refresh, callback]);

  useEffect(() => {
    if (isAuthenticated && !token) {
      refresh({ callback });
    }
  }, [isAuthenticated, token, refresh, callback]);

  if (sessionVerified) return children;

  return <SplashScreen />;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
export { useUser };
