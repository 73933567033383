import { Mui } from '../..';

export const useStyles = Mui.makeStyles(theme => ({
  logo: {
    height: 100,
  },
  payments: {
    height: 40,
    width: 40,
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
  },
  link: {
    cursor: 'pointer',
  },
}));
