import React from 'react';

import { Mui } from '../..';

export const PersonalInfo = props => <Mui.Icon {...props}>person_outlined</Mui.Icon>;
export const Shipping = props => <Mui.Icon {...props}>local_shipping_outlined</Mui.Icon>;
export const Freight = props => <Mui.Icon {...props}>departure_board_outlined</Mui.Icon>;
export const Voucher = props => <Mui.Icon {...props}>loyalty</Mui.Icon>;
export const Payment = props => <Mui.Icon {...props}>credit_card</Mui.Icon>;
export const Verify = props => <Mui.Icon {...props}>check_circle_outlined</Mui.Icon>;
export const BankAccount = props => <Mui.Icon {...props}>account_balance_outlined</Mui.Icon>;
