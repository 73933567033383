/**
 *
 * UserProvider ducks
 * Read more about this pattern: https://blog.rocketseat.com.br/estrutura-redux-escalavel-com-ducks/
 *
 */

import produce from 'immer';

// Constants

const app = 'app/common/UserProvider';

export const RETRIEVE_REQUEST = `${app}/RETRIEVE_REQUEST`;
export const RETRIEVE_SUCCESS = `${app}/RETRIEVE_SUCCESS`;
export const RETRIEVE_FAILURE = `${app}/RETRIEVE_FAILURE`;

export const LOGIN_REQUEST = `${app}/LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${app}/LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${app}/LOGIN_FAILURE`;

export const PASSWORD_RESET_REQUEST = `${app}/PASSWORD_RESET_REQUEST`;
export const PASSWORD_RESET_SUCCESS = `${app}/PASSWORD_RESET_SUCCESS`;
export const PASSWORD_RESET_FAILURE = `${app}/PASSWORD_RESET_FAILURE`;

export const SIGN_UP_REQUEST = `${app}/SIGN_UP_REQUEST`;
export const SIGN_UP_SUCCESS = `${app}/SIGN_UP_SUCCESS`;
export const SIGN_UP_FAILURE = `${app}/SIGN_UP_FAILURE`;

export const UPDATE_PASSWORD_REQUEST = `${app}/UPDATE_PASSWORD_REQUEST`;
export const UPDATE_PASSWORD_SUCCESS = `${app}/UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_FAILURE = `${app}/UPDATE_PASSWORD_FAILURE`;

export const PASSWORD_CONFIRM_REQUEST = `${app}/PASSWORD_CONFIRM_REQUEST`;
export const PASSWORD_CONFIRM_SUCCESS = `${app}/PASSWORD_CONFIRM_SUCCESS`;
export const PASSWORD_CONFIRM_FAILURE = `${app}/PASSWORD_CONFIRM_FAILURE`;

// Actions

export function loginRequest(payload) {
  return { type: LOGIN_REQUEST, payload };
}

export function loginSuccess(payload) {
  return { type: LOGIN_SUCCESS, payload };
}

export function loginFailure(payload) {
  return { type: LOGIN_FAILURE, payload };
}

export function retrieveRequest(payload) {
  return { type: RETRIEVE_REQUEST, payload };
}

export function retrieveSuccess(payload) {
  return { type: RETRIEVE_SUCCESS, payload };
}

export function retrieveFailure(payload) {
  return { type: RETRIEVE_FAILURE, payload };
}

export function passwordResetRequest(payload) {
  return { type: PASSWORD_RESET_REQUEST, payload };
}

export function passwordResetSuccess(payload) {
  return { type: PASSWORD_RESET_SUCCESS, payload };
}

export function passwordResetFailure(payload) {
  return { type: PASSWORD_RESET_FAILURE, payload };
}

export function signUpRequest(payload) {
  return { type: SIGN_UP_REQUEST, payload };
}

export function signUpSuccess(payload) {
  return { type: SIGN_UP_SUCCESS, payload };
}

export function signUpFailure(payload) {
  return { type: SIGN_UP_FAILURE, payload };
}

export function updatePasswordRequest(payload) {
  return { type: UPDATE_PASSWORD_REQUEST, payload };
}

export function updatePasswordSuccess(payload) {
  return { type: UPDATE_PASSWORD_SUCCESS, payload };
}

export function updatePasswordFailure(payload) {
  return { type: UPDATE_PASSWORD_FAILURE, payload };
}

export function passwordConfirmRequest(payload) {
  return { type: PASSWORD_CONFIRM_REQUEST, payload };
}

export function passwordConfirmSuccess(payload) {
  return { type: PASSWORD_CONFIRM_SUCCESS, payload };
}

export function passwordConfirmFailure(payload) {
  return { type: PASSWORD_CONFIRM_FAILURE, payload };
}

// Reducer

export const initialState = {
  isAuthenticated: false,
  isFetching: {
    login: false,
    retrieve: false,
    passwordReset: false,
    signUp: false,
    updatePassword: false,
    passwordConfirm: false,
  },
  user: {
    email: '',
    firstName: '',
    lastName: '',
    birthdate: null,
    isStaff: false,
    isInstitution: false,
    isSeller: false,
    isActive: false,
    dateJoined: '1988-03-03T21:00:00.000000Z',
    lastLogin: null,
    emails: [],
    addresses: [],
    lastSession: {},
    basketIsEmpty: true,
  },
};

/* eslint-disable default-case, no-param-reassign */
const userProviderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOGIN_REQUEST: {
        const { email } = action.payload;
        draft.isFetching.login = true;
        draft.user.email = email;
        break;
      }
      case LOGIN_SUCCESS: {
        const { response } = action.payload;
        const { user } = response;
        draft.isFetching.login = false;
        draft.user = user;
        break;
      }
      case LOGIN_FAILURE: {
        draft.isFetching.login = false;
        break;
      }
      case RETRIEVE_REQUEST: {
        draft.isFetching.retrieve = true;
        break;
      }
      case RETRIEVE_SUCCESS: {
        const { response } = action.payload;

        draft.isFetching.retrieve = false;
        draft.isAuthenticated = true;
        draft.user = response;

        break;
      }
      case RETRIEVE_FAILURE: {
        draft.user = initialState.user;
        draft.isAuthenticated = false;
        draft.isFetching.retrieve = false;
        break;
      }
      case PASSWORD_RESET_REQUEST: {
        draft.isFetching.passwordReset = true;
        break;
      }
      case PASSWORD_RESET_SUCCESS: {
        draft.isFetching.passwordReset = false;
        draft.component = 'login';
        break;
      }
      case PASSWORD_RESET_FAILURE: {
        draft.isFetching.passwordReset = false;
        break;
      }
      case SIGN_UP_REQUEST: {
        const { email } = action.payload;
        draft.isFetching.signUp = true;
        draft.user.email = email;
        break;
      }
      case SIGN_UP_SUCCESS: {
        draft.isFetching.signUp = false;
        break;
      }
      case SIGN_UP_FAILURE: {
        draft.isFetching.signUp = false;
        break;
      }
      case UPDATE_PASSWORD_REQUEST: {
        draft.isFetching.updatePassword = true;
        break;
      }
      case UPDATE_PASSWORD_SUCCESS: {
        draft.isFetching.updatePassword = false;
        break;
      }
      case UPDATE_PASSWORD_FAILURE: {
        draft.isFetching.updatePassword = false;
        break;
      }
      case PASSWORD_CONFIRM_REQUEST:
        draft.isFetching.passwordConfirm = true;
        break;
      case PASSWORD_CONFIRM_SUCCESS:
        draft.isFetching.passwordConfirm = false;
        break;
      case PASSWORD_CONFIRM_FAILURE:
        draft.isFetching.passwordConfirm = false;
        break;
    }
  });

export default userProviderReducer;
