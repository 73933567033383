import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import * as React from 'react';
import PropTypes from 'prop-types';

import { Grid, Mui } from '../..';
import { useStyles } from './styles';
import messages from './messages';

const AdapterLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

export const CustomLink = props => {
  const classes = useStyles(props);

  const re = new RegExp('^(http|https)://', 'i');
  const { title, onClick, href } = props;

  if (onClick) {
    return (
      <Mui.Link onClick={onClick} variant="body1" color="textPrimary" className={classes.link}>
        {title}
      </Mui.Link>
    );
  }

  if (re.test(href)) {
    return (
      <Mui.Link
        href={href}
        variant="body1"
        target="_blank"
        rel="noreferrer"
        color="textPrimary"
        className={classes.link}>
        {title}
      </Mui.Link>
    );
  }

  return (
    <Mui.Link
      component={AdapterLink}
      to={props.href}
      disabled={!props.href}
      variant="body1"
      color="textPrimary">
      {title}
    </Mui.Link>
  );
};

CustomLink.propTypes = {
  title: PropTypes.any,
  onClick: PropTypes.func,
  href: PropTypes.any,
};

function Section() {
  const sections = [
    {
      title: <FormattedMessage {...messages.footerSection1Title} />,
      items: [
        {
          title: <FormattedMessage {...messages.footerSection1Item1} />,
          href: '',
        },
        {
          title: <FormattedMessage {...messages.footerSection1Item2} />,
          href: '',
        },
        {
          title: <FormattedMessage {...messages.footerSection1Item3} />,
          href: '',
        },
        {
          title: <FormattedMessage {...messages.footerSection1Item4} />,
          href: '',
        },
      ],
    },
    {
      title: <FormattedMessage {...messages.footerSection2Title} />,
      items: [
        {
          title: <FormattedMessage {...messages.footerSection2Item1} />,
          href: '/families',
        },
        {
          title: <FormattedMessage {...messages.footerSection2Item2} />,
          href: '/institutions',
        },
        {
          title: <FormattedMessage {...messages.footerSection2Item3} />,
          href: '/sellers',
        },
      ],
    },
    {
      title: <FormattedMessage {...messages.footerSection3Title} />,
      items: [
        {
          title: <FormattedMessage {...messages.footerSection3Item1} />,
          href: '',
        },
        {
          title: <FormattedMessage {...messages.footerSection3Item2} />,
          href: '',
        },
        {
          title: <FormattedMessage {...messages.footerSection3Item3} />,
          href: 'https://www.eskolare.com/pages/cancellation-policy',
        },
        {
          title: <FormattedMessage {...messages.footerSection3Item4} />,
          href: 'https://www.eskolare.com/pages/return-policy',
        },
        {
          title: <FormattedMessage {...messages.footerSection3Item5} />,
          href: 'https://www.eskolare.com/pages/delivery-policy',
        },
      ],
    },
    {
      title: <FormattedMessage {...messages.footerSection4Title} />,
      items: [
        {
          title: <FormattedMessage {...messages.footerSection4Item1} />,
          href: 'https://documenter.getpostman.com/view/6419581/SW17TbKd?version=latest',
        },
        {
          title: <FormattedMessage {...messages.footerSection4Item2} />,
          href: '',
        },
        {
          title: <FormattedMessage {...messages.footerSection4Item3} />,
          href: 'https://status.eskolare.com/',
        },
      ],
    },
  ];

  return (
    <Grid container spacing={2}>
      {sections.map((section, i) => (
        <Grid item key={`${i.toString()}`} sm={3} xs={6}>
          <Mui.Typography variant="overline" color="textSecondary">
            {section.title}
          </Mui.Typography>
          {section.items.map((item, index) => (
            <div key={`${index.toString()}`}>
              <CustomLink title={item.title} href={item.href} />
            </div>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}

export default Section;
