import { Redirect } from 'react-router-dom';
import { sessionToken } from 'utils';
import { useUser, usePermissions } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';

// Pages
import LoginPage from 'pages/root/LoginPage';

function InstitutionGuard(props) {
  const { children } = props;

  // Custom hooks
  const { isAuthenticated } = useUser();
  const { permissions } = usePermissions();

  // Constants
  const { isInstitution, isStaff } = permissions;
  const { token } = sessionToken();

  if (!isAuthenticated && !token) return <LoginPage />;

  if (!isStaff && !isInstitution) return <Redirect to="/not-authorized" />;

  return children;
}

InstitutionGuard.propTypes = {
  children: PropTypes.any,
};

export default InstitutionGuard;
