import React, { Suspense, lazy } from 'react';

import Skeleton from './Skeleton';
import ErrorBoundary from './ErrorBoundary';
import retry from './retry';

const loadable = (importFunc, options = {}) => {
  const LazyComponent = lazy(() => retry(importFunc));

  const { type, fallback } = options;

  return props => (
    <Suspense fallback={fallback || <Skeleton type={type} />}>
      <ErrorBoundary>
        <LazyComponent {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

export default loadable;
