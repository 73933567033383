/*
 * Hero Messages
 *
 * This contains all the text for the Hero component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.landing.Hero';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Encontre {loop} em um único lugar',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage:
      'Tornamos a experiência de compra de produtos e serviços educacionais rápida e prática. Tudo em um único lugar!',
  },
  families: {
    id: `${scope}.families`,
    defaultMessage: 'Famílias',
  },
  institutions: {
    id: `${scope}.institutions`,
    defaultMessage: 'Instituições',
  },
  sellers: {
    id: `${scope}.sellers`,
    defaultMessage: 'Fornecedores',
  },
  products: {
    id: `${scope}.products`,
    defaultMessage: 'Produtos e serviços',
  },
});
