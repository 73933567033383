/**
 *
 * CommonJobNotification
 *
 */
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import React, { useEffect, useRef, useState } from 'react';

import { Badge, Mui } from '../../../components';
import { DisplayStatus, Dialogs } from '../../../components/common';
import { Drawer, ProgressWrapper } from '../../../components/layouts';

import * as actions from './actions';
import makeSelectCommonJobNotification from './selectors';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const stateSelector = createStructuredSelector({
  commonJobNotification: makeSelectCommonJobNotification(),
});

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function CommonJobNotification() {
  useInjectReducer({ key: 'commonJobNotification', reducer });
  useInjectSaga({ key: 'commonJobNotification', saga });

  // Hooks
  const dispatch = useDispatch();

  // Store
  const { commonJobNotification } = useSelector(stateSelector);

  // States
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState('');

  // Queries
  const [query, setQuery] = useState({
    page: 1,
    page_size: 10,
    ordering: '-date_created',
  });
  const { page, page_size: pageSize } = query;

  // Constants
  const { jobs, isFetching, list, retrieve, callback } = commonJobNotification;
  const { results = [], count = 0 } = list;
  const { length } = jobs;

  // Actions
  const handleJobDetails = jobUid => {
    dispatch(actions.retrieveRequest({ jobUid }));
    setSelectedJob(jobUid);
  };

  // Effects
  useEffect(() => {
    if (open) dispatch(actions.listRequest({ query }));
  }, [dispatch, query, open]);

  useEffect(() => {
    if (length === 0) {
      setActive(false);
    } else {
      setActive(true);
    }
  }, [length, active]);

  useInterval(
    () =>
      jobs.forEach(jobUid => {
        if (!isFetching.retrieve[jobUid])
          dispatch(
            actions.retrieveRequest({ jobUid, notify: true, ...(callback && { callback }) }),
          );
      }),
    active ? 10000 : null,
  );

  return (
    <>
      <Mui.IconButton onClick={() => setOpen(true)}>
        <Badge badgeContent={jobs.length} color="primary">
          <Mui.Icon color={jobs.length > 0 ? 'primary' : 'inherit'}>update</Mui.Icon>
        </Badge>
      </Mui.IconButton>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        title={<FormattedMessage {...messages.title} />}
        description={<FormattedMessage {...messages.description} />}>
        <Mui.Container>
          <ProgressWrapper loading={isFetching.list}>
            <Mui.Paper variant="outlined">
              <Mui.List dense>
                {results.length === 0 && (
                  <Mui.ListItem>
                    <Mui.ListItemText primary={<FormattedMessage {...messages.emptyMessage} />} />
                  </Mui.ListItem>
                )}
                {results.map((job, index, array) => (
                  <Mui.ListItem key={job.uid} divider={index < array.length - 1}>
                    <Mui.ListItemAvatar>
                      <DisplayStatus.Job
                        status={job.status}
                        statusDisplay={job.statusDisplay}
                        onlyIcon
                        variant="filled"
                      />
                    </Mui.ListItemAvatar>
                    <Mui.ListItemText
                      primary={job.typeDisplay}
                      secondary={
                        <>
                          {job.taskDisplay && job.actionDisplay && (
                            <Mui.Typography variant="body2">
                              {job.taskDisplay} - {job.actionDisplay}
                            </Mui.Typography>
                          )}
                          <Mui.Typography variant="body2">
                            <em>{job.dateCompleted}</em>
                          </Mui.Typography>
                        </>
                      }
                    />
                    <Mui.ListItemSecondaryAction>
                      {job.status === 'errored' && (
                        <Mui.Tooltip title={<FormattedMessage {...messages.viewMore} />}>
                          <Mui.IconButton onClick={() => handleJobDetails(job.uid)}>
                            <Mui.Icon>visibility</Mui.Icon>
                          </Mui.IconButton>
                        </Mui.Tooltip>
                      )}
                      {job.inputFile && (
                        <Mui.Tooltip title={<FormattedMessage {...messages.inputFileTooltip} />}>
                          <Mui.IconButton component="a" href={job.inputFile} target="_blank">
                            <Mui.Icon>file_copy</Mui.Icon>
                          </Mui.IconButton>
                        </Mui.Tooltip>
                      )}
                      {job.outputFile && (
                        <Mui.Tooltip title={<FormattedMessage {...messages.outputFileTooltip} />}>
                          <Mui.IconButton component="a" href={job.outputFile} target="_blank">
                            <Mui.Icon>cloud_download</Mui.Icon>
                          </Mui.IconButton>
                        </Mui.Tooltip>
                      )}
                    </Mui.ListItemSecondaryAction>
                  </Mui.ListItem>
                ))}
              </Mui.List>
            </Mui.Paper>
          </ProgressWrapper>
          {count > 0 && (
            <Mui.TablePagination
              component="div"
              count={count}
              page={page - 1}
              onPageChange={(event, newPage) =>
                setQuery(prevQuery => ({ ...prevQuery, page: newPage + 1 }))
              }
              onRowsPerPageChange={event =>
                setQuery(prevQuery => ({ ...prevQuery, page_size: event.target.value, page: 1 }))
              }
              rowsPerPage={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
            />
          )}
        </Mui.Container>
      </Drawer>
      <Dialogs.Alert
        open={!!selectedJob}
        onClose={() => setSelectedJob('')}
        severity="warning"
        title={retrieve[selectedJob]?.typeDisplay}>
        <Mui.Box display="flex">
          <Mui.Typography variant="body1">
            <strong>
              <FormattedMessage
                {...messages.errorMessage}
                values={{ quantity: retrieve[selectedJob]?.outputJson?.errors?.length }}
              />
            </strong>
            {retrieve[selectedJob]?.outputJson?.errors?.join(', ')}
          </Mui.Typography>
        </Mui.Box>
      </Dialogs.Alert>
    </>
  );
}

CommonJobNotification.propTypes = {};

export default CommonJobNotification;
