import { serverError500Codes } from 'utils/errorHandler';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Components
import { Button, Mui } from 'components';
import { ProgressWrapper, Stack } from 'components/layouts';

// Local Components
import { generateExport } from './utils';
import Tanstack from './Tanstack';

function GraphicTable({
  children,
  customExport,
  data,
  defaultViewType,
  error,
  exportData,
  exportFileName,
  filters,
  heading,
  subtitle,
  loading,
  mutate,
  noTableDataFoundMessage,
  tableData,
  tableProps,
}) {
  // States
  const [viewType, setViewType] = useState(defaultViewType);

  return (
    <Stack>
      <Mui.Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        gridGap={10}
        alignContent="center"
        alignItems="center">
        <Mui.Box>
          {heading && typeof heading === 'string' ? (
            <Mui.Typography variant="h5">{heading}</Mui.Typography>
          ) : (
            heading
          )}
          {subtitle && (
            <Mui.Box marginTop={1}>
              {typeof subtitle === 'string' ? (
                <Mui.Typography variant="body2">{subtitle}</Mui.Typography>
              ) : (
                subtitle
              )}
            </Mui.Box>
          )}
        </Mui.Box>

        <Mui.Box display="flex" gridGap={10}>
          {customExport}
          {exportData && exportData.length > 1 && (
            <Button
              startIcon={<Mui.Icon>get_app</Mui.Icon>}
              variant="outlined"
              onClick={() =>
                generateExport(exportFileName, exportData, { type: 'array', skipHeader: 1 })
              }>
              Exportar
            </Button>
          )}
          {!!children && tableData && (
            <>
              {viewType === 'chart' ? (
                <Button onClick={() => setViewType('table')}>Ver Dados</Button>
              ) : (
                <Button onClick={() => setViewType('chart')}>Ver Gráfico</Button>
              )}
            </>
          )}
        </Mui.Box>
      </Mui.Box>

      {filters}

      <ProgressWrapper loading={loading}>
        {serverError500Codes.indexOf(error?.response?.status) !== -1 ? (
          <Button onClick={() => mutate?.()}>Recarregar</Button>
        ) : (
          <>
            {!!children && viewType === 'chart' && children}
            {tableData && viewType === 'table' && (
              <>
                {tableData && Array.isArray(data) && data.length > 0 ? (
                  <Tanstack columns={tableData} data={data} {...tableProps} />
                ) : (
                  <Mui.Typography variant="body1">
                    {noTableDataFoundMessage || 'Nenhum dado encontrado no filtro selecionado'}
                  </Mui.Typography>
                )}
              </>
            )}
          </>
        )}
      </ProgressWrapper>
    </Stack>
  );
}

GraphicTable.propTypes = {
  children: PropTypes.any,
  customExport: PropTypes.any,
  data: PropTypes.any,
  defaultViewType: PropTypes.string,
  error: PropTypes.any,
  exportData: PropTypes.any,
  exportFileName: PropTypes.string,
  filters: PropTypes.any,
  heading: PropTypes.any,
  subtitle: PropTypes.any,
  loading: PropTypes.bool,
  mutate: PropTypes.func,
  noTableDataFoundMessage: PropTypes.string,
  tableData: PropTypes.any,
  tableProps: PropTypes.object,
};

GraphicTable.defaultProps = {
  defaultViewType: 'chart',
};

export default GraphicTable;
