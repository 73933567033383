import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

// Components
import { Mui } from 'components';

export const useStyles = Mui.makeStyles(theme => ({
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
}));

function Notification({ cookieProperty, title, children, ...restProps }) {
  // Hooks
  const classes = useStyles();

  // States
  const [open, setOpen] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  // Actions
  const handleClose = () => {
    if (dontShowAgain) {
      Cookies.set(cookieProperty, 'true');
    }
    setOpen(false);
  };

  // Effects
  useEffect(() => {
    const value = Cookies.get(cookieProperty);
    if (!value) {
      setOpen(true);
    }
  }, [cookieProperty]);

  if (!open) {
    return null;
  }

  return (
    <Mui.Dialog
      transitionDuration={{ enter: 250, exit: 150 }}
      TransitionComponent={Mui.Grow}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      {...restProps}>
      <Mui.CardHeader
        avatar={
          <Mui.Avatar className={classes.avatar}>
            <Mui.Icon color="inherit" fontSize="inherit">
              new_releases
            </Mui.Icon>
          </Mui.Avatar>
        }
        action={
          <Mui.IconButton aria-label="close" onClick={handleClose}>
            <Mui.Icon>close</Mui.Icon>
          </Mui.IconButton>
        }
        title={title}
        titleTypographyProps={{ variant: 'h5', noWrap: true }}
      />
      <Mui.DialogContent>
        <Mui.DialogContentText variant="body1" component="div">
          {children}
        </Mui.DialogContentText>
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.FormControlLabel
          control={
            <Mui.Checkbox
              name="dontShowAgain"
              value={dontShowAgain}
              onClick={() => setDontShowAgain(true)}
            />
          }
          label={<Mui.Typography variant="subtitle2">Não exibir novamente</Mui.Typography>}
        />
        <Mui.Button variant="outlined" color="secondary" size="large" onClick={handleClose}>
          Fechar
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
}

Notification.propTypes = {
  cookieProperty: PropTypes.string,
  title: PropTypes.string,
  startOpen: PropTypes.bool,
  children: PropTypes.any,
};

export default Notification;
