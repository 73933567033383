import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* retrieveRequestSaga(action) {
  const { callback } = action.payload;

  try {
    const response = yield call(api.retrieve);

    yield put(actions.retrieveSuccess({ callback, response: response.data }));
  } catch (error) {
    yield put(actions.retrieveFailure({ callback, error }));
  }
}

export function* retrieveSuccessSaga(action) {
  const { callback, response } = action.payload;

  if (callback) callback(response, null);

  yield true;
}

export function* retrieveFailureSaga(action) {
  const { callback, error } = action.payload;

  if (callback) callback(null, error);

  yield true;
}

export function* institutionsRequestSaga(action) {
  const { institutionSlug } = action.payload;

  try {
    const response = yield call(api.institutions, { institutionSlug });

    yield put(actions.institutionsSuccess({ institutionSlug, response: response.data }));
  } catch (error) {
    yield put(actions.institutionsFailure({ institutionSlug, error }));
  }
}

export function* sellersRequestSaga(action) {
  const { sellerSlug } = action.payload;

  try {
    const response = yield call(api.sellers, { sellerSlug });

    yield put(actions.sellersSuccess({ sellerSlug, response: response.data }));
  } catch (error) {
    yield put(actions.sellersFailure({ sellerSlug, error }));
  }
}

// Individual exports for testing
export default function* permissionProviderSaga() {
  yield takeLatest(constants.RETRIEVE_REQUEST, retrieveRequestSaga);
  yield takeLatest(constants.RETRIEVE_SUCCESS, retrieveSuccessSaga);
  yield takeLatest(constants.RETRIEVE_FAILURE, retrieveFailureSaga);
  yield takeLatest(constants.INSTITUTIONS_REQUEST, institutionsRequestSaga);
  yield takeLatest(constants.SELLERS_REQUEST, sellersRequestSaga);
}
