import * as React from 'react';
import PropTypes from 'prop-types';

import { Mui } from '../..';

function TabPanel(props) {
  const { children, value, index, ...restProps } = props;

  return (
    <Mui.Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...restProps}>
      <Mui.Box p={3}>{children}</Mui.Box>
    </Mui.Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
};

export default TabPanel;
