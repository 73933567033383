import { Redirect } from 'react-router-dom';
import React, { lazy } from 'react';

// Layouts
import EmptyLayout from 'layouts/EmptyLayout';
import InstitutionLayout from 'layouts/InstitutionLayout';

// Containers
import InstitutionGuard from '../InstitutionGuard';

export default [
  {
    exact: true,
    path: '/institutions/register',
    layout: EmptyLayout,
    component: lazy(() => import('pages/institutions/InstitutionRegistrationPage')),
  },
  {
    path: '/institutions/:institutionSlug',
    layout: InstitutionLayout,
    guard: InstitutionGuard,
    routes: [
      // Account
      {
        exact: true,
        path: '/institutions/:institutionSlug',
        component: lazy(() => import('pages/institutions/InstitutionHomePage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/profile',
        component: lazy(() => import('pages/institutions/InstitutionProfilePage')),
      },
      // Eskolare Insights
      {
        exact: true,
        path: '/institutions/:institutionSlug/general-indicators',
        component: lazy(() => import('pages/institutions/InstitutionGeneralDashboardPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/sales-agreement-indicators',
        component: lazy(() => import('pages/institutions/InstitutionSalesAgreementIndicatorsPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/nps-score',
        component: lazy(() => import('pages/institutions/InstitutionNpsScorePage')),
        permissionKey: 'cx.baskets',
      },
      // Management
      {
        exact: true,
        path: '/institutions/:institutionSlug/orders/:orderNumber',
        component: lazy(() => import('pages/institutions/InstitutionOrderDetailsPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/orders',
        component: lazy(() => import('pages/institutions/InstitutionOrderPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/institution-shipment/:shipmentCode',
        component: lazy(() => import('pages/institutions/InstitutionShipmentDetailsPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/institution-shipment',
        component: lazy(() => import('pages/institutions/InstitutionShipmentPage')),
      },
      // Suppport
      {
        exact: true,
        path: '/institutions/:institutionSlug/support/tickets/:ticketId',
        component: lazy(() => import('pages/institutions/InstitutionSupportTicketDetailsPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/support/tickets',
        component: lazy(() => import('pages/institutions/InstitutionSupportTicketPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/support/platform-articles/:platformArticleSlug',
        component: lazy(() =>
          import('pages/institutions/InstitutionSupportPlatformArticleDetailsPage'),
        ),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/support/platform-articles',
        component: lazy(() => import('pages/institutions/InstitutionSupportPlatformArticlesPage')),
      },
      // Relationship
      {
        exact: true,
        path: '/institutions/:institutionSlug/partnerships',
        component: lazy(() => import('pages/institutions/InstitutionPartnershipPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/enrollments/requests',
        component: lazy(() => import('pages/institutions/InstitutionEnrollmentRequestPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/enrollments',
        component: lazy(() => import('pages/institutions/InstitutionEnrollmentPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/product-alerts',
        component: lazy(() => import('pages/institutions/InstitutionProductAlertPage')),
      },
      // Others
      {
        exact: true,
        path: '/institutions/:institutionSlug/school-census',
        component: lazy(() => import('pages/institutions/InstitutionSchoolCensusPage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/sell-more',
        component: lazy(() => import('pages/institutions/InstitutionSellMorePage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/pages',
        component: lazy(() => import('pages/institutions/InstitutionPagePage')),
      },
      {
        exact: true,
        path: '/institutions/:institutionSlug/pages/view/:pageSlug',
        component: lazy(() => import('pages/institutions/InstitutionPageDetailsPage')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '/institutions',
    layout: EmptyLayout,
    guard: InstitutionGuard,
    component: lazy(() => import('pages/institutions/InstitutionChoosePage')),
  },
];
