import { alpha } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useFetch } from 'hooks';
import * as images from 'images';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui, MuiLab } from 'components';
import { DisplayData } from 'components/common';

import * as api from '../api';
import messages from '../messages';

const useStyles = Mui.makeStyles(theme => ({
  button: {
    display: 'block',
    color: theme.palette.text.textPrimary,
    padding: 0,
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: 0,
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    width: 48,
    height: 48,
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  },
}));

function CurrentEnrollment(props) {
  const { enrollmentSlug, onClick } = props;

  // Hooks
  const classes = useStyles();

  // Fetch
  const { data } = useFetch([enrollmentSlug && api.endpoints.enrollment(enrollmentSlug)], {
    dedupingInterval: 60 * 1000,
  });

  // Constants
  const { institution = {}, showcase = {}, student = {} } = data || {};
  const { picture, firstName } = student;
  const { logo, displayName } = institution;
  const { store, cycle } = showcase;

  return (
    <Mui.Button className={classes.button} onClick={onClick}>
      <Mui.Box display="flex" alignContent="center" alignItems="center" p={1}>
        <Mui.Box>
          <Mui.Hidden only={['xl', 'lg', 'md']}>
            <DisplayData.Picture
              src={picture || images.facesOtherFace}
              width={40}
              height={40}
              SmallAvatar={avatarProps => (
                <Mui.Avatar src={logo || images.facesMaleFace} {...avatarProps} />
              )}
              ratio={2}
            />
          </Mui.Hidden>
          <Mui.Hidden only={['sm', 'xs']}>
            <Mui.Avatar src={logo || images.facesMaleFace} className={classes.avatar} />
          </Mui.Hidden>
        </Mui.Box>
        <Mui.Box textAlign="left" flexGrow={1} ml={1}>
          <Mui.Hidden only={['xl', 'lg', 'md']}>
            <Mui.Typography variant="h6" component="div">
              <FormattedMessage
                {...messages.selectedEnrollment}
                values={{
                  firstName: <strong>{firstName || '?'}</strong>,
                }}
              />
            </Mui.Typography>
            <Mui.Typography variant="body1" color="primary">
              {displayName || <MuiLab.Skeleton variant="text" width={150} />}
            </Mui.Typography>
            <Mui.Box display="flex" alignItems="center">
              <Mui.Typography variant="body2" color="textPrimary">
                {store ? (
                  [store, showcase?.displayName].join('/')
                ) : (
                  <MuiLab.Skeleton variant="text" width={100} />
                )}
              </Mui.Typography>
              <Mui.Box ml={1}>
                {cycle ? (
                  <Mui.Chip size="small" color="primary" label={cycle} />
                ) : (
                  <MuiLab.Skeleton variant="text" width={30} />
                )}
              </Mui.Box>
            </Mui.Box>
          </Mui.Hidden>
          <Mui.Hidden only={['sm', 'xs']}>
            <Mui.Typography variant="caption" color="textSecondary" noWrap>
              <FormattedMessage {...messages.selectedTitle} />
            </Mui.Typography>
            <Mui.Typography variant="body1" color="primary">
              {displayName || <MuiLab.Skeleton variant="text" width={150} />}
            </Mui.Typography>
            <Mui.Box display="flex" alignItems="center">
              {store ? (
                <Mui.Box flexGrow={1}>
                  <Mui.Typography variant="body2" color="textSecondary" component="div">
                    {store}
                  </Mui.Typography>
                  <Mui.Typography variant="body2" color="textPrimary" component="div">
                    {showcase?.displayName}
                  </Mui.Typography>
                </Mui.Box>
              ) : (
                <MuiLab.Skeleton variant="text" width={100} />
              )}
              <Mui.Box ml={1}>
                {cycle ? (
                  <Mui.Chip size="small" color="primary" label={cycle} />
                ) : (
                  <MuiLab.Skeleton variant="text" width={30} />
                )}
              </Mui.Box>
            </Mui.Box>
          </Mui.Hidden>
        </Mui.Box>
      </Mui.Box>
      <Mui.Box my={1} p={1} className={classes.switch}>
        <Mui.Icon size="small">change_circle</Mui.Icon>
        <Mui.Box mr={1} />
        <Mui.Typography variant="body2">
          <FormattedMessage {...messages.switchStudent} />
        </Mui.Typography>
      </Mui.Box>
    </Mui.Button>
  );
}

CurrentEnrollment.propTypes = {
  enrollmentSlug: PropTypes.string,
  onClick: PropTypes.func,
};

export default CurrentEnrollment;
