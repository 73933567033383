import PropTypes from 'prop-types';
import React from 'react';

import { Button, Typography, Mui, Grid } from '../..';
import { Repeater } from '../../layouts';
import * as images from '../../../images';

export const useStyles = Mui.makeStyles(theme => ({
  root: {},
  hero: {
    width: '100%',
    height: '99.5vh',
    paddingTop: theme.spacing(12),
    backgroundImage: props =>
      `linear-gradient(rgba(255, 255, 255, 0.75),rgba(255, 255, 255, 0.6),rgba(255, 255, 255, 0.45)), url(${
        props.image || images.illustrationsDefaultCover
      });`,
    backgroundSize: 'cover',
    backgroundPosition: 'center 66px',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  },
  container: {
    height: '100%',
  },
  content: {
    height: '100%',
  },
}));

function Hero(props) {
  const { children, onClick, title, subtitle, label, image } = props;

  const classes = useStyles({ image });

  return (
    <div className={classes.root}>
      <section className={classes.hero}>
        <Mui.Container maxWidth="md" className={classes.container}>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className={classes.content}>
            <Grid item>{children}</Grid>
            <Grid item>
              <Grid container spacing={1} direction="column" alignItems="center">
                {title && (
                  <Grid item xs={12}>
                    <Typography variant="h1" color="inherit" align="center" gutterBottom>
                      {title}
                    </Typography>
                  </Grid>
                )}
                {subtitle && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      component="div"
                      color="inherit"
                      align="center"
                      gutterBottom>
                      {subtitle}
                    </Typography>
                  </Grid>
                )}
                {label && (
                  <Grid item>
                    <Button onClick={onClick} size="large">
                      {label}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Mui.Container>
      </section>
      <Repeater />
    </div>
  );
}

Hero.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  label: PropTypes.any,
  image: PropTypes.any,
};

export default Hero;
