/*
 *
 * SocialAuthentication constants
 *
 */

const app = 'app/common/SocialAuthentication';

export const SOCIAL_CONNECT_REQUEST = `${app}/SOCIAL_CONNECT_REQUEST`;
export const SOCIAL_CONNECT_SUCCESS = `${app}/SOCIAL_CONNECT_SUCCESS`;
export const SOCIAL_CONNECT_FAILURE = `${app}/SOCIAL_CONNECT_FAILURE`;
