import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import { Mui, Typography } from '../..';
import { Stack } from '../../layouts';
import messages from './messages';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    zIndex: 1100,
    position: 'fixed',
    width: 50,
    left: theme.spacing(1),
    bottom: theme.spacing(1.5),
  },
  mobile: {
    marginTop: theme.spacing(2),
  },
}));

function Share(props) {
  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ fullScreen });
  const [open, setOpen] = useState(false);

  const { type, size, url, title, content, hashtag } = props;

  if (type === 'bar')
    return (
      <Stack spacing={1} layout={{ xs: false }} alignItems="center" TransitionComponent={Mui.Grow}>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage {...messages.share} />
        </Typography>
        <WhatsappShareButton url={url} title={content}>
          <WhatsappIcon size={size} borderRadius={theme.shape.borderRadius * 2} />
        </WhatsappShareButton>
        <FacebookShareButton url={url} quote={content} hashtag={hashtag}>
          <FacebookIcon size={size} borderRadius={theme.shape.borderRadius * 2} />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={content}>
          <TwitterIcon size={size} borderRadius={theme.shape.borderRadius * 2} />
        </TwitterShareButton>
        <TelegramShareButton url={url} title={content}>
          <TelegramIcon size={size} borderRadius={theme.shape.borderRadius * 2} />
        </TelegramShareButton>
        <EmailShareButton url={url} subject={title} body={content}>
          <EmailIcon size={size} borderRadius={theme.shape.borderRadius * 2} />
        </EmailShareButton>
      </Stack>
    );

  return (
    <div className={classes.root}>
      {(!fullScreen || open) && (
        <Stack spacing={1} TransitionComponent={Mui.Grow}>
          <WhatsappShareButton url={url} title={content}>
            <WhatsappIcon round size={size} />
          </WhatsappShareButton>
          <FacebookShareButton url={url} quote={content} hashtag={hashtag}>
            <FacebookIcon round size={size} />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={content}>
            <TwitterIcon round size={size} />
          </TwitterShareButton>
          <TelegramShareButton url={url} title={content}>
            <TelegramIcon round size={size} />
          </TelegramShareButton>
          <EmailShareButton url={url} subject={title} body={content}>
            <EmailIcon round size={size} />
          </EmailShareButton>
        </Stack>
      )}
      {fullScreen && (
        <div className={classes.mobile}>
          <Mui.Tooltip placement="top" title={<FormattedMessage {...messages.share} />}>
            <Mui.Fab
              color="primary"
              size="medium"
              className={classes.fab}
              onClick={() => setOpen(!open)}>
              <Mui.Icon>share</Mui.Icon>
            </Mui.Fab>
          </Mui.Tooltip>
        </div>
      )}
    </div>
  );
}

Share.defaultProps = {
  size: 40,
};

Share.propTypes = {
  size: PropTypes.number,
  url: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  hashtag: PropTypes.string,
  type: PropTypes.oneOf(['bar', 'float']),
};

export default Share;
