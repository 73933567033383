import React from 'react';
import { useCart } from 'hooks';

// Components
import { Cart } from 'components/common';
import { Mui, Badge } from 'components';

// Containers
import FamilyPurchaseButton from 'containers/families/FamilyPurchaseButton';

function FamilyCartAnonymous() {
  // Hooks
  const { cartProvider, anonymousCart } = useCart();

  // Constants
  const { temporary = {}, anonymous } = cartProvider;
  const products = Object.keys(temporary)
    .filter(key => temporary[key].basket.quantity > 0)
    .map(key => temporary[key]);

  return (
    <>
      <Mui.IconButton onClick={() => anonymousCart(true)} id="cart-notification">
        <Badge badgeContent={products.length} color="error">
          <Mui.Icon color={products.length > 0 ? 'primary' : 'inherit'}>shopping_cart</Mui.Icon>
        </Badge>
      </Mui.IconButton>
      <Cart.Sidebar
        open={anonymous}
        onClose={() => anonymousCart(false)}
        products={products}
        PurchaseButton={newProduct => <FamilyPurchaseButton simple product={newProduct} />}
      />
    </>
  );
}

FamilyCartAnonymous.propTypes = {};

export default FamilyCartAnonymous;
