/*
 *
 * CommonJobNotification constants
 *
 */

const app = 'app/widgets/Notifications';

export const LIST_REQUEST = `${app}/LIST_REQUEST`;
export const LIST_FAILURE = `${app}/LIST_FAILURE`;
export const LIST_SUCCESS = `${app}/LIST_SUCCESS`;

export const RETRIEVE_REQUEST = `${app}/RETRIEVE_REQUEST`;
export const RETRIEVE_FAILURE = `${app}/RETRIEVE_FAILURE`;
export const RETRIEVE_SUCCESS = `${app}/RETRIEVE_SUCCESS`;

export const ADD_JOB = `${app}/ADD_JOB`;
export const REMOVE_JOB = `${app}/REMOVE_JOB`;
