import PropTypes from 'prop-types';
import React, { useState } from 'react';

import MuiIcon from '@material-ui/core/Icon';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiInputAdornment from '@material-ui/core/InputAdornment';

import Text from './Text';

function Password({ margin, variant, name, value, ...props }) {
  const [passwordMask, setPasswordMask] = useState(true);

  return (
    <Text
      type={passwordMask ? 'password' : 'text'}
      InputProps={{
        endAdornment: (
          <MuiInputAdornment position="end">
            <MuiIconButton onClick={() => setPasswordMask(!passwordMask)}>
              {passwordMask ? <MuiIcon>visibility_off</MuiIcon> : <MuiIcon>visibility</MuiIcon>}
            </MuiIconButton>
          </MuiInputAdornment>
        ),
      }}
      margin={margin || undefined}
      variant={variant || 'outlined'}
      name={name || Math.random().toString(16)}
      value={value}
      {...props}
    />
  );
}

Password.propTypes = {
  /**
   * If `true`, the `input` element will be focused during the first mount.
   */
  autoFocus: PropTypes.bool,
  /**
   * If `true`, the `input` element will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the input will indicate an error.
   */
  error: PropTypes.bool,
  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,
  /**
   * The helper text.
   */
  helperText: PropTypes.string,
  /**
   * If `dense`, will adjust vertical spacing.
   */
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value`.
   */
  onChange: PropTypes.func,
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder: PropTypes.string,
  /**
   * If `true`, the `input` element will be required.
   */
  required: PropTypes.bool,
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value: PropTypes.string,
  /**
   * The component variant.
   */
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};

Password.defaultProps = {
  autoFocus: false,
  fullWidth: true,
  margin: 'normal',
  required: false,
  variant: 'outlined',
};

export default Password;
