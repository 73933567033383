import { useEffect, useState, useCallback } from 'react';

const useZendesk = () => {
  const [isReady, setIsReady] = useState(false);

  const toggleVisibility = useCallback(param => {
    const container = document.getElementById('launcher');

    if (container) {
      container.style.visibility = param;

      return { done: true };
    }

    return { done: false };
  }, []);

  useEffect(() => {
    let interval;

    if (isReady) {
      clearInterval(interval);
    } else {
      interval = setInterval(() => {
        const { done } = toggleVisibility('visible');

        setIsReady(done);
      }, 6000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isReady, toggleVisibility]);

  useEffect(() => {
    toggleVisibility('visible');

    return () => {
      toggleVisibility('hidden');
    };
  }, [toggleVisibility]);
};

export default useZendesk;
