import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui, MuiLab, TextField } from 'components';
import {
  ORDER_STATUS,
  CONTRACT_STATUS,
  TUITION_STATUS,
} from 'components/common/DisplayStatus/Order';

const orderStatus = Object.keys(ORDER_STATUS).map(key => ORDER_STATUS[key]);
const contractStatus = Object.keys(CONTRACT_STATUS).map(key => CONTRACT_STATUS[key]);
const tuitionStatus = Object.keys(TUITION_STATUS).map(key => TUITION_STATUS[key]);

function OrderStatusFilter(props) {
  const { type, selectedStatus, onSelect, multiple, ...otherProps } = props;

  // Constants
  // prettier-ignore
  /* eslint-disable */
  const options =
  type === 'order' ? orderStatus :
  type === 'contract' ? contractStatus :
  type === 'tuition' ? tuitionStatus :
  [];
  /* eslint-enable */

  // Actions
  const handleStatusFilter = (event, selectedOption) =>
    onSelect(!multiple ? selectedOption?.status : selectedOption?.map(option => option.status));

  return (
    <MuiLab.Autocomplete
      multiple={multiple}
      options={options}
      limitTags={2}
      value={
        multiple
          ? options.filter(option => selectedStatus?.includes(option?.status))
          : options.find(option => option.status === selectedStatus)
      }
      getOptionLabel={option => option?.label}
      getOptionSelected={(option, value) => option?.status === value?.status}
      onChange={handleStatusFilter}
      renderOption={(option, { selected }) => (
        <>
          <Mui.ListItemIcon>
            <Mui.Icon color={selected ? 'primary' : 'secondary'}>{option?.icon}</Mui.Icon>
          </Mui.ListItemIcon>
          <Mui.ListItemText primaryTypographyProps={{ color: selected ? 'primary' : 'secondary' }}>
            {option?.label}
          </Mui.ListItemText>
        </>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Mui.Chip
            avatar={
              <Mui.Avatar>
                <Mui.Icon fontSize="small">{option?.icon}</Mui.Icon>
              </Mui.Avatar>
            }
            label={option?.label}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={inputParams => (
        <TextField {...inputParams} label="Status do pedido" margin="none" />
      )}
      {...otherProps}
    />
  );
}

OrderStatusFilter.propTypes = {
  type: PropTypes.string,
  selectedStatus: PropTypes.any,
  onSelect: PropTypes.func,
  multiple: PropTypes.bool,
};

export default OrderStatusFilter;
