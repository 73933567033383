import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Product } from '../../../../components/common';
import { Grid } from '../../../../components';
import { useBasket } from '../../../../hooks';

function PurchaseButton(props) {
  const { product, enrollment } = props;

  // Constants
  const { uid: enrollmentUid, slug: enrollmentSlug } = enrollment || {};
  const { uid: productUid, slug: productSlug } = product;

  // Hooks
  const { collectionSlug } = useParams();

  // Custom hooks
  const { basket, handling, onAdd, onRemove } = useBasket({
    productSlug,
    productUid,
    enrollmentUid,
    enrollmentSlug,
    collectionSlug,
  });

  return (
    <Grid container spacing={1} justifyContent="space-between" wrap="nowrap" alignItems="center">
      <Grid item>{product.hasStock && <Product.PriceLabel {...basket} />}</Grid>
      <Grid item>
        <Product.BuyButton
          simple
          product={product}
          basket={basket}
          loading={handling}
          onAdd={onAdd}
          onRemove={onRemove}
        />
      </Grid>
    </Grid>
  );
}

PurchaseButton.propTypes = {
  product: PropTypes.object,
  enrollment: PropTypes.object,
  basket: PropTypes.object,
};

export default PurchaseButton;
