import { AppBar, Box, Button, Divider, Toolbar, Link, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import * as images from 'images';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Logo } from 'components';
import { Repeater } from 'components/layouts';

import messages from './messages';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    height: 64,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

function TopBar({ className, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" elevation={0} {...rest}>
      <Toolbar className={classes.toolbar} id="toolbar">
        <a href="/">
          <Logo src={images.logoHorizontalColor} height="27px" className={classes.logo} />
        </a>
        <Box flexGrow={1} />
        <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/sign-up"
          underline="none"
          variant="body2">
          <FormattedMessage {...messages.signUpButton} />
        </Link>
        <Divider className={classes.divider} />
        <Button color="primary" component={RouterLink} to="/login" variant="contained" size="small">
          <FormattedMessage {...messages.loginButton} />
        </Button>
      </Toolbar>
      <Repeater />
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
