/*
 * SnackbarProvider Messages
 *
 * This contains all the text for the SnackbarProvider container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.widgets.SnackbarProvider';

export default defineMessages({
  UNKNOWN: {
    id: `${scope}.UNKNOWN`,
    defaultMessage:
      'Desculpe, este não é um erro que conseguimos prever em nossa plataforma. Por favor, entre em contato com nossa equipe de atendimento',
  },
  ERROR400: {
    id: `${scope}.ERROR400`,
    defaultMessage: 'Ops, houve um problema... {error}',
  },
  ERROR401: {
    id: `${scope}.ERROR401`,
    defaultMessage: '{error}',
  },
  ERROR403: {
    id: `${scope}.ERROR403`,
    defaultMessage: 'Esta chamada não é permitida (Erro 403)',
  },
  ERROR404: {
    id: `${scope}.ERROR404`,
    defaultMessage: 'Chamada não parece estar correta (Erro 404)',
  },
  ERROR405: {
    id: `${scope}.ERROR405`,
    defaultMessage: 'Método não permitido (Erro 405)',
  },
  ERROR429: {
    id: `${scope}.ERROR429`,
    defaultMessage: 'Excesso de requisições. Tente novamente em alguns segundos. (Erro 429)',
  },
  ERROR500: {
    id: `${scope}.ERROR500`,
    defaultMessage:
      'Tivemos algum problema em nossos servidores (Erro 500). Nossa equipe já foi informada, por favor, tente novamente em algumas horas',
  },
  ERROR502: {
    id: `${scope}.ERROR502`,
    defaultMessage: 'Bad Gateway (502)',
  },
  ERROR503: {
    id: `${scope}.ERROR503`,
    defaultMessage:
      'Nossos servidores parecem estar indisponíveis no momento (Erro 503). Tente novamente em alguns minutos',
  },
  ERROR504: {
    id: `${scope}.ERROR504`,
    defaultMessage:
      'A requisição demorou mais que o permitido, talvez seja um problema pontual (Erro 504). Tente novamente',
  },
  CREATE: {
    id: `${scope}.CREATE`,
    defaultMessage: 'Registro criado com sucesso!',
  },
  UPDATE: {
    id: `${scope}.UPDATE`,
    defaultMessage: 'Registro atualizado com sucesso!',
  },
  DELETE: {
    id: `${scope}.DELETE`,
    defaultMessage: 'Registro excluído com sucesso!',
  },
  // Notifier.js
  closeButton: {
    id: `${scope}.closeButton`,
    defaultMessage: 'Fechar',
  },
  // common/SignUpPage/saga.js
  MSG0003: {
    id: 'app.containers.common.SignUpPage.MSG0003',
    defaultMessage:
      'Obrigado por criar sua conta! Não esqueça de confirmar seu e-mail através da mensagem que enviamos',
  },
  MSG0127: {
    id: 'app.containers.common.SignUpPage.MSG0127',
    defaultMessage:
      'Este e-mail já está cadastrado em nossa plataforma, por favor, utilize e-mail ou senha para entrar ou alguma das redes sociais disponíveis',
  },
  // containers/common/UserProvider/saga.js
  MSG0142: {
    id: 'app.containers.common.UserProvider.MSG0142',
    defaultMessage: 'E-mail ou senha não conferem, tente novamente',
  },
  // common/PasswordResetPage/saga.js
  MSG0004: {
    id: 'app.containers.common.PasswordResetPage.MSG0004',
    defaultMessage:
      'Caso o e-mail {email} esteja cadastrado, em instantes enviaremos os próximos passos para redefinir senha',
  },
  // families/CreateStudentEnrollmentPage/saga.js
  MSG0009: {
    id: 'app.containers.families.CreateStudentEnrollmentPage.MSG0009',
    defaultMessage: 'Criamos o seu aluno e fizemos a matrícula na instituição escolhida',
  },
  // common/PasswordConfirmPage/saga.js
  MSG0010: {
    id: 'app.containers.common.PasswordConfirmPage.MSG0010',
    defaultMessage:
      'Sua senha foi alterada com sucesso. Aproveite e já tente entrar utilizando ela',
  },
  // families/CreditCardManagementPage/saga.js
  MSG0013: {
    id: 'app.containers.families.CreditCardManager.MSG0013',
    defaultMessage: 'O cartão de crédito foi excluído com sucesso!',
  },
  // families/ProfilePage/saga.js
  MSG0014: {
    id: 'app.containers.families.ProfilePage.MSG0014',
    defaultMessage: 'Seu perfil foi atualizado com sucesso!',
  },
  // families/OrderManagementPage/saga.js
  MSG0016: {
    id: 'app.containers.families.OrderManagementPage.MSG0015',
    defaultMessage:
      'Sua solicitação de cancelamento do pedido foi enviada com sucesso, aguarde o retorno de nossa equipe',
  },
  MSG0106: {
    id: 'app.containers.families.OrderManagementPage.MSG0106',
    defaultMessage: 'O pagamento foi solicitado com sucesso!',
  },
  // families/AccountBankAccountPage/saga.js
  MSG0018: {
    id: 'app.containers.families.BankAccountManagementPage.MSG0018',
    defaultMessage: 'A conta bancária foi excluída com sucesso!',
  },
  MSG0019: {
    id: 'app.containers.families.BankAccountManagementPage.MSG0019',
    defaultMessage: 'A conta bancária foi salva com sucesso!',
  },
  // families/StudentPage/saga.js
  MSG0020: {
    id: 'app.containers.families.StudentPage.MSG0020',
    defaultMessage: 'O aluno foi atualizado com sucesso!',
  },
  MSG0021: {
    id: 'app.containers.families.StudentPage.MSG0021',
    defaultMessage: 'O aluno foi matriculado com sucesso!',
  },
  MSG0024: {
    id: 'app.containers.families.StudentPage.MSG0024',
    defaultMessage: 'O aluno foi excluído com sucesso!',
  },
  MSG0025: {
    id: 'app.containers.families.StudentPage.MSG0025',
    defaultMessage: 'A matrícula foi arquivada com sucesso!',
  },
  // widgets/CommonEmailConfirmationAlert/saga.js
  MSG0022: {
    id: 'app.containers.widgets.CommonEmailConfirmationAlert.MSG0022',
    defaultMessage: 'Uma nova confirmação foi enviada para seu e-mail!',
  },
  // families/ChangePaymentPage/saga.js
  MSG0023: {
    id: 'app.containers.families.ChangePaymentPage.MSG0023',
    defaultMessage: 'A forma de pagamento foi alterada com sucesso!',
  },
  // landing/LandingPage/saga.js
  MSG0032: {
    id: 'app.containers.landing.LandingPage.MSG0032',
    defaultMessage: 'Obrigado por entrar em contato, em breve iremos responder sua mensagem!',
  },
  // families/ExpressCheckoutPtBrPage/saga.js
  MSG0035: {
    id: 'app.containers.landing.ExpressCheckoutPtBrPage.MSG0035',
    defaultMessage: 'Forma de pagamento salva com sucesso!',
  },
  // sellers/SellerProfilePage/saga.js
  MSG0036: {
    id: 'app.containers.sellers.SellerProfilePage.MSG0036',
    defaultMessage: 'O fornecedor foi salvo com sucesso!',
  },
  // sellers/SellerProductEditPage/saga.js
  MSG0037: {
    id: 'app.containers.sellers.SellerProductEditPage.MSG0037',
    defaultMessage: 'O produto/serviço {title} foi salvo com sucesso!',
  },
  MSG0066: {
    id: 'app.containers.sellers.SellerProductEditPage.MSG0066',
    defaultMessage: 'O produto/serviço {title} foi convertido com sucesso!',
  },
  // sellers/SellerOrderManagementPage/saga.js
  MSG0038: {
    id: 'app.containers.sellers.SellerOrderManagementPage.MSG0037',
    defaultMessage:
      'Recebemos o seu pedido de exportação de informações sobre pedidos. Você será notificado por e-mail assim que o arquivo estiver disponível',
  },
  // widgets/CommonJobNotification/saga.js
  MSG0039: {
    id: 'app.containers.sellers.CommonJobNotification.MSG0039',
    defaultMessage: 'Atividade finalizada com sucesso! {link} para baixar o arquivo',
  },
  MSG0143: {
    id: 'app.containers.sellers.CommonJobNotification.MSG0143',
    defaultMessage: 'Atividade finalizada com sucesso!',
  },
  MSG0114: {
    id: 'app.containers.sellers.CommonJobNotification.MSG0114',
    defaultMessage: 'Atividade finalizada com erro! {errors}',
  },
  // sellers/GeneralPriceManagerWidget/saga.js
  MSG0040: {
    id: 'app.containers.sellers.GeneralPriceManagerWidget.MSG0040',
    defaultMessage: 'O preço para {title} foi criado com sucesso',
  },
  MSG0052: {
    id: 'app.containers.sellers.GeneralPriceManagerWidget.MSG0052',
    defaultMessage: 'O preço para {title} foi editado com sucesso',
  },
  MSG0053: {
    id: 'app.containers.sellers.GeneralPriceManagerWidget.MSG0053',
    defaultMessage: 'O preço para {title} foi excluído com sucesso',
  },
  // sellers/InstitutionsPriceManagerWidget/saga.js
  MSG0041: {
    id: 'app.containers.sellers.InstitutionsPriceManagerWidget.MSG0041',
    defaultMessage: 'O preço para {title}, na instituição {institution}, foi criado com sucesso',
  },
  MSG0050: {
    id: 'app.containers.sellers.InstitutionsPriceManagerWidget.MSG0050',
    defaultMessage: 'O preço para {title}, na instituição {institution}, foi editado com sucesso',
  },
  MSG0051: {
    id: 'app.containers.sellers.InstitutionsPriceManagerWidget.MSG0051',
    defaultMessage: 'O preço para {title}, na instituição {institution}, foi excluído com sucesso',
  },
  // sellers/SellerStockView/saga.js
  MSG0042: {
    id: 'app.containers.sellers.SellerStockView.MSG0042',
    defaultMessage: 'O estoque do produto {title} foi editado com sucesso',
  },
  // sellers/SellerCarrierView/saga.js
  MSG0043: {
    id: 'app.containers.sellers.SellerCarrierView.MSG0043',
    defaultMessage: 'A transportadora {label} foi criada sucesso',
  },
  MSG0048: {
    id: 'app.containers.sellers.SellerCarrierView.MSG0048',
    defaultMessage: 'A transportadora {label} foi editada sucesso',
  },
  MSG0049: {
    id: 'app.containers.sellers.SellerCarrierView.MSG0049',
    defaultMessage: 'A transportadora {label} foi excluída sucesso',
  },
  MSG0113: {
    id: 'app.containers.sellers.SellerCarrierView.MSG0113',
    defaultMessage: 'Configurando a tabela de fretes...',
  },
  // families/FamilyPage/saga.js
  MSG0044: {
    id: 'app.containers.families.FamilyPage.MSG0044',
    defaultMessage: 'O produto {product} foi atualizado no carrinho!',
  },
  // sellers/SellerCarrierPostalCodesView/saga.js
  MSG0045: {
    id: 'app.containers.sellers.SellerCarrierPostalCodesView.MSG0045',
    defaultMessage:
      'A linha de frete de CEP {postalCodeStart} até {postalCodeEnd} foi criada com sucesso',
  },
  MSG0046: {
    id: 'app.containers.sellers.SellerCarrierPostalCodesView.MSG0046',
    defaultMessage:
      'A linha de frete de CEP {postalCodeStart} até {postalCodeEnd} foi editada com sucesso',
  },
  MSG0047: {
    id: 'app.containers.sellers.SellerCarrierPostalCodesView.MSG0047',
    defaultMessage: 'A linha de frete de CEP foi excluída com sucesso',
  },
  // sellers/SellerProductView/saga.js
  MSG0054: {
    id: 'app.containers.sellers.ProductPage.MSG0054',
    defaultMessage: 'O produto/serviço {title} foi criado com sucesso',
  },
  MSG0055: {
    id: 'app.containers.sellers.ProductPage.MSG0055',
    defaultMessage: 'O produto/serviço {title} foi editado com sucesso',
  },
  MSG0056: {
    id: 'app.containers.sellers.ProductPage.MSG0056',
    defaultMessage: 'O produto/serviço {title} foi excluído com sucesso',
  },
  // sellers/SellerProductVariantEditPage/saga.js
  MSG0057: {
    id: 'app.containers.sellers.ProductVariantDetailPage.MSG0057',
    defaultMessage: 'A variação de produto/serviço {title} foi atualizado(a) com sucesso',
  },
  // sellers/SellerProductMediaView/saga.js
  MSG0060: {
    id: 'app.containers.sellers.SellerProductMediaView.MSG0060',
    defaultMessage: 'A imagem do produto foi criada com sucesso',
  },
  MSG0061: {
    id: 'app.containers.sellers.SellerProductMediaView.MSG0061',
    defaultMessage: 'A imagem do produto foi editada com sucesso',
  },
  MSG0062: {
    id: 'app.containers.sellers.SellerProductMediaView.MSG0062',
    defaultMessage: 'A imagem do produto foi excluída com sucesso',
  },
  // sellers/SellerWarehouseView/saga.js
  MSG0063: {
    id: 'app.containers.sellers.SellerWarehouseView.MSG0063',
    defaultMessage: 'O armazém foi criado com sucesso',
  },
  MSG0064: {
    id: 'app.containers.sellers.SellerWarehouseView.MSG0064',
    defaultMessage: 'O armazém foi editado com sucesso',
  },
  MSG0065: {
    id: 'app.containers.sellers.SellerWarehouseView.MSG0065',
    defaultMessage: 'O armazém foi excluído com sucesso',
  },
  // sellers/PartnershipManagementPage/saga.js
  MSG0067: {
    id: 'app.containers.sellers.PartnershipManagementPage.MSG0067',
    defaultMessage: 'A parceria foi solicitada com sucesso',
  },
  MSG0068: {
    id: 'app.containers.sellers.PartnershipManagementPage.MSG0068',
    defaultMessage: 'A parceria foi atualizada com sucesso',
  },
  MSG0069: {
    id: 'app.containers.sellers.PartnershipManagementPage.MSG0069',
    defaultMessage: 'A parceria foi excluída com sucesso',
  },
  // sellers/SellerFinancialWithdrawalList/saga.js
  MSG0070: {
    id: 'app.containers.sellers.SellerFinancialWithdrawalList.MSG0070',
    defaultMessage: 'A solicitação de resgate foi cancelada com sucesso',
  },
  // sellers/FinancialWithdrawalDetailPage/saga.js
  MSG0071: {
    id: 'app.containers.sellers.FinancialWithdrawalDetailPage.MSG0071',
    defaultMessage: 'A solicitação de resgate parcial foi cancelada com sucesso',
  },
  MSG0072: {
    id: 'app.containers.sellers.FinancialWithdrawalDetailPage.MSG0072',
    defaultMessage: 'A nota fiscal foi adicionada com sucesso',
  },
  MSG0073: {
    id: 'app.containers.sellers.FinancialWithdrawalDetailPage.MSG0073',
    defaultMessage: 'A nota fiscal foi excluída com sucesso',
  },
  // sellers/FinancialCommissionInvoiceWorkflowWidget/saga.js
  MSG0074: {
    id: 'app.containers.sellers.FinancialCommissionInvoiceWorkflowWidget.MSG0074',
    defaultMessage: 'A nota fiscal foi aprovada',
  },
  MSG0075: {
    id: 'app.containers.sellers.FinancialCommissionInvoiceWorkflowWidget.MSG0075',
    defaultMessage: 'A nota fiscal foi recusada',
  },
  // sellers/SellerFinancialAvailableWithdrawalForm/saga.js
  MSG0076: {
    id: 'app.containers.sellers.SellerFinancialAvailableWithdrawalForm.MSG0076',
    defaultMessage: 'A solicitação de resgate foi criada com sucesso',
  },
  // sellers/BrandPage/saga.js
  MSG0077: {
    id: 'app.containers.sellers.BrandPage.MSG0077',
    defaultMessage: 'A marca foi criada com sucesso',
  },
  MSG0078: {
    id: 'app.containers.sellers.BrandPage.MSG0078',
    defaultMessage: 'A marca foi editada com sucesso',
  },
  MSG0079: {
    id: 'app.containers.sellers.BrandPage.MSG0079',
    defaultMessage: 'A marca foi excluída com sucesso',
  },
  // sellers/SellerOrderDetail/saga.js
  MSG0080: {
    id: 'app.containers.sellers.SellerOrderDetail.MSG0080',
    defaultMessage: 'Você iniciou o manuseio do pedido {orderNumber}',
  },
  MSG0081: {
    id: 'app.containers.sellers.SellerOrderDetail.MSG0081',
    defaultMessage: 'Você iniciou o cancelamento do pedido {orderNumber}',
  },
  MSG0082: {
    id: 'app.containers.sellers.SellerOrderDetail.MSG0082',
    defaultMessage: 'Nota fiscal adicionada ao pedido {orderNumber}',
  },
  MSG0083: {
    id: 'app.containers.sellers.SellerOrderDetail.MSG0083',
    defaultMessage: 'Nota fiscal do pedido {orderNumber} foi atualizada',
  },
  MSG0084: {
    id: 'app.containers.sellers.SellerOrderDetail.MSG0084',
    defaultMessage: 'Nota fiscal do pedido {orderNumber} foi marcada como entregue',
  },
  MSG0085: {
    id: 'app.containers.sellers.SellerOrderDetail.MSG0085',
    defaultMessage: 'Nota fiscal do pedido {orderNumber} foi excluída',
  },
  // institutions/InstitutionProfilePage/saga.js
  MSG0086: {
    id: 'app.containers.institutions.InstitutionProfilePage.MSG0086',
    defaultMessage: 'A instituição foi salva com sucesso!',
  },
  // institutions/InstitutionPartnershipManagementPage/saga.js
  MSG0087: {
    id: 'app.containers.institutions.InstitutionPartnershipManagementPage.MSG0087',
    defaultMessage: 'A parceria com o fornecedor foi solicitada com sucesso!',
  },
  MSG0088: {
    id: 'app.containers.institutions.InstitutionPartnershipManagementPage.MSG0088',
    defaultMessage: 'A parceria com o fornecedor foi atualizada com sucesso!',
  },
  // institutions/CollectionManagementPage/saga.js
  MSG0089: {
    id: 'app.containers.institutions.CollectionManagementPage.MSG0089',
    defaultMessage: 'A coleção foi criada com sucesso',
  },
  MSG0090: {
    id: 'app.containers.institutions.CollectionManagementPage.MSG0090',
    defaultMessage: 'A coleção {displayName} foi atualizada com sucesso',
  },
  MSG0091: {
    id: 'app.containers.institutions.CollectionManagementPage.MSG0091',
    defaultMessage: 'A coleção {displayName} foi excluída',
  },
  // institutions/EnrollmentManagementPage/saga.js
  MSG0092: {
    id: 'app.containers.institutions.EnrollmentManagementPage.MSG0092',
    defaultMessage: 'A matrícula foi criada com sucesso',
  },
  MSG0093: {
    id: 'app.containers.institutions.EnrollmentManagementPage.MSG0093',
    defaultMessage: 'A matrícula foi atualizada com sucesso',
  },
  // institutions/InstitutionEnrollmentRequestView/saga.js
  MSG0094: {
    id: 'app.containers.institutions.InstitutionEnrollmentRequestView.MSG0094',
    defaultMessage: 'Solicitação de matrícula confirmada',
  },
  MSG0095: {
    id: 'app.containers.institutions.InstitutionEnrollmentRequestView.MSG0095',
    defaultMessage: 'Solicitação de matrícula aprovada',
  },
  MSG0096: {
    id: 'app.containers.institutions.InstitutionEnrollmentRequestView.MSG0096',
    defaultMessage: 'Solicitação de matrícula rejeitada',
  },
  // institutions/InstitutionOrderManagementPage/saga.js
  MSG0097: {
    id: 'app.containers.institutions.InstitutionOrderManagementPage.MSG0097',
    defaultMessage:
      'Recebemos o seu pedido de exportação. Você será notificado por e-mail assim que o arquivo estiver disponível',
  },
  // families/FamilyEnrollmentView/saga.js
  MSG0098: {
    id: 'app.containers.families.FamilyEnrollmentView.MSG0098',
    defaultMessage: 'Solicitação foi aceita com sucesso!',
  },
  MSG0099: {
    id: 'app.containers.families.FamilyEnrollmentView.MSG0099',
    defaultMessage: 'Solicitação foi rejeitada com sucesso!',
  },
  MSG0134: {
    id: 'app.containers.families.FamilyEnrollmentView.MSG0134',
    defaultMessage:
      'A solicitação de matrícula foi recebida com sucesso. Aguarde o prazo informado pela instituição para que possa avaliar sua solicitação antes de liberar seu acesso a loja',
  },
  // integrations/EskolareApplicationIntegration/saga.js
  MSG0100: {
    id: 'app.containers.sellers.EskolareApplicationIntegration.MSG0100',
    defaultMessage: 'Integração instalada com sucesso!',
  },
  MSG0101: {
    id: 'app.containers.sellers.EskolareApplicationIntegration.MSG0101',
    defaultMessage: 'Integração atualizada com sucesso!',
  },
  // sellers/ProductSuggestionsPage/saga.js
  MSG0102: {
    id: 'app.containers.sellers.ProductSuggestionsPage.MSG0102',
    defaultMessage: 'Sugestão aceita com sucesso!',
  },
  MSG0103: {
    id: 'app.containers.sellers.ProductSuggestionsPage.MSG0103',
    defaultMessage: 'Sugestão rejeitada com sucesso!',
  },
  MSG0104: {
    id: 'app.containers.sellers.ProductSuggestionsPage.MSG0104',
    defaultMessage: 'Sugestão atualizada com sucesso!',
  },
  // sellers/ContractDetailPage/saga.js
  MSG0105: {
    id: 'app.containers.sellers.ContractDetailPage.MSG0105',
    defaultMessage:
      'Sua solicitação de cancelamento do contrato foi enviada com sucesso, aguarde o retorno de nossa equipe',
  },
  MSG0107: {
    id: 'app.containers.sellers.ContractDetailPage.MSG0107',
    defaultMessage: 'A solicitação de cancelamento do contrato foi feita com sucesso!',
  },
  // sellers/SellerContractPaymentWidget/saga.js
  MSG0108: {
    id: 'app.containers.sellers.SellerContractPaymentWidget.MSG0108',
    defaultMessage: 'Alteração da cobrança {code} feita com sucesso!',
  },
  MSG0109: {
    id: 'app.containers.sellers.SellerContractPaymentWidget.MSG0109',
    defaultMessage: 'Cobrança {code} foi criada com sucesso!',
  },
  // landing/ContactForm/saga.js
  MSG0110: {
    id: 'app.containers.landing.ContactForm.MSG0110',
    defaultMessage:
      'Sua mensagem foi enviada com sucesso! Obrigado pelo contato e aguarde nossa resposta!',
  },
  // families/PaymentMethodSelector/saga.js
  MSG0111: {
    id: 'app.containers.families.PaymentMethodSelector.MSG0111',
    defaultMessage: 'Cartão de crédito salvo!',
  },
  // common/CartProvider/saga.js
  MSG0112: {
    id: 'app.containers.families.CartProvider.MSG0112',
    defaultMessage: 'Obrigado! Já registramos seu interesse no {title}',
  },
  MSG0116: {
    id: 'app.containers.families.CartProvider.MSG0116',
    defaultMessage: '{title} foi adicionado ao carrinho!',
  },
  MSG0117: {
    id: 'app.containers.families.CartProvider.MSG0117',
    defaultMessage: '{title} foi removido do carrinho!',
  },
  // containers/sellers/SellerPaymentView/saga.js
  MSG0115: {
    id: 'app.containers.sellers.SellerPaymentView.MSG0115',
    defaultMessage:
      'Recebemos o seu pedido de exportação. Você será notificado por e-mail assim que o arquivo estiver disponível',
  },
  MSG0141: {
    id: 'app.containers.sellers.SellerPaymentView.MSG0141',
    defaultMessage: 'Notificação enviada com sucesso para {email}!',
  },
  // power-ups/SellerPowerUpSetting/saga.js
  MSG0118: {
    id: 'app.containers.powerups.SellerPowerUpSetting.MSG0118',
    defaultMessage: 'Power-Up instalado com sucesso!',
  },
  MSG0119: {
    id: 'app.containers.powerups.SellerPowerUpSetting.MSG0119',
    defaultMessage: 'Power-Up atualizado com sucesso!',
  },
  // sellers/SellerProductAlertList/saga.js
  MSG0120: {
    id: 'app.containers.sellers.SellerProductAlertList.MSG0120',
    defaultMessage:
      'Estamos trabalhando no processamento do seu relatório de notificações de interesse, aguarde alguns minutos que iremos encaminhar em seu e-mail',
  },
  // sellers/SellerProductOptionValueList/saga.js
  MSG0121: {
    id: 'app.containers.sellers.SellerProductOptionValueList.MSG0121',
    defaultMessage: 'O complemento {name} foi criado com sucesso!',
  },
  MSG0122: {
    id: 'app.containers.sellers.SellerProductOptionValueList.MSG0122',
    defaultMessage: 'O complemento {name} foi editado com sucesso!',
  },
  MSG0123: {
    id: 'app.containers.sellers.SellerProductOptionValueList.MSG0123',
    defaultMessage: 'O complemento {name} foi excluído com sucesso!',
  },
  // sellers/SellerProductOptionValidatorList/saga.js
  MSG0124: {
    id: 'app.containers.sellers.SellerProductOptionValidatorList.MSG0124',
    defaultMessage: 'O validador foi criado com sucesso!',
  },
  MSG0125: {
    id: 'app.containers.sellers.SellerProductOptionValidatorList.MSG0125',
    defaultMessage: 'O validador foi editado com sucesso!',
  },
  MSG0126: {
    id: 'app.containers.sellers.SellerProductOptionValidatorList.MSG0126',
    defaultMessage: 'O validador foi excluído com sucesso!',
  },
  // pages/institutions/InstitutionPublicPage/saga.js
  MSG0137: {
    id: 'app.pages.institutions.InstitutionPublicPage.MSG0137',
    defaultMessage:
      'Sua solicitação de matrícula foi enviada com sucesso! Aguarde a liberação por parte da {displayName} para que possa acessar a vitrine',
  },
  // pages/institutions/InstitutionCollectionPage/saga.js
  MSG0138: {
    id: 'app.containers.institutions.CollectionManagementPage.MSG0138',
    defaultMessage: 'Não foi possível criar a coleção',
  },
  // pages/institutions/InstitutionCollectionPage/saga.js
  // pages/institutions/InstitutionCollectionDetailsPage/saga.js
  MSG0139: {
    id: 'app.containers.institutions.CollectionManagementPage.MSG0139',
    defaultMessage: 'Não foi possível atualizar a coleção {displayName}',
  },
  // pages/institutions/InstitutionCollectionPage/saga.js
  MSG0140: {
    id: 'app.containers.institutions.CollectionManagementPage.MSG0140',
    defaultMessage: 'Não foi possível remover a coleção {displayName}',
  },
});
