import { scrollHandler } from 'utils';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';

import { Grid, Mui, SwipeableViews } from '../..';

export const useStyles = Mui.makeStyles(theme => ({
  feature: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    justifyContent: 'flex-start',
  },
  swipeableViewContainer: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  viewerImage: {
    objectFit: 'contain',
    objectPosition: 'center',
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
  },
  featuresContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  featureImage: {
    maxWidth: 30,
    marginRight: theme.spacing(1),
  },
}));

function FeatureExplorer(props) {
  const { features } = props;

  const classes = useStyles();
  const [active, setActive] = useState(0);
  const viewRef = useRef(null);
  const scrollToRef = () => scrollHandler(viewRef.current.offsetTop - 210);

  return (
    <Grid container justifyContent="center" spacing={8} alignItems="center">
      <Grid item md={8} xs={12}>
        <Mui.Box ref={viewRef}>
          <SwipeableViews
            onChangeIndex={newIndex => setActive(newIndex)}
            index={active}
            enableMouseEvents
            className={classes.swipeableViewContainer}>
            {features.map((feature, index) => (
              <div key={`${index.toString()}`}>
                {Math.abs(active - index) <= 2 ? (
                  <img
                    className={classes.viewerImage}
                    src={feature.helperImage}
                    alt={feature.body}
                  />
                ) : null}
              </div>
            ))}
          </SwipeableViews>
        </Mui.Box>
      </Grid>
      <Grid item md={4} xs={12} className={classes.featuresContainer}>
        {features.map((feature, index) => (
          <Feature
            key={`${index.toString()}`}
            {...feature}
            index={index}
            selected={active === index}
            onChange={() => {
              scrollToRef();
              setActive(index);
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
}

FeatureExplorer.propTypes = {
  features: PropTypes.any,
};

function Feature(props) {
  const { selected, body, onChange, icon } = props;

  const classes = useStyles();

  if (selected) {
    return (
      <Mui.Box mb={1}>
        <Mui.Grow in>
          <Mui.Paper elevation={10} onClick={onChange} className={classes.feature}>
            <img src={icon} alt={body} className={classes.featureImage} />
            <Mui.Typography variant="body2" color="textPrimary">
              {body}
            </Mui.Typography>
          </Mui.Paper>
        </Mui.Grow>
      </Mui.Box>
    );
  }

  return (
    <Mui.Box mb={1}>
      <Mui.Button onClick={onChange} className={classes.feature}>
        <img src={icon} alt={body} className={classes.featureImage} />
        <Mui.Typography variant="body2" color="textPrimary" align="left">
          {body}
        </Mui.Typography>
      </Mui.Button>
    </Mui.Box>
  );
}

Feature.propTypes = {
  selected: PropTypes.any,
  body: PropTypes.any,
  onChange: PropTypes.any,
  icon: PropTypes.any,
};

export default FeatureExplorer;
