import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

// Components
import * as images from 'images';
import { version } from 'version';
import { Mui } from 'components';

function Footer() {
  const options = (
    <Mui.Box pl={2} pr={2}>
      <Mui.Grid container spacing={2} wrap="nowrap">
        <Mui.Grid item sm="auto" xs={4}>
          <Mui.Link
            component={RouterLink}
            to="/terms"
            target="_blank"
            color="textSecondary"
            variant="body1">
            Termo de Uso
          </Mui.Link>
        </Mui.Grid>
        <Mui.Grid item sm="auto" xs={4}>
          <Mui.Link
            component={RouterLink}
            to="/privacy"
            target="_blank"
            color="textSecondary"
            variant="body1">
            Política de Privacidade
          </Mui.Link>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Box>
  );

  return (
    <Mui.Box mb={1} width="100%">
      <Mui.Box mb={1}>
        <Mui.Divider />
      </Mui.Box>
      <Mui.Hidden smUp>
        <Mui.Box mt={2} mb={2}>
          {options}
        </Mui.Box>
        <Mui.Box mb={3}>
          <center>
            <Mui.Tooltip title={`Versão ${version}`}>
              <a href="/" target="_blank">
                <img alt="Eskolare" height="24px" src={images.logoHorizontalGrey} />
              </a>
            </Mui.Tooltip>
          </center>
        </Mui.Box>
      </Mui.Hidden>
      <Mui.Hidden xsDown>
        <Mui.Box p={2} display="flex" justifyContent="space-between" alignItems="center">
          {options}
          <Mui.Tooltip title={`Versão ${version}`}>
            <a href="/" target="_blank">
              <img alt="Eskolare" height="24px" src={images.logoHorizontalGrey} />
            </a>
          </Mui.Tooltip>
        </Mui.Box>
      </Mui.Hidden>
    </Mui.Box>
  );
}

export default Footer;
