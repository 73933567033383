const PRODUCT_FIELDS = ['title', 'upc', 'subtitle', 'brand'];
const ITEM_FIELDS = ['displayName'];

export function clean(text = '') {
  if (!text) return '';
  const lowercase = text.toLowerCase();

  return lowercase.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function containsSearchInput(key, object, inputCleaned) {
  const field = object[key];

  return clean(field).includes(inputCleaned);
}

export function searchProductFilter(inputDirty, product = {}) {
  const inputCleaned = clean(inputDirty);
  const hasAnyMatch = PRODUCT_FIELDS.filter(key => containsSearchInput(key, product, inputCleaned));

  return hasAnyMatch.length > 0;
}

export function searchItemFilter(inputDirty, item = {}) {
  const inputCleaned = clean(inputDirty);
  const hasAnyMatch = ITEM_FIELDS.filter(key => containsSearchInput(key, item, inputCleaned));
  const { products, isEmpty } = item;

  if (isEmpty && !inputCleaned) return true;

  if (products) {
    const hasProducts = item.products.filter(product => {
      if (product.parent) return searchProductFilter(inputCleaned, product.parent);
      return searchProductFilter(inputCleaned, product);
    });

    return hasProducts.length > 0;
  }

  return hasAnyMatch.length > 0;
}
