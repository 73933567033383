/*
 *
 * CartProvider saga
 *
 */

import { put, select, takeLatest, takeEvery, all } from 'redux-saga/effects';
import axios from 'axios';
import React from 'react';

import { temporaryBasket } from './utils';
import * as ducks from './ducks';
import * as notifications from '../SnackbarProvider/ducks';
import envs from '../../../envs';

// API's

const domain = `${envs.PROTOCOL}://${envs.ENTRYPOINT}/api/families`;

export const endpoints = {
  clean: () => `${domain}/basket/clean/`,
  retrieve: () => `${domain}/basket/`,
  add: enrollmentSlug => `${domain}/basket/${enrollmentSlug}/item/`,
  handle: (enrollmentSlug, lineUid) => `${domain}/basket/${enrollmentSlug}/item/${lineUid}/`,
  remove: (enrollmentSlug, lineUid) => `${domain}/basket/${enrollmentSlug}/item/${lineUid}/`,
  update: () => `${domain}/basket/`,
  status: enrollmentSlug => `${domain}/basket/${enrollmentSlug}/status/`,
  addVoucher: () => `${domain}/basket/voucher/`,
  removeVoucher: () => `${domain}/basket/voucher/`,
  productAlert: (enrollmentSlug, productSlug) =>
    `${domain}/enrollments/${enrollmentSlug}/products/${productSlug}/alert/`,
};

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#3f2f0ebc-34f1-4d35-a66e-6fa46018e29b
 */
export function clean({ studentUid }) {
  const options = {
    method: 'post',
    url: endpoints.clean(),
    data: {
      studentUid,
    },
  };
  return axios(options);
}

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#82934f8f-a516-425b-8316-93c537d46147
 */
export function retrieve() {
  const options = {
    method: 'get',
    url: endpoints.retrieve(),
  };
  return axios(options);
}

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#2237c057-ef4c-46b5-ab2c-c5637c20864b
 */
export function add({ enrollmentSlug, productUid, collectionSlug, quantity, options }) {
  const payload = {
    method: 'post',
    url: endpoints.add(enrollmentSlug),
    data: {
      product: productUid,
      collection: collectionSlug,
      quantity,
      options,
    },
  };
  return axios(payload);
}

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#2237c057-ef4c-46b5-ab2c-c5637c20864b
 */
export function handle({ enrollmentSlug, lineUid, quantity }) {
  const options = {
    method: 'patch',
    url: endpoints.handle(enrollmentSlug, lineUid),
    data: {
      quantity,
    },
  };
  return axios(options);
}

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#b2a5d617-8e8b-4afd-b273-f235590c26af
 */
export function remove({ enrollmentSlug, lineUid }) {
  const options = {
    method: 'delete',
    url: endpoints.remove(enrollmentSlug, lineUid),
  };
  return axios(options);
}

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#68a5993b-5e17-4121-a44a-71ef1baf3653
 */
export function update({ postcode }) {
  const options = {
    method: 'patch',
    url: endpoints.update(),
    data: {
      postcode,
    },
  };
  return axios(options);
}

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#ebf8e538-7035-453c-ae51-3de266018d24
 */
export function status({ enrollmentSlug }) {
  const options = {
    method: 'get',
    url: endpoints.status(enrollmentSlug),
  };
  return axios(options);
}

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#85e51fd8-3dbd-47a5-bd3e-fe00e18a83f5
 */
export function addVoucher({ voucherCode }) {
  const options = {
    method: 'post',
    url: endpoints.addVoucher(),
    data: {
      voucherCode,
    },
  };
  return axios(options);
}

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#a0caa242-9090-48e9-b5cf-c950e5ed6ea5
 */
export function removeVoucher({ voucherCode }) {
  const options = {
    method: 'delete',
    url: endpoints.removeVoucher(),
    data: {
      voucherCode,
    },
  };
  return axios(options);
}

/**
 * Documentation: https://eskolare.postman.co/collections/2024076-b4c9b27d-5a9d-4a78-8028-5ae10fe63a76?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#a0caa242-9090-48e9-b5cf-c950e5ed6ea5
 */
export function productAlert({ enrollmentSlug, productSlug }) {
  const options = {
    method: 'post',
    url: endpoints.productAlert(enrollmentSlug, productSlug),
  };
  return axios(options);
}

// Side-effects

export function* cleanRequestSaga(action) {
  const { studentUid } = action.payload;

  try {
    const response = yield clean({ studentUid });

    yield put(ducks.cleanSuccess({ studentUid, response: response.data }));
  } catch (error) {
    yield put(ducks.cleanFailure({ studentUid, error }));
  }
}

export function* retrieveRequestSaga() {
  try {
    const response = yield retrieve();

    yield put(ducks.retrieveSuccess({ response: response.data }));
  } catch (error) {
    yield put(ducks.retrieveFailure({ error }));
  }
}

export function* addRequestSaga(action) {
  const { enrollmentSlug, product, quantity, collectionSlug, options, callback } = action.payload;
  const { uid: productUid } = product;

  try {
    const response = yield add({ enrollmentSlug, productUid, collectionSlug, quantity, options });

    yield put(ducks.addSuccess({ productUid, product, callback, response: response.data }));
  } catch (error) {
    yield put(ducks.addFailure({ productUid, error }));
  }
}

export function* addSuccessSaga(action) {
  const { product, response, callback } = action.payload;
  const { title } = product;
  const { cartProvider } = yield select();
  const { open } = cartProvider;

  if (callback) callback(response, false);

  const values = { title: <strong>{title}</strong> };
  yield put(notifications.enqueueSnackbar({ messageId: 'MSG0116', variant: 'success', values }));

  if (open) yield put(ducks.retrieveRequest());
}

export function* handleRequestSaga(action) {
  const { enrollmentSlug, lineUid, product, quantity, callback } = action.payload;
  const { uid: productUid } = product;

  try {
    const response = yield handle({ enrollmentSlug, lineUid, quantity });

    yield put(ducks.handleSuccess({ productUid, product, callback, response: response.data }));
  } catch (error) {
    yield put(ducks.handleFailure({ productUid, error }));
  }
}

export function* handleSuccessSaga(action) {
  const { response, callback } = action.payload;
  const { cartProvider } = yield select();
  const { open } = cartProvider;

  if (callback) callback(response, false);

  if (open) yield put(ducks.retrieveRequest());
}

export function* removeRequestSaga(action) {
  const { enrollmentSlug, lineUid, product, callback } = action.payload;
  const { uid: productUid } = product;

  try {
    const response = yield remove({ enrollmentSlug, lineUid });

    yield put(
      ducks.removeSuccess({ productUid, lineUid, product, callback, response: response.data }),
    );
  } catch (error) {
    yield put(ducks.removeFailure({ productUid, error }));
  }
}

export function* removeSuccessSaga(action) {
  const { product, response, callback } = action.payload;
  const { title } = product;
  const { cartProvider } = yield select();
  const { open } = cartProvider;

  if (callback) callback(response, false);

  const values = { title: <strong>{title}</strong> };
  yield put(notifications.enqueueSnackbar({ messageId: 'MSG0117', variant: 'info', values }));

  if (open) yield put(ducks.retrieveRequest());
}

export function* updateRequestSaga(action) {
  const { postcode } = action.payload;

  try {
    const response = yield update({ postcode });

    yield put(ducks.updateSuccess({ response: response.data }));
  } catch (error) {
    yield put(ducks.updateFailure({ error }));
  }
}

export function* statusRequestSaga(action) {
  const { enrollmentSlug } = action.payload;

  try {
    const response = yield status({ enrollmentSlug });

    yield put(ducks.statusSuccess({ enrollmentSlug, response: response.data }));
  } catch (error) {
    yield put(ducks.statusFailure({ enrollmentSlug, error }));
  }
}

export function* addVoucherRequestSaga(action) {
  const { voucherCode } = action.payload;

  try {
    const response = yield addVoucher({ voucherCode });
    yield put(ducks.addVoucherSuccess({ voucherCode, response: response.data }));
  } catch (error) {
    yield put(ducks.addVoucherFailure({ voucherCode, error }));
  }
}

export function* addVoucherSuccessSaga() {
  yield put(ducks.retrieveRequest());
}

export function* removeVoucherRequestSaga(action) {
  const { voucherCode } = action.payload;

  try {
    const response = yield removeVoucher({ voucherCode });
    yield put(ducks.removeVoucherSuccess({ voucherCode, response: response.data }));
  } catch (error) {
    yield put(ducks.removeVoucherFailure({ voucherCode, error }));
  }
}

export function* removeVoucherSuccessSaga() {
  yield put(ducks.retrieveRequest());
}

export function* productAlertRequestSaga(action) {
  const { enrollmentSlug, productSlug } = action.payload;

  try {
    const response = yield productAlert({ enrollmentSlug, productSlug });

    yield put(ducks.productAlertSuccess({ enrollmentSlug, productSlug, response: response.data }));
  } catch (error) {
    yield put(ducks.productAlertFailure({ enrollmentSlug, productSlug, error }));
  }
}

export function* productAlertSuccessSaga(action) {
  let values = {};

  const {
    response: { product },
  } = action.payload;

  if (product) {
    values = { title: <strong>{product.title}</strong> };
  }

  yield put(notifications.enqueueSnackbar({ messageId: 'MSG0112', variant: 'success', values }));
}

// TODO: refactor after all checks (The reducer approach doesn't work)
export function* setTemporarySaga(action) {
  const { event, product, callback, collectionSlug } = action.payload;

  if (product) {
    const { slug, basket, title } = product;
    const { quantity } = basket;

    localStorage?.setItem(
      'temporaryBasket',
      JSON.stringify({
        ...temporaryBasket(),
        [slug]: { ...product, basket: { ...basket, collectionSlug } },
      }),
    );

    const values = { title: <strong>{title}</strong> };

    if (event === 'add' && quantity === 1) {
      yield put(
        notifications.enqueueSnackbar({ messageId: 'MSG0116', variant: 'success', values }),
      );
    } else if (event === 'remove' && quantity === 0) {
      yield put(notifications.enqueueSnackbar({ messageId: 'MSG0117', variant: 'info', values }));
    }
  }

  if (callback) callback();

  yield true;
}

export function* genericSuccessSaga(action) {
  const { response } = action.payload;
  yield put(ducks.retrieveSuccess({ response }));
}

// TODO: refactor after all checks (The reducer approach doesn't work)
export function* transferTemporarySaga(action) {
  const { enrollment, history } = action.payload;
  const { slug: enrollmentSlug } = enrollment || {};

  const { cartProvider } = yield select();

  const { temporary: temporaryReducer } = cartProvider;
  const anonymousReducer = Object.keys(temporaryReducer || []);

  const temporaryLocalStorage = temporaryBasket();
  const anonymousLocalStorage = Object.keys(temporaryLocalStorage || []);

  const anonymous = anonymousReducer.length > 0 ? anonymousReducer : anonymousLocalStorage;
  const temporary = anonymousReducer.length > 0 ? temporaryReducer : temporaryLocalStorage;

  if (anonymous.length > 0) {
    yield all(
      anonymous
        .filter(key => temporary[key].basket.quantity > 0)
        .map(key => {
          const product = temporary[key];
          const { basket } = product;
          const { quantity, options = {} } = basket;
          const { values } = options;

          const payload = {
            enrollmentSlug,
            product,
            quantity,
            options: values,
          };

          return put(ducks.addRequest(payload));
        }),
    );

    history.push('/checkout');
  }

  yield localStorage?.removeItem('temporaryBasket');
  yield all([put(ducks.clearTemporary())]);
}

// Individual exports for testing
export default function* cartProviderSaga() {
  yield takeLatest(ducks.CLEAN_REQUEST, cleanRequestSaga);
  yield takeLatest(ducks.CLEAN_SUCCESS, genericSuccessSaga);
  yield takeLatest(ducks.RETRIEVE_REQUEST, retrieveRequestSaga);
  yield takeEvery(ducks.ADD_REQUEST, addRequestSaga);
  yield takeEvery(ducks.ADD_SUCCESS, addSuccessSaga);
  yield takeEvery(ducks.HANDLE_REQUEST, handleRequestSaga);
  yield takeEvery(ducks.HANDLE_SUCCESS, handleSuccessSaga);
  yield takeEvery(ducks.REMOVE_REQUEST, removeRequestSaga);
  yield takeEvery(ducks.REMOVE_SUCCESS, removeSuccessSaga);
  yield takeLatest(ducks.UPDATE_REQUEST, updateRequestSaga);
  yield takeLatest(ducks.UPDATE_SUCCESS, genericSuccessSaga);
  yield takeLatest(ducks.STATUS_REQUEST, statusRequestSaga);
  yield takeLatest(ducks.ADD_VOUCHER_REQUEST, addVoucherRequestSaga);
  yield takeLatest(ducks.ADD_VOUCHER_SUCCESS, addVoucherSuccessSaga);
  yield takeLatest(ducks.REMOVE_VOUCHER_REQUEST, removeVoucherRequestSaga);
  yield takeLatest(ducks.REMOVE_VOUCHER_SUCCESS, removeVoucherSuccessSaga);
  yield takeLatest(ducks.PRODUCT_ALERT_REQUEST, productAlertRequestSaga);
  yield takeLatest(ducks.PRODUCT_ALERT_SUCCESS, productAlertSuccessSaga);
  yield takeLatest(ducks.SET_TEMPORARY, setTemporarySaga);
  yield takeLatest(ducks.TRANSFER_TEMPORARY, transferTemporarySaga);
}
