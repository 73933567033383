import { createSelector } from 'reselect';
import { initialState } from './ducks';

/**
 * Direct selector to the snackbarProvider state domain
 */

const selectSnackbarProviderDomain = state => state.snackbarProvider || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by SnackbarProvider
 */

const makeSelectSnackbarProvider = () =>
  createSelector(selectSnackbarProviderDomain, substate => substate);

export default makeSelectSnackbarProvider;
export { selectSnackbarProviderDomain };
