import React from 'react';
import PropTypes from 'prop-types';

import { Mui } from '../..';

export const useStyles = Mui.makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function SingleProduct(props) {
  const { children } = props;

  const classes = useStyles({});

  return (
    <Mui.Container maxWidth="lg" className={classes.root}>
      {children}
    </Mui.Container>
  );
}

SingleProduct.propTypes = {
  children: PropTypes.any,
};

export default SingleProduct;
