/*
 * PersonasTabs Messages
 *
 * This `conta`ins all the text for the PersonasTabs container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.landing.PersonasTabs';

export default defineMessages({
  personasTabsFamiliesLabel: {
    id: `${scope}.personasTabsFamiliesLabel`,
    defaultMessage: 'Famílias',
  },
  personasTabsFamiliesTitle: {
    id: `${scope}.personasTabsFamiliesTitle`,
    defaultMessage: 'Compre em poucos minutos',
  },
  personasTabsFamiliesOverline: {
    id: `${scope}.personasTabsFamiliesOverline`,
    defaultMessage: 'Conveniência, comodidade e praticidade',
  },
  personasTabsInstitutionsLabel: {
    id: `${scope}.personasTabsInstitutionsLabel`,
    defaultMessage: 'Instituições',
  },
  personasTabsInstitutionsTitle: {
    id: `${scope}.personasTabsInstitutionsTitle`,
    defaultMessage: 'Gestão de vendas e fornecedores em um único lugar',
  },
  personasTabsInstitutionsOverline: {
    id: `${scope}.personasTabsInstitutionsOverline`,
    defaultMessage: 'Gestão centralizada do seu marketplace',
  },
  personasTabsSellersLabel: {
    id: `${scope}.personasTabsSellersLabel`,
    defaultMessage: 'Fornecedores',
  },
  personasTabsSellersTitle: {
    id: `${scope}.personasTabsSellersTitle`,
    defaultMessage: 'Venda diretamente para as famílias em poucos minutos',
  },
  personasTabsSellersOverline: {
    id: `${scope}.personasTabsSellersOverline`,
    defaultMessage: 'Venda online de forma rápida e integrada',
  },
});
