import PropTypes from 'prop-types';
import React from 'react';

import MuiButton from '@material-ui/core/Button';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

function Button({ loading, onClick, startIcon, endIcon, ...muiProps }) {
  const defaultProps = {
    variant: 'contained',
    color: 'primary',
    disableElevation: true,
  };

  return loading ? (
    <MuiButton {...defaultProps} {...muiProps}>
      <MuiCircularProgress color="inherit" size={22} />
    </MuiButton>
  ) : (
    <MuiButton
      {...defaultProps}
      endIcon={endIcon}
      startIcon={startIcon}
      onClick={onClick}
      {...muiProps}
    />
  );
}

Button.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.any,
  endIcon: PropTypes.any,
};

export default Button;
