import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import * as userProviderDucks from '../UserProvider/ducks';

export function* socialConnectRequestSaga(action) {
  const { provider, accessToken, callback } = action.payload;
  try {
    const response = yield call(api.socialConnect, { provider, accessToken });

    yield put(actions.socialConnectSuccess({ callback, response: response.data }));
  } catch (error) {
    yield put(actions.socialConnectFailure({ error }));
  }
}

export function* socialConnectSuccessSaga(action) {
  const { callback, response } = action.payload;

  yield put(userProviderDucks.loginSuccess({ callback, response }));
}

// Individual exports for testing
export default function* socialAuthenticationSaga() {
  yield takeLatest(constants.SOCIAL_CONNECT_REQUEST, socialConnectRequestSaga);
  yield takeLatest(constants.SOCIAL_CONNECT_SUCCESS, socialConnectSuccessSaga);
}
