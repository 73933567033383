import { FormattedMessage } from 'react-intl';
import { useInView } from 'react-intersection-observer';
import * as images from 'images';
import * as React from 'react';

// Components
import { Grid, Mui, Typography } from 'components';

import messages from './messages';

export const useStyles = Mui.makeStyles(() => ({
  root: {
    paddingTop: 128,
    paddingBottom: 128,
  },
  icon: {
    width: 32,
  },
}));

function ReasonsToChoose() {
  // Hooks
  const classes = useStyles();
  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));
  const [inViewRef, inView] = useInView({ threshold: 0.5 });

  const items = [
    {
      icon: images.iconsShoppingCart,
      title: <FormattedMessage {...messages.reasonsToChooseReason1} />,
      body: <FormattedMessage {...messages.reasonsToChooseReason1Description} />,
    },
    {
      icon: images.iconsSecure,
      title: <FormattedMessage {...messages.reasonsToChooseReason2} />,
      body: <FormattedMessage {...messages.reasonsToChooseReason2Description} />,
    },
    {
      icon: images.iconsMoney,
      title: <FormattedMessage {...messages.reasonsToChooseReason3} />,
      body: <FormattedMessage {...messages.reasonsToChooseReason3Description} />,
    },
    {
      icon: images.iconsSupport,
      title: <FormattedMessage {...messages.reasonsToChooseReason4} />,
      body: <FormattedMessage {...messages.reasonsToChooseReason4Description} />,
    },
    {
      icon: images.iconsLock,
      title: <FormattedMessage {...messages.reasonsToChooseReason5} />,
      body: <FormattedMessage {...messages.reasonsToChooseReason5Description} />,
    },
    {
      icon: images.iconsShippingTruck,
      title: <FormattedMessage {...messages.reasonsToChooseReason6} />,
      body: <FormattedMessage {...messages.reasonsToChooseReason6Description} />,
    },
  ];

  return (
    <div className={classes.root} ref={inViewRef}>
      <Mui.Container maxWidth="lg">
        <Typography component="p" variant="overline" color="primary" align="center">
          <FormattedMessage {...messages.reasonsToChooseSectionOverline} />
        </Typography>
        <Typography variant="h1" align="center" color="textPrimary">
          <FormattedMessage {...messages.reasonsToChooseSectionTitle} />
        </Typography>
        <Mui.Box mt={8}>
          <Grid container justifyContent="center" spacing={!fullScreen ? 5 : 0}>
            {items.map((item, index) => (
              <Grid key={`${index.toString()}`} item lg={4} md={6} xs={12}>
                <Mui.Grow
                  in={inView}
                  timeout={{
                    appear: 750 * (index + 1),
                    enter: 300 * (index + 1),
                    exit: 750 * (index + 1),
                  }}>
                  <Mui.Box mb={1}>
                    <Grid container spacing={2}>
                      <Grid item sm={3} xs={12}>
                        <center>
                          <img src={item.icon} className={classes.icon} alt={index} />
                        </center>
                      </Grid>
                      <Grid item sm={9} xs={12}>
                        <Typography variant="h4" gutterBottom color="textPrimary">
                          {item.title}
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {item.body}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Mui.Box>
                </Mui.Grow>
              </Grid>
            ))}
          </Grid>
        </Mui.Box>
      </Mui.Container>
    </div>
  );
}

export default ReasonsToChoose;
