/*
 * CartProvider Messages
 *
 * This contains all the text for the CartProvider container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.families.CartProvider';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Seu carrinho',
  },
});
