import { useEffect } from 'react';

const useScript = ({ url, id, permanent = false, disabled = false }) => {
  useEffect(() => {
    if (!disabled) {
      const script = document.getElementById(id);

      if (!script) {
        const newScript = document.createElement('script');

        newScript.id = id;
        newScript.src = url;
        newScript.async = true;

        if (document.body) document.body.appendChild(newScript);
      }
    }

    return () => {
      if (!disabled && !permanent) {
        const newScript = document.getElementById(id);

        if (newScript && document.body) document.body.removeChild(newScript);
      }
    };
  }, [disabled, url, id, permanent]);
};

export default useScript;
