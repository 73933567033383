import { loadable } from '../../../utils';

export const Card = loadable(() => import('./Card'), { type: 'box' });
export const Display = loadable(() => import('./Display'), { type: 'box' });
export const Alert = loadable(() => import('./Alert'), { type: 'button' });
export const BuyBox = loadable(() => import('./BuyBox'), { type: 'box' });
export const BuyButton = loadable(() => import('./BuyButton'), { type: 'box' });
export const Description = loadable(() => import('./Description'), { type: 'text' });
export const Header = loadable(() => import('./Header'), { type: 'header' });
export const Enrollment = loadable(() => import('./Enrollment'), { type: 'box' });
export const Image = loadable(() => import('./Image'), { type: 'image' });
export const Information = loadable(() => import('./Information'), { type: 'text' });
export const PriceLabel = loadable(() => import('./PriceLabel'), { type: 'text' });
export const Review = loadable(() => import('./Review'), { type: 'list' });
export const PaymentOption = loadable(() => import('./PaymentOption'), { type: 'box' });
export const SoldBy = loadable(() => import('./SoldBy'), { type: 'box' });
export const ShowcaseProductCard = loadable(() => import('./ShowcaseProductCard'), { type: 'box' });
export const ShowcaseProductListItem = loadable(() => import('./ShowcaseProductListItem'), {
  type: 'box',
});
export const VariantSelect = loadable(() => import('./VariantSelect'), { type: 'select' });
export const View = loadable(() => import('./View'), { type: 'view' });
