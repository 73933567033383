import { loadable } from 'utils';

export const AggregatedCommission = loadable(() => import('./AggregatedCommission'), {});
export const Create = loadable(() => import('./Create'), {});
export const Commission = loadable(() => import('./Commission'), {});
export const CommissionAccount = loadable(() => import('./CommissionAccount'), {});
export const Grade = loadable(() => import('./Grade'), {});
export const ImportWizard = loadable(() => import('./ImportWizard'), {});
export const Partner = loadable(() => import('./Partner'), {});
export const Product = loadable(() => import('./Product'), {});
export const Replicate = loadable(() => import('./Replicate'), {});
export const StoreSetup = loadable(() => import('./StoreSetup'), {});
export const Update = loadable(() => import('./Update'), {});
