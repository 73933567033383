import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Mui } from '../..';
import * as DisplayData from '../DisplayData';
import messages from './messages';
import * as images from '../../../images';

const useStyles = Mui.makeStyles(theme => ({
  fab: {
    zIndex: 1100,
    position: 'fixed',
    bottom: theme.spacing(9),
    right: 20,
  },
}));

function FloatUser(props) {
  const classes = useStyles();

  const theme = Mui.useTheme();
  const fullScreen = Mui.useMediaQuery(theme.breakpoints.down('sm'));

  const { onClick, count, picture } = props;

  return (
    <Mui.Tooltip placement="top" title={<FormattedMessage {...messages.cartLabel} />}>
      <Mui.Fab
        color="primary"
        size={fullScreen ? 'medium' : 'large'}
        className={classes.fab}
        onClick={onClick}>
        <Mui.Badge color="error" badgeContent={count}>
          <DisplayData.Picture src={picture || images.facesOtherFace} />
        </Mui.Badge>
      </Mui.Fab>
    </Mui.Tooltip>
  );
}

FloatUser.propTypes = {
  onClick: PropTypes.func,
  count: PropTypes.number,
  picture: PropTypes.any,
};

export default FloatUser;
